import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {CategoryOptionsFilter,  ComputeOptionsRequest,} from '../api';
import {
  AssemblyOption,
  AXIOS_CANCEL_MSG,
  Page
} from '../api/models';
import { ConfiguratorContext } from '../context';
import {SorterResult} from "antd/es/table/interface";
import {SelectionInfo} from "../components/Quote/AssemblySelectionTable";


interface CategoryOptionsProps {
  modelId:number | undefined,
  categoryId:number | undefined,
  options?:ComputeOptionsRequest
  filter?: CategoryOptionsFilter
  current?: number
  pageSize?:number,
  sorter?: SorterResult<SelectionInfo> | SorterResult<SelectionInfo>[];
}

export const useCategoryOptionsPage = (props: CategoryOptionsProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:CategoryOptionsProps  ) : Promise<Page<AssemblyOption> | undefined> => {

    const { modelId, categoryId, options, filter, current, pageSize, sorter } = props;
    if (!categoryId) return;
    if (!modelId) return;

    const sort = [ sorter ].flatMap(v => v)
        .filter( v => v?.columnKey !== undefined )
        .map( v => ([
          v?.columnKey?.toString(),
          v?.order === 'descend' ? 'desc' : 'asc',
        ].join(',')))


    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchCategoryOptionsPage(modelId, categoryId, {
        options,
        filter,
        page: {
          size: pageSize,
          page: current,
          sort
        }
      },  cancelSource.token );

      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'fetchCategoryOptionsPage',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useCategoryOptionsPage;

