import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {ListTruckRequest} from '../api';
import {
  AXIOS_CANCEL_MSG,
  ListMasterScheduleResponse,
} from '../api/models';
import { ConfiguratorContext } from '../context';

type MasterScheduleProps = ListTruckRequest;

export const useMasterSchedule = (props: MasterScheduleProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:MasterScheduleProps  ) : Promise<ListMasterScheduleResponse | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.listMasterSchedule(props, cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      return resp;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }

  // Use SWR for data fetching
  return useSWR([
    'listMasterSchedule',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useMasterSchedule;

