import {Input, Modal, notification, Result, Space} from "antd";
import {useContext, useEffect, useState} from "react";
import BMButton, {BMButtonProps} from "../BMButton";
import {Quote} from "../../api/models";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import {useQuoteContext} from "../../contexts/QuoteContext";

const AddPricingHistoryButton = (props:Omit<BMButtonProps, 'onChange'> & {
    onChange?: () => void
} ) => {

    const [isOpen, setIsOpen] = useState(false);

    const { onChange:a, ...btnProps} = props;

    const intl = useIntl();
    const configurator = useContext(ConfiguratorContext);
    const {quote} = useQuoteContext();

    const [confirmNumber, setConfirmNum] = useState<string>();
    const [confirmTxt, setConfirmTxt] = useState<string>();
    const [validationError, setValidationError] = useState<boolean>();

    const addPricingHistory = async () : Promise<Quote|undefined> => {
        if ( !quote ) return;

        try {
            const resp = await configurator.api.addPricingHistory(quote.displayRevisionId);
            return resp.data;
        } catch (e:any) {
            const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
            const msg = "Failed to add the pricing history. " + errorMsg;

            notification.error( { message: msg });
        }

        return;
    };

    const handleClick = async (e:any) => {
        if ( confirmNumber === confirmTxt ) {
            await addPricingHistory();
            props.onChange?.();
            setIsOpen(false);
        }
        else {
            setValidationError(true);
        }
    }

    return <>
        <BMButton {...btnProps} onClick={() => setIsOpen(true)} >Add Pricing History</BMButton>
        <Modal
            open={isOpen}
            okText="Confirm"
            onOk={handleClick}
            onCancel={() => setIsOpen(false)}
            afterOpenChange={(open) => {
                if( open ) {
                    setConfirmNum( String( Math.round(1000000 * Math.random()) ));
                }
            }}
            centered
        >
            <Result
                status="warning"
                title="Add Missing Pricing History"
            >
                <Space direction={"vertical"}>
                    <div>Please type the following confirmation number:</div>
                    <div style={{fontStyle: "italic", fontWeight: "bold", textAlign: "center"}} >
                        {confirmNumber?.substring(0, 3)}&nbsp;{confirmNumber?.substring(3)}
                    </div>
                    <div><Input value={confirmTxt} onChange={(e) => {
                        setConfirmTxt(e.target.value);
                        setValidationError(false);
                    }} /></div>
                    { validationError &&
                      <div style={{color: "#ff4d4f"}} >Does not match the confirmation number.</div>}
                </Space>
            </Result>
        </Modal>
    </>
}

export default AddPricingHistoryButton;
