import axios, {CancelTokenSource} from 'axios';
import {useCallback, useContext, useRef} from 'react';
import useSWR from 'swr';
import {AXIOS_CANCEL_MSG, QuoteReview} from '../api/models';
import { ConfiguratorContext } from '../context';
import {QuoteReviewOptions} from "../api";

interface QuoteReviewProps {
  quoteRevisionId:number | undefined
  options: QuoteReviewOptions
}

export const useQuoteReview = (props: QuoteReviewProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:QuoteReviewProps  ) : Promise<QuoteReview | undefined> => {

    const { quoteRevisionId, options } = props;
    if (!quoteRevisionId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, options, cancelSource.token );
      cancelTokenSourceRef.current = undefined;
      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
        'fetchQuoteReview',
        props
      ],
      ([_k, p]) => fetcher(p),
      {
        shouldRetryOnError: true,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: false,
      dedupingInterval:1
    }
  );
};


export default useQuoteReview;

