import axios, {CancelTokenSource} from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {
  AXIOS_CANCEL_MSG,
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface SelectCustomOptionPriceDifferenceProps {
  customOptionId:number | undefined,
  options: { selectedModelId?: number | undefined,
    selections?: number[],
    customOptions?: number[],
    quoteRevisionId?: number,
    percentDiscount?: number,
    debugRules?: boolean,
  },
}

export const useSelectCustomOptionPriceDifference = (props: SelectCustomOptionPriceDifferenceProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:SelectCustomOptionPriceDifferenceProps  ) : Promise<number | undefined> => {

    const { customOptionId, options } = props;
    if (!customOptionId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchSelectCustomOptionPriceDifference(customOptionId, options, cancelSource.token );

      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'fetchSelectCustomOptionPriceDifference',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useSelectCustomOptionPriceDifference;

