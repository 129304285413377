import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { useIntl } from "react-intl";
import { Button, Dropdown, Form, Input, MenuProps, Modal, Row, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { TableFilter } from "../../api/models";

const SaveFilter = (props: {tableName: string, size?: SizeType, style?: any}) => {

  const [filterName, setFilterName] = useState<string | undefined>(undefined);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [tableFilters, setTableFilters] = useState<TableFilter[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const getPreference = async () => {
    try {
      const resp = await configurator.api.getTableFilters();
      setTableFilters(resp.data.filter(q => q.tableName === props.tableName));
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to get all filters. " + errorMsg });
    }
  }

  useEffect(() => {
    getPreference();
  }, []);

  const saveTableFilter = async () => {
    const obj: TableFilter = {
      tableName: props.tableName,
      queryName: filterName,
      query: window.location.href,
    };

    try {
      await configurator.api.saveTableFilter(obj);
      getPreference();
      setShowFilterModal(false)
      notification.success({message: "Successfully save filter."})
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save filter. " + errorMsg });
    }
  }

  const onCancel = () => {
    setShowFilterModal(false);
    setFilterName(undefined);
  }

  const onDelete = async (f: TableFilter) => {

    if (!f.id) return;

    try {
      await configurator.api.deleteTableFilter(f.id);
      notification.success({message: "Successfully delete filter."})
      getPreference();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to delete filter. " + errorMsg });
    }
  }

  const goToQuery = async (f: TableFilter) => {
    if (f.query) window.location.href = f.query
  }


  const dropdownItems: MenuProps['items'] = tableFilters.map((f: TableFilter, i: number) => {return {
    key: f.queryName + "-" + i,
    label: 
    <Row justify={"space-between"}>
      <Button style={{border: "none"}}
        type="text"
        onClick={() => goToQuery(f)}
        size={props.size}
      >
        {f.queryName}
      </Button>
      <Button 
        type="text"
        shape="circle"
        className="cancel-button"
        size={props.size}
        icon={<CloseOutlined />}
        onClick={() => {onDelete(f)}}
      />
    </Row>,
  }});


  return (
    <>
      <style>
        {`
          .custom-dropdown {
          }

          .custom-dropdown .ant-btn {
            border: 1px solid #1677ff !important;
            color: #1677ff !important;
          }

          .custom-dropdown .ant-btn:hover {
            color: white !important;
            background-color: #1677ff !important;
          }

          .custom-dropdown .ant-btn:last-child {
            border-left: none;
          }

          .custom-dropdown .ant-dropdown-menu-item-active {
            background-color: white !important;
          }

          .cancel-button:hover {
            color: #1677ff !important;
          }

          .cancel-button {
            color: red !important;
          }

          .save-filter-button {
            border: 1px solid #1677ff !important;
            color: #1677ff !important;
          }

          .save-filter-button:hover {
            color: white !important;
            background-color: #1677ff !important;
          }
        `}
      </style>
      {!tableFilters.length
          ? <Button onClick={() => setShowFilterModal(true)} size={props.size} style={props.style} className="save-filter-button">Save Filter</Button>
          : <div style={props.style}>
            <Dropdown.Button
                menu={{items:dropdownItems}}
                size={props.size}
                onClick={() => setShowFilterModal(true)}
                rootClassName="custom-dropdown"
            >
              Save Filter
            </Dropdown.Button>
          </div> }
      <Modal
        title="Save Filter"
        onOk={saveTableFilter}
        okText="save"
        cancelButtonProps={{style: {display: "none"}}}
        open={showFilterModal}
        onCancel={onCancel}
      >
        <Form.Item>
          <Input value={filterName} onChange={(e) => setFilterName(e.target.value)}/>
        </Form.Item>
      </Modal>
    </>
  );

}

export default SaveFilter;
