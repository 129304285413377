import { Button, Checkbox, Col, Dropdown, Form, Input, Modal, notification, Result, Row, Table, Upload } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfiguratorContext } from "../context";
import { Category, OPERATION_TYPE, Permission } from "../api/models";
import {
  UploadOutlined,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import Utils, {ExportableColumn} from "../util/util";
import useBaseCategories from "../swr/useBaseCategories";


enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

interface ImportFailure {
  id:string, 
  message:string
}

const AssemblyCategoriesPage = () => {
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [dataFilter, setDataFilter] = useState("");
  const [isCab, setCab] = useState<boolean>(false);
  const [createCategoryForm] = Form.useForm();
  const [_category, categoryAsync] = useAsyncState<Category>();
  const [uploadFile, setUploadFile] = useState<{status:string, response?: ImportFailure[]}>({status: UPLOADING_STATUS.INIT});
  const [isOpen, setIsOpen] = useState<boolean>();
  const categories = useBaseCategories()

  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);

  useEffect(()=> {
    if( uploadFile.status == UPLOADING_STATUS.DONE ) {
      categories.mutate();
    }
  }, [ uploadFile.status] )

  const filteredCategories = categories.data?.filter((c) => c.name.toLowerCase().includes(dataFilter.toLowerCase())).filter((c) => !isCab || c.bomLocation === OPERATION_TYPE.CAB);

  const onNewCategoryFinish = async (values:Record<string, any>) => {
    categoryAsync.setLoading();
    try {
      await configurator.api.createCategory({
        name: values.name,
        categoryId: values.categoryId,
      });
      categoryAsync.setDone();
      notification.success({message:"Category created"});

      await categories.mutate();

      setIsOpen(false);
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to to save. " + errorMsg });
    }
  };


  const onClickNew = () => {
    createCategoryForm.resetFields();
    setIsOpen(true);
  };


  const handleExportCsv = async () => {
    if ( !categories ) return;

    const splitName = (name:string) : {key:string, name:string} | undefined => {
      const ar = name.split("-");
      if ( !ar.length ) return;
      if ( ar.length === 1 ) return { key: "", name: name }

      const key = parseInt( ar[0], 10 );
      if ( isNaN(key) ) return { key: "", name: name }

      return { key: ar[0], name:  ar.splice(1).join() };
    }


    const exportColumns :ExportableColumn<Category>[] = [
      {
        title: "Id",
        key: "id",
        render: (rec) => String(rec.id),
        renderCSV: (rec) => String(rec.id)
      },
      {
        title: "Sort Order",
        key: "sort",
        render: (rec) => splitName( rec.name )?.key || "",
        renderCSV: (rec) => splitName( rec.name )?.key || ""
      },
      {
        title: "Name",
        key: "name",
        render: (cat:Category) =>
            <Link style={{whiteSpace: "nowrap"}} to={"/categories/" + encodeURIComponent(cat.id)}>{cat.name}</Link>,
        renderCSV: (rec) => splitName( rec.name )?.name || ""
      },
      {
        title: "Section",
        key: "configuratorSection",
        render: (rec) => rec.configuratorSection,
        renderCSV: (rec) => rec.configuratorSection
      },
      {
        title: "Quote Section",
        key: "quoteSection",
        render: (rec) => rec.quoteSection || "",
        renderCSV: (rec) => rec.quoteSection || ""
      },
      {
        title: "Primary Engineer",
        key: "primaryEngineer",
        render: (rec) => Utils.formatUsername(rec.primaryEngineer),
        renderCSV: (rec) => rec.primaryEngineer?.name
      },
      {
        title: "Team",
        key: "engineeringTeam.name",
        render: (rec) => rec.engineeringTeam?.name,
        renderCSV: (rec) => rec.engineeringTeam?.name
      },
      {
        title: "Exclude ERP Report",
        key: "excludeERPExport",
        render: (rec) => String( rec.excludeERPExport ?? "" ),
        renderCSV: (rec) => String( rec.excludeERPExport ?? "" )
      },
      {
        title: "Allow Multiple BOM",
        key: "allowMultiple",
        render: (rec) => String( rec.allowMultiple ?? "" ),
        renderCSV: (rec) => String( rec.allowMultiple ?? "" )
      },
      {
        title: "Non-Discounted",
        key: "nonDiscounted",
        render: (rec) => String( rec.nonDiscounted ?? "" ),
        renderCSV: (rec) => String( rec.nonDiscounted ?? "" )
      },
      {
        title: "Non-Discounted Type",
        key: "nonDiscountedType",
        render: (rec) => String( rec.nonDiscountedType ?? "" ),
        renderCSV: (rec) => String( rec.nonDiscountedType ?? "" )
      },
      {
        title: "Markup",
        key: "markup",
        render: (rec) => String( rec.markup ?? "" ),
        renderCSV: (rec) => String( rec.markup ?? "" )
      },
      {
        title: "Operation Id",
        key: "operationId",
        render: (rec) => rec.operationId ?? "",
        renderCSV: (rec) => rec.operationId ?? ""
      },
      {
        title: "Tags",
        key: "tags",
        render: (rec) => rec.tags?.join(",") ?? "",
        renderCSV: (rec) => rec.tags?.join(",") ?? ""
      },
      {
        title: "Authorized Dealers",
        key: "authorizedCustomers",
        render: (rec) => rec.authorizedCustomers?.join("|") || "",
        renderCSV: (rec) => rec.authorizedCustomers?.join("|") || ""
      },
      {
        title: "Lead Time (days)",
        key: "leadTimeDays",
        render: (rec) => String(rec.leadTimeDays) || "",
        renderCSV: (rec) => String(rec.leadTimeDays) || ""
      },
      {
        title: "Design Time (weeks)",
        key: "designTimeWeeks",
        render: (rec) => String(rec.designTimeWeeks) || "",
        renderCSV: (rec) => String(rec.designTimeWeeks) || ""
      },
      {
        title: "Procurement Time (weeks)",
        key: "procurementTimeWeeks",
        render: (rec) => String(rec.procurementTimeWeeks) || "",
        renderCSV: (rec) => String(rec.procurementTimeWeeks) || ""
      },
      {
        title: "Image",
        key: "image",
        render: (rec) => rec.fullImageUrl || "",
        renderCSV: (rec) => [ rec.fullImageUrl ].filter( v=>v)
            //truncate query string
                .map( i => i?.lastIndexOf("?") )
                .map( i => i === -1 ? rec.fullImageUrl?.length : i )
                .map( i => rec.fullImageUrl?.substring(0, i) )
                .find(v=>v)
            || ""
      },
      {
        title: "Notes",
        key: "notes",
        render: (rec) => rec.notes || "",
        renderCSV: (rec) => rec.notes || ""
      },
    ];

    const resp = await configurator.api.getCategories();

    Utils.exportDataAsCSV("categoryNames", resp.data || [], exportColumns);
  }

  const onUploadChange = ({file}) => {
    setUploadFile( file );
    return file.response;
  };
  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;

  const dropdownItems = [
    {
      key: 1,
      label:<Button icon={<DownloadOutlined style={{marginRight: ".5rem"}} />}
        type="text"
        onClick={handleExportCsv} 
      >
        Export CSV
      </Button> }
  ];

  if ( canWrite ) {
    dropdownItems.push({
      key: 2,
      label: <Upload
        name="file"
        action={configurator.api.getImportAssemblyCategoryCSVUrl()}
        onChange={onUploadChange}
        withCredentials={true}
        showUploadList={false}
      >
        <Button type="text"
          icon={<UploadOutlined />}
          loading={isUploading}
        >
          Import CSV
        </Button>
      </Upload> });
  }

  return <div className="site-layout-background">
      <Title level={2}>Assembly Categories</Title>
      <Row gutter={[16, 16]} style={{marginBottom: "1rem"}}>
        <Col>
          <Dropdown trigger={["click"]}
            menu={{items:dropdownItems}}
          >
            <Button type="primary" 
              icon={<MoreOutlined />} 
            >Options</Button>
          </Dropdown>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={onClickNew}
          >
            New
          </Button>
        </Col>
      </Row>
      <Row style={{marginBottom: "1rem"}} justify={"space-between"}>
        <Input
          allowClear
          style={{ width: "200px" }}
          onChange={(e) => setDataFilter(e.target.value) }
          value={dataFilter}
          placeholder="Enter text to filter list"
        />
        <Checkbox
          style={{marginBottom: "7px"}}
          onChange={() => setCab(!isCab)}
        >
          {'CAB'}
        </Checkbox>
      </Row>
      <Table
        loading={categories.isLoading}
        bordered
        rowKey="id"
        columns={[
          {
            title: "Name",
            key: "name",
            render: (cat:Category) =>
                <Link style={{whiteSpace: "nowrap"}} to={"/categories/" + encodeURIComponent(cat.id)}>{cat.name}</Link>,
          }
        ]}
        dataSource={filteredCategories}
        scroll={{x: "auto"}}
      />
      <Modal
        onCancel={() => setIsOpen(false)}
        okButtonProps={{ disabled: categoryAsync.isLoading() }}
        title="New Category"
        open={isOpen}
        onOk={() =>createCategoryForm.submit()}
      >
        <Form
          labelCol={{ span: 8 }}
          form={createCategoryForm}
          name="newCategory"
          onFinish={onNewCategoryFinish}
        >
          <Form.Item
            label="Category Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Category ID"
            name="categoryId"
            rules={[{ required: true, message: "Category ID is required" }]}
          >
            <Input placeholder="ID for this category, letters and underscores only" />
          </Form.Item>
        </Form>
      </Modal>
    <Modal 
      open={uploadFile.status == UPLOADING_STATUS.DONE || uploadFile.status === UPLOADING_STATUS.ERROR}
      onOk={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      onCancel={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      cancelButtonProps={{style :{display : 'none'}}}
    >
      <Result
        status={uploadFile.status == UPLOADING_STATUS.DONE ? "success" : "error"}
        title={uploadFile.status == UPLOADING_STATUS.DONE ? "Upload Successful" : "Error"}
        subTitle={ !!uploadFile?.response?.length && <div style={{textAlign: "left"}}>
          <div>The following records had errors:</div>
          <ul>
          {uploadFile?.response?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
          </ul>
        </div> }
      />
    </Modal>
  </div>;
};

export default AssemblyCategoriesPage;
