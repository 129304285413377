import { Select, SelectProps } from "antd";
import { PAGINATION_MAX_PAGE_SIZE } from "../api/models";
import useModels from "../swr/useModels";

const ModelMultipleSelector = (props: SelectProps) => {

  const models = useModels({
    inactive: true,
    deleted: true,
    size: PAGINATION_MAX_PAGE_SIZE,
  })

  return <Select
    {...props}
      loading={models.isLoading}
      mode="multiple"
      showSearch
      allowClear
      optionFilterProp="label"
      style={props.style}
      options={models.data?.content?.map(m => ({value: m.id, label: m.name}))}
    />
};

export default ModelMultipleSelector;
