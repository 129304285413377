import {CSSProperties, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Assembly, Category, CategoryMetadata, AXIOS_CANCEL_MSG} from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import Utils from "../util/util";
import {ColumnType, TablePaginationConfig} from "antd/lib/table";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { FilterValue, SorterResult, SortOrder } from "antd/es/table/interface";
import { ConfiguratorContext } from "../context";
import { Input, notification, Table } from "antd";
import axios, {CancelTokenSource} from "axios";
import useAssemblyList from "../swr/useAssemblyList";
import {AssemblyFilterOptions} from "../api";
import {SelectionInfo} from "./Quote/AssemblySelectionTable";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

const DEFAULT_PAGE_SIZE = 3;

const DEFAULT_SORT = {
  columnKey: 'bom',
  order: 'ascend' as SortOrder
};

type AssemblySort = SorterResult<Assembly> | SorterResult<Assembly>[];

const AssemblySelectionTableWithMetadata = (props:{
  category: Category | undefined
  value?: Assembly
  onChange?: (a:Assembly | undefined) => void
}) => {

  const { category } = props;

  const [filter, setFilter] = useState<AssemblyFilterOptions>();
  const [sort, setSort] = useState<AssemblySort | undefined>(DEFAULT_SORT);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize:  DEFAULT_PAGE_SIZE,
    position: ["bottomLeft"],
    current: 1,
  });

  const assemblies = useAssemblyList( {
    filterOptions: filter,
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort
  } );

  useEffect(() => {
    const filter = {categoryId:category?.id}
    setFilter(filter);
  },[category]);


  const selectedCategory = props.category;

  const getColumnForMetadata = ( md:CategoryMetadata ) : ColumnType<Assembly> => {

    return {
      key: md.id,
      title: md.name,
      width: 150,
      render: (s:Assembly) => 
        s.metadata.filter( md1 => md1.categoryMetadata.id == md.id)?.map(Utils.getMetadataValue).find(v=>v),
    };

  };

  const labelStyle =  { wordWrap: "break-word", wordBreak: "break-word", width: "20rem", } as CSSProperties;

  const metadataColumns = selectedCategory?.metadata?.sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
    .map( md  => getColumnForMetadata( md ) ) || [];

  const columns:ColumnType<Assembly>[] = [
    {
      key: selectedCategory?.categoryId,
      title: "Name",
      fixed: "left",
      render: (a: Assembly) => <div style={labelStyle}>{!!a.label?.length ? a.label : a.bomDescription}, <span style={{whiteSpace:"nowrap"}}>{a.bom}</span></div>
    },
    ...metadataColumns
  ];

  const handleSelectRow = <T extends Assembly>(record: T, selected: boolean, selectedRows: T[], nativeEvent: Event) => {
    props.onChange?.(record);
  }

  const handleTableChange = async (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: SorterResult<Assembly> | SorterResult<Assembly>[] | undefined) => {
    setPagination(pagination);
    setSort(sorter)
  }

  const handleChangeSearch = (filterQuery:string) => {
    const f = {...filter, filterQuery, filterMetadataQuery: filterQuery};
    const pg = {...pagination, current: 1, };
    setPagination(pg);
    setFilter(f);
  }
  return <>
    <div style={{width: "100%", margin: ".5rem 0 .5rem 0"}}>
      <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search assemblies and metadata" />
    </div>
    <Table
      bordered
      size="small"
      rowKey="id"
      loading={assemblies.isLoading}
      columns={columns}
      dataSource={assemblies.data?.content}
      onChange={handleTableChange}
      pagination={pagination}
      rowSelection={{
        type: 'radio',
        onSelect: handleSelectRow,
        selectedRowKeys: props?.value?.id ? [props.value.id] : [],
        hideSelectAll: true,
      }}
      scroll={{ x: true }}
    />
  </>

}

export default AssemblySelectionTableWithMetadata;

