import {notification} from "antd";
import {useContext} from "react";
import {useIntl} from "react-intl";
import { Quote} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import BMButton, {BMButtonProps} from "../BMButton";
import useQuote from "../../swr/useQuote";

const CreateChangeOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quote} = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const handleSaveSalesChangeOrder = async () => {
    const quote = await createSalesChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);
    }
  }

  const createSalesChangeOrder = async () : Promise<Quote | undefined> => {
    if ( !quote ) return;

    try {
      const resp = await configurator.api.createChangeOrder(quote.displayRevisionId);
      await quoteAsync.mutate(resp.data);

      notification.success({message:"Change Order Created!"});
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create change order. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  return <>
    <BMButton
      type="primary"
      onClick={handleSaveSalesChangeOrder}
      {...btnProps}
    />
  </>
}

export default CreateChangeOrderButton;
