import { Affix, Badge, Col, Descriptions, Divider, Dropdown, Form, Modal, Row, Space, Tabs, Tooltip, notification, Alert, Checkbox, CheckboxProps, Skeleton } from "antd";
import { ConfiguratorContext } from "../context";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import {
  AssemblyBase,
  BaseCategory,
  Category,
  CategoryIdAssembliesIdMap,
  CustomOptionType,
  Customer,
  DealerAdjustment,
  FRAME_SILL_LENGTH,
  IncentiveProgramInfo,
  Model,
  NeedVerifyDash,
  NonDiscountOption,
  Performance,
  Permission,
  PoNumber,
  PricingBreakdown,
  PricingOption,
  QUOTE_DEFAULTS,
  Quote,
  QuoteAssemblyException,
  QuoteShare,
  Revision,
  ShippingDestination,
  User,
  ApprovalAction,
  SyncStatus,
  EpicorSyncStatus,
  SalesTeam,
  AXIOS_CANCEL_MSG,
  AssemblyOption,
  CategoryIdCustomOptionIdMap,
} from "../api/models";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";
import { useForm } from "antd/es/form/Form";
import { LockState, useQuoteLock } from "../hook/useQuoteLock";
import { useIntl } from "react-intl";
import { SelectedModelInfo } from "../components/Quote/ModelSelectionWizard";
import axios, { CancelTokenSource } from "axios";
import QuoteLock from "../components/Quote/QuoteLock";
import AssemblySectionMenu from "../components/Quote/AssemblySectionMenu";
import Title from "antd/lib/typography/Title";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { WarningFilled, ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import QuoteInfoTab from "../components/Quote/QuoteInfoTab";
import QuoteHistoryTab from "../components/Quote/QuoteHistoryTab";
import Utils from "../util/util";
import { RequestedShipping } from "../components/Quote/CustomerShippingInfo";
import {
  ComputeOptionsRequest,
  SalesTeamRequest,
  SaveDto
} from "../api";
import Paragraph from "antd/lib/typography/Paragraph";
import { DescriptionsItemType } from "antd/lib/descriptions";
import QuotePerformanceTab from "../components/Quote/QuotePerformanceTab";
import QuoteDashTab from "../components/Quote/QuoteDashTab";
import QuoteExportsDropDown from "../components/Quote/QuoteExportsDropDown";
import BMButton from "../components/BMButton";
import SubmitQuoteButton from "../components/Quote/SubmitQuoteButton";
import SubmitOrderButton from "../components/Quote/SubmitOrderButton";
import AbandonChangeOrderButton from "../components/Quote/AbandonChangeOrderButton";
import SetDealerPriceButtonModal from "../components/Quote/SetDealerPriceButtonModal";
import MoveTrucksButton from "../components/Quote/MoveTrucksButton";
import AssemblyExceptionButtonModal from "../components/Quote/AssemblyExceptionButtonModal";
import BomDetails from "../components/BomDetails";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import QuoteCopyModal from "../components/QuoteCopyModal";
import SplitOrder from "../components/Quote/SplitOrder";
import GenerateSerialButton from "../components/Quote/GenerateSerialButton";
import ArchiveQuoteSwitch from "../components/Quote/ArchiveButton";
import CancelOrderModule from "../components/Quote/CancelOrderModule";
import CreateChangeOrderButton from "../components/Quote/CreateSalesChangeOrderButton";
import {ValidateFields, ValidateErrorEntity} from "rc-field-form/lib/interface";
import UnsavedChangesWarning from "../hook/unsaved_changes_warning";
import PriceViewSwitch from "../components/Quote/PriceViewSwitch";
import { SegmentedValue } from "antd/es/segmented";
import WorkflowProgress from "../components/WorkflowProgress"
import CustomOptionsButtonModal from "../components/Quote/CustomOptionsButtonModal";
import ShareQuoteButtonModal from "../components/Quote/ShareQuoteButtonModal";
import QuoteContextProvider, { useQuoteContext } from "../contexts/QuoteContext";
import ConvertReservationButton from "../components/Quote/ConvertReservationButton";
import QuoteAuditView from "../components/Quote/QuoteAuditView";
import UndoQuoteModalButton from "../components/Quote/UndoQuoteModalButton";
import QuoteTrucksButtonModal from "../components/Quote/QuoteTrucksButtonModal";
import ReviseQuoteButton, { AbandonReviseQuoteButton } from "../components/Quote/ReviseQuoteButton";
import { ItemType } from "antd/es/menu/interface";
import RequestPoModalButton from "../components/RequestPoModalButton";
import { FEATURE_DOCUSIGN } from "../api/features";
import SubmitDocusignOrderButton from "../components/Quote/SubmitDocusignOrderButton";
import SubmitChangeOrderButton from "../components/Quote/SubmitChangeOrderButton";
import useComputedValid from "../swr/useComputedValid";
import useLatestApprovals from "../swr/useLatestApprovals";
import useModelCategories from "../swr/useModelCategories";
import useAssemblyExceptions from "../swr/useAssemblyExceptions";
import useQuoteHistory from "../swr/useQuoteHistory";
import useQuoteReview from "../swr/useQuoteReview";
import useQuote from "../swr/useQuote";
import useComputePricing from "../swr/useComputePricing";
import RemoveSalesOrderButton from "../components/Quote/RemoveSalesOrder";
import RevertCancelButton from "../components/Quote/RevertCancelButton";
import AddPricingHistoryButton from "../components/Quote/AddPricingHistoryButton";
import useCustomOptions from "../swr/useCustomOptions";
import AssemblySelectionTable from "../components/Quote/AssemblySelectionTable";

export const FORM_PERCENT_DISCOUNT = 'percentDiscount';

export const QUOTE_INFO_PANEL_KEY = "quote-info"
export const PERFORMANCE_PANEL_KEY = "performance-details"
export const DASH_PANEL_KEY = "dash-drawing"
export const HISTORY_PANEL_KEY = "history"
export const ASSEMBLY_PANEL_KEY = "assembly"

const SPEC_ONLY_VIEW = "Spec Only";
const SALES_PRICE_VIEW = "Sales";
const ADMIN_PRICE_VIEW = "Admin";

export interface FormValues {
  copyName?: string
  quoteName: string
  endCustomer: Customer | undefined
  percentDiscount: number
  quantity: number
  shippingDestination: ShippingDestination | undefined
  quoteNotes: string | undefined
  salesRequests: string | undefined
  poNumber: PoNumber | undefined
  modelInfo: SelectedModelInfo | undefined
  gvwrCap: number | undefined
  shippingDate: Dayjs | undefined
  productionDate: Dayjs | undefined
  bmSalesReps: User[] | undefined
  engineer: User | undefined
  incentivePrograms: IncentiveProgramInfo[] | undefined
  allCustomOptions:CustomOptionType[] | undefined
  salesTeam: SalesTeam | undefined
  stateRegistration: string
  orderProbability: string | undefined
  expectedOrderDate: Dayjs| undefined
  expectedShipDate: Dayjs| undefined
  nonDiscountOptions: NonDiscountOption[]
  dealerAdjustments: DealerAdjustment[]
}

export interface CheckDirtyProps {
  formValues?: FormValues
  selectedAssemblies?: CategoryIdAssembliesIdMap
  selectedCustomOptions?: CustomOptionType[]
  dealerAdjustments?: DealerAdjustment[]
  nonDiscountOptions?: NonDiscountOption[]
}

export function isAssembly(option: AssemblyBase | CustomOptionType | undefined) : boolean {
  if ( !option ) return false;
  return 'bom' in option;
}

const Configurator = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const history = useHistory();

  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();
  const isSalesDesk = configurator.isSalesDesk();
  const isDealer = configurator.isDealerSales();
  const isFinance = configurator.isFinance();
  const isSales = configurator.isInternalSales();
  const isDocusignEnabled = configurator.hasFeature(FEATURE_DOCUSIGN);
  const hasAdminView = configurator.isEngineering() || configurator.isSalesDesk() || configurator.isAdmin();

  const [quoteForm] = useForm();

  const params = useParams<Record<string, string>>();
  const [revisionParam, _setRevisionParam] = useQueryParam<number | undefined | null >("rev", NumberParam);
  const [tabParam, setTabParam] = useQueryParam<string | undefined | null >("tab", StringParam);
  const [tabKey, setTabKey] = useState<string>(tabParam || QUOTE_INFO_PANEL_KEY);

  const selectedModel = Form.useWatch<SelectedModelInfo | undefined>('modelInfo', quoteForm);
  const percentDiscount = Form.useWatch<number | undefined>('percentDiscount', quoteForm);

  const isMobile = useCheckMobileScreen();
  const layoutStyle = isMobile ? { display: "flex" } : {};

  const [_previewQuote, previewQuoteAsync] = useAsyncState<Quote>();
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [_computedSelections, computedSelectionsAsync] = useAsyncState<CategoryIdAssembliesIdMap>();
  const [epicorSyncStatus, epicorSyncStatusAsync] = useAsyncState<SyncStatus>();
  const [truckGvwr, truckGvwrAsync] = useAsyncState<number>();
  const [hidePrice, setHidePrice] = useState(false);  // Price hide to user ot not
  
  const [selectedCategory, setSelectedCategory] = useState<BaseCategory>();
  const [selectedOptions, setSelectedOptions] = useState<CategoryIdAssembliesIdMap>();
  const [selectedCustomOptions, setSelectedCustomOptions] = useState<CategoryIdCustomOptionIdMap | undefined>();

  const [adminView, setAdminView] = useState<boolean>(hasAdminView); // AdminView is only for price detail to users
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [optionNotes, setOptionNotes] = useState<Record<string, string>>({});
  const [filterOptionsQuery, setFilterOptionsQuery] = useState<string>();
  const [disableAutoSelect, setDisableAutoselect] = useState<boolean>(false);
  const [disableAssemblyPricing, setDisableAssemblyPricing] = useState<boolean>(true);
  //todo
  const [needVerifyDash, setNeedVerifyDash] = useState<NeedVerifyDash>();
  const [truckDescription, setTruckDescription] = useState<string>();
  const [validationAlert, setValidationAlert] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const cancelComputedSelectionsTokenSourceRef = useRef<CancelTokenSource>();

  const quoteAsync = useQuote({ quoteId: params.quoteId, revision: revisionParam || undefined });
  const quote = quoteAsync.data;

  const approvals = useLatestApprovals({
    quoteRevisionId: quote?.displayRevisionId,
  });

  const quoteHistory = useQuoteHistory({
    quoteId: quote?.quoteId,
    showAbandoned: false
  });

  const computedValid = useComputedValid({
    modelId: selectedModel?.modelInfo.id,
    options: {
      currentSelections: Object.values(selectedOptions || {}).flat(),
      customOptions: Object.values( selectedCustomOptions || {} ).flat(),
      quoteRevisionId: quote?.displayRevisionId,
    }
  });

  const review = useQuoteReview({
    quoteRevisionId: quote?.displayRevisionId,
    options: {
      dealerView: !adminView,
    }
  })


  const getFormValues = () : FormValues => {
    const allValues = quoteForm.getFieldsValue();
    const newAllValues = isAdminOrEngineering ? allValues : { ...allValues };
    return newAllValues
  }

  const buildDefaultComputePricingArgs = () : ComputeOptionsRequest | undefined => {
    const values = getFormValues();

    const selectedModelInfo = values.modelInfo;
    const modelId = selectedModelInfo?.modelInfo.id;
    if ( !modelId ) return;

    const customOptions = Object.values(selectedCustomOptions || {} ).flat().sort();

    return {
      selectedModelId: modelId,
      currentSelections: Object.values( selectedOptions || {} ).flat().sort(),
      pricingSnapshotId: quote?.pricingConfig?.pricingSnapshot?.id,
      percentDiscount: values.percentDiscount,
      shippingDestinationId: values.shippingDestination?.id,
      dealerAdjustments: values.dealerAdjustments,
      nonDiscountOptions: values.nonDiscountOptions,
      customOptions,
      incentivePrograms:values.incentivePrograms?.map(ip => ip.id ).sort(),
    }
  }

  const computePricingDetails = buildDefaultComputePricingArgs();
  const computedPricing = useComputePricing({
    //this effectively waits till the quote is loaded
    quoteId: quote?.quoteId,
    rev: quote?.revision,
    options: computePricingDetails,
  });

  const savedPricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  })

  const modelCategoriesAsync = useModelCategories({
    modelId: selectedModel?.modelInfo.id,
    options: {
      quoteRevisionId: quote?.displayRevisionId,
      assemblyFilter: filterOptionsQuery,
      dealerView: !adminView,
    }
  })

  const customOptionsAsync = useCustomOptions({
    quoteRevisionId: quote?.displayRevisionId,
    selections: Object.values(selectedOptions || {}).flat()
  })

  const modelCategories = modelCategoriesAsync.data
      ?.filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

  const isNewQuote = !quote && !quoteAsync.isLoading;
  const [lockState, userActivity, retryLock] = useQuoteLock(quote, isNewQuote, formDirty);

  const selectedRevisionId = quote?.displayRevisionId || 0;
  const isCurrentRevision = isNewQuote || Number(quote?.currentRevisionId) === Number(selectedRevisionId);

  const isLocked = [ LockState.LOCKED, LockState.EXCEEDED ].includes(lockState);

  useEffect(() => {
    if ( quote ) {

      setTruckDescription(quote?.truckDescription);

      setSelectedOptions(quote?.selections);
      console.log( "updated quote setSelectedCustomOptions", quote?.selectedCustomOptions );
      setSelectedCustomOptions(quote?.selectedCustomOptions);

      setOptionNotes(quote?.selectionNotes || {});

      savedPricing.mutate();
      computedPricing.mutate();
      computedValid.mutate();
      review.mutate();
      quoteHistory.mutate();
      approvals.mutate();
      customOptionsAsync.mutate();

      loadPerformance();
      loadEpicorSyncStatus(quote?.quoteId);

      quoteForm.resetFields();
      updateDirty();
    }

  }, [quote]);

  useEffect(() => {
        if (quote && selectedModel) {

          const opts = [ ...Object.values(selectedOptions || {}).flat().sort(),
            ...Object.values(selectedCustomOptions || {}).flat().sort() ];
          const qopts = [ ...Object.values(quote.selections || {}).flat().sort(),
            ...Object.values(quote.selectedCustomOptions || {}).flat().sort() ];

          const diff = _.difference(opts, qopts );
          if ( diff.length ) {
            saveQuoteForm()
          }
          else {
            updateDirty();
          }
        }
      },
      //save on changed selections
      [
        Object.values(selectedCustomOptions || {}).flat().sort().join("-"),
        Object.values(selectedOptions || {}).flat().sort().join("-")
      ] );


  useEffect(() => {
    //reload if locked switch, it's already been loaded once, and notcurrently loading
    const isUnlocked = (lockState === LockState.UNLOCKED);
    const isLoaded = (quote && !quoteAsync.isLoading);
    if (isUnlocked && isLoaded) {
      quoteAsync.mutate();
    }
  }, [isLocked]);

  useEffect(() => {
    userActivity();
  }, [filterOptionsQuery]);

  useEffect(() => {
    updateTruckGvwr(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedCategory) {
      handleTabClick(ASSEMBLY_PANEL_KEY);
    }
  }, [selectedCategory]);

  const updateDirty = (props?:CheckDirtyProps) => {
    userActivity();
    setFormDirty( checkDirty(props) );
  }


  const loadEpicorSyncStatus = async (quoteId:string | undefined) : Promise<SyncStatus | undefined> => {
    if (!(configurator.isAdmin() || configurator.isEngineering())) return;
    if ( !quoteId ) return;

    epicorSyncStatusAsync.setLoading()

    try {
      const resp = await configurator.api.epicorSyncStatus({
        page: 0, size: 1, filter: quoteId
      });
      const syncStatus = resp.data.content?.[0];
      epicorSyncStatusAsync.setDone(syncStatus)
      return syncStatus;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load epicor sync status. " + errorMsg });
      epicorSyncStatusAsync.setFail(e.message);
    }

    return;
  }

  const assemblyExceptions = useAssemblyExceptions({quoteId: quote?.quoteId})

  const quoteState = Utils.getQuoteState(configurator, quote, quoteAsync.isLoading, isLocked);
  const {
    isLatestRevision,
    isEffectiveRevision,
    isOrder,
    isDraft,
    isPending,
    isSplitOrder,
    isSalesChangeOrder,
    isEngineeringChangeOrder,
    isOrderShipped,
    isOrderCancelled,
    isApproved,
    isReadOnly,
    isInitialLoading,
    isReviseQuote,
    isArchived,
    canChangeReadOnly,
    hasWritePermission,
    hasEngineeringChangePermission,
  } = quoteState;
  //console.log( "quoteState", quoteState );

  const isQuoteOwner = quote?.owner?.id === configurator.userInfo?.id;
  const isQuoteSales = quote?.salesTeam?.sales?.some(rep => rep.id === configurator.userInfo?.id);
  const isChangeOrder = isEngineeringChangeOrder || isSalesChangeOrder;

  const hasDealerReadPermission = configurator.hasAnyPermission([Permission.DEALER_ADMIN_READ, Permission.DEALER_MANAGEMENT_READ]);
  const hasSplitPermission =  configurator.hasPermission(Permission.CHANGE_ORDER_WRITE);
  const hasCancelOrderPermission = configurator.hasPermission(Permission.CANCEL_ORDER_WRITE);
  const isQuoteOnlyPermission = configurator.isQuoteOnly();
  const hasSubmitOrderPermission = (isSales || isDealer) && !isQuoteOnlyPermission;
  const hasSubmitQuotePermission = (isSales || isDealer);

  const pricingDetails = computedPricing.data;

  const isWorking =
    computedSelectionsAsync.isLoading() ||
    ( computedValid.isLoading || !computedValid.data ) ||
    modelCategoriesAsync.isLoading ||
    previewQuoteAsync.isLoading() ||
    quoteAsync.isValidating || isSaving;

  const updateTruckGvwr = (selectedOptions: CategoryIdAssembliesIdMap | undefined) => {

    const selections = selectedOptions ? Object.values(selectedOptions).flat() : [];
    if (selections.length === 0) return;

    truckGvwrAsync.setLoading();
    try {
      configurator.api.fetchTruckGvwr(selections)
        .then(resp => truckGvwrAsync.setDone(resp.data),
          reason => truckGvwrAsync.setFail(reason));
    }
    catch (e: any) {
      truckGvwrAsync.setFail(e.message);
    }
  }

  const clearCustomOptionCategory = (categoryId:number, customOptionLst:CategoryIdCustomOptionIdMap | undefined) : CategoryIdCustomOptionIdMap | undefined => {
    const next = {
      ...customOptionLst,
    };
    delete next[categoryId]
    return next;
  }

  const handleClearSelections = async (category:BaseCategory) => {

    const options = {...selectedOptions, [ category.id ]: [] };

    const customOptionLst = clearCustomOptionCategory(category.id, selectedCustomOptions);

    getTruckDescription(options);

    //CFGR-709
    setSelectedCustomOptions(customOptionLst);
    setSelectedOptions(options);
  }

  const handleUpdateOptionNotes = (assemblyId:number | undefined, note:string | undefined) => {
    if ( !assemblyId ) return;

    setOptionNotes({ ...optionNotes, [assemblyId]:note});
  }

  const selectAssembly = (selected:Record<number, number[]> | undefined, category:BaseCategory, assemblyId: number ): number[] => {

    const categoryOptions = [...selected?.[ category.id ] || []];

    //toggle previously selected option
    if ( categoryOptions.find( id => id === assemblyId ) ) {
        return categoryOptions.filter( id => id !== assemblyId );
    }

    if ( !category.allowMultiple ) {
      return [ assemblyId ];
    }

    return [ ...categoryOptions, assemblyId];
  }

  const handleSelectCategory = (cat:BaseCategory | undefined) => {
    handleTabClick(ASSEMBLY_PANEL_KEY);
    setSelectedCategory(cat);
  }

  const handleSelectAssembly = async (category:BaseCategory,  option: AssemblyBase | CustomOptionType | undefined) => {
    if ( !category ) return;
    if ( !selectedModel ) return;
    if ( !selectedOptions ) return;

    const assembly = isAssembly(option) ? option as AssemblyOption : undefined;
    const customOption = !isAssembly(option) ? option as CustomOptionType : undefined;

    let options = {...selectedOptions};
    let customOptions = {...selectedCustomOptions};

    if ( assembly ) {

      //update assemblies
      const lst = selectAssembly( selectedOptions, category, assembly.id );
      options = {...selectedOptions, [ category.id ]: lst };

      //clear any custom options
      if ( !category.allowMultiple ) {
        customOptions = { ...selectedCustomOptions, [category.id]: [] };
      }
    }
    else if ( customOption ) {

      //update custom option
      const lst = selectAssembly(selectedCustomOptions, category, customOption.id ) || [];
      customOptions = {...customOptions, [ category.id ]: lst };

      //clear any assemblies
      if ( !category.allowMultiple ) {
        options = {...selectedOptions, [ category.id ]: [] };
      }
    }
    else {
      options = {...selectedOptions, [ category.id ]: [] };
      customOptions = { ...selectedCustomOptions, [category.id]: [] };
    }

    if ( !disableAutoSelect ) {
      const auto = await computeAutoSelections( computedSelectionsAsync, selectedModel.modelInfo.id, {
        currentSelections: Object.values( options || {} ).flat(),
        customOptions: Object.values( customOptions || {} ).flat(),
        quoteRevisionId: quote?.displayRevisionId,
        latestAssembly: assembly?.id //only assemblies
      } );
      setSelectedOptions(auto || options);
    }
    else {
      setSelectedOptions(options);
    }

    setSelectedCustomOptions(customOptions);
  }


  const loadPerformance = () => {
    const quoteId = params.quoteId;
    const rev = revisionParam || undefined;
    if ( !quoteId ) return;
    configurator.api.fetchQuotePerformance(quoteId, rev).then(
      (res) => {performanceAsync.setDone(res.data);}, 
    ).catch(e => performanceAsync.setFail(e.message))
  }

  const existPerformanceError = () => {
    return !( !performance?.performanceWeight?.weightsMissing.length 
    && !performance?.performanceWeight?.tareMissing.length 
    && !performance?.performanceWeight?.gvwrMissing.length 
    && !performance?.performanceData?.performanceMissing.length 
    && !performance?.performanceDimension?.dimensionMissing.length );
  }

  const buildSalesTeamRequest = (salesTeam:SalesTeam | undefined) : SalesTeamRequest | undefined => {
    if ( !salesTeam ) return;

    return {
      id: salesTeam.id,
      name: salesTeam.name,
      sales: salesTeam.sales?.map( u => u.id ),
      engineers: salesTeam.engineers?.map( u => u.id ),
      support: salesTeam.support?.map( u => u.id ),
      viewers: salesTeam.viewers?.map( u => u.id ),
    };
  }

  const buildSaveDto = (values:FormValues): SaveDto => {

    const selections = Object.values( selectedOptions || {} ).flat().sort();
    const customOptions = Object.values( selectedCustomOptions || {} ).flat().sort();

    const selectedModelInfo = values.modelInfo;
    const modelId = selectedModelInfo?.modelInfo.id;
    const salesTeam = buildSalesTeamRequest( values.salesTeam );

    const expectedOrderDate = values.expectedOrderDate ? values.expectedOrderDate?.toDate() : undefined;
    const expectedShipDate = values.expectedShipDate ? values.expectedShipDate?.toDate() : undefined;
    const epicorDisabled = quote?.epicorDisabled;

    return {
      shippingDestination: values.shippingDestination?.id,
      quantity: values.quantity,
      salesRequests: values.salesRequests,
      gvwrCap: values.gvwrCap,
      name: values.quoteName,
      endCustomerId: values.endCustomer?.id,
      percentDiscount: values.percentDiscount,
      notes: values.quoteNotes,
      modelId,
      selections,
      assemblyNotes: optionNotes,
      archived: quote?.archived || false,
      dealerAdjustmentList: values.dealerAdjustments,
      nonDiscountOptionList: values.nonDiscountOptions,
      incentivePrograms: values.incentivePrograms?.map(ip => ip.id),
      customOptions,
      salesTeam,
      stateRegistration: values.stateRegistration,
      orderProbability: values.orderProbability,
      expectedOrderDate,
      expectedShipDate,
      epicorDisabled,
      salesOrderNumber: quote?.salesOrderNumber,
      changeSummary: quote?.changeSummary,
    };
  }

  const checkDirty = (props?:CheckDirtyProps): boolean => {
    const formValues = props?.formValues || getFormValues();
    const selectedAssemblyLst = props?.selectedAssemblies || selectedOptions;

    if (isOrderCancelled || !isEffectiveRevision) return false;

    const quoteSelections = Object.values(quote?.selections || {}).flat().sort();
    const selections = selectedAssemblyLst ? Object.values(selectedAssemblyLst).flat().sort() : [];
    if (!_.isEqual(quoteSelections, selections)) {
      console.log( "dirty selections", _.difference(quoteSelections, selections));
      return true;
    }

    const quoteCustomOptionIdLst = Object.values(quote?.selectedCustomOptions || {}).flat().sort();
    const selectedCustomOptionIdLst = Object.values( selectedCustomOptions || {} ).flat().sort();
    if (!_.isEqual(quoteCustomOptionIdLst, selectedCustomOptionIdLst)) {
      console.log( "dirty custom options", quoteCustomOptionIdLst, selectedCustomOptionIdLst);
      return true;
    }

    return Object.entries(formValues).some(v => {
      const key = testDirty(v);
      //this is for debugging, show the dirty key
      if ( !!key ) {
        console.log( "dirty key", key );
      }
      return !!key;
    });
  }

  const testDirty = ([k, v]) : string | undefined => {

    switch (k) {
      case 'nonDiscountOptions': {
        return !isPricingOptionEqual(v, quote?.nonDiscountOptionList)
            ? k : undefined;
      }
      case 'dealerAdjustments': {
        return !isPricingOptionEqual(v, quote?.dealerAdjustmentList)
              ? k : undefined;
      }
      case 'salesTeam': {
        return !_.isEqual(v, quote?.salesTeam ) 
          ? k : undefined;
      }
      case 'endCustomer': {
        return quote?.endCustomer?.id !== v?.id 
          ? k : undefined;
      }
      case 'shippingDestination': {
        return quote?.shippingDestination?.id !== v?.id 
          ? k : undefined;
      }
      case 'quoteName': {
        return ( quote?.name?.trim() !== v?.trim() ) 
          ? k : undefined
      }
      case 'quoteNotes': {
        return ( quote?.notes?.trim() !== v?.trim() ) 
          ? k : undefined
      }
      case 'quantity':
      case 'gvwrCap':
      case FORM_PERCENT_DISCOUNT: {
        return quote?.[k] != v
          ? k : undefined
      }
      case 'modelInfo': {
        return (quote?.model.id !== v?.modelInfo.id ) 
          ? k : undefined;
      }
      case 'expectedOrderDate':
      case 'expectedShipDate':
        return ( quote?.[k] !== v && !dateStrToMoment(quote?.[k])?.isSame(v) )
          ? k : undefined;
      case 'productionDate':
      case 'shippingDate':
      case 'bmSalesReps':
      case 'salesSupport':
      case 'copyName':
      case 'salesRequests':
      case 'requestedShipping':
      case 'poNumber':
      case 'engineer':
      case 'owner': {
        return undefined;
      }
      case 'incentivePrograms': {
        return (!_.isEqual( [...(v || [])].sort(), [...(quote?.incentivePrograms || [])].sort() )) 
          ? k : undefined;
      }
      default: {
        return ((quote == null && v != null && v?.trim() !== '') || (quote != null &&
          (((k in quote) && (v?.trim() !== quote[String(k)])) || (!(k in quote) && v != null && String(v).trim() !== ''))))
          ? k : undefined;
      }
    }
  }

  const isPricingOptionEqual = (oldArr: PricingOption[] | undefined, newArr: PricingOption[] | undefined) => {

    const cmp = (a:PricingOption,b:PricingOption) => a.key.localeCompare(b.key);
    const oldList = oldArr?.sort(cmp);
    const newList = newArr?.sort(cmp);

    return _.isEqual(oldList, newList);
  }

  const handleUpdateDiscountPercent = (discountPercent:number) => {
    quoteForm.setFieldValue( FORM_PERCENT_DISCOUNT, discountPercent  );
    onQuoteValuesChange({[FORM_PERCENT_DISCOUNT]: discountPercent}, quoteForm.getFieldsValue(true));
  }

  
  const onQuoteValuesChange = async (values: Record<string, any>, allValues: {}) => {
    handleQuoteValuesChange( values, allValues );
  }
  const handleQuoteValuesChange = async (values: Record<string, any>, _allValues: {}, disableSave?: boolean) => {

    let isSaveForm = false;
    if ( 'modelInfo' in  values ) {
      await onChooseModel( values.modelInfo );
    }

    if ( 'incentivePrograms' in  values ) {
      isSaveForm = true;
    }

    if ( 'requestedShipping' in values ) {
      await updateRequestedShipping( quote?.displayRevisionId, values.requestedShipping );
    }


    if ( 'salesTeam' in values ) {
      isSaveForm = true;
    }

    if ( 'endCustomer' in values ) {
      isSaveForm = true;
    }

    if ( 'stateRegistration' in values ) {
      isSaveForm = true;
    }

    if ( 'orderProbability' in values || 'expectedOrderDate' in values || 'expectedShipDate' in values ) {
      isSaveForm = true;
    }

    if ( 'shippingDestination' in values ) {
      isSaveForm = true;
    }

    if ( 'dealerAdjustments' in values || 'nonDiscountOptions' in values ) {
      isSaveForm = true;
    }

    if ( !isNewQuote && !disableSave && isSaveForm ) {

      updateDirty();
      await saveQuoteForm();
    }
    else {
      updateDirty();
    }

  };

  const onChooseModel = async (selectedModelInfo: SelectedModelInfo) : Promise<Model | undefined> => {

    //only update on diffent model ( not model year )
    if ( quote?.model.id === selectedModelInfo?.modelInfo.id )  return;

    const modelId = selectedModelInfo?.modelInfo.id;

    try {
      const resp = await configurator.api.getModelDetail(modelId);
      const modelDetail = resp.data;
      if ( !modelDetail )  return;

      if(modelDetail.initialConcession && !quote) {
        quoteForm.setFieldValue( FORM_PERCENT_DISCOUNT, modelDetail.initialConcession);
      }

      const options = selectedModelInfo.selections;
      setSelectedOptions( options );
      setSelectedCustomOptions([])

      return modelDetail;

    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to get model details. " + errorMsg

      notification.error( { message: msg });
    }

    return;
  };

  const setQuoteFormValues = async (updated:Quote | undefined, disableSave?: boolean) => {

    if ( !updated ) return;

    quoteForm.setFieldsValue(populateFormValues(updated));

    setSelectedOptions(updated?.selections);

    setOptionNotes(updated?.selectionNotes || {});

    //get changed values
    const diff = _.differenceWith(_.toPairs(updated), _.toPairs(quote), _.isEqual)
    .reduce( (acc, v ) => {
      const key = v[0]; const value = v[1];
      acc[ key ] = value;
      return acc;
    }, {});
    await handleQuoteValuesChange(diff, updated, disableSave );
  }

  const populateFormValues = (quote: Quote | undefined) => {
    if (!quote) return QUOTE_DEFAULTS;

    const productionDate = dateStrToMoment(quote.productionDate );
    const shippingDate = dateStrToMoment(quote.shippingDate );
    const customerShippingDate = dateStrToMoment(quote.customerShippingDate);

    const modelInfo = {
      modelInfo: quote.model,
      modelYear: quote?.modelYear || ( dayjs().year() + 1 ),
      selections: quote.selections
    };

    return {
      quoteName: quote.name,
      quoteNotes: quote.notes,
      salesRequests: quote.salesRequests,
      poNumber: quote.poNumber,
      endCustomer: quote.endCustomer,
      stateRegistration: quote.stateRegistration,
      quantity: quote.trucks?.length || quote.quantity,
      percentDiscount: quote.percentDiscount,
      shippingDestination: quote.shippingDestination,
      owner: quote.owner,
      gvwrCap: quote.gvwrCap,
      productionDate,
      shippingDate,
      modelInfo: modelInfo,
      incentivePrograms:quote.incentivePrograms,
      requestedShipping: {
        customerShippingDate,
        cadence: quote.cadence,
        truckCustomerShippingDateList: quote.truckCustomerShippingDateList,
      },
      salesTeam: quote.salesTeam,
      orderProbability: quote.orderProbability,
      expectedOrderDate: dateStrToMoment( quote.expectedOrderDate),
      expectedShipDate: dateStrToMoment( quote.expectedShipDate ),
      nonDiscountOptions: quote.nonDiscountOptionList,
      dealerAdjustments: quote.dealerAdjustmentList
    };
  }

  const dateStrToMoment = (d:Date | undefined ) : Dayjs | undefined => {
    if ( !d ) return;
    return dayjs(d, 'YYYY-MM-DD')
  }

  const updateRequestedShipping = async (quoteRevisionId: number | undefined, requestedShipping: RequestedShipping): Promise<Quote | undefined> => {
    if (!quoteRevisionId) return;

    try {

      var resp = await configurator.api.updateRequestedShipping(quoteRevisionId, requestedShipping);
      await quoteAsync.mutate(resp.data);

      notification.success({message: "Requested shipping updated"})

      return resp.data;

    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg =  "Failed to update requested shipping. " + errorMsg

      notification.error( { message: msg });
    }

    return;
  }

  const handleTabClick = (key: string) => {
    setTabKey(key);
    const param = [ ASSEMBLY_PANEL_KEY, QUOTE_INFO_PANEL_KEY].includes(key) ? undefined : key;
    setTabParam(param)
  }

  const getPriceView = () => {
    if (isDealer) {
      return [SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
    else if (hasAdminView) {
      return [ADMIN_PRICE_VIEW, SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
    else {
      return [SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
  }

  const setPriceView = (value: SegmentedValue) => {
    if (String(value) === ADMIN_PRICE_VIEW) {
      setAdminView(hasAdminView);
      setHidePrice(false);
    }
    else if (String(value) === SALES_PRICE_VIEW) {
      setAdminView(false);
      setHidePrice(false);
    }
    else if (String(value) === SPEC_ONLY_VIEW) {
      setAdminView(hasAdminView);
      setHidePrice(true);
    }
  }

  const getTruckDescription = async (options: CategoryIdAssembliesIdMap) => {
    const selectionIds = Object.values(options).flat() as number[];
    try {
      if (quote) {
        const resp = await configurator.api.getTruckDescription(quote?.displayRevisionId, selectionIds);
        setTruckDescription(resp.data);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const getQuoteRevisionById = (quote: Quote | undefined, id: number | undefined): Revision | undefined => {
    return quote?.revisions.find(r => r.id === id);
  };

  const revision = getQuoteRevisionById( quote, quote?.displayRevisionId );

  const updatePricingSnapshot = async (pricingSnapshotId?: number | undefined) => {
    if ( !quote ) return;

    try {

      const resp = await configurator.api.updatePricingSnapshot( quote.displayRevisionId, pricingSnapshotId );
      if ( resp ) {

        notification.success({message: "Successfully update price, please submit quote approval."});

        await quoteAsync.mutate(resp.data);
        computedPricing.mutate();
        savedPricing.mutate();
      }

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update pricing. " + errorMsg });
    }

  }

  const quoteDescriptionItems:DescriptionsItemType[] = !quote ? [] : [
    {
      key: "quote",
      label: <div key="quote-label">{"Quote"}
      </div>,
      children:
            <Paragraph copyable={{ text: quote.quoteId }} style={{marginRight: ".4rem"}}>
              <Link to={"/configurator/" + encodeURI(quote.quoteId)}>
                <span data-testid="quoteIdStr" style={{whiteSpace: "nowrap"}}>{quote.quoteId}</span>
              </Link>
            </Paragraph>
    },
    {
      key: "revision",
      label: "Revision",
      children:<span>Rev. {quote.revision} {(quote.epicorRevision && quote.epicorRevision !== "-") ? `( Epicor ${quote.epicorRevision})` : undefined}</span>
    },
    {
      key: "partNumber",
      label: "Part Number",
      children:
          <>
            <span data-testid="partNumberString" >{quote.partNumberString || "NA"}</span>
            {quote.partNumberString != undefined && quote.partNumberString !== "" && <Paragraph copyable={{text: quote.partNumberString}}></Paragraph>}
          </>
    },
    {
      key: "quoteStatus",
      label: "Status",
      children:
        <span data-testid="quoteStatusStr">
          {Utils.formatQuoteDisplayStatus(quote)}
        </span>
    },
  ]

  if ( !!quote?.trucks?.length  ) {
    quoteDescriptionItems.push({
      key: "serialNumber",
      label: !(!quote || !quote.trucks?.length || !revision) ? "Serial Number" : "",
      children: <>
        <div style={{display: 'flex', gap: '.8rem', alignItems: "baseline", flexWrap: "wrap", paddingRight: "1rem" }}>
          <QuoteTrucksButtonModal  />
        </div>
      </>
    });
  }

  if( !isDealer && !!quote?.salesOrderNumber ) {
    quoteDescriptionItems.push({
      key: "salesOrderNumber",
      label: quote.salesOrderNumber && !isDealer ? "Epicor Sales Order" : "",
      children: quote.salesOrderNumber && !isDealer ? <span>{quote.salesOrderNumber}</span> : <></>
    })
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }


  const computeAutoSelections = async (computedSelectionsAsync:AsyncState<CategoryIdAssembliesIdMap>, modelId:number, options?:ComputeOptionsRequest ) : Promise<CategoryIdAssembliesIdMap | undefined> => {

    if ( cancelComputedSelectionsTokenSourceRef.current ) {
      cancelComputedSelectionsTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelComputedSelectionsTokenSourceRef.current = cancelSource;

    try {
      computedSelectionsAsync.setLoading()
      const resp = await configurator.api.computeAutoSelections(modelId, options, cancelSource.token );
      cancelComputedSelectionsTokenSourceRef.current = undefined;

      computedSelectionsAsync.setDone( resp.data.selections );
      return resp.data.selections;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load compute options. " + errorMsg });
        computedSelectionsAsync.setFail(e.message);
      }
    }

    return {};
  };

  const handleChangePricingView = (pricingDetails: PricingBreakdown) => {

    quoteForm.setFieldValue("dealerAdjustments", pricingDetails.dealerAdjustments);
    quoteForm.setFieldValue("nonDiscountOptions", pricingDetails.nonDiscountOptions);

    //trigger a save
    handleQuoteValuesChange({
      dealerAdjustments: pricingDetails.dealerAdjustments,
      nonDiscountOptions: pricingDetails.nonDiscountOptions
    }, {} )
  }

  const isCategorySelectionValid = (category: BaseCategory) : boolean => {
    const selectedLst = selectedOptions?.[category.id] || [];
    const computedLst = computedValid?.[category.id] || [];
    return !!selectedLst?.length && _.isEqual([...selectedLst].sort(), [...computedLst].sort());
  };

  const allSelectedOrFrameSillLengthIsOnlyUnselectedCategory = () :boolean => {
    const allUnselected = modelCategories?.filter(c => !isCategorySelectionValid(c)) || [];
    //all are selected
    if ( allUnselected.length === 0 ) return true;
    //more than one category is unselected
    if ( allUnselected.length > 1 ) return false;
    //is single unselected category FRAME_SILL_LENGTH?
    return allUnselected[0].name.includes(FRAME_SILL_LENGTH);
  }

  const handleAddException = (quoteAssemblyException:QuoteAssemblyException) => {
    //automatically select an assembly on adding it
    const category = modelCategories?.find( c => c.id === quoteAssemblyException.assembly.categoryId );
    if ( category ) {
      handleSelectAssembly( category, quoteAssemblyException.assembly );
    }
  }
      
  const saveEngineeringLock = async (quoteId:number, lock:boolean) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      //fetch new pricing
      const resp = await configurator.api.saveQuoteEngineeringLock(quoteId, lock);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to lock quote. " + errorMsg });
    }

    return;
  }

  const handleEngineeringLock = async (lock:boolean) => {
    if ( !quote ) return;

    const savedQuote = await saveEngineeringLock( quote.id, lock );
    if ( savedQuote ) {
      await quoteAsync.mutate( saveEngineeringLock(quote.id, lock));
    }

  }

  const getDisabledSplitMsg = () : string | undefined => {

    return formDirty ? "Please save change or create change order before attempting to split."
    : isOrderCancelled? "Order is cancelled."
    : (isSalesChangeOrder && isPending) ? "Change order in progress."
    : (isEngineeringChangeOrder && isPending) ? "Engineering change in progress."
    : (isSplitOrder && isPending) ? "Split order in progress."
    : isPending ? "Open approvals must be resolved."
    : !isLatestRevision ? "Must be latest revision."
    : undefined;
  }

  const getDisabledSaveMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
      : quote?.archived ? "Archvived quote cannot be modified."
      : quote?.expired ? "Expired quote cannot be modified."
      : !formDirty ? "There are no changes to save." 
      : isWorking ? "Please wait till background work has completed."
      : ( isEngineeringChangeOrder && !hasEngineeringChangePermission ) ? "This is an engineering change and can only be changed by an engineer."
      : undefined;
  }

  const getDisabledUndoMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
      : quote?.archived ? "Archived quote cannot be modified."
      : quote?.expired ? "Expired quote cannot be modified."
      : isWorking ? "Please wait till background work has completed."
      : ( isEngineeringChangeOrder && !hasEngineeringChangePermission ) ? "This is an engineering change and can only be changed by an engineer."
      : undefined;
  }

  const getDisabledAbandonMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save or reset changes before abandoning."
      : isWorking ? "Please wait till background work has completed."
      : ( isEngineeringChangeOrder && !hasEngineeringChangePermission ) ? "This is an engineering change and can only be changed by an engineer."
      : undefined;
  }

  const getDisabledSubmitMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save changes before submitting."
      : quote?.expired ? "This quote is expired.  Revise the quote before resubmitting."
      : isWorking ? "Please wait till background work has completed."
      : ( isEngineeringChangeOrder && !hasEngineeringChangePermission ) ? "This is an engineering change and can only be changed by an engineer."
      : undefined;
  }

  const getDisabledReviseQuoteMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save changes before submitting."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledSubmitOrderMsg = () => {
    const submitMsg = getDisabledSubmitMsg();
    return !!submitMsg ? submitMsg
      : quote?.expired ? "This quote is expired.  Revise the quote before resubmitting."
      : undefined;
  }

  const getDisabledConvertReservationMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledOptionsMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      //: formDirty ? "These options are not available while there are unsaved changes."
      : undefined;
  }

  const hasCopyPermission = ():boolean => {
    const hasPermission = configurator.hasPermission(Permission.COPY_QUOTE_WRITE);
    return isQuoteOwner || isQuoteSales || hasPermission || hasDealerReadPermission || (!!quote?.stock);
  }

  const hasConvertStockPermission = ():boolean => {
    return isAdmin || isSalesDesk;
  }

  const getChangeOrderDisabledMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
      : quote?.archived ? "Archvived quote cannot be modified."
      : formDirty ? "Please save changes or reset."
      : isWorking ? "Please wait till background work has completed."
            //: !isQuoteModifiable() ? "This quote cannot be modified."
            //: !isChangeableRevision ? "This revision cannot be modified."
      : undefined;
  }

  const getConvertToStockDisabledMsg = () => {
    return formDirty ? "Please save change or create change order before attempting to split."
    : isOrderCancelled? "Order is cancelled."
    : (isSalesChangeOrder && isPending) ? "Change order in progress."
    : (isEngineeringChangeOrder && isPending) ? "Engineering change in progress."
    : (isSplitOrder && isPending) ? "Split order in progress."
    : isPending ? "Open approvals must be resolved."
    : !isLatestRevision ? "Must be latest revision."
    : undefined;
  }
 
  const getDisabledAssemblyExceptionsMsg = () : string | undefined => {

    return isNewQuote ? "Quote must be saved before an exception can be added.."
      : undefined;
  }

  const handleValidateForm = async () : Promise<ValidateFields | undefined> => {

    try {
      const values = await quoteForm.validateFields();
      return values;
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      //notification.error({message: "Please fix validation errors in Quote Info." });
      setValidationAlert(validationErrors.errorFields.map(f => f.errors.join(" ")));
    }

    return;
  }

  const handleReviseQuote = async () => {
    const q = await reviseQuote();
    if ( q ) {
      quoteAsync.mutate(quote);
    }
  }
  const reviseQuote = async () : Promise<Quote | void> => {
    if ( !quote ) return;

    try {
      const resp = await configurator.api.reviseQuote(quote.id)
      quoteAsync.mutate(resp.data);

      notification.success({message:"Reverted to Quote"});

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to revert quote. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  const saveQuoteForm = async () : Promise<Quote | undefined> => {
    if ( !quote ) return;

    setIsSaving(true);
    const dto = buildSaveDto( quoteForm.getFieldsValue() );

    var saved =  saveQuote(dto);
    if ( saved ) {
      await quoteAsync.mutate(saved);
      notification.success({message:"Quote Saved."});
    }

    setIsSaving(false);

    return saved;
  }

  const saveQuote = async (dto:SaveDto) : Promise<Quote | undefined> => {
    if ( !quote ) return;

    try {
      const resp = await configurator.api.saveQuoteRevision(quote.displayRevisionId, dto)

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save quote. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  
  const createQuote = async (dto:SaveDto) : Promise<Quote | undefined> => {

    try {
      const resp = await configurator.api.createQuote(dto)
      notification.success({message:"Quote Created."});
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create quote. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  const handleCreateQuote = async () => {

    const dto = buildSaveDto( quoteForm.getFieldsValue() );
    const savedQuote = await createQuote(dto);
    if (savedQuote ) {
      quoteAsync.mutate(savedQuote);
      setFormDirty(false); //avoid navigation alert
      setTimeout( () => history.push("/configurator/" + encodeURI(savedQuote.quoteId)), 10 );
    }
  }

  const handleSave = async () => {

    try {
      await quoteForm.validateFields();

      if ( isNewQuote ) {
        await handleCreateQuote();
      }
      else {
        await saveQuoteForm();
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleQuoteShareChange = async (shareLst:QuoteShare[] | undefined) => {
    const updatedQuote = { ...quote, quoteShares: shareLst } as Quote;
    if( updatedQuote ) {
      quoteAsync.mutate(updatedQuote)
    }
  }

  const handleReset = () => {
    Modal.confirm( {
      title: "Confirm Reset",
      content: "This will reset to the last saved changes.",
      onOk: () => quoteAsync.mutate()
    })
  }

  const reloadQuote = ()  => {
    quoteAsync.mutate();
  }

  const handleSubmission = (q:Quote)  => {
    quoteAsync.mutate();
    approvals.mutate();
  }

  const optionActionItems = new Array<ItemType>();

  if (configurator.isAdmin() ) {

    var adminSubMenuChildren = [
      {
        key: "adminSubMenu-assemblyExceptions",
        label: <SetDealerPriceButtonModal
            targetPrice={quote?.poNumber?.amount || pricingDetails?.totalPrice}
            revisionId={quote?.displayRevisionId}
            onChange={reloadQuote} type="text"/>
      },
      {
        key: "adminSubMenu-fixPricingHistory",
        label: <AddPricingHistoryButton
            onChange={reloadQuote} type="text"/>
      },
      {
        key: "adminSubMenu-moveTrucks",
        label: <MoveTrucksButton type="text"/>
      },
    ];

    if ( quote?.cancelled ) {
      adminSubMenuChildren.push(
          {
            key: "adminSubMenu-revertCancel",
            label: <RevertCancelButton type="text"
                                       onChange={() => {
                                         quoteAsync.mutate();
                                         quoteHistory.mutate();
                                       }} >Revert Cancellation</RevertCancelButton>
          },
      )
    }

    optionActionItems.push( {
      key: "adminSubMenu",
      label: "Admin",
      children: adminSubMenuChildren,
    });
  }

  if (configurator.isAdmin() || configurator.isEngineering()) {
    optionActionItems.push( {
      key: "engineeringSubMenu",
      label: "Engineering",
      children: [
        {
          key: "engineeringSubMenu-customOptions",
          label:
            <CustomOptionsButtonModal
              type="text"
              selectedCustomOptions={selectedCustomOptions}
              disabled={!!getDisabledAssemblyExceptionsMsg()}
              onDisabledClick={() => notifyDisabled(getDisabledAssemblyExceptionsMsg())}
              onChange={reloadQuote}
            />
        },
        {
          key: "engineeringSubMenu-assemblyExceptions",
          label:
            <AssemblyExceptionButtonModal
              type="text"
              disabled={!!getDisabledAssemblyExceptionsMsg()}
              onDisabledClick={() => notifyDisabled(getDisabledAssemblyExceptionsMsg())}
              onAdd={handleAddException}
            >
              Assembly Exceptions
              <Badge count={assemblyExceptions.data?.length} size="default" style={{marginLeft: ".2rem"}} />
            </AssemblyExceptionButtonModal>
        },
        {
          key: "engineeringSubMenu-engineerLock",
          label:
            <div style={{textAlign: "center"}}>
            <label >
              <Checkbox
                onChange={(e) => handleEngineeringLock(e.target.checked)}
                checked={!!quote?.lockedByEngineer}
                style={{padding:0}}
              />
              <span style={{marginLeft: "0.5rem"}}>
              Engineering Lock
              </span>
            </label>
            </div>
        },
        {
          key: "disableEpicor",
          label:
                <DisableEpicorCheckbox />
        }
      ]
    } as ItemType );
  }

  if (hasWritePermission) {
    optionActionItems.push( {
      key: "shareQuote",
      label: <ShareQuoteButtonModal type="text"
        className="ghostBmButton"
        onChange={handleQuoteShareChange}
      >Share Quote</ShareQuoteButtonModal>
    } );
  }

  if ( isSales ) {
    optionActionItems.push( {
      key: "bomDetails",
      label: <BomDetails
        type="text"
        selectedOptions={selectedOptions}
        canAccessAll={isEngineering || isAdmin || isFinance || isSalesDesk}
        pricingSnapshotId={quote?.pricingConfig?.pricingSnapshot?.id}
      />
    } );
  }

  optionActionItems.push( {
    key: "diffRevisions",
    label: <DiffRevisionModalButton />
  });

  if ( quote?.id ) {
    optionActionItems.push( {
      key: "releaseLock",
      label: <BMButton 
        type="text" 
        onClick={async () => {
          try {
            await configurator.api.releaseQuoteLock(quote.id);
            history.push("/quotes");
          } 
          catch (e:any) {
            notification.error( { message: "Failed to release quote lock. "});
          }
        }}
      >Release Quote Lock</BMButton>
    });
  }

  if ( !isNewQuote && hasCopyPermission() ) {

    optionActionItems.push( {
      key: "copy",
      label: <QuoteCopyModal
        disabled={isWorking}
        type="text"
        onFinished={(copy) => {
          window.open("/configurator/" + encodeURI(copy.quoteId), "_blank");
        }}
      />});
  }

  if ( isDocusignEnabled && ( isAdmin || isSalesDesk ) ) {
    optionActionItems.push( {
      key: "po-request",
      label: <RequestPoModalButton type="text" >Request PO</RequestPoModalButton>
    });
  }

  if ( ( isAdmin || isSalesDesk ) && !!quote?.salesOrderNumber ) {
    optionActionItems.push( {
      key: "clearSalesOrder",
      label: <RemoveSalesOrderButton type="text" onChange={reloadQuote} >Remove Sales Order</RemoveSalesOrderButton>
    });
  }

  if (isApproved && isLatestRevision && !formDirty ) {
    if ( hasSplitPermission && ( ( quote?.quantity || 0 ) > 1 )) {
      optionActionItems.push({
        key: "split",
        label: <SplitOrder
          type="text"
          className="ghostBmButton"
          quote={quote}
          disabled={!!getDisabledSplitMsg()}
          onDisabledClick={() => notifyDisabled(getDisabledSplitMsg())}
        />});
    }

    if ( !isNewQuote && ( isAdmin || isEngineering || isSalesDesk ) && !(isOrderShipped || isOrderCancelled) ) {
      optionActionItems.push({
        key: "generateSerialNumber",
        label: <GenerateSerialButton type="text" />
      });
    }

    if (hasCancelOrderPermission) {
      optionActionItems.push({
        key: "cancelOrder",
        label: <CancelOrderModule type="text" quote={quote}/>
      })
    }
  }

  if ( isOrder && hasConvertStockPermission() ) {
    optionActionItems.push( {
      key: "stock",
      label: <InventoryCheckbox 
        disabled={!!getConvertToStockDisabledMsg()}
      />
    });
  }

  if ( !isNewQuote && ( isAdmin || isEngineering || isSalesDesk || isQuoteOwner || isQuoteSales ) ) {
    optionActionItems.push({
      key: "archive",
      label: <ArchiveQuoteSwitch />
    });
  }

  const quoteContext = {
    quote, quoteId:params.quoteId, revision:revisionParam || undefined, adminView, isLocked,
    selectedModel, selectedOptions, selectedCustomOptions, setQuoteFormValues, validateQuoteForm:handleValidateForm,
    reviseQuote: handleReviseQuote,
    quoteDetails: computePricingDetails,
  };

  const tabLst =[
    {
      key: QUOTE_INFO_PANEL_KEY,
      label: <>
        <span>Quote Info</span>
        {!!validationAlert?.length && <Tooltip title={validationAlert.map(a => <div key={a + "-alert"}>{a}</div>)}>
          <WarningFilled data-testid="quoteInfoAlertIcon" style={{marginLeft: ".5rem", fontSize: "20px",  color: "orange" }} size={5}/>
        </Tooltip>}
      </>,
      children: 
      <QuoteInfoTab
        isWorking={isWorking}
        isLocked={isLocked}
        quoteForm={quoteForm}
        truckGvwr={truckGvwr}
        formDirty={formDirty}
        hidePrice={hidePrice}
        getFormValues={getFormValues}
        updatePricingSnapshot={updatePricingSnapshot}
        handleUpdateDiscountPercent={handleUpdateDiscountPercent}
        onQuoteValuesChange={onQuoteValuesChange}
        populateFormValues={populateFormValues}
        handleChangePricingView={handleChangePricingView}
      />,
      forceRender: true,
    },
    {
      key: ASSEMBLY_PANEL_KEY,
      label: "Truck Options",
      children:
          !!selectedCategory
          ? <AssemblySelectionTable
            loading={isWorking}
            percentDiscount={quoteForm.getFieldValue( FORM_PERCENT_DISCOUNT )}
            selectedCategory={selectedCategory}
            onSelectOption={handleSelectAssembly}
            filterOptionsQuery={filterOptionsQuery}
            optionNotes={optionNotes}
            onUpdateOptionNotes={handleUpdateOptionNotes}
            onClearSelections={handleClearSelections}
            disableAssemblyPricing={disableAssemblyPricing}
          />
          : <Row justify={"center"}><Title level={5}>Select category to show assembly table.</Title></Row>
    },
    {
      key: PERFORMANCE_PANEL_KEY,
      label: 
      <>
        <span>Performance</span>
        {existPerformanceError() && <Tooltip title={"Please review errors in Performance Details tab"}>
          <WarningFilled data-testid="performancePanelWarningIcon" style={{marginLeft: ".5rem", fontSize: "20px",  color: "orange" }} size={5}/>
        </Tooltip>}
      </>,
      children: 
      <QuotePerformanceTab performanceAsync={performanceAsync}/>,
      forceRender: true,
    },
    {
      key: DASH_PANEL_KEY,
      label:
      <>
        <span>Dash Drawing</span>
        {(needVerifyDash?.hasUnassignedComponents) && <Tooltip title={"There are errors with the dash layout.  Please review."}>
          <WarningFilled  data-testid="dashDrawingPanelWarningIcon" style={{marginLeft: ".5rem", fontSize: "20px", color: "orange"}} />
        </Tooltip>}
      </>,
      children: 
      <>
        {quote && <QuoteDashTab
          quote={quote}
        />}
      </>,
      forceRender: true,
    },
    {
      key: HISTORY_PANEL_KEY,
      label: "History",
      children: 
      <QuoteHistoryTab
        tabKey={tabKey}
      />
    },
  ];

  if ( configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk() ) {
    tabLst.push( {
      key: "QuoteAuditTab",
      label: "Audit",
      children: <QuoteAuditView />
    })
  }

  return (    <div>
    <style>
    {`
      .ant-descriptions .ant-descriptions-row > th, .ant-descriptions .ant-descriptions-row > td {
        padding-bottom: 5px;
      }

      .ant-descriptions .ant-typography {
        a{
          user-select: text;
        }
      }
    `}
    </style>

    <QuoteContextProvider value={quoteContext}>
    <div style={layoutStyle}>
      <AssemblySectionMenu
        loading={isWorking}
        computedOptions={computedValid.data}
        filterQuery={filterOptionsQuery}
        updateFilterQuery={setFilterOptionsQuery}
        onCategoryChange={setSelectedCategory} 
        onClickCategory={handleSelectCategory}
        onToggleAutoSelect={setDisableAutoselect}
        disableAssemblyPricing={disableAssemblyPricing}
        onToggleAssemblyPricing={setDisableAssemblyPricing}
      />
      {process.env.REACT_APP_PRODUCTION_ENV !== 'true' && 
        <Affix style={{  textAlign: "center", backgroundColor: "rgba(0, 0, 0, 0)"}}>
          <div style={{backgroundColor: "yellow",display: "inline-block", padding: ".4rem" }}> This is a non-production environment. </div>
        </Affix>
      }
      <div className="site-layout-background" style={{ marginLeft: isMobile ? "0px" : "75px" }}>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "-2rem" }}>
            <Title level={3}>
              Configurator{" "}
              <PriceViewSwitch
                options={getPriceView()}
                defaultValue={getPriceView()[0]}
                setPriceView={setPriceView}
              />
            </Title>
            {isCurrentRevision && (<div style={{ color: 'green', fontWeight: 'bold', marginTop: ".2rem" }}>Current Revision</div>)}
          </div>
          <div>

            <QuoteLock
              style={{ marginBottom: "20px" }}
              lockState={lockState}
              retryLock={retryLock}
              userActivity={userActivity}
            />

            {isPending && <div>
              <WorkflowProgress
                approvals={approvals.data}
                hideDescription={true}
                screenChangeWidthPx={1300}
              />
              <Divider orientation="right"/>
            </div>}

            {isArchived &&
              <Alert type="error" message={<>
                <div>This quote has been archived.  No changes are permitted.</div>
              </>}
              />}

            <AlertOldRevision />

            <Row>
              <Skeleton loading={!quote && quoteAsync.isLoading}>
                <Descriptions
                    layout="vertical"
                    colon={false}
                    column={4}
                    items={quoteDescriptionItems}
                    labelStyle={{fontWeight: "bold", color: "black"}}
                />
                {!!quote?.truckDescription &&
                <Descriptions
                    layout="vertical"
                    colon={false}
                    column={4}
                    items={[
                      {
                        key: "truckDescription",
                        label: "Truck Description",
                        span: 4,
                        children:
                            <Paragraph copyable={{ text: truckDescription || quote?.truckDescription || '' }} style={{marginRight: ".4rem"}}>
                              <span>{truckDescription || quote?.truckDescription || ''}</span>
                            </Paragraph>
                      }
                    ]}
                    labelStyle={{fontWeight: "bold", color: "black"}}
                />}
            </Skeleton>
            </Row>
            <Row justify={"space-between"}  style={{marginBottom: "1rem"}} >

              <Col>
                <Space>

                  <QuoteExportsDropDown
                    selectedRevisionId={selectedRevisionId}
                    pendingChanges={formDirty}
                    allSelected={allSelectedOrFrameSillLengthIsOnlyUnselectedCategory()} 
                    disabled={isWorking}
                  />

                  <Dropdown trigger={["click"]}
                    disabled={!optionActionItems.length}
                    menu={{items:optionActionItems}}
                  >
                    {/* this div is to avoid a warning with strict mode */}
                    <div>
                      <BMButton 
                        type="primary" 
                        disabled={!!getDisabledOptionsMsg()}
                        onDisabledClick={() => notifyDisabled(getDisabledOptionsMsg())}
                        icon={<MoreOutlined/>} 
                        data-testid="quote-options-btn"
                      >Options</BMButton>
                    </div>
                  </Dropdown>

                </Space>
              </Col>

              <Col>
                <Space>

                  {(!isReadOnly) &&
                  <UndoQuoteModalButton
                    disabled={!!getDisabledUndoMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledUndoMsg())}
                  />
                  }

                  {(!isReadOnly ) &&
                  <BMButton
                    disabled={!!getDisabledSaveMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledSaveMsg())}
                    onClick={handleReset}
                  >
                    Reset
                  </BMButton>}

                  {(!isReadOnly || canChangeReadOnly ) &&
                  <BMButton
                    type="primary"
                    data-testid="quote-save-button"
                    disabled={!!getDisabledSaveMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledSaveMsg())}
                    onClick={handleSave}
                  >
                    Save
                  </BMButton>}

                  {/* create change order */}
                  {(isOrder && isLatestRevision && !isOrderCancelled && ((isApproved && hasSubmitOrderPermission) )) &&
                      <CreateChangeOrderButton
                          disabled={!!getChangeOrderDisabledMsg()}
                          onDisabledClick={() => notifyDisabled(getChangeOrderDisabledMsg())}
                          onChange={handleSubmission}
                      >
                        Create Change Order
                      </CreateChangeOrderButton>}

                  {(isDraft && isChangeOrder ) &&
                    <AbandonChangeOrderButton
                      disabled={!!getDisabledAbandonMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledAbandonMsg())}
                      onChange={handleSubmission}
                  />}

                  {(isDraft && hasSubmitOrderPermission && isChangeOrder ) &&
                      <SubmitChangeOrderButton
                        disabled={!!getDisabledSubmitMsg()}
                        onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                        onValidate={handleValidateForm} 
                        onChange={handleSubmission}
                      />}

                  {(isDraft && isReviseQuote ) &&
                    <AbandonReviseQuoteButton 
                      disabled={!!getDisabledAbandonMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledAbandonMsg())}
                      onChange={handleSubmission}
                  />}

                {(isApproved && !isPending && !isOrder && !isOrderCancelled ) &&
                  <>
                    <ReviseQuoteButton
                      disabled={!!getDisabledReviseQuoteMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledReviseQuoteMsg())}
                      onClick={handleReviseQuote}
                    >
                      Revise Quote
                    </ReviseQuoteButton>

                    {(hasSubmitOrderPermission  && !isOrderCancelled && !quote?.reservation ) && <>
                      {isDocusignEnabled 
                        ? <SubmitDocusignOrderButton 
                          disabled={!!getDisabledSubmitOrderMsg()}
                          onDisabledClick={() => notifyDisabled(getDisabledSubmitOrderMsg())}
                          onChange={handleSubmission}
                        />
                        : <SubmitOrderButton 
                          disabled={!!getDisabledSubmitOrderMsg()}
                          onDisabledClick={() => notifyDisabled(getDisabledSubmitOrderMsg())}
                          onChange={handleSubmission}
                        />}
                    </>}
                  </>}

                  {(isApproved && !isPending && !isOrder && hasSubmitOrderPermission && quote?.reservation ) &&
                    <ConvertReservationButton
                      disabled={!!getDisabledConvertReservationMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledConvertReservationMsg())}
                      onChange={handleSubmission}
                  />}

                  {(!isOrder && !isApproved && !isPending && hasSubmitQuotePermission ) &&

                    <>
                      {( configurator.isInternalSales() ) 
                        ? <Dropdown
                          trigger={["click"]}
                          menu={{items:[
                            { 
                              key:"submitQuote",
                              label: <SubmitQuoteButton 
                                type="text"
                                disabled={!!getDisabledSubmitMsg()}
                                onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                                onChange={handleSubmission}
                              />
                            },
                            {
                              key:"submitQuoteReservation",
                              label: <SubmitQuoteButton 
                                type="text"
                                disabled={!!getDisabledSubmitMsg()}
                                onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                                onChange={handleSubmission}
                                isReservation={true}
                              />
                            }
                          ]}}
                        >
                          {/* this div is to avoid a warning with strict mode */}
                          <div>
                            <BMButton 
                              type="primary" 
                              disabled={!!getDisabledSubmitMsg()}
                              onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                              icon={<MoreOutlined/>}
                            >Submit</BMButton>
                          </div>
                        </Dropdown>
                        : 
                        <SubmitQuoteButton 
                          disabled={!!getDisabledSubmitMsg()}
                          onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                          onChange={handleSubmission}
                        />}
                    </>}

                </Space>
              </Col>

            </Row>

            <Space direction="vertical" style={{width: "100%"}}>

              {isAdminOrEngineering && <>

                {(epicorSyncStatus?.erpSyncStatus == EpicorSyncStatus.FAILURE ) &&
                  <Alert type="error"
                    closable={true}
                    message={<>
                      <span>The export to epicor has failed.</span>
                      {!!epicorSyncStatus.erpSyncMessage?.length && <>
                        &nbsp;&nbsp;<span>{epicorSyncStatus?.erpSyncMessage}.</span>
                      </>}
                    </>}
                  />
                }

                {(epicorSyncStatus?.erpSyncStatus == EpicorSyncStatus.PENDING ) &&
                  <Alert type="error" message="The export to epicor is pending." closable={true} />
                }
              </>}

              {(quote?.latestApproval?.action === ApprovalAction.REJECTED && quote.latestApproval.quoteInfo.quoteRevisionId === quote.displayRevisionId && !isPending ) &&
                <Alert type="error" message={<>
                  <div>{isOrder ? "These changes have" : "This quote has"} been rejected by {!!quote.latestApproval.actionedBy && <span>{quote.latestApproval.actionedBy.name}</span>}.</div>
                  {!!quote.latestApproval.actionNotes?.length && <div style={{padding: ".4rem"}}>Details: {quote.latestApproval.actionNotes}</div> }
                  <div>For more information contact your sales engineer or sales desk.</div>
                </>}
                />
              }

            </Space>

            <Tabs 
              activeKey={tabKey}
              onTabClick={(key) => handleTabClick(key)}
              items={tabLst}
            />
          </div>
        </Col>
      </div>

    </div>
    </QuoteContextProvider>
    <UnsavedChangesWarning isDirty={formDirty} />
  </div>);
}

const DisableEpicorCheckbox = (props:CheckboxProps) => {

  const { quote} = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const handleDisableEpicor = (enabled: boolean) => {
    if ( !quote ) return;

    if ( enabled ) {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will prevent this order from syncing with Epicor.',
        onOk: () => toggleEpicor(quote.displayRevisionId, enabled),
      });
    }
    else {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will enable epicor syncing.  Please verify why it was originally disabled.',
        onOk: () => toggleEpicor(quote.displayRevisionId, enabled),
      });
    }
  }

  const toggleEpicor = async (quoteRevisionId:number, enabled:boolean) => {

    try {

      const resp = await configurator.api.updateQuoteRevision(quoteRevisionId, { epicorDisabled: enabled});
      await quoteAsync.mutate(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to updateQuote. " + errorMsg });
    }
  }

  return <div style={{textAlign: "center"}}>
    <label >
      <Checkbox
          {...props}
          checked={quote?.epicorDisabled}
          onChange={(e) => handleDisableEpicor(e.target.checked)}
          style={{padding:0}}
      />
      <span style={{marginLeft: "0.5rem"}}>Disable Epicor</span>
    </label>
  </div>
}



const InventoryCheckbox = (props:CheckboxProps) => {

  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const convertToStock = async (quoteId:number, enabled:boolean) => {

    try {

      const resp = await configurator.api.convertQuoteToStock(quoteId, enabled);
      await quoteAsync.mutate(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to convert to stock. " + errorMsg });
    }
  }

  const handleConvertStock = () => {
    if ( !quote ) return;

    if ( !quote.stock ) {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will move the order to inventory where it can be re-sold.',
        onOk: () => convertToStock(quote.id, true),
      });
    }
    else {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will move the order out of inventory.',
        onOk: () => convertToStock(quote.id, false),
      });
    }

  }

  return <div style={{marginLeft: "1rem"}}>
    <label >
      <Checkbox
        {...props}
        checked={quote?.stock}
        onChange={() => handleConvertStock()}
        style={{padding:0}}
      />
      <span style={{marginLeft: "0.5rem"}}>Inventory</span>
    </label>
  </div>
}

const AlertOldRevision = () => {
  const quoteContext = useQuoteContext();
  const quote = quoteContext.quote;

  if ( !quote || quote?.displayRevisionId === quote?.effectiveRevisionId ) return <></>

  var latestUrl = "/configurator/" + encodeURI(quote?.quoteId);

  return <div style={{marginBottom: "1rem"}}>
    <Alert
        type="warning"
        showIcon
        message={<>This is an old revision. <Link to={latestUrl}>Return to latest.</Link></>}
        style={{marginBottom: "1rem"}}
    />
  </div>
}

export default Configurator;
