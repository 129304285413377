import { Button, Col, Divider, Form, Input, FormProps, notification, Row, Select, Table } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {useIntl} from "react-intl";
import {CountryCode, Customer, CustomerTags, EmailPattern, EntityType} from "../api/models";
import { ConfiguratorContext } from "../context";
import {useAsyncState} from "../hook/useAsyncState";
import BMButton, {BMButtonProps} from "./BMButton";
import {ColumnType} from "antd/es/table";
import {debounce} from "lodash";
import DealerSelector from "./dealer_selector";
import { useWatch } from "antd/es/form/Form";
import StatesSelector from "./StatesSelector";
import Utils from "../util/util";
import ModalWizard from "./ModalWizard";
import { WizardInstance, WizardStep } from "./Wizard";
import useCustomers from "../swr/useCustomers";

export const EditCustomerForm = (props:FormProps) => {
  const country = useWatch("countryCode", props.form);
  const configurator = useContext(ConfiguratorContext);
  const isAdmin = configurator.isAdmin();

  useEffect(() => {
    props.form?.resetFields();
  }, [props.initialValues]);

  return <Form
    {...props}
    labelCol={{span: 6}}
    wrapperCol={{span: 18}}
  >
    <Form.Item
      name="id"
      hidden={true}
    >
      <Input disabled={!!props.initialValues?.id} />
    </Form.Item>
    <Form.Item
      label="Name"
      name="name"
      rules={[{required: true, message: "Name is required"}]}
    >
      <Input />
    </Form.Item>

    <Divider orientation="left" style={{marginTop: "3rem"}}>Address</Divider>
    <Form.Item
      label="Line 1"
      name="addressLine1"
      rules={[{required: true, message: "Address is required"}]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Line 2"
      name="addressLine2"
    >
      <Input />
    </Form.Item>
    <Form.Item 
      label="City" 
      name="city" 
      rules={[{required: true, message: "City is required"}]}>
      <Input />
    </Form.Item>
    <Form.Item
      label="State"
      name="stateProvince"
      rules={[{required: true, message: "State is required"}]}
    >
      <StatesSelector country={country}/>
    </Form.Item>
    <Form.Item
      label="Postal Code"
      name="postalCode"
      rules={[{required: true, message: "Postal code is required"}]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Country"
      name="countryCode"
    >
      <Select
        showSearch
        options={[
          {label: "United States", value: CountryCode.US},
          {label: "Canada", value: CountryCode.CA},
        ]} />
    </Form.Item>

    <Divider orientation="left" style={{marginTop: "3rem"}}>Contact</Divider>
    <Form.Item
      label="Title"
      name="contactTitle"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Name"
      name="contactName"
      rules={[{required: true, message: "Contact name is required."}]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Role"
      name="contactRole"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Phone"
      name="contactPhone"
      rules={[{required: true, message: "Contact phone is required."}]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Email"
      name="contactEmail"
      rules={[
        {
          required: true,
          message: "Contact email is required.",
        },
        {
          pattern: EmailPattern,
          message: 'Please enter a valid email address!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Entity Type"
      name="entityType"
      rules={[
        {
          required: true,
          message: "Entity Type is required.",
        },
      ]}
    >
      <Select
        options={Object.values(EntityType).map(val => {return {
          label: Utils.snakeCaseToFirstLetterCapitalized(val),
          value: val,
        }})}
      />
    </Form.Item>

      <Form.Item
        label="Dealer"
        name="dealerId"
        hidden={!isAdmin}
      >
        <DealerSelector style={{ width: "100%" }} />
      </Form.Item>

    <Form.Item
        label="Tags"
        name="tags"
        hidden={!isAdmin}
    >
      <Select mode="tags"
              options={Object.values(CustomerTags).map(t => ({layout: t, value:t}))}
      />
    </Form.Item>

</Form>
}

const DEFAULT_PAGE_SIZE = 5;

const CustomerEntry = (props: Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?:Customer
  onChange?:(c:Customer | undefined)=>void
  filter?:(c:number)=>void
}) => {
  const { id:a, value:b, onChange:c, ...btnProps } = props;

  const [isOpen, setIsOpen] = useState<boolean>();
  const [editCustomer, setEditCustomer] = useState<Customer>();
  const [customer, setCustomer] = useState<Customer | undefined>(props.value);
  const [wizardInstance, setWizardInstance] = useState<WizardInstance | undefined>();
  const [_activeStep, setActiveStep] = useState<React.Key | undefined>();

  const handleOpen = () => {
    setCustomer(props.value);
    wizardInstance?.resetSteps();
  }

  const handleCancel = () => {
    setIsOpen(false);
    setEditCustomer( undefined );
  }

  const handleRemove = () => {
    props.onChange?.(undefined);
    setIsOpen(false);
  }
  
  const handleDone = () => {
    props.onChange?.(customer);
    setIsOpen(false);
  }

  const handleNew = () => {
     wizardInstance?.nextStep();
  }

  const handleEdit = () => {
    setEditCustomer( customer );
     wizardInstance?.nextStep();
  }

  const handleCancelEdit = () => {
    setEditCustomer( undefined );
    wizardInstance?.prevStep();
  }

  const handleDoneEdit = (c:Customer | undefined) => {
    setEditCustomer( undefined );
    setIsOpen(false);
    props.onChange?.( c );
  }

  const disabled = btnProps.disabled;
  const handleDisabledClick = () => {
      btnProps.onDisabledClick?.();
  };
  const handleStep = (i:WizardInstance | undefined, s:WizardStep | undefined) => {
    setWizardInstance(i);
    setActiveStep(s?.key);
  }

  const btnLbl =  ( props.value ) ? props.value?.name
    : disabled ? "None"
    : "Add Customer";

  //hide border when disabled
  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};


  return <>
    <BMButton type="text"
      className="ghostBmButton"
      data-testid="endCustomer-button"
      disabled={disabled}
      onDisabledClick={handleDisabledClick}
      onClick={() => setIsOpen(true)}
      style={{padding: "0", ...btnProps.style}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <ModalWizard
      title="Select Customer"
      showSteps={false}
      open={isOpen}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if(open) {
          handleOpen();
        }
      }}
      width={"45rem"}
      onStep={handleStep}
      steps={[
      {
          key: "selectCustomer",
          title: "Select Customer",
          body: () => <div>

            <Row gutter={8} justify="end" style={{marginBottom: "1rem"}}>
              <Col> 
                <Button type='primary' disabled={!customer} onClick={handleEdit} >Edit</Button>
              </Col>
              <Col> 
                <Button type="primary" onClick={handleNew} >New</Button> 
              </Col>
            </Row>

            <SelectEndCustomer value={customer} onChange={setCustomer} />
          </div>,
          footer:() => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="remove" danger onClick={handleRemove}>Remove</Button>
            <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse"}}>
              <Button key="done" type="primary" onClick={handleDone}>Done</Button>
              <Button key="cancel" onClick={handleCancel}>Cancel</Button>
            </div>
          </div>
        },
        useEditCustomerStep({
          title: editCustomer?.id ? "Edit " + editCustomer.name : "Add Customer",
          hidden: !editCustomer,
          value: editCustomer,
          onChange: handleDoneEdit,
          onCancel: handleCancelEdit
        })
      ]}
      />
  </>
};

export const useEditCustomerStep = (props: {
  key?: React.Key
  title?: string
  hidden?: boolean
  value: Customer | undefined
  onChange?:(t:Customer | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const customers = useCustomers();

  const [form] = Form.useForm();
  const [_customer, customerAsync] = useAsyncState<Customer>();

  const handleSave = async () => {

    try {
      const values = await form.validateFields();

      const customer = await saveCustomer( values );
      if ( customer ) {
        props.onChange?.( customer );
      }
    }
    catch(e:any) {
    }
  }

  const handleCancel = () => {
    props.onCancel?.();
  }

  const saveCustomer = async (customer: Customer) : Promise<Customer | undefined> => {

    customerAsync.setLoading()
    try {
      const resp = (customer.id) 
        ? await configurator.api.updateCustomer(customer)
        : await configurator.api.createCustomer(customer);
      notification.success({message:"Customer Saved"});
      customerAsync.setDone( resp.data );

      customers.mutate();
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error saving customer.  " + errorMsg });
      customerAsync.setFail(e.message);
    }
    return;
  };
  
  return {
    title: props.title,
    key: props.key || "editCustomer",
    body: () => <div>
      <EditCustomerForm
        form={form}
        initialValues={props.value}
      />
    </div>,
    footer:(nav:WizardInstance) => 
      <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="save" type="primary" onClick={handleSave} loading={customerAsync.isLoading()} >Save</Button>
        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
      </div>
  }
}

export const SelectEndCustomer = (props: {
  value?: Customer
  onChange?:(c:Customer | undefined)=>void
}) => {

  const {value:customer} = props;

  const [filter, setFilter] = useState<string | undefined>();
  const [pagination, setPagination] = useState<any>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });

  const customers = useCustomers();

  useEffect(() => {
    const page = ( customer &&  datasource?.length ) ? Math.floor( ( datasource.map( c => c.id ).findIndex( id => id === customer?.id) / DEFAULT_PAGE_SIZE) + 1 ) : 1;
    setPagination( { pageSize: DEFAULT_PAGE_SIZE, current: page } );
  }, [customers.data]);

  useEffect(() => {
    if (filter) {
      const page = ( customer &&  datasource?.length ) ?
        Math.floor( ( datasource.findIndex( c => c?.name?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase())) / DEFAULT_PAGE_SIZE) + 1 )
        : 1;
      setPagination( { pageSize: DEFAULT_PAGE_SIZE, current: page } );
    }
  }, [filter]);


  const columns:ColumnType<Customer>[] = [
    {
    dataIndex: "name",
    title: "Name",
    },
    {
      dataIndex: "Address",
      title: "Name",
      render: (_, c: Customer) => Utils.getAddress(c),
    },
  ];


  const handleChangeFilter = useCallback(
    debounce( (e:any) => {
      setFilter( e.target.value )
    }, 400 )
    , [] );

  const datasource = customers.data?.filter( m => !filter || m.name?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase() ) )

  const handleSelectRow = (record:Customer) => {

    //toggle previously selected
    if ( props.value?.id === record.id ) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(record);
  }


  return <div>
    <style>
      {`
        /* don't show error border on filter */
        .customerFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
        .customerFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
        border-color: #d9d9d9;
        }
        .customerLst .ant-table-content { padding: 5px; } /* don't clip corners */
        .customerLst .ant-table-cell { border: none !important; } /* remove table cell borders */
        /* add error border to table */
        .ant-form-item-has-error .customerLst .ant-table-content {
        border: solid 1px #ff4d4f;
        border-radius: 15px;
        }
      `}
    </style>

    <Input className="customerFilter" onChange={handleChangeFilter} placeholder="Filter Customers" allowClear />

    <Table 
      className="customerLst"
      showHeader={false}
      columns={columns}
      loading={customers.isLoading}
      rowKey="id"
      dataSource={datasource}
      onChange={(pagination) => setPagination(pagination)}
      pagination={pagination}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: customer?.id ? [customer.id] : [],
      }}

    />
  </div>

};

export default CustomerEntry;
