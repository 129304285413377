import { Select, SelectProps } from "antd";
import Utils from "../util/util";
import useModelCategories, {ModelCategoryRequestOptions} from "../swr/useModelCategories";

const SelectModelCategory = (props: Omit<SelectProps, 'options'> & {
  modelId?:number,
  options?:ModelCategoryRequestOptions
}) => {

  const {modelId, options, ...selectProps } = props;
  const categories = useModelCategories({
    modelId,
    options
  });

  return (
    <Select
      allowClear
      showSearch
      {...selectProps}
      optionFilterProp="label"
      loading={categories.isLoading}
      options={categories.data?.map(cat => ({value:cat.id, label: Utils.stripSortingPrefix(cat.name)}))}
    />
  );
};

export default SelectModelCategory;
