import {Button, Modal, notification, Result, Space, Spin} from "antd";
import Title from "antd/lib/typography/Title";
import _ from "lodash";
import React, { useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Quote, RevisionChangeDto, RevisionType} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import BMButton, {BMButtonProps} from "../BMButton";
import QuoteRevisionDiffTable from "../Table/QuoteRevisionDiffTable";
import { useQuoteContext } from "../../contexts/QuoteContext";
import ModalWizard from "../ModalWizard";
import {Link} from "react-router-dom";
import useQuoteHistory from "../../swr/useQuoteHistory";
import useQuote from "../../swr/useQuote";

const AbandonChangeOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quote} = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [diff, diffAsync] = useAsyncState<RevisionChangeDto>();

  const history = useQuoteHistory({
    quoteId: quote?.quoteId,
    showAbandoned:false
  });

  const abandonChangeOrder = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    try {

      const resp = await configurator.api.abandonRevision(quote.displayRevisionId );
      await quoteAsync.mutate(resp.data);
      history.mutate();

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to abandon change order. " + errorMsg;

      notification.error( { message: msg });
    }

    return;
  };

  const loadDiff = async () : Promise<RevisionChangeDto | undefined> => {
    if ( !quote ) return;

    try{
      diffAsync.setLoading();
      const resp = await configurator.api.verifyRevisionChange(
        {
          quoteId: quote.id,
          verifyingRevisionId: quote.displayRevisionId,
          compareCurrentRevision: true,
        }
      );
      diffAsync.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response.data.message });
      const msg = "Failed to get change difference. " + errorMsg;

        notification.error( { message: msg });
        diffAsync?.setFail(e.message);
    }     
  }

  function isEmptyDeep(obj:any) {
    if(_.isObject(obj)) {
      if(Object.keys(obj).length === 0) return true
        return _.every(_.map(obj, v => isEmptyDeep(v)))
    } else if(_.isString(obj)) {
      return !obj.length
    }
    return false
  }

  const handleAbandonClick = async () => {

    const diff = await loadDiff();
    if (!isEmptyDeep( diff?.diffDto) ) {
      setIsOpen(true);
    }
    else {
      handleAbandon();
    }
  }

  const handleAbandon = async () => {
    const quote = await abandonChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);
    }
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  return <>
    <BMButton
      onClick={handleAbandonClick}
      {...btnProps}
    >
      Abandon
    </BMButton>
    <ModalWizard
        showSteps={false}
        centered
        open={isOpen}
        onCancel={handleCancel}
        width={"80%"}
        style={{
            maxWidth: "70rem",
            minWidth: "50rem"
        }}
        steps={[
          {
            key:"warning",
            hidden: quote?.revisionType != RevisionType.SPLIT_ORDER,
            body: (_nav) =>
                <Result status="warning"
                        title={"This will cancel the entire split."}
                >
                  This quote is part of a split change. The following quotes will be effected:
                  <ul>
                    {quote?.approvalGroups
                        ?.flatMap(ag => ag.quoteRevisions)
                        .filter( qr => qr.quoteId !== quote.quoteId)
                        .map(partner => (
                            <li key={partner.quoteId}>
                              <Link style={{ marginLeft: "5px", marginRight: "5px" }} to={"/configurator/" + encodeURI(partner.quoteId)}>
                                {partner.quoteId}
                              </Link>
                            </li>
                        ))}
                  </ul>
                </Result>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
              <Button key="back" onClick={handleCancel} >Cancel</Button>
            </div>,
          },
          {
            key: "review",
            body: (_nav) => <Spin spinning={diffAsync.isLoading() || quoteAsync?.isLoading}>
              <div style={{marginTop: "1rem"}}>
                <Space direction={"vertical"} style={{width: "100%"}}>
                  {!!diff?.changeSummary?.length && <>
                    <Title level={5}>Change Summary: </Title>
                    <span>{diff.changeSummary}</span>
                  </>}
                  <QuoteRevisionDiffTable diff={diff?.diffDto} hidePriceChange={true}/>
                </Space>
              </div>
            </Spin>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button onClick={() => setIsOpen(false)} >
                Cancel
              </Button>
              <Button type="primary" onClick={handleAbandon} >
                Abandon
              </Button>
            </div>,
          }
        ]}
          />
  </>
}

export default AbandonChangeOrderButton;

