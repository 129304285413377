import {Form, InputNumber, Modal, notification} from "antd";
import {useContext, useState} from "react";
import BMButton, {BMButtonProps} from "../BMButton";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import {useQuoteContext} from "../../contexts/QuoteContext";
import useQuote from "../../swr/useQuote";

const SetDealerPriceButtonModal = (props: Omit<BMButtonProps, 'onChange'> & {
  targetPrice: number | undefined
  revisionId: number | undefined
  onChange?: () => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>();

  const [form] = Form.useForm();
  const { revisionId, onChange, targetPrice, ...buttonProps} = props;
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const {quote} = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const handleOk = async () => {
    if ( !revisionId ) return;

    try {
      var values = await form.validateFields();

      if ( values.targetPrice !== undefined ) {
        priceMatch( revisionId, values.targetPrice );
        await quoteAsync.mutate();
        setIsOpen(false);

        onChange?.();
      }

    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

  }

  const priceMatch = async ( revisionId: number, target:number ) => {
    //don't update a price of zero
    if ( !target ) return;

    try {
      const resp = await configurator.api.priceMatchRevision(revisionId, target);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to assign engineer. " + errorMsg });
    }

    return;
  }


  const resetTargetValue = () => {
    form.resetFields();
    form.validateFields();
  }

  return <>
    <BMButton
      className="ghostBmButton"
      type="text"
      {...buttonProps}
      disabled={buttonProps.disabled}
      onClick={() => setIsOpen(true)}
    >Set Dealer Price</BMButton>
    <Modal 
      title={"Set Dealer Price"}
      open={isOpen}
      onOk={() => handleOk()}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={resetTargetValue}
    >
      <Form form={form} 
        initialValues={{targetPrice }}
      >
        <Form.Item label="Target Total Price" name="targetPrice" >
          <InputNumber 
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Type dollar amount." 
            controls={true} 
            style={{width:"100%"}}
          />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

export default SetDealerPriceButtonModal;
