import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {AXIOS_CANCEL_MSG, Page, ApprovalFilter, SortDirection, Approval} from '../api/models';
import { ConfiguratorContext } from '../context';
import {SorterResult} from "antd/es/table/interface";


interface ApprovalListProps {
  filter?: ApprovalFilter
  page?: number
  size?:number,
  sorter?:SorterResult<Approval> | SorterResult<Approval>[]
}

export const useApprovalList = (props: ApprovalListProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:ApprovalListProps  ) : Promise<Page<Approval> | undefined> => {

    const { filter, page, size, sorter } = props;

    const sort = [sorter].flat()
        .filter( s => !!s?.columnKey )
        .map( s => ({
          field: s?.columnKey?.toString() || "",
          direction: ( s?.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
        }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {

      const resp = await configurator.api.listQuoteApprovals({
          ...filter,
          page: page || 0,
          size: size || 20,
          sort,
        },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }

  // Use SWR for data fetching
  return useSWR([
    'listQuoteApprovals',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useApprovalList;

