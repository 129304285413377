import {Button, Card, StepProps, Steps, Tooltip} from "antd";
import {Approval, ApprovalAction } from "../api/models";
import {CSSProperties, useContext} from "react";
import {ConfiguratorContext} from "../context";
import {CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import dayjs from "dayjs";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {useHistory} from "react-router-dom";

const WorkflowProgress = (props: {
  approvals:Approval[] | undefined
  hideDescription? : boolean
  screenChangeWidthPx? : number
  style?: CSSProperties
}) => {

  const history = useHistory();

  const configurator = useContext(ConfiguratorContext);
  const isInternalSales = configurator.isInternalSales();
  const {hideDescription, screenChangeWidthPx=1200} = props;
  const direction = useCheckMobileScreen(screenChangeWidthPx) ? "vertical" : "horizontal";

  const approvals = props.approvals;

  type StepStatus = 'finish' | 'process' | 'error';
  const getStatus = (approval: Approval): StepStatus =>
      approval?.action === ApprovalAction.REJECTED ? "error"
          :approval?.action === ApprovalAction.APPROVED ? "finish"
              : "process";

  const getActionTime = (approval: Approval) => {
    if (approval?.actionedAt) {
      return `At: ${dayjs(approval?.actionedAt == null ? approval?.createdAt : approval?.actionedAt).format("M/D/YY, h:mm a")}`
    }
    return !approval.action && "Processing";
  }

  const getActionColor = (approval: Approval) => {
    return approval?.action ? (approval?.action === ApprovalAction.APPROVED ? "green" : "red") : "#1677ff";
  }

  const ActionIcon = (props:{ approval: Approval}) => {
    return (props.approval?.action === ApprovalAction.APPROVED
        ? <Tooltip title="Approved"><CheckCircleOutlined style={{fontSize: "12px", marginRight: "5px", color: getActionColor(props.approval)}} /></Tooltip>
        : <Tooltip title="Rejected"><CloseCircleOutlined style={{fontSize: "12px", marginRight: "5px", color: getActionColor(props.approval)}} /></Tooltip>);
  }


  const items: StepProps[] = approvals?.map((approval, idx) => ({
    title:
        (!hideDescription && approval.action )
            ? <Card
                title={<>
                  { (approval?.actionNotes && isInternalSales) &&
                      <Tooltip title={approval?.actionNotes ? `Note: ${approval?.actionNotes}` : ""}>
                        <ExclamationCircleFilled style={{ color: "#1677ff", marginRight: "5px" }} />
                      </Tooltip>}
                  <div>
                    {approval.approvalStep.name}
                    {approval.action === ApprovalAction.APPROVED && " (Approved)"}
                  </div>
                </> }
                bordered={false}
                style={{ maxWidth: 140, minWidth: 120, marginTop: "10px" }}
                rootClassName="step-card"
            >
                <ActionIcon approval={approval} />
                <span style={{fontWeight: "500", fontSize: "12px", color: getActionColor(approval)}}>
                        <span>{approval?.actionedBy?.name || "Skipped"}</span>
                      </span>
                <div key={`${idx}-actionedAt`} style={{fontWeight: "500", fontSize: "12px", color: getActionColor(approval)}}>{getActionTime(approval)}</div>
            </Card>
            : approval.id ?
                <Button type={"text"} className={"ghostBmButton"} onClick={() => handleChangeStep(approval)}>
                    <div>
                        <span style={{textDecoration: (approval.approver && !approval.action) ? "underline" : undefined}}>{approval.approvalStep.name}</span>
                        {(!approval.action) && <div style={{color: "orange", fontWeight: 'bold'}} >(Pending)</div>}
                    </div>
                </Button>
            : <div>
                {approval.approvalStep.name}
            </div>,
    status: getStatus(approval),
  })) || [];

  const handleChangeStep = (approval) => {
    if ( approval.id ) {
      history.push( `/approvals/${approval.id}`);
    }
  }

  return <>
    <style>
      {`
        .workflow-steps .ant-steps-item-content {
          width: 170px !important;
          margin-top: 6px !important;
          margin-left: -30px !important;
        }

        .step-card {
          box-shadow: 0 0.4px 1.5px 0.5px rgba(0, 0, 0, 0.2) !important;
        }

        .step-card .ant-card-head {
          font-size: 12px;
          justify-content: normal !important;
          max-width: 180px;
          padding-left: 4px;
          padding-right: 4px;
          min-height: 40px;
          flex-direction: unset;
        }

        .step-card .ant-card-head-title {
          white-space: normal;
        }
        
        .step-card .ant-card-body {
          font-size: 12px;
          justify-content: normal !important;
          padding: 0px !important;
          padding-left: 2px !important;
          padding-right: 2px !important;
        }

        @media (max-width: ${screenChangeWidthPx}px) {
          .workflow-steps .ant-steps-item {
            padding-bottom: 20px !important;
          }

          .workflow-steps .ant-steps-item-title {
            margin-left: 10px !important;
            margin-bottom: 10px !important;
            margin-top: -5px !important;
          }

          .workflow-steps .ant-steps-item-tail {
            margin-top: 6px !important;
          }
          
        }
      `}
    </style>
        <Steps
          data-testid="ApprovalTransitionInfoSteps"
          items={items}
          rootClassName="workflow-steps"
          style={{
              ...(props.style || {}),
              marginTop: "1rem"
           }}
          size="small"
          responsive={true}
          labelPlacement="vertical"
          direction={direction}
        />
      </>
}

export default WorkflowProgress;