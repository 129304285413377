import {Button, Descriptions, Form, Space, Input, notification, Row, Col, Checkbox, Card} from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useState } from "react";
import BMButton, { BMButtonProps } from "./BMButton";
import ModalWizard from "./ModalWizard";
import { WizardStep } from "./Wizard";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../context";
import PagedDescriptions from "./PagedDescriptions";
import { useQuoteContext } from "../contexts/QuoteContext";
import { currencyFormatter } from "./CurrencyInputNumber";
import Utils from "../util/util";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import useComputePricing from "../swr/useComputePricing";

export interface RequestPurchaseOrderFormValue {
  signerName: string
  signerEmail: string
  dealerName: string
  dealerAddress: string
  customerName: string
  customerAddress: string
  shipTo: string
  sendConfirmation: boolean
  sendSpecs: boolean
  sendPo: boolean
}

const RequestPoModalButton = (props:Omit<BMButtonProps, 'onChange' | 'quote' > & {
  onChange?:(co:RequestPurchaseOrderFormValue) => void
  onSkip?:() => void
}) => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quote } = useQuoteContext();

  const {onChange, ...btnProps} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [docusignLoading, setDocusignLoading] = useState<boolean>(false);

  const requestPurchaseOrder = async (quoteRevisionId: number | undefined, req:RequestPurchaseOrderFormValue) : Promise<boolean | undefined> => {
    if ( !quoteRevisionId) return;

    setDocusignLoading(true);
    try {
      await configurator.api.requestPo(quoteRevisionId, req)
      return true;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to request purchase order. " + errorMsg });
    }
    setDocusignLoading(false);

    return false;
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleChange = async (values:RequestPurchaseOrderFormValue) => {

    await requestPurchaseOrder( quote?.displayRevisionId, values );

    setIsOpen(false)

    props.onChange?.(values);
  }

  const handleSkip = async () => {

    setIsOpen(false)

    props.onSkip?.();
  }

  const {steps} = useRequestPoSteps({
    loading:docusignLoading,
    onFinished: handleChange,
    onSkip: handleSkip
  })

  return <>
    <BMButton type="primary"
      {...btnProps}
      onClick={handleOpen}
    >{props.children}</BMButton>
    <ModalWizard
      open={isOpen}
      onCancel={handleCancel}
      showSteps={false}
      steps={steps}
    />
  </>

}

export const useRequestPoSteps = (props: {
  loading?:boolean | undefined
  onFinished: (v:RequestPurchaseOrderFormValue) => void
  onSkip: () => void
}): {steps: WizardStep[]} => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quote } = useQuoteContext();

  const {isOrder} = Utils.getQuoteState(configurator, quote);

  const [requestPoForm] = useForm();

  const [exportProgress, setExportProgress] = useState<number|undefined>();

  const handleExportSerialNumbers = async () => {
    const quoteRevisionId = quote?.displayRevisionId;
    if ( !quoteRevisionId ) return;

    const url = configurator.api.getExportPoDetailsCSVUrl(quoteRevisionId);
    configurator.api.downloadCsv( url, undefined, setExportProgress )
      .catch((e) => {
        const errorMsg = intl.formatMessage({ id: e.message })
        notification.error( {message: "Export failed. " + errorMsg } );
      })
      .finally( () => {
        setExportProgress( undefined );
      });
  }

  const handleRequestPO = async () => {

    try {
      const formValues = await requestPoForm.validateFields() as RequestPurchaseOrderFormValue;

      if ( configurator.isDealerSales() ) {
        formValues.sendConfirmation = !isOrder;
        formValues.sendSpecs = true;
        formValues.sendPo = true;
      }

      if ( !(formValues.sendConfirmation || formValues.sendSpecs || formValues.sendPo ) ) {
        throw new Error("At least one document must be sent.")
      }

      props.onFinished(formValues);
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      const errs = validationErrors.errorFields?.map( f => f.errors ).flat().map( (msg, ndx) => <li key={`validationError-${ndx}`}>{msg}</li> );
      const errorMsg = !errs?.length ? "Please fix validation errors." : <ul>{errs}</ul>;
      notification.error({message: errorMsg });
    }
  }

  const handleSkip = async () => {

    try {
      props.onSkip();
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const isDealerQuote = !!quote?.owner?.dealerId;

  const signerName = isDealerQuote 
    ? quote?.owner?.name 
    : quote?.endCustomer?.contactName;

  const signerEmail = isDealerQuote 
    ? quote?.owner?.email 
    : quote?.endCustomer?.contactEmail;

  const defaultPoNumber = `PO${quote?.quoteId}R${quote?.revision}`;

  const initialValues = {
    signerName,
    signerEmail,
    dealerName: quote?.owner?.dealerName,
    dealerAddress: Utils.getAddress(quote?.dealerCustomer),
    customerName: quote?.endCustomer?.name,
    customerAddress: Utils.getAddress(quote?.endCustomer),
    shipTo: Utils.getAddress( quote?.shippingDestination?.customer ),
    poNumber: defaultPoNumber,
    sendConfirmation: !isOrder,
    sendSpecs: true,
    sendPo: true
  };

  const steps:WizardStep[] = [
    {
      key:"poSummary",
      title: "Purchase Order Summary",
      body: (_nav) => <>
        <PurchaseOrderDescription />
      </>,
      footer: (nav) => <div style={{display: "flex", justifyContent:"space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Button onClick={handleExportSerialNumbers} loading={exportProgress !== undefined}>Export</Button>
        <Space >
          <Button key="back" onClick={() => nav.prevStep()} >Back</Button>
          <Button key="next" type="primary" disabled={false} onClick={() => nav.nextStep()} >Next</Button>
        </Space>
      </div>,
    },
    {
      key:"requestPo",
      title: "Please verify and correct the following information.",
      body: (_nav) => <>
        <Form 
          size="small"
          layout="vertical"
          form={requestPoForm}
          initialValues={initialValues}>

          {!configurator.isDealerSales() &&
          <Card title={"Documents"} bordered={false} size={"small"} style={{boxShadow: "none"}}>
            <Row justify={"space-between"}>
              {!isOrder &&
              <Col>
                <Form.Item label="Confirmation Letter"
                           name="sendConfirmation"
                           valuePropName="checked"
                           style={{marginBottom: 0}}
                >
                  <Checkbox />
                </Form.Item>
              </Col>}
              <Col>
                <Form.Item label="Specifications"
                           name="sendSpecs"
                           valuePropName="checked"
                           style={{marginBottom: 0}}
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Purchase Order"
                           name="sendPo"
                           valuePropName="checked"
                           style={{marginBottom: 0}}
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </Card>}

          <Form.Item label="Signer Name" name="signerName"
              rules={[{ required: true, message: "A signer name is required.", }]}
              hidden={configurator.isDealerSales()}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Signer Email" name="signerEmail" 
              rules={[{ required: true, message: "A signer email is required.", }]}
              hidden={configurator.isDealerSales()}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Dealer Name" name="dealerName" 
              hidden={!isDealerQuote}
              rules={[{ required: isDealerQuote, message: "A signer email is required.", }]}
              >
              <Input />
            </Form.Item>

            <Form.Item label="Dealer Address" name="dealerAddress"  
              hidden={!isDealerQuote}
              rules={[{ required: isDealerQuote, message: "A signer email is required.", }]}
              >
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Customer Name" name="customerName" >
              <Input />
            </Form.Item>

            <Form.Item label="Customer Address" name="customerAddress" >
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Ship To" name="shipTo" >
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="PO Number" name="poNumber" >
              <Input />
            </Form.Item>


        </Form>
      </>,
      footer: (nav) => <div style={{display: "flex", justifyContent:"space-between", padding: "1rem .3rem .3rem .3rem" }}>
        {(configurator.isAdmin() || configurator.isSalesDesk()) &&
          <Button key="back" onClick={handleSkip} >Skip</Button>}
        <Space>
        <Button key="back" onClick={() => nav.prevStep()} >Back</Button>
        <Button key="next" type="primary" loading={props.loading} onClick={handleRequestPO} >Next</Button>
        </Space>
      </div>,
    },

  ];

  return {
    steps,
  };

}

export const PurchaseOrderDescription = () => {

  const {quote} = useQuoteContext();

  const computedPricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  });

  return <Space direction='vertical'>
    <div style={{marginBottom: "1rem"}} >
      <div style={{fontWeight: "600",}} >Truck Serial Numbers</div>
      <PagedDescriptions
        className="bm-descriptions"
        rows={2}
        column={5} 
        items={quote?.trucks
          ?.sort((a,b) => parseInt(a.truckSerialNumberStr) < parseInt(b.truckSerialNumberStr) ? -1 : 1)
          .map(t => ({children: t.truckSerialNumberStr}))} 
      />
    </div>
    <Descriptions 
      className="bm-descriptions"
      column={1} 
      items={[
        {label: "Unit Price",  children: currencyFormatter(computedPricing.data?.dealerPrice)},
        {label: "Quantity",  children: quote?.trucks?.length},
        {label: "Total",  children: currencyFormatter( ( quote?.trucks?.length || 0 ) * ( computedPricing.data?.dealerPrice || 0 ) ) },
      ]}
      style={{maxHeight: "10rem", overflow: "auto"}}
    />
  </Space>
}


export default RequestPoModalButton;
