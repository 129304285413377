import {
  ChangeLeadTime,
  DAYS_IN_WEEK,
  Permission,
  Truck,
  TruckTags
} from "../../api/models"
import { ConfiguratorContext } from "../../context";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  Button,
  Input,
  Table,
  Tooltip,
  message,
  notification,
  Result,
  Space,
  Checkbox,
  Form,
  Descriptions,
} from "antd";
import Modal from "antd/es/modal/Modal";
import dayjs from "dayjs";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {useIntl} from "react-intl";
import { WarningOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import Utils, {ExportableColumn} from "../../util/util";
import _ from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../Wizard";
import BMButton from "../BMButton";
import BMReadOnly from "../BMReadOnly";
import {TruckRequest} from "../../api";
import TxtInputPopover from "../widgets/TxtInputPopover";
import {DescriptionsItemType} from "antd/es/descriptions";
import TextAreaPopover from "../widgets/TextAreaPopover";
import useQuote from "../../swr/useQuote";

const QuoteTrucksButtonModal = (props:{
  hideCopy?:boolean | undefined
  labelText?: ReactNode | string | undefined
}) => {
  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const [isOpen, setIsOpen] = useState(false);
  const [isEditSerials, setIsEditSerials] = useState(false);
  const [leadTime, leadTimeAsync] = useAsyncState<ChangeLeadTime>();
  const configurator = useContext(ConfiguratorContext);
  const [trucks, trucksAsync] = useAsyncState<Truck[]>(quote?.trucks);
  const intl = useIntl();

  const [activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  //note: required to handle change in visibility
  useEffect(() => {
    if ( isEditSerials ) {
      wizardInstance.current?.nextStep();
    }
  }, [isEditSerials]);

  const { isSplitOrder, isReadOnly } = Utils.getQuoteState(configurator, quote );

  const NOT_FOUND = -1;
  const updateTruck =  async (trucksAsync:AsyncState<Truck[]>, truckId:number, req:TruckRequest) : Promise<Truck | undefined> => {

    try {
      trucksAsync.setLoading();
      const resp = await configurator.api.updateTruck( truckId, req )

      const lst = trucksAsync.val;

      const ndx = lst?.findIndex( r => r.id == resp.data.id ) ?? NOT_FOUND;
      if ( ndx === NOT_FOUND ) throw new Error("Truck not found.");

      lst?.splice( ndx, 1, resp.data );

      trucksAsync.setDone( [...(lst || [])] );

      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update truck. " + errorMsg });

      trucksAsync.setFail(errorMsg);
    }

    return;
  };

  function getSerialNumberLabel() {
    if(!trucks?.length) return "N/A";

    const serialNumberStr = Utils.buildSerialNumberStr(trucks.map(t => t.truckSerialNumberStr));

    if((serialNumberStr?.length || 0 ) <= 16) {
      return serialNumberStr;
    }

    if(trucks.length > 2) {
      return (trucks[0].truckSerialNumberStr + ', ' + trucks[1].truckSerialNumberStr + ', and ' + (trucks.length - 2) + ' more');
    }
    else {
      return trucks.map(t => t.truckSerialNumberStr).join(', ');
    }
  }

  const getLeadTime = async(quoteId:number | undefined) => {

    leadTimeAsync.setLoading();
    try {
      const resp = await configurator.api.getLeadTime(quoteId);
      leadTimeAsync.setDone(resp.data);
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get lead time " + errorMsg });
      leadTimeAsync.setFail(e.message);
    }
  }


  const getTrucks = async (quoteRevisionId:number | undefined) : Promise<Truck[] | undefined> => {
    if ( !quoteRevisionId ) return;

    try {
      trucksAsync.setLoading();
      const resp = await configurator.api.getTrucks(quoteRevisionId);
      const trucks = resp.data
      trucks.sort((a,b) => a.truckSerialNumberStr.localeCompare(b.truckSerialNumberStr))
      trucksAsync.setDone(trucks);
      return resp.data;
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch trucks. " + errorMsg;
      notification.error( { message: msg });
      trucksAsync.setFail(msg);
    }

    return;
  }

  const serialNumberLbl = props.labelText || getSerialNumberLabel();

  const handleCancel = () => {
    setIsOpen(false);
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDisabledEditTruckSerial = () : string | undefined => {
    return !quote?.trucks?.length ? "There are no trucks."
      : isSplitOrder ? "Serial numbers cannot be edited on a split quote."
      : isReadOnly ? "The quote is currently read-only" 
      : undefined;
  }

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleEditSerials = () => {
    setIsEditSerials(true);
  }

  const handleEditSerialsCancel = () => {
    wizardInstance.current?.nextStep();
    setIsEditSerials(false);
  }

  const handleEditSerialsChange = async () => {
    await quoteAsync.mutate();
    getTrucks(quote?.displayRevisionId);
    wizardInstance.current?.nextStep();
    setIsEditSerials(false);
  }

  const steps:WizardStep[] = [
    {
      key: 1,
      title: "Trucks",
      body: (_nav) => <div>
        <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem"}}>
          {configurator.hasPermission(Permission.TRUCK_SERIAL_NUMBERS_WRITE) &&
            <BMButton 
              type='primary'
              size="small"
              disabled={!!getDisabledEditTruckSerial()}
              onDisabledClick={() => notifyDisabled(getDisabledEditTruckSerial())}
              onClick={handleEditSerials}
            >Edit Serial Numbers
            </BMButton>}
        </div>

        <TruckListingTable
          loading={trucksAsync.isLoading()}
          trucks={trucks}
          leadTime={leadTime}
          onChange={(t, r) => updateTruck( trucksAsync, t.id, r)}
        />
      </div> ,
      footer:() => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
      </div>
    },
    useEditSerialWizardStep({
      key:2,
      hidden: !isEditSerials,
      onCancel:handleEditSerialsCancel,
      onChange:handleEditSerialsChange,
    }),
  ];

  const bodyStyle = {paddingTop: "2rem"}
  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return <>
    <div style={{ display: 'flex', alignItems: "baseline" }}>
      <Button data-testid="serialNumberStr" className="ghostBmButton"
        style={{alignItems: 'start', textAlign: 'left', padding: 0, border: "none", boxShadow: "none"}} 
        onClick={() => setIsOpen(true)} 
      ><span style={{borderBottom: "1px solid black"}}>{serialNumberLbl}</span></Button>
      {!props.hideCopy && <>
        <Paragraph copyable={{text: quote?.serialNumberStr}}></Paragraph>
      </>}
    </div>
    <Modal 
      open={isOpen}
      width={"90rem"}
      onCancel={handleCancel}
      title={title}
      footer={footer}
      styles={{body: bodyStyle}}
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if ( open ) {
          getTrucks(quote?.displayRevisionId);
          getLeadTime(quote?.id);
        }
      }}
    >
      <Wizard 
        showSteps={false}
        steps={steps}
        onStep={handleStep}
      />
    </Modal>
  </> 
}

const TruckListingTable = (props: {
  trucks: Truck[] | undefined
  loading: boolean | undefined
  leadTime:ChangeLeadTime | undefined
  onChange?: (t:Truck, r:TruckRequest) => void
}) => {

  const {trucks, leadTime} = props;

  const [exporting, setExporting] = useState<number | undefined>(undefined);
  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const { quote } = useQuoteContext();

  const { isEngineeringLocked } = Utils.getQuoteState(configurator, quote );
  const canChangeTrucks = ( configurator.isSalesDesk() || configurator.isEngineering() || configurator.isAdmin() ) && !isEngineeringLocked;

  const isReadOnly = !canChangeTrucks;

  const revId = quote?.displayRevisionId;

  const LeadTimeInfo = () => {

    const leadTimeDays = _.max([leadTime?.categoryLeadTimeDays, (leadTime?.standardLeadTimeWeeks || 0) * DAYS_IN_WEEK]);
    if( leadTimeDays ) {
      const dt = dayjs().add(leadTimeDays, 'day').format('M/D/YYYY');
      const categories = leadTime?.leadTimeCategories?.map(c => Utils.stripSortingPrefix(c.name)).join(", ");
      const leadTimeDescription = leadTimeDays === leadTime?.categoryLeadTimeDays
        ? `Production lead time of ${leadTime?.categoryLeadTimeDays} days due to: ${categories}.`
        : `Standard production lead time of ${leadTime?.standardLeadTimeWeeks} weeks.`;

      return <>{dt}
        <Tooltip title={leadTimeDescription}>
          <WarningOutlined style={{color:"orange", marginLeft: ".4rem"}} />
        </Tooltip>
      </>
    }

    return <></>

  }


  const onExportParFile = async (truck: Truck) => {
    if ( !revId ) return;

    const url = configurator.api.getBendixParFileExportUrl(revId, Number(truck.id));
    setExporting( Number(truck.id) );
    try {
      await configurator.api.downloadCsv( url )
    }
    catch(e:any) {
      message.error( e.message );
    }
    setExporting( undefined );

  };

  const columns:ExportableColumn<Truck>[] = [
    {
      key: 'truckSerialNumberStr',
      title: 'Truck Sn',
      fixed: "left",
      render: (t:Truck) => <span>{t.truckSerialNumberStr}</span>,
      renderCSV: (t:Truck) => t.truckSerialNumberStr
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      fixed: "left",
      renderCSV: (t:Truck) => t.vin
    },
    {
      title: 'Engine Sn',
      dataIndex: 'engineSerialNumber',
      renderCSV: (t:Truck) => t.engineSerialNumber
    },
    {
      title : 'Transmission Sn',
      dataIndex : 'transmissionSerialNumber',
      renderCSV: (t:Truck) => t.transmissionSerialNumber
    },
    {
      title : 'Steer Axle Sn',
      key: "steerAxle",
      render: (t) => [t.steerAxle1, t.steerAxle2, t.steerAxle3].filter(v=>v).join(","),
      renderCSV: (t) => [t.steerAxle1, t.steerAxle2, t.steerAxle3].filter(v=>v).join(","),
    },
    {
      title : 'Drive Axle Sn',
      key: "driveAxle",
      render: (t) => [t.driveAxle1, t.driveAxle2, t.driveAxle3].filter(v=>v).join(","),
      renderCSV: (t) => [t.driveAxle1, t.driveAxle2, t.driveAxle3].filter(v=>v).join(","),
    },
    {
      title : 'Pusher Axle Sn',
      key: "pusherAxle",
      render: (t) => t.pusherAxle1,
      renderCSV: (t) => t.pusherAxle1
    },
    {
      title : 'Tag Axle Sn',
      key: "tagAxle",
      render: (t) => t.tagAxle,
      renderCSV: (t) => t.tagAxle
    },
    {
      key: 'projectedProductionDate',
      title: 'Production',
      render: (t: Truck) => t.productionDate ? <span style={{color: "green"}}>{dayjs(t.productionDate).format('MM/DD/YYYY')}</span>
          : t.projectedProductionDate ? <span style={{color: "orange"}}>{dayjs(t.projectedProductionDate).format('MM/DD/YYYY')}</span>
          : <LeadTimeInfo />,
      renderCSV: (t: Truck) => t.projectedProductionDate ? dayjs(t.projectedProductionDate).format('MM/DD/YYYY') : ""
    },
    {
      key: 'projectedReadyToShip',
      title: 'Ready to Ship Date',
      render: (t: Truck) => t.readyToShip ? <span style={{color: "green"}}>{dayjs(t.readyToShip).format('MM/DD/YYYY')}</span>
          : t.projectedReadyToShip ? <span style={{color: "orange"}}>{dayjs(t.projectedReadyToShip).format('MM/DD/YYYY')}</span>
              : <></>,
      renderCSV: (t: Truck) => t.projectedReadyToShip ? dayjs(t.projectedReadyToShip).format('MM/DD/YYYY') : ""
    },
    {
      key: 'projectedShipDate',
      title: 'Ship Date',
      render: (t: Truck) => t.shipDate ? <span style={{color: "green"}}>{dayjs(t.shipDate).format('MM/DD/YYYY')}</span>
          : t.projectedShipDate ? <span style={{color: "orange"}}>{dayjs(t.projectedShipDate).format('MM/DD/YYYY')}</span>
          : <></>,
      renderCSV: (t: Truck) => t.projectedShipDate ? dayjs(t.projectedShipDate).format('MM/DD/YYYY') : ""
    },
    {
      key: 'purchaseOrder',
      title: 'PO Number',
      render: (t) => <div style={{display: "flex", justifyContent: "center"}}>
        <BMReadOnly readOnly={isReadOnly} value={t.purchaseOrder} >
        <TruckPurchaseOrderInput truck={t} onChange={props.onChange} />
        </BMReadOnly>
      </div>,
      renderCSV: (t: Truck) => t.purchaseOrder
    },
    {
      key: 'physicalLocation',
      title: 'Physical Location',
      render: (t) => <div style={{display: "flex", justifyContent: "center"}}>
        <BMReadOnly readOnly={isReadOnly} value={t.physicalLocation} >
        <TruckPhysicalLocationInput truck={t} onChange={props.onChange}/>
        </BMReadOnly>
      </div>,
      renderCSV: (t: Truck) => t.physicalLocation
    },
    {
      key: 'notes',
      title: 'Notes',
      render: (t) => <div style={{display: "flex", justifyContent: "center"}}>
        <BMReadOnly readOnly={isReadOnly} value={t.notes} >
        <TruckNotesInput truck={t} onChange={props.onChange} />
        </BMReadOnly>
      </div>,
      renderCSV: (t: Truck) => t.notes
    },
    {
      title: 'Tags',
      key: "toggles",
      hidden: isReadOnly,
      renderCSV: (t: Truck) => t.tags?.join(","),
      render: (t) => {

        var items = new Array<DescriptionsItemType>();
        if ( configurator.hasGlobalViewAccess() ) {
          items.push( {
            label: "FGI",
            children: <Checkbox
                defaultChecked={t.tags.includes(TruckTags.Fgi)}
                onChange={(e) => {
                  props.onChange?.(t, {
                    tags: Utils.toggleTag(t.tags, TruckTags.Fgi, e.target.checked)
                  })
                }}
            />
          });
          items.push( {
            label: "Sold",
            children: <Checkbox
                defaultChecked={t.tags.includes(TruckTags.FgiSold)}
                onChange={(e) => {
                  props.onChange?.(t, {
                    tags: Utils.toggleTag(t.tags, TruckTags.FgiSold, e.target.checked)
                  })
                }}
            />
          });
        }

        return <Descriptions
            style={{width: "10rem"}}
            size="small"
            column={2}
            items={items}
        />
      }
    }
  ];

  if (isAdminOrEngineering) {
    columns.push({
      key: 'export',
      title: 'Par File',
      render: (t: Truck) => exporting === t.id ? <LoadingOutlined /> :
          <Button
              type="primary"
              size="small"
              shape="circle"
          icon={<DownloadOutlined />}
          onClick={() => onExportParFile(t)}
        />
    });
  }

  const handleExportCsv = async () => {
    if ( !trucks?.length ) return;

    const csvFileNameComponents = [ "trucks", Utils.buildSerialNumberStr(trucks.map(t=>t.truckSerialNumberStr)) ];

    const csvFileName = csvFileNameComponents.join( "_" )

    Utils.exportDataAsCSV(csvFileName, trucks, columns);
  }

  return <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

  <div style={{display: "flex", flexDirection:"row-reverse"}}>
    <Tooltip title="Download CSV">
      <Button icon={<DownloadOutlined />} shape="circle" onClick={handleExportCsv} />
    </Tooltip>
  </div>

   <Table
          bordered
          rowKey="id"
          loading={props.loading}
          dataSource={trucks}
          columns={columns}
          scroll={{ x: true }}
        />
  </Space>
};

const useEditSerialWizardStep = (props:{
  key: React.Key
  hidden?: boolean
  onChange?:() => void
  onCancel?:() => void
}) : WizardStep => {

  const configurator = useContext(ConfiguratorContext);
  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const [snStrStatus, setSnStrStatus] = useState<string | undefined>()
  const [exportToEpicor, setExportToEpicor] = useState<boolean>(false)

  const quoteSerialNumbers = quote?.trucks?.map( t => t.truckSerialNumberStr ) || []
  const quoteSerialNumberStr = Utils.buildSerialNumberStr( quoteSerialNumbers );
  const [snStr, setSnStr] = useState<string | undefined>(quoteSerialNumberStr)

  const [activeKey, setActiveKey] = useState<React.Key>();
  const [wizardInstance, setWizardInstance] = useState<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    setWizardInstance(instance);
  };

  const handleChangeSerialNumber = (e:any) => {
    setSnStr( e.target.value );
  }

  const handleUpdateSerials = (nav:WizardInstance) => {
    if (!snStr) return;

    try {

      const snLst = Utils.expandTruckSerialNumberStr( snStr );

      if ( snLst.length > quoteSerialNumbers.length ) throw new Error( `Too many serial numbers! Remove ${snLst.length - quoteSerialNumbers.length} serial numbers.` );
      if ( snLst.length < quoteSerialNumbers.length ) throw new Error( `Not enough serial numbers! Provide ${quoteSerialNumbers.length - snLst.length} more serial numbers.` );

      setSnStr( snStr );
      setSnStrStatus( undefined );

      nav.nextStep();
    }
    catch( e:any ) {
      setSnStrStatus( e.message );
    }
  }

  const handleReset = (nav:WizardInstance) => {

    //reset ui
    setSnStr( quoteSerialNumberStr );
    setSnStrStatus( undefined );
    nav.resetSteps();
  }

  const handleConfirm = async (nav:WizardInstance) => {
    if ( !quote ) return;
    if ( !quote ) return;
    if (!snStr) return;

    const snLst = Utils.expandTruckSerialNumberStr( snStr );
    try {
      const resp = await configurator.api.saveTruckSerials( quote.id, quote.displayRevisionId, snLst, exportToEpicor );
      await quoteAsync.mutate( resp.data );
      nav.nextStep();
    }
    catch(e:any) {
      nav.prevStep();
    }
  }

  const err = snStrStatus || quoteAsync?.error?.message;

  const steps:WizardStep[] = [
    {
      key: "1",
      body:(_nav) =>  <div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

          <div style={{marginTop: ".4rem", marginBottom: "1rem"}} >Serial numbers can be described as a comma separated list, ranges (inclusive), or a combination. For example: 1,2-4,5 is 1,2,3,4,5</div>

          <Form.Item label="Serial Number Range">
          <Input 
            value={snStr}
            status={snStrStatus && "error"}
            onChange={handleChangeSerialNumber}
          />
          </Form.Item>

          <div style={{color: "red", maxHeight: "5.5rem", overflow:"auto", whiteSpace:"pre-line" }}>{err}</div>

        </div>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
        <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
        <Button key="next" type="primary" 
          disabled={snStr === quoteSerialNumberStr}
          onClick={() => handleUpdateSerials(nav)}
        >Next</Button>
        </Space>
        <Button key="reset" onClick={() => handleReset(nav)}>Reset</Button>
      </div>
    },
    {
      key: "2",
      body:(_nav) => <div>
        <Space direction="vertical">
          <Form.Item label="Serial Numbers" >
            <BMReadOnly readOnly={true} renderValue={() => snStr}>
              <Input  />
            </BMReadOnly>
          </Form.Item>
          <div><Checkbox onChange={(e) => setExportToEpicor(e.target.checked)}>Export to Epicor</Checkbox></div>
        </Space>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="back" onClick={nav.prevStep}>Back</Button>
          <Button key="submit" type="primary" onClick={() => handleConfirm(nav)}>Confirm</Button>
        </Space>
      </div>
    },
    {
      key:"3",
      body: (_nav) => <div>
        <Result
          status="success"
          title="Serial Numbers Changed"
          subTitle={`The serial numbers for quote ${quote?.quoteId} have been changed to ${quoteSerialNumberStr}.`}
          extra={[
            <Button type="primary" key="done" onClick={props.onChange}>Ok</Button>,
          ]}
          style={{padding: "0"}}
        />
      </div>,
      footer: (_nav) => <></>
    }
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance, activeStep);
  const footer = getWizardFooter( wizardInstance, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: (_nav) => 
      <Wizard 
        key="editSerialsWizard"
        steps={steps}
        onStep={handleStep}
      />,
    title: (_nav) => title,
    footer: (_nav) => footer,
  }
}

const TruckNotesInput = (props:{
  truck:Truck | undefined
  onChange?: (cw:Truck, s:TruckRequest) => void
}) => {
  const {truck, onChange, ...inputProps} = props;
  return <TextAreaPopover
      {...inputProps}
      value={truck?.notes}
      placeholder="Type Notes"
      onChange={(notes) => {

        if ( !truck ) return;
        onChange?.( truck, {
          notes
        });
      }}
  />;
}

const TruckPhysicalLocationInput = (props:{
  truck:Truck | undefined
  onChange?: (cw:Truck, s:TruckRequest) => void
}) => {
  const {truck, onChange, ...inputProps} = props;
  return <TxtInputPopover
      {...inputProps}
      value={truck?.physicalLocation}
      placeholder="Type Physical Location"
      onChange={(physicalLocation) => {

        if ( !truck ) return;
        onChange?.( truck, {
          physicalLocation
        });
      }}
  />;
}

const TruckPurchaseOrderInput = (props:{
  truck:Truck | undefined
  onChange?: (cw:Truck, s:TruckRequest) => void
}) => {
  const {truck, onChange, ...inputProps} = props;
  return <TxtInputPopover
      {...inputProps}
      value={truck?.purchaseOrder}
      placeholder="Type PO Number"
      onChange={(purchaseOrder) => {
        if ( !truck ) return;
        onChange?.( truck, {
          purchaseOrder
        });
      }}
  />;
}

export default QuoteTrucksButtonModal;