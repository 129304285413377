import { Alert, Button, Col, Form, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import { useContext, useState } from "react";
import { EpicorExportPartsResponse, EpicorExportPartsResult } from "../api/models";
import QuoteSelector from "../components/Quote/QuoteSelector";
import { ConfiguratorContext } from "../context";
import QuoteFilterControls from "../components/QuoteFilterControls";
import {QuoteStatusSelections} from "../components/quote_status_selector";

const EpicorBulkExport = () => {
    const [form] = useForm();
    const [exporting, setExporting] = useState<boolean>(false);
    const configurator = useContext(ConfiguratorContext);
    const [response, setResponse] = useState<EpicorExportPartsResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const onFinish = async (values: any) => {
        try {
            setErrorMessage(undefined);
            setResponse(undefined);
            setExporting(true);
            const resp = await configurator.api.epicorExportParts({
                quoteIds: values.quoteIds,
                allOrders: false
            });
            setResponse(resp);
        }
        catch (e: any) {
            setErrorMessage('Failed to export at this time');
        }
        finally {
            setExporting(false);
        }
    };

    const columns = [
        {
            title: 'Part Number',
            key: 'partNumber',
            render: (result:EpicorExportPartsResult) => {
                return result.partNumber + ' (Rev ' + result.revision + ')';
            }
        },
        {
            title: 'Quote',
            dataIndex: 'quoteName',
            key: 'quoteName'
        },
        {
            title: 'Sync Triggered',
            key: 'success',
            render: (result:EpicorExportPartsResult) => {
                return result.triggered ? 'Yes' : 'No';
            }
        },
        {
            title: 'Errors',
            key: 'errors',
            render: (result:EpicorExportPartsResult) => {
                return result.errors?.join(', ');
            }
        }
    ];

    return (
        <div className="site-layout-background">
            <Title level={2}>Epicor Bulk Export</Title>
            <Col span={8}>
                <div style={{marginBottom: "1rem"}}>This page can be used to bulk export orders to Epicor.</div>
                {errorMessage && <div>
                    <Alert type="error" message={errorMessage} /><br /></div> }
                <Form form={form} onFinish={onFinish} >
                    <Form.Item label="Quote" name="quoteIds" rules={[{ required: true, message: 'You must select at least one quote' }]}>
                        <QuoteSelector allowMultiple quoteStatusFilter={QuoteStatusSelections.ORDER} />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={exporting} type="primary" htmlType="submit">Export</Button>
                    </Form.Item>
                </Form>
            </Col>

            <Col span={12}>
                {response &&
                <Table rowKey="partNumber" dataSource={response.results} columns={columns} />
                }
            </Col>
        </div >
    );
};

export default EpicorBulkExport;
