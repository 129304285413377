import "../util/mobile-table.css";
import styles from "./quotes.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Button, Space, TablePaginationConfig, notification, Card, Tooltip, Descriptions, Row, DatePicker, Form } from "antd";
import {useCallback, useContext, useEffect, useState} from "react";
import { ConfiguratorContext } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import {DateFilterType, QUOTE_TABLE, QuoteFilter, Quote, DEFAULT_THROTTLE} from "../api/models";
import {throttle} from 'lodash';
import { ColumnType } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import useCheckMobileScreen from '../hook/useCheckMobileScreen';
import ExportQuotesModal from "../components/Quote/ExportQuotesModal";
import {useIntl} from "react-intl";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import {ExclamationCircleOutlined, TruckOutlined} from "@ant-design/icons";
import NewQuoteWizard from "../components/Quote/NewQuoteWizard";
import { DescriptionsItemType } from "antd/es/descriptions";
import QuoteFilterControls from "../components/QuoteFilterControls";
import OrderProbability from "../components/Quote/OrderProbability";
import { ListQuotesResponse, OrderExpectationRequest } from "../api";
import useQuoteList from "../swr/useQuoteList";
import {TableProps} from "antd/lib";

export type ListQuotesResponseSort = SorterResult<ListQuotesResponse> | SorterResult<ListQuotesResponse>[]

const Quotes = (props:{
  title?:string
  defaultFilter?:QuoteFilter
}) => {
  const intl = useIntl();
  const isMobile = useCheckMobileScreen();
  const configurator = useContext(ConfiguratorContext);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [myQuotesParam, setMyQuotesParam] = useQueryParam<boolean | undefined | null>("my", BooleanParam);
  const [archivedParam, setArchivedParam] = useQueryParam<boolean | undefined | null>("includingArchived", BooleanParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [quoteStatus, setQuoteStatus] = useQueryParam("status", ArrayParam);
  const [quoteSalespersonsParam, setQuoteSalespersonsParam] = useQueryParam("salespersons", ArrayParam);
  const [quoteEngineersParam, setQuoteEngineersParam] = useQueryParam("engineers", ArrayParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [modelsParam, setModelsParam] = useQueryParam("models", NumericArrayParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerId", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationId", NumericArrayParam);
  const [includingCancelledParam, setIncludingCancelledParam] = useQueryParam<boolean | undefined | null>("includingCancelledOrder", BooleanParam);
  const [draftOnlyParam, setDraftOnlyParam] = useQueryParam<boolean | undefined | null>("hasDraftRevision", BooleanParam);
  const [stateRegistrationParam, setStateRegistrationParam] = useQueryParam<(string | null)[]|undefined|null>("stateRegistration", ArrayParam);
  const [sort, setSort] = useState<ListQuotesResponseSort>({
    columnKey: 'updatedAt',
    order: 'descend'
  });

  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: myQuotesParam || undefined,
    includingArchived: archivedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    includeReservations: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    filterAssemblies: filterAssemblies as number[] || undefined,
    quoteStatus: [quoteStatus as (string|null) || []].flat() || undefined,
    engineers: [quoteEngineersParam as (string|null) || []].flat(),
    salespersons: [quoteSalespersonsParam as (string|null) || []].flat(),
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
    includingCancelledOrder: includingCancelledParam || false,
    hasDraftRevision: draftOnlyParam || false,
    models: [modelsParam as (number | null) || []].flat(),
    stateRegistration: stateRegistrationParam?.filter(v=>v) as (string[] | undefined),
    ...props.defaultFilter,
  });


  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const quoteLst = useQuoteList({
    filter: {
      ...filter,
      stock: filter.search?.length ? undefined : false
    },
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);

  const handleTableChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: ListQuotesResponseSort) => {
    setPagination(pagination);

    setSort(sorter);
  };


  const updateOrderExpectation =  async (quoteRevisionId:number, key:keyof OrderExpectationRequest, req:OrderExpectationRequest) : Promise<Quote | undefined> => {

    try {
      const resp = await configurator.api.updateOrderExpectation( quoteRevisionId, key, req )

      await quoteLst.mutate();

      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update quote. " + errorMsg });
    }

    return;
  };

  const handleChangeOrderExpectation = (quote:ListQuotesResponse, changedValues: any) => {
    Object.keys( changedValues ).forEach( key => {
      if ( key === "orderProbability" ) {
        const orderProbability = changedValues[ key ];
        updateOrderExpectation( quote.displayRevisionId, key, { orderProbability } );
      }
      else if ( key === "expectedOrderDate" ) {
        const expectedOrderDate = changedValues[ key ]?.toDate()
        updateOrderExpectation( quote.displayRevisionId, key, { expectedOrderDate } );
      }
      else if ( key === "expectedShipDate" ) {
        const expectedShipDate = changedValues[ key ]?.toDate()
        updateOrderExpectation( quote.displayRevisionId, key, { expectedShipDate } );
      }
    });

  }



  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = useCallback(throttle( (_values: Record<string, any>, filter:QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setArchivedParam(filter.includingArchived);
    setMyQuotesParam(filter.myQuotes);
    setDealerFilterParam(filter.dealerLst);
    setAssembliesFilter(filter.filterAssemblies);
    setQuoteStatus(filter.quoteStatus);
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setQuoteSalespersonsParam(filter.salespersons);
    setStateRegistrationParam(filter.stateRegistration);
    setQuoteEngineersParam(filter.engineers);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    filter.includeReservations = filter.ordersOnly;
    setIncludingCancelledParam(filter.includingCancelledOrder);
    setDraftOnlyParam(filter.hasDraftRevision);
    setModelsParam(filter.models);
    setFilter(filter);
  }, DEFAULT_THROTTLE), []);

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!archivedParam && setArchivedParam(undefined);
    !!myQuotesParam && setMyQuotesParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!filterAssemblies?.length && setAssembliesFilter(undefined);
    !!quoteStatus?.length && setQuoteStatus(undefined);
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!quoteSalespersonsParam?.length && setQuoteSalespersonsParam(undefined);
    !!stateRegistrationParam?.length && setStateRegistrationParam(undefined);
    !!quoteEngineersParam?.length && setQuoteEngineersParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
    !!draftOnlyParam && setDraftOnlyParam(undefined);
    !!modelsParam?.length && setModelsParam(undefined);
    setIncludingCancelledParam(undefined);
  }

  return (
    <div className="site-layout-background">
        <Row>
          <Title level={2}>{props.title || "Quotes"}</Title>
        </Row>
        <QuoteFilterControls
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
          tableName={QUOTE_TABLE}
        />
        {isMobile
          ? <MobileTable
            dataSource={quoteLst.data?.content}
            loading={quoteLst.isLoading}
            onChange={handleTableChange}
            pagination={{...pagination, total: quoteLst.data?.totalElements}}
          />
          : <DesktopTable
            dataSource={quoteLst.data?.content}
            loading={quoteLst.isLoading}
            onChange={handleTableChange}
            pagination={{...pagination, total: quoteLst.data?.totalElements}}
            filter={filter}
            sort={sort}
            onChangeOrderExpectation={handleChangeOrderExpectation}
          />
        }

    </div>
  );
};

const DesktopTable = (props: TableProps<ListQuotesResponse> & {
  filter:QuoteFilter
  sort:ListQuotesResponseSort
  onChangeOrderExpectation: (q:ListQuotesResponse, c: any) => void
}) => {
  const {  filter:a, sort:b, onChangeOrderExpectation:c, ...tableProps } = props;

  const configurator = useContext(ConfiguratorContext);

  const hasComparePermissions = configurator.isSalesDesk() || configurator.isAdmin() || configurator.isEngineering();
  const warningTextStyle = {color: "red", fontWeight: 'bold'};

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      title: "Quote Details",
      key: "quoteId",
      render: (q) => <BaseQuoteDescription quote={q} />,
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "modelName",
      key: "latestRevision.model.name",
      sorter: true,
      render: (_, q) => q.model.name,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "latestRevision.quantity",
    },
    {
      title: "Status",
      key: "status",
      render: (q) => <>
        <Space style={{minWidth: "5.5rem"}}>
          {Utils.formatQuoteDisplayStatus(q)}
          {!!q.archived && <div style={warningTextStyle}>(Archived)</div>}
        </Space>
        {q.shippingDate && <Tooltip title={`Shipped on ${dayjs(q.shippingDate).format("M/DD/YY")}`}><TruckOutlined style={{fontSize: "1.2rem"}} /></Tooltip>}
      </>,
      sorter: true,
    },
    {
      title: "Sales Person",
      key: "owner",
      render: (_owner, q) =>
          <div style={{minWidth: "7rem"}}>
            {Utils.formatUsername(q.owner)}
          </div>,
      sorter: true,
    }];

  if ( configurator.isInternalSales() ) {
    columns.push({
      title: "Engineer",
      key: "salesTeam.engineerMembers.user.name",
      render: (_owner, q) => q.salesTeam?.engineers?.map(u => u.name).join(", "),
      sorter: true,
    })
  }

  columns.push({
    title: "Production Date",
    key: "latestRevision.summary.productionDate",
    sorter: true,
    render: (q) => q.productionDate ? dayjs(q.productionDate).format("M/DD/YY") : undefined
  })

  if ( configurator.isInternalSales() && !configurator.isEngineering() ) {
    columns.push({
      title: "Forecast",
      key: "expectedOrderDate",
      render: (q) =>
        <Form size="small"
          onValuesChange={(c) => props.onChangeOrderExpectation( q, c )}
          initialValues={{
            orderProbability: q.orderProbability,
            expectedOrderDate: q.expectedOrderDate ? dayjs(q.expectedOrderDate) : undefined,
            expectedShipDate: q.expectedShipDate ? dayjs(q.expectedShipDate) : undefined,
          }}
        >
          <Form.Item label="Probabilty" style={{margin: 0}} name="orderProbability">
            <OrderProbability bordered={false} />
          </Form.Item>
          <Form.Item label="Order" style={{margin: 0, minWidth: "12.5rem"}} name="expectedOrderDate">
            <DatePicker bordered={false} />
          </Form.Item>
          <Form.Item label="Ship" style={{margin: 0, minWidth: "12.5rem"}} name="expectedShipDate">
            <DatePicker bordered={false} />
          </Form.Item>
        </Form>,
      sorter: true,
    })
  }

  columns.push({
    title: "Last Updated",
    key: "updatedAt",
    render: (_updatedAt, q) => (
      <span>{dayjs(q.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
    ),
    sorter: true,
    defaultSortOrder: "descend",
  });

  return <Space direction="vertical" size="small" style={{ display: 'flex' }}>
    <div style={{display: "flex", flexDirection: "row-reverse"}}>
      <Space direction="horizontal">
        <ExportQuotesModal sort={props.sort} filter={props.filter} modulename={QUOTE_TABLE}/>
        {hasComparePermissions && <DiffRevisionModalButton btnLabel="Compare" title="Quote Comparison" className="" type="primary"  /> }
        <NewQuoteWizard type="primary" >New</NewQuoteWizard>
      </Space>
    </div>
    <Table
        {...tableProps}
        data-testid="quoteListTable"
        bordered
        columns={columns}
        rowKey="quoteId"
    />
  </Space>
}

const MobileTable = (props: TableProps<ListQuotesResponse> ) => {

  const gotoQuote = (q:ListQuotesResponse) => window.open("/inventory/" + encodeURI(q.quoteId), "_blank");

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      render: (_val, quote, _ndx) => {
        return <>
          <Card
            hoverable={true}
            onClick={() => gotoQuote(quote)}
            title={<>
              <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.name}</div>
              <div className={styles["quote-id"]}>({quote.quoteId})</div>
            </>
            }
          >
            <div className="section" style={{ maxWidth: "17rem" }}>
              <div className={styles["quote-status"]}>
                {Utils.formatQuoteDisplayStatus(quote)}
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "3rem" }}>
              <div className="section">
                <div>Salesperson:</div>
                <div><span>{quote?.owner?.name}</span></div>
                <div><span>{dayjs(quote.updatedAt).format("MMMM Do YYYY")}</span></div>
              </div>

              {quote?.serialNumberStr &&
                <div className="section">
                  <div>Serial No:</div>
                  <div><span>{quote?.serialNumberStr}</span></div>
                </div>
              }

            </div>
          </Card>
        </>;
      },
    },

  ];

  return <>
    <div style={{display: "flex", flexDirection: "row-reverse"}}>
      <Link to="/configurator">
        <Button type='primary'>New</Button>
      </Link>
    </div>
      <Table
          {...props}
        data-testid="quoteListTable"
        columns={columns}
        rowKey="id"
        className="mobile-table"
      />
  </>


}

export const BaseQuoteDescription = (props:{
  quote:ListQuotesResponse
  hideTruckDescription?:boolean
}) => {

  const {quote:q} = props;

  const title = q.name || q.truckDescription || q.quoteId;
  const descriptionItems = [] as DescriptionsItemType[];
  if ( title !== q.quoteId) descriptionItems.push( { key: "quoteId", label:"Quote Id", children: <span style={{whiteSpace: "nowrap"}}>{q.quoteId}</span> });
  if ( q.partNumberString?.length ) descriptionItems.push( { key: "partNo", label:"Part No.", children: <span style={{whiteSpace: "nowrap"}}>{q.partNumberString}</span> });
  if ( q.serialNumberStr?.length ) descriptionItems.push( { key: "serialNo", label:"Serial No.", children: <span style={{whiteSpace: "nowrap"}}>{q.serialNumberStr}</span> });

  const isLastUpdateHalfYearAgo = (q: ListQuotesResponse) => {
    return dayjs(q.updatedAt).isBefore(dayjs(new Date()).subtract(6, 'month'));
  }

  return <>
    <div key={q.quoteId} style={{marginBottom: ".4rem"}}>
      <Link to={"/configurator/" + encodeURI(q.quoteId)}>{title}</Link>
      {isLastUpdateHalfYearAgo(q) && <Tooltip title={`Please archive the inactive quote. Last updated is on ${dayjs(q.updatedAt).format("MMM Do, YYYY")}`}>
        <ExclamationCircleOutlined style={{color: "orange", marginLeft: "5px"}}/>
      </Tooltip>}
    </div>
    <Descriptions 
      column={1}
      size="small"
      items={descriptionItems}
      style={{minWidth: "14rem"}}
    />
    {(!props.hideTruckDescription && !!q.truckDescription?.length && title !== q.truckDescription) &&
      <div>{q.truckDescription}</div> }
  </>;
}

export default Quotes;
