import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {AssemblyInfo, AXIOS_CANCEL_MSG, Page} from '../api/models';
import { ConfiguratorContext } from '../context';
import {AssemblyFilterOptions, FilteredAssembliesRequest} from "../api";
import {SorterResult, SortOrder} from "antd/es/table/interface";


type AssemblyListProps<T> = {
  filterOptions?: AssemblyFilterOptions
  current?: number
  pageSize?: number
  sorter?: SorterResult<T> | SorterResult<T>[]
};

const DEFAULT_PAGE_SIZE = 20;

const DEFAULT_SORT = {
  columnKey: 'assemblyCategory.name',
  order: 'ascend' as SortOrder,
}

export const useAssemblyList = (props: AssemblyListProps<AssemblyInfo>) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:AssemblyListProps<AssemblyInfo>  ) : Promise<Page<AssemblyInfo> | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sorter = props.sorter || DEFAULT_SORT;

    const columnFilterValues = props.filterOptions?.columnFilterValues;

    const opts:FilteredAssembliesRequest = {
      filterOptions: {
        ...props.filterOptions,
        columnFilterValues: columnFilterValues ? JSON.stringify(columnFilterValues) : undefined
      },
      pageable: {
        page: props.current ?? 0,
        size:props.pageSize || DEFAULT_PAGE_SIZE,
        // @ts-ignore
        sorter
      }
    }

    try {
      const resp = await configurator.api.fetchAssemblyInfoList(opts, cancelSource.token )
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }

  // Use SWR for data fetching
  return useSWR([
    'fetchAssemblyInfoList',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useAssemblyList;

