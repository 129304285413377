import {Quote, Approval, ApproverRole} from "../api/models";
import Utils from "../util/util";
import WorkflowProgress from "./WorkflowProgress";
import useUsers from "../swr/useUsers";


const ApprovalTransitionInfo = (props: {
  approvals: Approval[] | undefined
  approval: Approval | undefined,
  isSingleAction: boolean,
  hideDescription?: boolean;
}) => {

  const users = useUsers();

  const {approval, isSingleAction} = props;
  const approverLst = approval?.approvalStep.approvers.map(a => {
    if ( Object.values(ApproverRole).map(String).includes(a) ) {
      return Utils.snakeCaseToFirstLetterCapitalized( a )
    }
    else {
      return users.data?.filter(u => u.id === a ).map(Utils.formatUsername)?.[0];
    }
  }).join(" or ");

  return <div key="transition-info">
    <style>
      {`
        .one-line > span { 
          font-weight: 600;
          margin-left: .3rem;
        }
      `}
    </style>
    {!props.hideDescription && <>
      <div style={{marginBottom: "1rem"}}>
        <div >Notes:</div>
        <div style={{whiteSpace: "break-spaces"}}>{approval?.notes}</div>
      </div>

      <div className="one-line">{!isSingleAction ? 'Approval needed from:' : 'Action needed from: '}
        <span>{approverLst} </span>

      </div>
    </>}

    <WorkflowProgress approvals={props.approvals} hideDescription={props.hideDescription} />
    
  </div>;
}

export default ApprovalTransitionInfo;

