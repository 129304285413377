import Title from "antd/lib/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import {
  Table,
  Button,
  notification,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Select,
  SelectProps,
  Badge,
  Space,
  Modal,
  Popover,
  DatePicker,
  ButtonProps
} from "antd";
import  { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { InfoCircleTwoTone, ExclamationOutlined } from "@ant-design/icons";
import { ConfiguratorContext } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import {
  AXIOS_CANCEL_MSG,
  BaseQuote,
  CategoryInfo,
  CommentTopic, CUSTOM_WORK_LIST,
  CustomWorkReview,
  DEFAULT_THROTTLE,
  EngineeringTeam,
  PAGINATION_MAX_PAGE_SIZE,
  Quote,
  SortDirection,
  User
} from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {throttle} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import { getCSVRow } from "../helpers/csv";
import {useIntl} from "react-intl";
import {FilterDropdownProps, SortOrder} from "antd/es/table/interface";
import CommentActivityList, { CommentActivityListProps } from "../components/CommentActivityList";
import {FormProps } from "antd/es/form/Form";
import SelectEngineeringTeamsButtonModal from "../components/Quote/SelectEngineeringTeamsButtonModal";
import axios, {CancelTokenSource} from "axios";
import BMButton, { BMButtonProps } from "../components/BMButton";
import UserMultiSelector from "../components/UserMultiSelector";
import { CustomWorkReviewRequest } from "../api";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import UserSingleSelector from "../components/widgets/UserSingleSelector";
import QuoteEngineerSelector from "../components/QuoteEngineerSelector";
import _ from "lodash";
import React from "react";
import useUsers from "../swr/useUsers";
import useBaseCategories from "../swr/useBaseCategories";
import useCustomWorkList from "../swr/useCustomWorkList";
import useQuoteComments from "../swr/useQuoteComments";
import SaveFilter from "../components/widgets/SaveFilter";

const { RangePicker } = DatePicker;

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

const getDueDateStr = (date: Date ) => {
  const days = date ? dayjs(date).diff(dayjs(), 'day') : undefined
  return days !== undefined ? days + " days" : '';
};

const reviewFilterDefaults = {
  includingArchived: false,
  ordersOnly: true,
  review: true,
}

interface CustomWorkFilter {
  search?: string
  categoryId?: number
  teamId?:number
  engineers?:string[]
  dateFilterStart?: string;
  dateFilterEnd?: string;
}

const CommentList = (props: Omit<CommentActivityListProps, 'topic'>) => <CommentActivityList {...props} topic={CommentTopic.VfdReview} />

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SORT:SortResult<CustomWorkReview> = [
  {
    columnKey: 'dueDate',
    order: 'ascend' as SortOrder
  } 
]

const CustomWorkReviewPage = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const configurator = useContext(ConfiguratorContext);

  const defaultEngineer = ( configurator.isAdmin() || configurator.isReleaseEngineering() ) ? []
      : ( configurator.isEngineering() && !!configurator.userInfo?.name) ? [configurator.userInfo.name]
      : [];

  const [_customWork, customWorkAsync] = useAsyncState<CustomWorkReview>();

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [teamIdParam, setTeamIdParam] = useQueryParam<number|undefined|null>("team", NumberParam);
  const [categoryIdParam, setCategoryIdParam] = useQueryParam<number|undefined|null>("category", NumberParam);
  const [engineersParam, setEngineersParam] = useQueryParam<Array<string | null> | undefined | null>("engineers", ArrayParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [showCommentsParam, setShowCommentsParam] = useQueryParam<boolean|undefined|null>("comments", BooleanParam);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(!!showCommentsParam);

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const defaultCustomWorkFilter = {
    search: searchFilterParam || undefined, //silly fix for null
    teamId: teamIdParam || undefined, //silly fix for null
    categoryId: categoryIdParam || undefined, //silly fix for null
    engineers: (engineersParam || undefined) as (string[] | undefined) || defaultEngineer, //silly fix for null
    dateFilterStart: dateFilterStartParam ? dateFilterStartParam : undefined,
    dateFilterEnd: dateFilterEndParam ? dateFilterEndParam : undefined,
  };
  const [filter, setFilter] = useState<CustomWorkFilter>(defaultCustomWorkFilter);
  const [commentProps, setCommentProps] = useState<CustomWorkCommentProps | undefined>();
  //this is to refresh the table
  const [customWorkKey, setCustomWorkKey] = useState<string | undefined>(crypto.randomUUID());

  const initialSort:SortResult<CustomWorkReview> = ( !!sortFieldQueryParam?.length && !!sortDirectionQueryParam?.length )
    ? { columnKey: sortFieldQueryParam, order: sortDirectionQueryParam as SortOrder }
    : DEFAULT_SORT;
  const [sort, setSort] = useState<SortResult<CustomWorkReview>>(initialSort);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);


  const handleChangeEcn =  async (cw:CustomWorkReview, req:CustomWorkReviewRequest) : Promise<CustomWorkReview | undefined> => {
    const cwTeam = updateCustomWork( customWorkAsync, cw.quote.id, cw.categoryInfo.id, req );
    if ( !cwTeam ) {
      customWork.mutate();
    }
    return cwTeam;
  }

  const updateCustomWork =  async (customWorkAsync:AsyncState<CustomWorkReview>, quoteId:number, categoryId:number, req:CustomWorkReviewRequest) : Promise<CustomWorkReview | undefined> => {

    try {
      customWorkAsync.setLoading();
      const resp = await configurator.api.updateCustomWorkReview( quoteId, categoryId, req );

      if (resp.data) {
        customWork.mutate();
      }

      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update custom work. " + errorMsg });

      customWorkAsync.setFail(errorMsg);
    }

    return;
  };

  const customWork = useCustomWorkList({
    ...filter,
    ...reviewFilterDefaults,
    page: (pagination.current || 1) - 1,
    size: pagination.pageSize || DEFAULT_PAGE_SIZE,
    sort: ([sort].flat() || DEFAULT_SORT).map(sorter => ({
      field: sorter.columnKey as string,
      direction: (sorter.order === 'descend' ? 'desc' : 'asc') as SortDirection,
    }))
  });

  const handleAssignTeam = async (engineeringTeam:EngineeringTeam | undefined, category: CategoryInfo ) : Promise<EngineeringTeam | undefined> => {
    if ( !engineeringTeam ) return;

    try {
      const resp = await configurator.api.updateCategoryEngineeringTeam(category.id, engineeringTeam?.id);

      customWork.mutate();

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to assign Engineering Team. " + errorMsg;
      notification.error( { message: msg });
    }
  }

  const handleShowComments = (quote:BaseQuote, category:CategoryInfo) => {
    setCommentProps({ quote, category, });
    setIsCommentsOpen(true);
    setShowCommentsParam(true);
  }

  const handleHideComments = () => {
    setCustomWorkKey(crypto.randomUUID());
    setIsCommentsOpen(false);
    setShowCommentsParam(false);
  }

  const tableOnChange =  (pagination:TablePaginationConfig, filters:Record<string, FilterValue | null>, sorter: SortResult<CustomWorkReview>) => {
    setPagination(pagination);

    handleFilterChange(filters)

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };


  const buildCustomWorkFilterFromFilters = (filters:Record<string, FilterValue | null>) : CustomWorkFilter => {
    return Object.keys(filters)
      .map(key => {

        const val = filters[ key ];
        if ( key === ColumnKey.Category && val !== null ) {
          return { categoryId: val[0] as number };
        }
        else if ( key === ColumnKey.Engineer && val !== null ) {
          return { engineers: val as string[] };
        }
        else if ( key === ColumnKey.Team && val !== null ) {
          return { teamId: val[0] as number };
        }
        else if ( key === ColumnKey.ProductionDate && val !== null ) {
          return { 
            dateFilterStart: dayjs( val[0] as string ).format('YYYY-MM-DD') as string,
            dateFilterEnd: dayjs( val[1] as string ).format('YYYY-MM-DD') as string 
          };
        }

        return { [key]: val };
      })
      .reduce( (a,v) => ({ ...a, ...v }), {} as CustomWorkFilter );
  }


  const handleFilterChange = ( tableFilter:Record<string, FilterValue | null> ) => {

    const nextFilter = {
      search: filter.search,
      ...buildCustomWorkFilterFromFilters(tableFilter)
    };

    if ( !_.isEqual( nextFilter, filter ) ) {
      updateFilter(nextFilter );
    }
  }
  const handleSearchChange = ( search:string ) => {
    updateFilter({
      ...filter,
      search,
    });
  }
  const handleResetFilters = () => {
    setCustomWorkKey(crypto.randomUUID());
    updateFilter({});
  }
  const updateFilter = ( filter:CustomWorkFilter ) => {
    setSearchFilterParam(filter.search);
    setTeamIdParam(filter.teamId);
    setCategoryIdParam(filter.categoryId);
    setEngineersParam(filter.engineers );
    setDateFilterStartParam(filter.dateFilterStart);
    setDateFilterEndParam(filter.dateFilterEnd);
    setPagination({ ...pagination, current: 1 });

    setFilter(filter);
  };

  const exportCustomWork = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.listCustomWork({
        ...filter,
        ...reviewFilterDefaults,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'asc',
          field: 'dueDate',
        }
      })).data;

      var csv = [[
        'Hot',
        'Category',
        'PrimaryEngineer',
        'Team',
        'PartNo',
        'Status',
        'Model',
        'ProductionDate',
        'DueDate',
      ].join(',')];

      resp.content.forEach((cw: CustomWorkReview) => {
        var team = cw.customWorkTeam || cw.categoryWorkTeam;
        csv.push(getCSVRow([
          String(cw.quote.engineeringStatusHot),
          Utils.stripSortingPrefix(cw.categoryInfo.name),
          team?.primaryEngineer?.name || "",
          team?.engineeringTeam?.name || "",
          cw.quote?.partNumberString || "",
          Utils.formatQuoteDisplayStatusStr(cw.quote) || "",
          cw.quote.model.name,
          dayjs(cw.quote.productionDate).format("MM/DD/YYYY"),
          dayjs(cw.dueDate).format("MM/DD/YYYY")
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'custom-work-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  const saveEngineeringHot = async (quoteId:number, hot:boolean) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.saveQuoteHot(quoteId, hot);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to set order as hot. " + errorMsg });
    }

    return;
  }

  const toggleQuoteHot = async (quote:BaseQuote) => {
    await saveEngineeringHot( quote.id, !quote.engineeringStatusHot );

    customWork.mutate();
  }

  const firstSort = [ sort ].flat()?.[0]

  const ColumnKey = {
    Category: 'assemblyCategory.name',
    Engineer: 'primaryEngineerName',
    Team: "assemblyCategory.categoryEngineeringTeam.engineeringTeam.name",
    ProductionDate: "quoteRevision.summary.productionDate",
  }

  const columns:ColumnType<CustomWorkReview>[] = [
    {
      title: "Hot",
      key: "quoteRevision.quote.engineeringStatusHot",
      sorter: true,
      render: (cw:CustomWorkReview) => 
        configurator.isAdmin() 
          ? cw.quote.engineeringStatusHot ? <Button onClick={() => toggleQuoteHot(cw.quote)} type="text" shape="circle" icon={<ExclamationOutlined style={{color: "red"}} />} />
          : <Button style={{backgroundColor: "rgba( 0, 0, 0, 0.05 )"}} type="text" shape="circle" onClick={() => toggleQuoteHot(cw.quote)} />
          : cw.quote.engineeringStatusHot && <div style={{textAlign: "center"}}><ExclamationOutlined style={{color: "red"}} /></div> 
    },
    {
      title: "Category",
      key: ColumnKey.Category,
      sorter: true,
      render: (cw:CustomWorkReview) => Utils.stripSortingPrefix( cw.categoryInfo?.name ),
      defaultFilteredValue: categoryIdParam ? [ categoryIdParam ] : undefined,
      filterMultiple:true,
      filterDropdown: (p) => <FilterDropDown {...p} ><CategorySelector style={{width: "15rem"}} placeholder="Search category" /></FilterDropDown>
    },
    {
      title: "Engineer",
      key: ColumnKey.Engineer,
      sorter: true,
      render: (cw:CustomWorkReview) => 
        <EditCustomWorkTeamButtonModal 
          value={cw}
          onChange={() => customWork.mutate()} />,
      defaultFilteredValue: defaultCustomWorkFilter.engineers?.length ? defaultCustomWorkFilter.engineers as FilterValue : undefined,
      filterMultiple:true,
      filterDropdown: (p) => <FilterDropDown {...p} ><QuoteEngineerSelector style={{width: "15rem"}} placeholder="Search engineers" /></FilterDropDown>,
    },
    {
      title: "Team",
      sorter: true,
      key: ColumnKey.Team,
      render: (cw:CustomWorkReview) => {
        var team = cw.customWorkTeam?.engineeringTeam || cw.categoryWorkTeam?.engineeringTeam;
        if ( !team  ) {
          return <SelectEngineeringTeamsButtonModal category={cw.categoryInfo} onChange={(engTeam) => handleAssignTeam(engTeam, cw.categoryInfo )} />
        }
        return team.name;
      },
      defaultFilteredValue: teamIdParam ? [ teamIdParam ] : undefined,
      filterMultiple:true,
      filterDropdown: (p) => <FilterDropDown {...p} ><TeamSelector style={{width: "15rem"}} placeholder="Search team" /></FilterDropDown>,
    },
    {
      title: "Part No.",
      key: "quoteRevision.partNumberMinor",
      sorter: true,
      width:  "10rem", //fixed size to prevent wrapping
      render: (cw:CustomWorkReview) => <QuoteCommentButton quote={cw.quote} onClick={() => handleShowComments(cw.quote, cw.categoryInfo)} />
    },
    {
      title: "Model",
      key: "quoteRevision.model.name",
      sorter: true,
      render: (cw:CustomWorkReview) => cw.quote.model.name
    },
    {
      title: "Status",
      key: "quoteRevision.quote.status",
      sorter: true,
      render: (cw:CustomWorkReview) => Utils.formatQuoteDisplayStatus(cw.quote),
    },
    {
      title: "Notes",
      key: "notes",
      render: (cw:CustomWorkReview) => 
        <Paragraph
          style={{maxWidth: "10rem" }}
          ellipsis={{ rows: 1, tooltip: true}}
        >
          {cw.notes}
        </Paragraph>
    },
    {
      title:"ECN",
      key: "ecn",
      sorter: true,
      render: (r) => <div style={{display: "flex", justifyContent: "center"}} >
        <EcnInput customWork={r} onChange={handleChangeEcn} />
      </div>
    },
    {
      title: "Production",
      key: ColumnKey.ProductionDate,
      sorter: true,
      render: (cw:CustomWorkReview) => cw.quote.productionDate ? dayjs(cw.quote.productionDate).format("MM/DD/YYYY") : undefined,
      filterSearch:true,
      filterMultiple:true,
      defaultFilteredValue: dateFilterStartParam && dateFilterEndParam ? [ dateFilterStartParam, dateFilterEndParam  ] : undefined,
      filterDropdown: (p) => <FilterDropDown {...p} ><RangePicker style={{width: "15rem"}} /></FilterDropDown>,
    },
    {
      title: "Due",
      key: "dueDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (cw:CustomWorkReview) => getDueDateStr(cw.dueDate),
    },
  ];

  return <div className="site-layout-background">

    <Space direction="vertical" style={{width:"100%"}} >

    <div style={{width: "100%", display: "flex", justifyContent:"space-between" }}>
        <Title level={2}>Custom Work Review</Title>
        <Space>
          <SaveFilter tableName={CUSTOM_WORK_LIST} />
          <Button onClick={handleResetFilters}>Reset</Button>
          <Button type="primary" loading={isExporting} onClick={exportCustomWork}>Export</Button>
        </Space>
    </div>

    <div>
      <Input value={filter.search} onChange={(e) => handleSearchChange(e.target.value)} placeholder="Search keywords" allowClear />
    </div>

   <Table
      key={customWorkKey}
      loading={customWork.isLoading}
      onChange={tableOnChange}
      bordered
      pagination={{...pagination, total: customWork.data?.totalElements}}
      dataSource={customWork.data?.content}
      columns={columns}
      rowKey={(r) => r.quote.quoteId + r.categoryInfo.id }
    />

    </Space>

    <CommentsDrawer 
      open={isCommentsOpen}
      onClose={handleHideComments}
      quote={commentProps?.quote}
      category={commentProps?.category}
    />

    </div>;
};

const QuoteCommentButton = (props:ButtonProps & {
  quote:BaseQuote
}) => {

  const {quote, ...btnProps} = props;

  const quoteCommentsAsync = useQuoteComments({
    quoteId: quote.quoteId,
    options: {
      topic: [CommentTopic.VfdReview],
    }
  });
  const quoteComments = quoteCommentsAsync.data;

  const total = quoteComments?.length || 0;
  const unread = quoteComments?.filter(c => !c.lastViewedAt).length || 0;
  const stats = { total, unread };

  const handleClick = (e) => {
    quoteCommentsAsync.mutate();
    props.onClick?.(e);
  }

  return <>
    <Link to={"/configurator/" + encodeURI(quote.quoteId)} target="_blank">{quote.partNumberString}</Link>
    <Button {...btnProps}
            onClick={handleClick}
            shape="circle"
            type="text"
            icon={<Badge count={stats.total} size="small" color={stats.unread ? "red" : "grey" } >
              <InfoCircleTwoTone />
            </Badge>} />
  </>
}

interface CustomWorkCommentProps{
  quote:BaseQuote | undefined
  category:CategoryInfo | undefined
}

const getCategoryTag = (category:CategoryInfo | undefined) => category && `#${_.upperFirst(_.camelCase( Utils.stripSortingPrefixOpt(category?.name) ))}`;

const CommentsDrawer = (props:DrawerProps & CustomWorkCommentProps) => {

  const { quote, category, ...drawerProps } = props;

  const categoryTag = getCategoryTag(category);
  return <>
    <Drawer
      {...drawerProps}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
      <CommentList quoteId={quote?.quoteId} 
        tags={categoryTag ? [categoryTag] : undefined} 
        topicTags={categoryTag ? [categoryTag] : undefined} />
    </Drawer>
  </>
}

const CategorySelector = ( props: SelectProps<number> ) => {

  const categories = useBaseCategories().data;

  const { ...selectProps} = props;
  const options = categories?.sort((a,b) => Utils.stripSortingPrefix(a.name).localeCompare(Utils.stripSortingPrefix(b.name)))
    .map(c => ({label: Utils.stripSortingPrefix(c.name), value: c.id}));

  return <Select 
    {...selectProps}
    showSearch
    optionFilterProp="label" 
    allowClear
    options={options}
  />

};


const TeamSelector = (props: SelectProps<number> ) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [teamLst, teamLstAsync] = useAsyncState<EngineeringTeam[]>([]);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    if( teamLstAsync.isInitial() ) {
      handleSearch();
    }
  }, []);


  const handleFocus = () => {
    if( teamLstAsync.isInitial() ) {
      handleSearch();
    }
  }

  const handleSearch = async (s?:string) => {
    loadEngineeringTeams( teamLstAsync, s );
  };

  const loadEngineeringTeams = useCallback(throttle( async (engineeringTeamLstAsync:AsyncState<EngineeringTeam[]>, search:string | undefined ) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    engineeringTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchEngineeringTeamList({
          search
        },
        cancelSource.token
      );
      cancelTokenSourceRef.current = undefined;

      engineeringTeamLstAsync.setDone( resp.data.content );
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error({message:"Engineering Teams failed to load. " + errorMsg});
        engineeringTeamLstAsync.setFail( e.message );
      }
    }

  }, DEFAULT_THROTTLE ), [] );

  return <Select
    {...props}
    showSearch
    allowClear
    onFocus={handleFocus}
    optionFilterProp="label"
    onSearch={handleSearch}
    loading={teamLstAsync?.isLoading()}
    options={teamLst?.map(team => ({label:team.name, value:team.id}))}
  />

};


const EditCustomWorkTeamButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value:CustomWorkReview
  onChange?: (v:CustomWorkReview | undefined) => void
}) => {

  const {value:a, onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [selectedCustomWorkReview, selectedCustomWorkReviewAsync] = useAsyncState<CustomWorkReview>();
  const [initialValues, setInitialValues] = useState<CustomWorkTeamFormValues>();

  useEffect( () => {
    form.resetFields();
  }, [initialValues])

  const saveCustomWorkTeam = async (quoteId: number, categoryId: number, customWorkTeam:CustomWorkReviewRequest) : Promise<CustomWorkReview | undefined> => {

    selectedCustomWorkReviewAsync.setLoading()
    try {
      const resp = await configurator.api.updateCustomWorkReview(quoteId, categoryId, customWorkTeam)
      selectedCustomWorkReviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit engineering Team. " + errorMsg;
      notification.error( { message: msg });
      selectedCustomWorkReviewAsync.setFail(msg);
    }

    return;
  }

  const handleAfterOpen = async (open:boolean) => {
    if (open) {

      //if primary or engineers are set, use them
      //else load them from category and team defaults
      var team = props.value.customWorkTeam;
      if ( team?.primaryEngineer || team?.engineers?.length ) {
        setInitialValues({
          primaryEngineer: team?.primaryEngineer,
          engineers: team?.engineers,
        });
      }
      else {
        setInitialValues({
          primaryEngineer: props.value.categoryWorkTeam?.primaryEngineer
        })
      }
    }
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOk = async () => {
    try {
      const formValues:CustomWorkTeamFormValues = await form.validateFields();
      const quoteId = props.value.quote.id;
      const categoryId = props.value.categoryInfo.id;

      const engineerIds = formValues.engineers?.map(e => e.id );
      const req = {
        primaryEngineerId: formValues.primaryEngineer?.id || null,
        engineerIds: engineerIds?.length ? engineerIds : null,
      }
      const customWorkTeam = await saveCustomWorkTeam(quoteId, categoryId, req);
      props.onChange?.( customWorkTeam && {
        ...selectedCustomWorkReview,
        ...customWorkTeam,
      });
      setIsOpen(false);
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      const errs = validationErrors.errorFields.map( f => f.errors ).flat().map( (msg, ndx) => <li key={`validationError-${ndx}`}>{msg}</li> );
      const errorMsg = !errs.length ? "Please fix validation errors." : <ul>{errs}</ul>;
      notification.error({message: errorMsg });
    }
  }
  const handleReset = async () => {
      const quoteId = props.value.quote.id;
      const categoryId = props.value.categoryInfo.id;

      const req = {
        primaryEngineerId: null,
        engineerIds: null,
      }
    const customWorkTeam = await saveCustomWorkTeam(quoteId, categoryId, req);
    props.onChange?.( customWorkTeam && {
      ...selectedCustomWorkReview,
      ...customWorkTeam,
    });
    setIsOpen(false);
  }

  const btnStyle = {borderBottom: "1px solid black"};

  const team = props.value.customWorkTeam || props.value.categoryWorkTeam;
  const teamEngineers = team?.engineers
      ?.filter(v => v)
      .filter(e => e.id !== team?.primaryEngineer?.id )
      .sort((a,b) => (a?.name || "" ).localeCompare((b?.name || "")));

  const engineers =  ( team === props.value.customWorkTeam ) ? [[team?.primaryEngineer], teamEngineers].flat().filter(v=>v)
      : [ props.value.categoryWorkTeam?.primaryEngineer ];

  const btnTitle = engineers.length
    ? <ul style={{ listStyle: "none", padding: 0, margin: 0, textAlign: "left" }} >
        {engineers.map(e => (<li key={["engineer", props.value.quote.id, e?.id].join("-")} style={{paddingBottom: ".4rem"}}><span style={btnStyle}>{e?.name}</span></li>))}
    </ul>
    : <span style={btnStyle}>None</span>;

  return <>
    <BMButton {...btnProps}
      style={{height:"auto"}}
      type="text"
      className="ghostBmButton"
      onClick={() => setIsOpen(true)}
    >{btnTitle}</BMButton>
    <Modal
      open={isOpen}
      width="50rem"
      title="Edit Custom Work Team"
      onCancel={handleCancel}
      afterOpenChange={handleAfterOpen}
      footer={ <div style={{display: "flex", gap: ".5rem", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <div>
          <Button danger onClick={handleReset}>Reset</Button>
        </div>
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleOk}>Save</Button>
        </Space>
      </div> }
    >
      <div key="formStep">
        <CustomWorkTeamForm form={form} initialValues={initialValues} />
      </div>
    </Modal>
  </>;
}


export interface CustomWorkTeamFormValues {
  primaryEngineer?: User | undefined
  engineers?:User[] | undefined
}

export const CustomWorkTeamForm = (props:FormProps ) =>  {

  const { ...formProps} = props;

  const users = useUsers();

  const internalUserLst = users.data?.filter(u => !u.dealerId );

  return <Form 
    {...formProps}
    layout="vertical"
  >
    <Form.Item name="primaryEngineer" 
      label="Primary Engineer"
    >
      <UserSingleSelector userLst={internalUserLst}/>
    </Form.Item>
    <Form.Item name="engineers" 
      label="Additional Engineers"
    >
      <UserMultiSelector userLst={internalUserLst}/>
    </Form.Item>
  </Form>
}

const EcnInput = (props:{
  customWork:CustomWorkReview | undefined
  onChange?: (cw:CustomWorkReview, s:CustomWorkReviewRequest) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const btnStyle = {borderBottom: "1px solid black"};
  const [ecn, setEcn] = useState<string>();

  const handleOpen = () => {
    setEcn( props.customWork?.ecn );
    setIsOpen(true);
  }
  const handleBlur = () => {

    setIsOpen(false);

    if ( !props.customWork ) return;

    props.onChange?.(  props.customWork, { 
      ecn: ecn || null
    } );
  }

  return <Popover
    trigger={"click"}
    afterOpenChange={(open) => {
      if( open ) {
        inputRef.current?.focus();
      }
    }}
    open={isOpen}
    content={<Input ref={inputRef}
      value={ecn}  
      placeholder="Type ECN"
      onChange={(e) => setEcn(e.target.value)}
      onBlur={handleBlur}
      onPressEnter={() => inputRef.current?.blur()}
      allowClear
    /> }
    arrow={false}
  >
    {!!props.customWork?.ecn?.length 
    ? <Button type="text" onClick={handleOpen} title={props.customWork?.ecn}>
      <span style={{...btnStyle, maxWidth:"5rem", overflow: "hidden", textOverflow: "ellipsis"}}>{props.customWork?.ecn}</span>
    </Button>
    : <Button style={{backgroundColor: "rgba( 0, 0, 0, 0.05 )"}} shape="circle" type="text" onClick={handleOpen} /> }
  </Popover>;
}

const FilterDropDown = (props: FilterDropdownProps & {
  children: ReactElement
}) => {

  const { children, ...filterProps } = props;

  const handleReset = () => {
    filterProps.clearFilters?.();
    filterProps.confirm();
  }

  const handleOk = () => {
    filterProps.confirm();
  }

  const newElement = React.cloneElement(props.children, {
    value: filterProps.selectedKeys,
    onChange: (ndx:React.Key | React.Key[]) => filterProps.setSelectedKeys([ndx].flat())
  });

  return <div style={{padding: "1rem"}}>
    <Space direction="vertical" >
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <Button onClick={handleReset} >Reset</Button>
      <Button type="primary" onClick={handleOk}>OK</Button>
    </div>
    <div style={{maxHeight: "25rem", overflowY: "auto"}}>
      <div style={{marginBottom: ".5rem"}}>{newElement}</div>
    </div>
    </Space>
  </div>;
}

export default CustomWorkReviewPage;
