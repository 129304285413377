import {useCallback, useContext, useEffect, useState} from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select, SelectProps } from "antd";
import {AssemblyBase, DEFAULT_THROTTLE} from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {throttle} from "lodash";
import {AssemblyFilterOptions} from "../api";
import useAssemblyInfoList from "../swr/useAssemblyInfoList";


const DEFAULT_FILTER :AssemblyFilterOptions = {
  imported: true,
  isDashAssembly: false,
}

const AssemblySelector = (props: SelectProps<number[]> & {
  categoryId?: number | undefined
}) => {

  const {categoryId:a, ...selectProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [selectedLst, selectedLstAsync] = useAsyncState<AssemblyBase[]>();
  const [filterOptions, setFilterOptions] = useState<AssemblyFilterOptions>(DEFAULT_FILTER);

  useEffect(() => {

    if ( !selectedLstAsync.isLoading() ) {
      if ( props.value?.length ) {
        selectedLstAsync.setLoading();
        Promise.all( props.value.map( id => configurator.api.getAssembly(id) ) )
        .then( selected => {
          selectedLstAsync.setDone( selected.map( r => r.data ) );
        })
        .catch((e:any) => {
          const errorMsg = intl.formatMessage({ id: e.message });
          notification.error( { message: "Failed to get selected assemblies. " + errorMsg });
          selectedLstAsync.setFail(e.message);
        });
      }
    }
  }, [props.value]);


  const handleSearch = useCallback(throttle(async (bom?:string) => {
    setFilterOptions({
      ...DEFAULT_FILTER,
      filterQuery: bom,
      categoryId: props.categoryId,
    });
  }, DEFAULT_THROTTLE), []);


  const assemblies = useAssemblyInfoList( {
    filterOptions,
  } );

  //add previously selected values to the data
  const distinct = (selectedLst || []).concat( assemblies.data?.content || [] ).reduce( (acc, v) => {
    acc[v.bom] = v
    return acc;
  }, {} );
  const datasource = Object.values( distinct ) as AssemblyBase[];

  return <Select
    mode="multiple"
    showSearch
    allowClear
    {...selectProps}
    optionFilterProp="label"
    onSearch={handleSearch}
    loading={assemblies.isLoading}
    options={datasource?.map(asm => {
      const lbl = asm.label || asm.bomDescription
      return {
        label: `${asm.bom} (${lbl})`,
        value: asm.id
      };
    }
    )}
  />

};

export default AssemblySelector;
