import "../util/mobile-table.css";
import Title from "antd/lib/typography/Title";
import {Table, Space, notification, Button, Form, Result, Descriptions, Steps, StepProps} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { ColumnType, TablePaginationConfig } from "antd/lib/table/interface";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import BMButton, { BMButtonProps } from "../components/BMButton";
import ModalWizard from "../components/ModalWizard";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../components/Wizard";
import {ApprovalRequest, ApprovalStep, PAGINATION_MAX_PAGE_SIZE} from "../api/models";
import {ApprovalStepForm, ApprovalStepFormValues} from "../components/Quote/ApprovalStepForm";
import useApprovalSteps from "../swr/useApprovalSteps";
import _ from "lodash";
import {PlusOutlined} from "@ant-design/icons";
import Utils from "../util/util";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";

const ApprovalStepsPage = () => {

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    position: ["bottomLeft"],
    pageSize: PAGINATION_MAX_PAGE_SIZE,
    hideOnSinglePage: true
  });

  const approvalStepLst = useApprovalSteps({
    current: pagination.current,
    pageSize: PAGINATION_MAX_PAGE_SIZE
  });

  const handleEditApprovalStep = (step: ApprovalStep | undefined) => {
    approvalStepLst.mutate();
  }

  const handleAddApprovalStep = (step: ApprovalStep | undefined) => {
    approvalStepLst.mutate();
  }

  const approvalStepByApprovalType = _.groupBy(approvalStepLst.data?.content.sort((a,b) => a.orderKey - b.orderKey), 'approvalType' );
  const dataSource = Object.values(ApprovalRequest).map(id =>({id}));

  const columns:ColumnType<{id: ApprovalRequest}>[] = [
    {
      render: ({id}) =>  {

        const steps = approvalStepByApprovalType[id];
        const maxKey = Math.max(...(steps?.map(s => s.orderKey) || []));
        return <>

          <style>
            {`
            .ant-steps .ant-steps-item .ant-steps-item-content {
                display: flex;
                justify-content:center;
                }
            `}
          </style>
          <div style={{textAlign: "center", marginBottom: "2rem"}}>
          <Title level={4}>{Utils.snakeCaseToFirstLetterCapitalized(id)}</Title>
          </div>
          <Steps
              style={{marginBottom: "6rem"}}
              labelPlacement={"vertical"}
              items={steps?.map(((step, ndx) => ({
                status: 'process',
                title:
                    <EditApprovalStepButtonModal type="text" className="ghostBmButton"
                                                 value={step}
                                                 onChange={handleEditApprovalStep}
                    >
                      <Space direction={"vertical"}>
                        <div style={{textDecoration: "underline"}}>{step.name || step.approvers?.map(Utils.snakeCaseToFirstLetterCapitalized).join(", ")}</div>
                        {step.disabled && <div>(disabled)</div>}
                      </Space>
                    </EditApprovalStepButtonModal>
              } as StepProps)))
                  .concat([{
                    status: 'process',
                    style: {display:"flex", justifyContent:"center"},
                    title:
                        <EditApprovalStepButtonModal type="text" className="ghostBmButton"
                                                     value={{
                                                       approvalType: id,
                                                       orderKey: maxKey + 1
                                                     }}
                                                     onChange={handleAddApprovalStep}
                        >
                          <PlusOutlined />
                        </EditApprovalStepButtonModal>
                  }
                  ])}
          />
        </>

      }

    },
  ];

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>

        <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
          <Title level={2}>Approval Steps</Title>
        </div>

        <Table
          rowKey="id"
          dataSource={dataSource}
          loading={approvalStepLst.isLoading}
          pagination={{...pagination, total: approvalStepLst.data?.totalElements}}
          showHeader={false}
          bordered={false}
          columns={columns}
          className="mobile-table"
        />

      </Space>
    </div>
  );
};

const EditApprovalStepButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?: ApprovalStepFormValues | undefined
  onChange?:(d:ApprovalStep | undefined) => void
  onCancel?:() => void
  open?:boolean
}) => {

  const {value:a, onChange:b, open:c, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(props.open || false);
  const [selectedApprovalStep, selectedApprovalStepAsync] = useAsyncState<ApprovalStepFormValues>();

  const [_activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleChangeApprovalStep = (approvalStep:ApprovalStep | undefined) => {
    setIsOpen(false);
    props.onChange?.(approvalStep);
  }

  const handleCancel = () => {
    setIsOpen(false);
    props.onCancel?.();
  }

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    />
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      afterOpenChange={(open) => {
        if(open) {
          selectedApprovalStepAsync.setDone(props.value);
        }}}
      steps={[
        useEditApprovalStepStep({
          key:1,
          value: selectedApprovalStep,
          onChange: handleChangeApprovalStep,
          onCancel: handleCancel
        }),
      ] }
    />
  </>;
}

const useEditApprovalStepStep = (props: {
  key: React.Key
  hidden?: boolean
  value: ApprovalStepFormValues | undefined
  onChange?:(t:ApprovalStep | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [form] = Form.useForm();

  const [_selectedApprovalStep, selectedApprovalStepAsync] = useAsyncState<ApprovalStep>();
  const [activeKey, setActiveKey] = useState<React.Key>();
  const [initialValues, setInitialValues] = useState<ApprovalStepFormValues>();

  useEffect(() => {
    setInitialValues( props.value && {
      ...props.value,
      //sort conditions
      skipConditions: props.value.skipConditions?.sort((a,b) => b.id - a.id ),
      requiredConditions: props.value.requiredConditions?.sort((a,b) => b.id - a.id )
    });
  }, [props.value] );


  useEffect(() => {
    form.resetFields();
  }, [initialValues] );

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleCancel = () => {
    props.onCancel?.();
  }

  const handleSave = async () => {

    try {
      const values = await form.validateFields() as ApprovalStepFormValues;
      if ( !values.approvers?.length ) {
        throw new Error("At least one approver role is required.");
      }

      const saved =  !props.value?.id
          ? await createApprovalStep(values)
          : await editApprovalStep(values) ;

      if ( saved ) {
        props.onChange?.(saved);
      }
    } catch (e: any) {
      const validationErrors = e as ValidateErrorEntity;
      const errorMsg = validationErrors.errorFields.flatMap(f => f.errors).join(" ");
      const msg = errorMsg || "Please fix validation errors. ";
      notification.error( { message: msg });
      selectedApprovalStepAsync.setFail(msg);
    }
  }

  const handleConfirmDelete = async () => {
    if ( !props.value?.id ) return;

    const rslt = await editApprovalStep({id: props.value.id, hidden: true});

    if ( rslt ) {
      props.onChange?.(undefined);
    }
  }

  const editApprovalStep = async (approvalStep:ApprovalStepFormValues) : Promise<ApprovalStep | undefined> => {
    if ( !approvalStep.id ) return;

    selectedApprovalStepAsync.setLoading()
    try {
      const resp = await configurator.api.updateApprovalStep(approvalStep.id, approvalStep)
      selectedApprovalStepAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit approval step. " + errorMsg;
      notification.error( { message: msg });
      selectedApprovalStepAsync.setFail(msg);
    }

    return;
  }

  const createApprovalStep = async (approvalStep:ApprovalStepFormValues) : Promise<ApprovalStep | undefined> => {
    if ( !approvalStep ) return;

    selectedApprovalStepAsync.setLoading();
    try {
      const resp = await configurator.api.createApprovalStep(approvalStep)
      selectedApprovalStepAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create approval step. " + errorMsg;
      notification.error( { message: msg });
      selectedApprovalStepAsync.setFail(msg);
    }

    return;
  }

  const steps:WizardStep[] = [
        {
          key:1,
          title: props.value ? "Edit " + (props.value.name || "" ) + " Approval Step" : "Add Approval Step",
          body: () => <div key="formStep">
            <ApprovalStepForm form={form} initialValues={initialValues} />
          </div>,
          footer:(nav) => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
            {props.value &&
              <Button danger key="delete" onClick={() => nav.nextStep()}>Delete</Button>
            }
            <div style={{display: "lex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Space>
                <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                <Button key="save" type="primary" onClick={() => handleSave()}>Save</Button>
              </Space>
            </div>
          </div>
        },
        {
          key:2,
          body: () =>  <div key="confirmStep" >
            <ConfirmDeleteApprovalStepResult value={props.value} />
          </div>,
          footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button type="primary" danger onClick={() => handleConfirmDelete()}>Delete</Button>
            <Button onClick={() => nav.prevStep()}>Back</Button>
          </div>
        }
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: () => 
      <Wizard 
        key="editApprovalStep"
        steps={steps} 
        showSteps={false}
        onStep={handleStep}
      />,
    title: () => title,
    footer: () => footer,
  }

}

const ConfirmDeleteApprovalStepResult = (props:{
  value:ApprovalStepFormValues | undefined
}) => {
  return  <Result
    status="warning"
    title="The following approval step will be deleted:"
    style={{padding: "0"}}
  >
    <Descriptions
      column={1}
      className="dialog-approvalStep"
      items={ [
          {
        label: "Name",
        children: props.value?.name
      },
        {
          label: "Approval Type",
          children: props.value?.approvalType
        },
      {
        label: "Roles",
        children: props.value?.approvers?.join(", ")
      },
        {
          label: "Notifications",
          children: props.value?.notificationEvents
        },
      ]}
      size="small"
    />
  </Result>

}

export default ApprovalStepsPage;
