import { Modal, notification, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { QuoteRevisionDiff, PAGINATION_MAX_PAGE_SIZE, QuoteAuditData, QuoteAuditInfo, SortDirection} from "../../api/models";
import BMButton, { BMButtonProps } from "../../components/BMButton";
import { ConfiguratorContext } from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import QuoteRevisionDiffTable from "../Table/QuoteRevisionDiffTable";

const PREVIOUS_AUDIT_ID = -1;
const NOT_FOUND = -1;
const UndoQuoteModalButton = (props:BMButtonProps) => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quote, setQuoteFormValues} = useQuoteContext();
  const [_quoteUndo, quoteUndoAsync] = useAsyncState<QuoteAuditData>();
  const [_quoteAuditDiff, quoteAuditDiffAsync] = useAsyncState<QuoteRevisionDiff>();
  const [undoIdSet, setUndoIdSet] = useState<QuoteAuditInfo[]>();
  const [undoId, setUndoId] = useState<number | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>();

  useEffect(() => {
    loadQuoteUndo(quote?.quoteId).then( lst => {
      setUndoIdSet(lst);
      setUndoId( lst?.[1]?.id );
    });
  }, [quote?.quoteId]);


  const loadQuoteUndo =  async (quoteId:string | undefined) : Promise<QuoteAuditInfo[] | undefined> => {
    if ( !quoteId ) return;

    const sort = [{
      field: "id",
      direction: 'desc' as SortDirection,
    }];

    try {
      const resp = await configurator.api.fetchQuoteAudit({
        quoteId,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE,
        sort
      })

      return resp.data.content;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Report failed to load undo. " + errorMsg });
    }

    return;
  };


  const loadQuoteUndoByAuditId = async (auditId:number ) : Promise<QuoteAuditData | undefined> => {

    quoteUndoAsync?.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteByAudit(auditId)
      quoteUndoAsync?.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch undo. " + errorMsg });
      quoteUndoAsync?.setFail(errorMsg);
    }

    return;
  }

  const loadQuoteAuditDiff = async (quoteAuditDiffAsync:AsyncState<QuoteRevisionDiff>, auditIdA:number | undefined, auditIdB:number | undefined ) : Promise<QuoteRevisionDiff | undefined> => {

    quoteAuditDiffAsync.setLoading();

    try {
      const resp = await configurator.api.fetchQuoteAuditDiff(auditIdA, auditIdB)
      quoteAuditDiffAsync.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch undo differences. " + errorMsg });
      quoteAuditDiffAsync.setFail(e.message);
    }

    return;
  }


  const handleUndo = () => {
    if( !undoId ) return;

    loadQuoteUndoByAuditId(undoId)
      .then( q => {
        setQuoteFormValues?.( q?.quote, true )

        const ndx = undoIdSet?.findIndex( u => u.id === undoId ) || NOT_FOUND; 
        if ( ndx !== NOT_FOUND && ( ndx < ( undoIdSet?.length || 0) ) ) {
          const nextUndoId = undoIdSet?.[ ndx + 1 ];
          setUndoId( nextUndoId?.id );
        }
        else {
          setUndoId( undefined )
        }

        setIsOpen(false);
      });
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDisabledUndoMsg = () => {
    return !undoIdSet ? "There is no undo history."
      :undefined
  }


  return <>
    <BMButton
      {...props}
      disabled={!!getDisabledUndoMsg() || props.disabled}
      onDisabledClick={() => {
        if ( !!getDisabledUndoMsg() ) {
          notifyDisabled(getDisabledUndoMsg())
        }
        else {
          props.onDisabledClick?.();
        }
      }}
      onClick={() => setIsOpen(true)}
    >
      Undo
    </BMButton>
    <Modal title='Restore the following changes:'
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={'90rem'}
      onOk={handleUndo}
      afterOpenChange={(open:boolean) => {
        if(open) {
          loadQuoteAuditDiff(quoteAuditDiffAsync, undoId, PREVIOUS_AUDIT_ID );
        }
      }}
    >
      <Spin spinning={quoteAuditDiffAsync?.isLoading()} >
        <QuoteRevisionDiffTable diff={quoteAuditDiffAsync?.val} />
      </Spin>
    </Modal>
  </>

}

export default UndoQuoteModalButton;

