import {FormattedMessage} from "react-intl";
import _ from "lodash";
import {
  ApprovalRequest,
  ApproverRole,
  AssemblyBase,
  AssemblyInfo,
  AXIOS_CANCEL_MSG,
  BaseCategory,
  Customer,
  CustomOptionType, MAXIMUM_FILENAME_LENGTH,
  Performance,
  Permission,
  Quote,
  QuoteDisplayStatus,
  QuoteReview,
  Revision,
  RevisionApprovalStatus,
  RevisionType,
  User
} from "../api/models";
import React, {ReactNode} from "react";
import {notification, Space, Tooltip} from "antd";
import dayjs from "dayjs";
import {ColumnType} from "antd/es/table";
import {Configurator} from "../context";
import axios, {CancelTokenSource} from "axios";
import {TruckOutlined} from "@ant-design/icons";

export const formatCSVDecimal = (() => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (val:number | undefined) :string => {
    if ( val === undefined || isNaN(val) ) return '0.00';

    return formatter.format( val ).replaceAll( ",", "");
  }
})();

interface QuoteStatusProps {
  status: string,
  quoteApprovalStatus: string | undefined,
  isExpired?:boolean | undefined,
  reservation?: boolean | undefined
}

export interface ExportableColumn<T> extends ColumnType<T> {
  renderCSV?: ( rec:T ) => string | undefined
}

export interface SearchTerm {
  value:string, not:boolean
}

export interface HSLColor {
  h:number
  s:number
  l:number
}
export class Utils {
  static getAssemblyLabel(asm:AssemblyBase) : string | undefined {
    if (asm.label && asm.label.length > 0) {
      return asm.label;
    }
    return asm.bomDescription;
  }

  static formatUsername(u:User|undefined):string|undefined {  
    return u?.dealerName ? `${u?.name} (${u?.dealerName})` : u?.name; 
  }

  static getMetadataValue(md:{valueText?:string, valueBool?:boolean, valueNumeric?:number, valueDecimal?:number} | undefined) : string | number | undefined {
    if (!md) return;
    if (md.valueText != null) return md.valueText;
    if (md.valueNumeric != null) return md.valueNumeric;
    if (md.valueDecimal != null) return md.valueDecimal;
    if (md.valueBool) return md.valueBool ? "True" : "False";
    return;
  }
  static formatApprovalType(approvalType: string | undefined, reservation?: boolean | undefined): React.ReactNode {
    if (!approvalType) return <></>;

    if (!!reservation) {
      approvalType = "RESERVATION";
    }

    return <FormattedMessage
      id={"approvalType." + approvalType}
      defaultMessage={approvalType}
    />;
  }

  static formatQuoteDisplayStatusStr(q:{displayStatus:QuoteDisplayStatus | undefined }): string | undefined {
    if ( !q.displayStatus ) return;

    const {major, minor } = q.displayStatus;
    return [major, minor].filter(v => v).join(" - ");
  }

  static formatQuoteDisplayStatus(q:{displayStatus:QuoteDisplayStatus | undefined, shipDate?:Date } | undefined): React.ReactNode {
    const noTextStyle = {};
    const infoTextStyle = {color: "blue", fontWeight: 'bold'};
    const warningTextStyle = {color: "orange", fontWeight: 'bold'};
    const errorTextStyle = {color: "red", fontWeight: 'bold'};

    if ( !q?.displayStatus ) return <></>;

    const {major, minor } = q.displayStatus;
    const minorStyle =
        minor == "Approved" ? noTextStyle
            : minor == "Pending" ? warningTextStyle
                : minor == "Rejected" ? errorTextStyle
                    : undefined;
    return <>
      <Space direction={"vertical"} size={"small"} style={{rowGap: 0}}>
        <div>{major}</div>
        {minor && <div style={minorStyle}>({minor})</div>}
        {q.shipDate && <Tooltip title={`Shipped on ${dayjs(q.shipDate).format("M/DD/YY")}`}><TruckOutlined style={{fontSize: "1.2rem"}} /></Tooltip>}
      </Space>
    </>
  };


  static snakeCaseToFirstLetterCapitalized = (str: string | undefined) => {
    if (!str) return '';
    if (str === ApproverRole.ENGINEERING) return "Application Engineering";
    const ret = str.split('_').map(str =>
      str[0].charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    ).join(' ');
    return ret;
  }

  static formatPercent = (() => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    });

    return (val:number | undefined, defaultValue?: string) :string => {
      if ( val === undefined || isNaN(val) ) return defaultValue ?? 'N/A';
      return formatter.format( val );
    }
  })();

  static formatRevisionLabel = (rev: Revision | undefined, currentRevisionId: number | undefined, forDealer: boolean) : string => {
    if ( !rev ) return '';

    const hasEpicorRev = !!rev.erpRevisionNum && (rev.revisionApprovalStatus == RevisionApprovalStatus.APPROVED );

    const epicorStatus = (!forDealer && hasEpicorRev) ? `( Epicor ${rev.revisionString} )` : '';

    /*
    const approvalStatus = rev.abandoned ? "Abandoned"
        : rev.approvalStatus == RevisionApprovalStatus.REJECTED ? "Rejected"
        : rev.approvalStatus == RevisionApprovalStatus.APPROVED ? "Approved"
        : rev.approvalStatus == RevisionApprovalStatus.PENDING_APPROVAL ? "Pending"
        : "Draft";
     */
    const approvalStatus = (rev.id > (currentRevisionId || rev.id) && rev.abandoned ) ? "Abandoned"
        : rev.id !== currentRevisionId ? "Previous"
        : "";

    const revisionType = !rev.revisionType ? ""
        : rev.revisionType === RevisionType.SPLIT_ORDER ? "Split"
        : [RevisionType.ENGINEERING_CHANGE, RevisionType.CHANGE_ORDER ].includes(rev.revisionType) && rev.revisionApprovalStatus !== RevisionApprovalStatus.APPROVED ? "Change Order"
        : "";

    const revisionStr = `Revision ${rev.revision}`

    const major = [ revisionStr, epicorStatus ].filter(v=>v).join(" ");
    const minor = [ revisionType, approvalStatus ].filter(v=>v).join( " ");
    return [major, minor].filter(v=>v).join( " - ");
  };


  static usDollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  static usDollarCentsFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  static formatNumber = ( formatter:Intl.NumberFormat, val:number | undefined, defaultValue?: string) :string => {
    if ( val === undefined || isNaN(val) ) return defaultValue ?? 'N/A';
    const factor = Math.pow(10, 4);
    const roundedVal = Math.ceil(val * factor) / factor;
    return formatter.format( roundedVal );
  };

  static formatMoney = ( val:number | undefined, defaultValue?: string) :string => {
    return this.formatNumber( Utils.usDollarFormatter, val, defaultValue );
  }

  static formatUsDollarCents = ( val:number | undefined, defaultValue?: string) :string => {
    return this.formatNumber( Utils.usDollarCentsFormatter, val, defaultValue );
  }

  static formatMoneyWithSign = (val: number | undefined): string => {
    const formattedValue = Utils.formatMoney(val);
    
    if (formattedValue === 'N/A') return formattedValue;

    if (!val || (val && val < 0)) {
      return formattedValue;
    } else {
      return '+' + formattedValue;
    }
  }

  static bisectList<T>( lst:Array<T>, pred:(x:T)=>boolean ):Array<T[]> {

    return lst.reduce( this.bisect(pred), [ new Array<T>(), new Array<T>() ] );
  }

  static bisect<T>(pred:(x:T)=>boolean) {
    return (acc:Array<T[]>, v:T ) : Array<T[]> => {
          const [ s, r ] =  acc;

          const cond = pred( v )

          if ( cond ) {
            s.push( v );
          }
          else {
            r.push( v );
          }

          return acc;

      } ;
  }

  static diff( a:any, b:any ) {
      return _.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual)
  }

  static saveNavigation() {
        window.sessionStorage.setItem("auth0-redirect", window.location.href );
  }

  static restoreNavigation() {
    const nav = window.sessionStorage.getItem("auth0-redirect");
    if ( nav ) {
      window.sessionStorage.removeItem("auth0-redirect");
      window.location.href = nav;
    }
  }

  static isOrder(quote: Quote | undefined | null): boolean {
    return !!quote?.partNumberString?.length;
  }

  static stripSortingPrefix(name: string | undefined | null) : string {
    return Utils.stripSortingPrefixOpt(name) || "";
  }
  static stripSortingPrefixOpt(name: string | undefined | null) : undefined | string {
    if (!name) return;

    const match = name.trim().match(/[0-9]+[ ]*\-(.*)/);
    if (match) {
      return match[1].trim();
    }

    return name;
  };

  static getSortingPrefixOpt(name: string | undefined | null) : undefined | number {
    if (!name) return;

    const match = name.trim().match(/([0-9]+[ ]*)\-(.*)/);
    if (match) {
      return Number( match[1].trim() );
    }

    return undefined;
  };

  static joinNodes(data:ReactNode[], delimiter: ReactNode) : ReactNode {

    return <>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && delimiter}
            {item}
          </React.Fragment>
        ))}
      </>
  };

  static warning(msg:string | undefined) : void {
    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  static getEngineeringLeadTime = (customOption: CustomOptionType, categories: BaseCategory[]): string => {
    const category = categories?.find(cat => cat.id === customOption.category?.id);
    if (category?.designAndProcurementWeeks || category?.designAndProcurementWeeks === 0) {
      return String(category.designAndProcurementWeeks);
    }
    return '';
  }

  static getAddress(customer: Customer | undefined) : string | undefined {
    if ( !customer ) return;

    const address = [customer.addressLine1, customer.addressLine2].filter(v => v).map(str => str?.trim()).join(" ");
    const city = [customer.city || undefined].filter(v => v).map(str => str?.trim()).join(" ");
    const zip = [customer.stateProvince, customer.postalCode].filter(v => v).map(str => str?.trim()).join(" ");
    return [address, city, zip].filter(v => v).join(", ");
  }

  static exportDataAsCSV<T>(filename:string, data:T[], columns:ExportableColumn<T>[]) : void {
    if ( data.length === 0 ) return;

    const csvHeader = [ 
      columns
      .map( c => `"${c.title}"` )
      .join(",") 
    ];
    const csvData = data.map( d => columns
                             .map( c => c.renderCSV?.( d ) ) //get the column from the data
                             .map( c => c !== undefined ? String( c ) : "" )
                             .map( c => c.replaceAll( '"', '""' ) ) //escape double quotes
                             .map( c => `"${c}"` ) //wrap in double quotes
                             .join(",") 
                            );

    const CSV = csvHeader.concat( csvData ).join('\n');

    window.URL = window.webkitURL || window.URL;

    const contentType = 'text/csv';

    const csvFile = new Blob([CSV], {type: contentType});

    const csvFileNameComponents = [ filename ];

    const ts = dayjs().format( "YYYY-MM-DD" );
    csvFileNameComponents.push( ts );

    const csvFileName = csvFileNameComponents.join( "_" ).slice(0, MAXIMUM_FILENAME_LENGTH) + ".csv";

    var a = document.createElement('a');
    a.download = csvFileName;
    a.href = window.URL.createObjectURL(csvFile);
    a.click()

  }

  static isWithinLeadTime(productionDate: Date | undefined, leadTime: number | undefined): boolean {
    if (!productionDate || !leadTime) return false;
    return dayjs(productionDate).isBefore(dayjs().add(leadTime, 'day'));
  }

  static getQuoteReadOnlyMsg(conditions: {
    isEffectiveRevision:boolean | undefined
    isRevisionApproved:boolean | undefined
    isInitialLoading:boolean | undefined
    isLocked:boolean | undefined
    hasWritePermission:boolean | undefined
    isEngineeringLocked:boolean | undefined
    isReservation :boolean | undefined
    isOrder: boolean | undefined
    isOrderCancelled:boolean | undefined
    isDraft:boolean | undefined
    isPending:boolean | undefined
    isEngineeringChangeOrder:boolean | undefined
    isWritable: boolean | undefined
    isPendingSplit: boolean | undefined
    isArchived: boolean | undefined
    isExpired: boolean | undefined
    hasEngineeringChangePermission: boolean | undefined
  }) : string | undefined {

    const {
      isEffectiveRevision,
      isRevisionApproved,
      isInitialLoading,
      isLocked,
      hasWritePermission,
      isEngineeringLocked,
      isReservation ,
      isOrder,
      isDraft,
      isPending,
      isOrderCancelled,
      isEngineeringChangeOrder,
      isWritable,
      isArchived,
      isExpired,
      hasEngineeringChangePermission
    } = conditions;

    return isInitialLoading ? "Please wait for the quote to finish loading."
      : isArchived  ? "This quote has been archived.  No changes are permitted."
      : isOrder && isPending ? "Orders cannot be changed while pending approval."
      : isOrder && !isDraft ? "Orders can only be changed with a change order."
      : isLocked ? "This quote is locked by another user."
      : !hasWritePermission ? "Additional permissions are needed to edit this quote."
      : isEngineeringLocked ? "This quote is locked by engineering."
      : !isEffectiveRevision ? "Only the latest effective revision be modified."
      : isRevisionApproved ? "A quote cannot be changed once approved.  Please create a change order or revise the quote."
      : isReservation ? "This is a reservation and cannot be modified."
      : isOrderCancelled ? "This order has been canceled."
      : (isEngineeringChangeOrder && !hasEngineeringChangePermission  ) ? "This is an engineering change and can only be changed by an engineer."
      : !isWritable ? "Additional permissions are needed to edit this quote."
      : isExpired  ? "This quote has expired.  No changes are permitted."
      : undefined ;

  }
  static getQuoteState(configurator:Configurator, quote:Quote | undefined, isLoading?:boolean, isLocked?:boolean ) {

    const isEngineering = configurator.isEngineering();
    const isAdmin = configurator.isAdmin();
    const isSalesDesk = configurator.isSalesDesk();
    const isSubordinate = ( quote?.owner?.id && configurator.userInfo?.subordinates.includes(quote.owner.id) ) || false;

    const isEngineeringLocked = !( isEngineering || isAdmin ) && !!quote?.lockedByEngineer;

    const hasEngineeringChangePermission = configurator.isEngineering() || configurator.isAdmin();

    const hasWritePermission = quote?.hasWritePermission;

    const isInitialLoading = isLoading && !quote;

    const isFirstRevision = quote?.revision == 1;
    const isLatestRevision = quote?.displayRevisionId === quote?.latestRevisionId;
    const isEffectiveRevision = quote?.displayRevisionId === quote?.effectiveRevisionId;
    const revision = quote;

    const isCancelled = revision?.cancelled;
    const isSplitOrder = revision?.revisionType === RevisionType.SPLIT_ORDER;
    const isPendingSplit = !isCancelled && isSplitOrder &&
        quote?.approvalGroups?.filter(ag => ag.approvalType === ApprovalRequest.CHANGE_ORDER ).some(ag => ag.approvalStatus !== RevisionApprovalStatus.APPROVED )

    const isOrder = Utils.isOrder(quote);
    const isOrderPending = !revision?.latestApproval?.action && revision?.latestApproval?.approvalType == ApprovalRequest.ORDER;
    const isDraft = ( revision?.revisionApprovalStatus === RevisionApprovalStatus.DRAFT || revision?.revisionApprovalStatus === RevisionApprovalStatus.REJECTED ) && !isCancelled;
    const isApproved= revision?.revisionApprovalStatus === RevisionApprovalStatus.APPROVED;
    const isPending = revision?.revisionApprovalStatus === RevisionApprovalStatus.PENDING_APPROVAL || isOrderPending;

    const approvers = new Set(quote?.latestApproval?.approvalStep.approvers);
    const isReleaseEngineeringApprovalEdit = isPending && configurator.isReleaseEngineering() && !quote?.latestApproval?.action && approvers.has(ApproverRole.RELEASE_ENGINEERING);
    const isApplicationEngineeringApprovalEdit =  isPending && configurator.isEngineering() && !quote?.latestApproval?.action && approvers.has( ApproverRole.ENGINEERING );
    const isSalesDeskApprovalEdit =  isPending && configurator.isSalesDesk() && !quote?.latestApproval?.action && approvers.has( ApproverRole.SALES_DESK );
    const isApprovalEdit = ( isReleaseEngineeringApprovalEdit || isApplicationEngineeringApprovalEdit || isSalesDeskApprovalEdit );

    const isSalesChangeOrder = revision?.revisionType == RevisionType.CHANGE_ORDER || isSplitOrder;
    const isEngineeringChangeOrder = revision?.revisionType == RevisionType.ENGINEERING_CHANGE;
    const isReviseQuote = !isFirstRevision && !isApproved && !isOrder;

    const isOrderShipped = quote?.shipped;
    const isOrderCancelled = quote?.cancelled;
    const isExpired = quote?.expired;
    const isArchived = quote?.archived;
    const canChangeReadOnly = ( isSalesDesk || isEngineering || isAdmin ) && !isEngineeringLocked && !isArchived;

    const isNewQuote = !quote && !isLoading;

    const writeable = 
      isNewQuote ||
      isApprovalEdit ||  //is role approval step
      (isDraft && isEffectiveRevision) || 
      (isDraft && isSalesChangeOrder ) //is a sales change order
    ;

    const readOnlyMsg = this.getQuoteReadOnlyMsg({
      isEffectiveRevision,
      isRevisionApproved: isApproved,
      isInitialLoading,
      isLocked,
      hasWritePermission,
      isEngineeringLocked,
      isReservation: !!quote?.reservation,
      isOrderCancelled,
      isOrder,
      isDraft,
      isPending,
      isEngineeringChangeOrder,
      isWritable: writeable,
      isPendingSplit,
      isArchived,
      isExpired,
      hasEngineeringChangePermission,
    });

    const isReadOnly = !!readOnlyMsg;

    const rslt = {
      isInitialLoading,
      isEngineeringLocked,
      isLatestRevision,
      isEffectiveRevision,
      effectiveRevision: revision,
      isPendingSplit,
      isOrder,
      isPending,
      isDraft,
      isSplitOrder,
      isSalesChangeOrder,
      isEngineeringChangeOrder,
      isOrderShipped,
      isOrderCancelled,
      isApproved,
      isReadOnly,
      isApprovalEdit,
      readOnlyMsg,
      isReviseQuote,
      hasWritePermission,
      isArchived,
      canChangeReadOnly,
      isSubordinate,
      hasEngineeringChangePermission,
    }
    //console.log(rslt);
    return rslt;

  }

  static getPerformanceErrors(perf:Performance | undefined) : Array<string> | undefined {

    if ( !perf ) return undefined;

    const data = perf.performanceData;
    const errs = [
      data?.gradeabilityAlert && "The gradeability needs review.",
      data?.wheelslipAlert && "The wheelslip needs review.",
      data?.accelerationRateAlert && "The acceleration rate needs review.",
      data?.startabilityAlert && "The startability needs review.",
      data?.gearedSpeedAlert && "The gearing speed needs review."
    ].filter(v=>v) as Array<string>;

    if ( perf?.performanceWeight?.weightsMissing.length 
        || perf?.performanceWeight?.tareMissing.length 
      || perf?.performanceWeight?.gvwrMissing.length 
      || perf?.performanceData?.performanceMissing.length 
      || perf.performanceDimension?.dimensionMissing.length ) {
        errs.push( "There are missing selections causing calculation errors.");
      }

      return errs;

  }

  static reviewHasErrors(review:QuoteReview | undefined, ignorePerformance?: boolean ) : boolean {
    if( !review ) return false;

    if ( review.customOptions && Object.values( review.customOptions ).length ) return true;
    if ( review.invalidAssemblyLst && Object.values( review.invalidAssemblyLst ).length ) return true;
    if ( review.obsoleteAssemblyLst && Object.values( review.obsoleteAssemblyLst ).length ) return true;
    if ( review.missingCategoryLst && Object.values( review.missingCategoryLst ).length ) return true;

    if (!ignorePerformance ) {
      if ( Utils.getPerformanceErrors(review.performance)?.length ) return true;
    }

    return false;

  }

 static searchValue =  (searchFilter:SearchTerm[] | undefined, value:string | undefined) => {
    if ( !searchFilter?.length ) return true;

    return searchFilter.every(s => {
      const rslt = value?.toLowerCase().includes( s.value.toLowerCase() );
      return s.not ? !rslt : rslt;
    });
  }
  static splitByWhitespacePreservingQuotes(input:string | undefined) : SearchTerm[] | undefined {
    if ( !input ) return;

    const regex = /(!?"[^"]*"|!?[^\s"]+)/g;
    let matches = new Array<SearchTerm>();
    let match:RegExpExecArray | null;

    while ((match = regex.exec(input)) !== null) {
        let value = match[0];
        let not = value.startsWith('!');
        
        if (not) {
            value = value.substring(1);
        }

        // Remove surrounding quotes if they exist
        if (value.startsWith('"') && value.endsWith('"')) {
            value = value.substring(1, value.length - 1);
        }

        matches.push({ value: value, not: not });
    }

    return matches;
  }

  static buildSerialNumberStr(snLst:string[] | undefined):string | undefined {
    return snLst
      ?.map( sn => Number.parseInt(sn) )
      .filter( sn => !isNaN(sn) )
      .sort( )
      .reduce( ( acc, sn ) => {

        const lastSet = acc[ acc.length - 1 ] || [];
        const lastSn = lastSet[ lastSet.length - 1 ];

        //if no set, it's the first.
        //create first set
        if ( !lastSn ) {
          lastSet.push( sn );
          acc.push( lastSet );
          return acc;
        }

        //if contiguous with last serial
        //add to the current set
        if ( sn - 1 === lastSn ) {
          lastSet.push( sn );
          return acc;
        }

        //otherwise, create new set
        acc.push( [ sn ] ); 
        return acc;

      }, new Array<Array<number>>() )
      .reduce( (acc, snRange ) => {

        //if single set, leave solo
        if ( snRange.length === 1 ) {
          acc.push( String( snRange.pop() ) )
          return acc;
        }

        //if only two, join them
        if ( snRange.length === 2 ) {
          acc.push( snRange.join(", ") )
          return acc;
        }

        //if multiple serials, create range
        acc.push( snRange.shift() + "-" + snRange.pop() )
        return acc;

      }, new Array<string>() )
      .join( "," );
  }


  static expandTruckSerialNumberStr( snStr:string ):number[] {
    //use Set to remove duplicates
    return [...(new Set( snStr.split( "," )
      .flatMap( v => {

        const arr = v.split( "-" ); //split ranges

        const r1 = Number.parseInt( arr[0] );
        if ( isNaN(r1) ) throw new Error(`Invalid number. (${arr[0]})` );

        if ( arr.length === 1 ) return [ r1 ]; //if only one value, not a range, return

        const r2 = Number.parseInt( arr[1] );
        if ( isNaN(r2) ) throw new Error(`Invalid number. (${arr[1]})` );

        const rangeSize = ( r2 + 1 ) - r1; //inclusive
        if ( rangeSize < 1 ) throw new Error(`Invalid range size. The range ending number must be larger than the starting number. ${r1} - ${r2}.  size = (${rangeSize})` );
        if ( rangeSize > 10000 ) throw new Error(`Invalid range size. It's too big. ${r1} - ${r2}.  size = (${rangeSize})` );

        return new Array<number>( rangeSize ).fill( r1 ).map( ( v, ndx ) => v + ndx ); //create an full array from the range

      })
    ))];


  }


  static executeWithCancelToken = async <T extends Object> (ref: React.MutableRefObject<any>, request: (token: any) => Promise<T | undefined>) => {
    const cancelSource = Utils.getCancelTokenSource(ref);
    try {
      const resp = await request(cancelSource.token);
      ref.current = undefined;
      return resp;
    } catch (e: any) {
      const msg = e.response?.data?.message || e.message;
      if (msg !== AXIOS_CANCEL_MSG) {
        throw e;
      }
    }
  };

  static getCancelTokenSource = (cancelTokenRef: React.MutableRefObject<CancelTokenSource | undefined>): CancelTokenSource => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel(AXIOS_CANCEL_MSG);
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelSource;
    return cancelSource;
  };

  static getAssemblyInfoDisplayLabel = (asm:AssemblyInfo) : string => {
    const lbl = ( asm.label?.length )
      ? asm.label
      : asm.bomDescription 

      return [asm.bom, lbl].filter(v=>v).join( " - " );
  }

  static isEmptyDeep = (obj:any) => {
    if ( obj === undefined || obj === null ) {
      return true;
    }
    else if(_.isObject(obj)) {
        if(Object.keys(obj).length === 0) return true
        else return _.every(_.map(obj, v => Utils.isEmptyDeep(v)))
    }
    else if(_.isString(obj)) {
      return !obj.length
    }

    return false
  }

  static notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  static toggleTag = <T extends Object> (tags:Array<T> | undefined, t:T, enabled: boolean) : Array<T> => {
    const s = new Set<T>(tags);
    if ( enabled ) s.add( t )
    else s.delete( t );
    return [...s]
  }


}

export default Utils;
