import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Input, message, Modal, Row, Space, Table, Tooltip} from "antd";
import { useAsyncState } from "../hook/useAsyncState";
import { CategoryIdAssembliesIdMap, AssemblyInfo} from "../api/models";
import { ConfiguratorContext} from "../context";
import { debounce } from "lodash";
import { DownloadOutlined } from "@ant-design/icons";
import Utils, {ExportableColumn, formatCSVDecimal} from "../util/util";
import {BMButtonProps} from "./BMButton";
import { useQuoteContext } from "../contexts/QuoteContext";

const getEpicorMoneyDiff = (rec:AssemblyInfo) => {
    return (rec.standardMaterialCost || 0) - ( rec.epicorStandardMaterialCost || 0 )
}

const getEpicorPercentDiff = (rec:AssemblyInfo) => {
    return Math.round( ( ( rec.epicorStandardMaterialCost ? (rec.standardMaterialCost || 0) / rec.epicorStandardMaterialCost - 1 : 0) * 100 ) ) / 100;
}

const BomDetails = (props: {
  pricingSnapshotId: number | undefined
  selectedOptions: CategoryIdAssembliesIdMap | undefined
  canAccessAll: boolean
} & BMButtonProps ) => {
  const { selectedOptions, pricingSnapshotId, canAccessAll } = props;

  const configurator = useContext(ConfiguratorContext);
  const { quote } = useQuoteContext();
  const quoteId = quote?.quoteId;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [invertFilter, setInvertFilter] = useState<boolean>(false);
  const [filter, _setFilter] = useState<string | undefined>();
  const [isFiltering, setFiltering] = useState<boolean>(false);
  const [selectionInfo, selectionInfoAsync] = useAsyncState<AssemblyInfo[]>();

  const setFilter = useCallback(
    debounce( (s:string | undefined) => {
      _setFilter( s )
      setFiltering( false );
    }, 400 )
  , [] );

  const handleChangeFilter = (e:any) => {
    setFiltering( true );
    setFilter( e.target.value )
  }

  const handleInvertFilter = (_e:any) => {
    setInvertFilter( !invertFilter );
  }

  //show failure message
  useEffect(()=> {
    if ( selectionInfoAsync.isFail() )  {
      message.error("Assemblies failed to load. " + selectionInfoAsync.err );
    }
  }, [ selectionInfoAsync.state ] );

  //reset data on options change
  useEffect(() => {
      selectionInfoAsync.setInit();
  }, [ selectedOptions ]);

  //load data one showing modal and data is init/fail
  useEffect(() => {

      const refetch = selectionInfoAsync.isInitial() || selectionInfoAsync.isFail()
      if ( showModal && refetch ) {
          loadSelectedOptions()
      }
  }, [ showModal ]);

  const loadSelectedOptions = () => {
      if ( !selectedOptions ) return;

      selectionInfoAsync.setLoading()

      const selections = Object.values( selectedOptions ).flat() as number[];
      if ( selections.length === 0 ) return;

      if ( !pricingSnapshotId ) return;

      const quoteRevisionId = quote?.displayRevisionId;
      try {
        configurator.api.fetchSelectionInfo( {selections, pricingSnapshotId,  quoteRevisionId} )
        .then(resp => selectionInfoAsync.setDone(resp.data),
              reason => selectionInfoAsync.setFail(reason));
      }
      catch (e:any) {
        selectionInfoAsync.setFail(e.message);
      }
  }

  const handleSaveBtn = () => {
    setShowModal(false);
    setFilter(undefined);
  }
  const handleCancelBtn = () => {
    setShowModal(false);
    setFilter(undefined);
  }

  const handleExportCsv = () => {
    if ( data.length === 0 ) return;

    const csvFileNameComponents = [ "BOMDetails" ];
    if ( quoteId ) csvFileNameComponents.push( quoteId );
    if ( filter ) csvFileNameComponents.push( filter );

    const csvFileName = csvFileNameComponents.join( "_" );

    Utils.exportDataAsCSV(csvFileName, data, columns);
  }

  const sortDecimal = (a:number | undefined, b:number | undefined ) => {
      if ( a === undefined && b === undefined ) return 0;
      if ( a === undefined ) return -1;
      if ( b === undefined ) return 1;

      return a - b;
  }

  const getDisplayLabel = (asm:AssemblyInfo):string => {
      return asm.label || asm.bomDescription || "";
    }

  let columns: ExportableColumn<AssemblyInfo>[] = [
    {
      title: 'BOM',
      dataIndex: 'bom',
      sorter: (a, b) => (a.bom).localeCompare(b.bom),
      render: (bom) => <span style={{whiteSpace: "nowrap"}}>{bom}</span>,
      renderCSV: (rec) => rec.bom
    },
    {
      title: 'Label / Description',
      dataIndex: 'label',
      sorter: (a, b) => getDisplayLabel(a).localeCompare(getDisplayLabel(b)),
      render: (_val, rec, _ndx) => getDisplayLabel( rec ),
      renderCSV: (rec) => getDisplayLabel( rec ),
      width: "60%",
    },
  ];

    if (canAccessAll) {
        columns.push( {
            title: 'Material Cost',
            align: "right",
            dataIndex: 'standardMaterialCost',
            sorter: (a, b) => sortDecimal(a.standardMaterialCost, b.standardMaterialCost),
            render: (val, _rec, _ndx) => Utils.formatUsDollarCents(val),
            renderCSV: (rec) => formatCSVDecimal(rec.standardMaterialCost),
        });
    }

    if (configurator.isAdmin() || configurator.isFinance()) {
        columns.push( {
            title: 'Epicor Cost',
            align: "right",
            dataIndex: 'epicorStandardMaterialCost',
            sorter: (a, b) => sortDecimal( a.epicorStandardMaterialCost, b.epicorStandardMaterialCost ),
            render: (val, _rec, _ndx) => Utils.formatUsDollarCents( val ),
            renderCSV: (rec) => formatCSVDecimal( rec.epicorStandardMaterialCost ) ,
        });

        columns.push( {
            title: 'Diff $',
            align: "right",
            sorter: (a, b) => sortDecimal( getEpicorMoneyDiff(a), getEpicorMoneyDiff(b) ),
            render: (_val, rec, _ndx) => Utils.formatUsDollarCents( getEpicorMoneyDiff(rec) ),
            renderCSV: (rec) => formatCSVDecimal( getEpicorMoneyDiff(rec) ) ,
        });

        columns.push( {
            title: 'Diff %',
            align: "right",
            sorter: (a, b) => sortDecimal( getEpicorPercentDiff(a), getEpicorPercentDiff(b) ),
            render: (_val, rec, _ndx) => Utils.formatPercent( getEpicorPercentDiff(rec) ),
            renderCSV: (rec) => Utils.formatPercent( getEpicorPercentDiff(rec) ) ,
        });
    }

    if (canAccessAll) {
        columns.push( {
            title: 'Labor Hours',
            align: "right",
            dataIndex: 'laborHours',
            sorter: (a, b) => sortDecimal(a.laborHours, b.laborHours),
            render: (val, _rec, _ndx) => formatCSVDecimal(val),
            renderCSV: (rec) => formatCSVDecimal(rec.laborHours),
        });

        columns.push( {
            title: 'Total Labor Cost',
            align: "right",
            dataIndex: 'laborCost',
            sorter: (a, b) => sortDecimal(a.laborCost, b.laborCost),
            render: (val, _rec, _ndx) => Utils.formatUsDollarCents(val),
            renderCSV: (rec) => formatCSVDecimal(rec.laborCost),
        });
    }

  //prepare data for display
  const NOT_FOUND = -1;
  const data = selectionInfo
    ?.filter( d => {
      if ( !filter ) return true;

      const line = columns.map( c => {
        const val = d[ c.dataIndex as string ];
        if ( c.render ) return c.render(val, d, 0);
        return val;
      }).join(" ");
      const ndx = line.toUpperCase().indexOf( filter.toUpperCase() )
      return invertFilter ? ( ndx === NOT_FOUND ) : ( ndx !== NOT_FOUND );
    })
    || [];

  //remove other props for button props
  const { pricingSnapshotId:b, selectedOptions:c, canAccessAll:d,  ...btnProps } = props;

  return (
    <>
      <Button 
        {...btnProps}
        onClick={() => { setShowModal(true) }}
      >BOM Details</Button>

      <Modal title="BOM Details"
        open={showModal} 
        onCancel={handleCancelBtn}
        onOk={handleSaveBtn} 
        style={{maxWidth: "80rem"}}
        width="70%"
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

          <Row gutter={16}>
            <Col flex={1}>
              <Input onChange={handleChangeFilter} placeholder="Filter BOM" allowClear />
            </Col>
            <Col flex={1}>
              <Checkbox style={{marginTop: "4px" }} onChange={handleInvertFilter} checked={invertFilter}>Invert Filter</Checkbox>
            </Col>

            <Col flex={1} style={{textAlign: "right"}} >
              {canAccessAll && <Tooltip title="Download CSV">
                <Button icon={<DownloadOutlined />} shape="circle" onClick={handleExportCsv} />
              </Tooltip>}
            </Col>
          </Row>

          <Table
            key="bom-details"
            bordered
            loading={selectionInfoAsync.isLoading() || isFiltering }
            scroll={{ x: "1500" }}
            columns={columns}
            dataSource={data}
            rowKey="id"
          />
        </Space>
      </Modal>
    </>

  );
}

export default BomDetails

