import Title from "antd/lib/typography/Title";
import { Table, Space, notification,  Input, Button, Form, Result, Descriptions } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import BMButton, { BMButtonProps } from "../components/BMButton";
import ModalWizard from "../components/ModalWizard";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../components/Wizard";
import { NotificationEvent } from "../api/models";
import useNotificationEvents from "../swr/useNotificationEvents";
import {NotificationEventForm, NotificationEventFormValues} from "../components/Quote/NotificationEventForm";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface NotificationEventFilter {
  search?: string
}

const DEFAULT_PAGE_SIZE = 20;
const NotificationEventsPage = () => {
  const isMobile = useCheckMobileScreen();

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [filter, setFilter] = useState<NotificationEventFilter>({
    search: searchFilterParam || undefined, //silly fix for null
  });

  const defaultSort = {
    columnKey: "name",
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<SortResult<NotificationEvent>>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const notificationEventLst = useNotificationEvents({
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SortResult<NotificationEvent>) => {

    if ( sorter != sort ) {
      const firstSort = [ sort ].flat()[0];
      setSortFieldQueryParam( firstSort.columnKey?.toString() );
      setSortDirectionQueryParam( firstSort.order );
      setSort(sorter);
      setCurrentPageParam(1);
      setPagination({
        current: 1,
        ...pagination
      });
    }
    else {
      setPagination(pagination);
    }
  };

  const handleChangeSearch = (search:string) => {
    setSearchFilterParam(search);
    setFilter({
      search
    });
  }

  const handleEditNotificationEvent = () => {
    notificationEventLst.mutate();
  }

  const handleAddNotificationEvent = () => {
    notificationEventLst.mutate();
  }

  const columns:ColumnType<NotificationEvent>[] = [
    {
      title: "Name",
      render: (ne:NotificationEvent) => <EditNotificationEventButtonModal type="text" className="ghostBmButton"
        value={ne}
        onChange={handleEditNotificationEvent}
      >
        <span style={{textDecoration: "underline"}}>{ne.name || ne.id || ne.recipients.join(", ")}</span>
      </EditNotificationEventButtonModal>
        
    },
    {
      title: "Template Id",
      render: (ne:NotificationEvent) => ne.templateId
    },
    {
      title: "Recipients",
      render: (ne:NotificationEvent) => ne.recipients.join(", ")
    },
    {
      title: "Disabled",
      render: (ne:NotificationEvent) => ne.disabled ? "disabled" : ""
    },
  ];

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>

        <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
          <Title level={2}>Notification Events</Title>
        </div>

        <Table
          dataSource={notificationEventLst.data?.content}
          loading={notificationEventLst.isLoading}
          pagination={{...pagination, total: notificationEventLst.data?.totalElements}}
          onChange={tableOnChange}
          bordered
          columns={columns}
          rowKey="id"
        />

      </Space>
    </div>
  );
};

const EditNotificationEventButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?: NotificationEvent | undefined
  onChange?:(d:NotificationEvent | undefined) => void
  onCancel?:() => void
  open?:boolean
}) => {

  const {value:a, onChange:b, open:c, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(props.open || false);
  const [selectedNotificationEvent, selectedNotificationEventAsync] = useAsyncState<NotificationEvent>();

  const [_activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleChangeNotificationEvent = (notificationEvent:NotificationEvent | undefined) => {
    setIsOpen(false);
    props.onChange?.(notificationEvent);
  }

  const handleCancel = () => {
    setIsOpen(false);
    props.onCancel?.();
  }

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    />
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      afterOpenChange={(open) => {
        if(open) {
          selectedNotificationEventAsync.setDone(props.value);
        }}}
      steps={[
        useEditNotificationEventStep({
          key:1,
          value: selectedNotificationEvent,
          onChange: handleChangeNotificationEvent,
          onCancel: handleCancel
        }),
      ] }
    />
  </>;
}

const useEditNotificationEventStep = (props: {
  key: React.Key
  hidden?: boolean
  value: NotificationEvent | undefined
  onChange?:(t:NotificationEvent | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [form] = Form.useForm();

  const [_selectedNotificationEvent, selectedNotificationEventAsync] = useAsyncState<NotificationEvent>();
  const [activeKey, setActiveKey] = useState<React.Key>();
  const [initialValues, setInitialValues] = useState<NotificationEventFormValues>();

  useEffect(() => {
    setInitialValues(props.value);
  }, [props.value] );


  useEffect(() => {
    form.resetFields();
  }, [initialValues] );

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleCancel = () => {
    props.onCancel?.();
  }

  const handleSave = async () => {

    try {
      const values = await form.validateFields() as NotificationEventFormValues;

      const saved =  !props.value?.id
          ? await createNotificationEvent(values)
          : await editNotificationEvent(values) ;

      if ( saved ) {
        props.onChange?.(saved);
      }
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleConfirmDelete = async () => {
    if ( !props.value?.id ) return;

    const rslt = await deleteNotificationEvent(props.value.id);

    if ( rslt ) {
      props.onChange?.(props.value);
    }
  }

  const editNotificationEvent = async (notificationEvent:NotificationEventFormValues) : Promise<NotificationEvent | undefined> => {

    selectedNotificationEventAsync.setLoading()
    try {
      const resp = await configurator.api.updateNotificationEvent(notificationEvent.id, notificationEvent)
      selectedNotificationEventAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit notification event. " + errorMsg;
      notification.error( { message: msg });
      selectedNotificationEventAsync.setFail(msg);
    }

    return;
  }

  const deleteNotificationEvent = async (id:string) : Promise<boolean> => {

    try {
      await configurator.api.deleteNotificationEvent(id)
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete notification event. " + errorMsg;
      notification.error( { message: msg });
    }

    return false;
  }

  const createNotificationEvent = async (notificationEvent:NotificationEventFormValues) : Promise<NotificationEvent | undefined> => {
    if ( !notificationEvent ) return;

    selectedNotificationEventAsync.setLoading();
    try {
      const resp = await configurator.api.createNotificationEvent(notificationEvent)
      selectedNotificationEventAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create notification event. " + errorMsg;
      notification.error( { message: msg });
      selectedNotificationEventAsync.setFail(msg);
    }

    return;
  }



  const steps:WizardStep[] = [
        {
          key:1,
          title: props.value ? "Edit " + (props.value.name || "" ) + " Notification Event" : "Add Notification Event",
          body: () => <div key="formStep">
            <NotificationEventForm form={form} initialValues={initialValues} />
          </div>,
          footer:(nav) => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
            {props.value &&
              <Button danger key="delete" onClick={() => nav.nextStep()}>Delete</Button>
            }
            <div style={{display: "lex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Space>
                <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                <Button key="save" type="primary" onClick={() => handleSave()}>Save</Button>
              </Space>
            </div>
          </div>
        },
        {
          key:2,
          body: () =>  <div key="confirmStep" >
            <ConfirmDeleteNotificationEventResult value={props.value} />
          </div>,
          footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button type="primary" danger onClick={() => handleConfirmDelete()}>Delete</Button>
            <Button onClick={() => nav.prevStep()}>Back</Button>
          </div>
        }
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: () => 
      <Wizard 
        key="editNotificationEvent"
        steps={steps} 
        showSteps={false}
        onStep={handleStep}
      />,
    title: () => title,
    footer: () => footer,
  }

}

const ConfirmDeleteNotificationEventResult = (props:{
  value:NotificationEvent | undefined
}) => {
  return  <Result
    status="warning"
    title="The following notification event will be deleted:"
    style={{padding: "0"}}
  >
    <Descriptions
      column={1}
      className="dialog-notificationEvent"
      items={ [
          {
        label: "Name",
        children: props.value?.name
      },
        {
          label: "Template Id",
          children: props.value?.templateId
        },
      {
        label: "Recipients",
        children: props.value?.recipients?.join(", ")
      },
      ]}
      size="small"
    />
  </Result>

}




export default NotificationEventsPage;
