import UserMultiSelector, {UserMultiSelectorProps} from "./UserMultiSelector";
import useUsers from "../swr/useUsers";

const QuoteEngineerSelector = (props: Omit<UserMultiSelectorProps, "value" | "onChange"> & {
  value?:string[]
  onChange?: (users: string[]) => void;
}) => {

  const userLst = useUsers().data;

  const internalUserLst = userLst?.filter( u => !u.dealerId );

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}

export default QuoteEngineerSelector;

