import {useCallback, useEffect, useState} from "react";
import Table, {ColumnType} from "antd/es/table";
import {debounce} from "lodash";
import Utils from "../util/util";
import {Input, Row} from "antd";
import {User} from "../api/models";
import CopyContact from "./CopyContact";
import useUsers from "../swr/useUsers";

const DEFAULT_PAGE_SIZE = 5;
const NOT_FOUND = -1;

const UserSelection = (props:{
  id?:string
  value?:string
  loading?:boolean
  options?:User[]
  onChange?:(u:User | undefined)=>void
}) => {

  const users = useUsers();
  const userLst = props.options || users.data;

  const [filter, setFilter] = useState<string | undefined>();
  const [pagination, setPagination] = useState<any>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });

  useEffect(() => {
    const ndx = datasource.map( c => c.id ).findIndex( id => id === props.value)
    const page = ( ndx === NOT_FOUND ) ? 1 : Math.floor( ( ndx / DEFAULT_PAGE_SIZE) + 1 );

    setPagination( { ...pagination, defaultCurrent: page, current:page } );
  }, [props.options, filter]);

  const columns:ColumnType<User>[] = [
    {
      dataIndex: "name",
      title: "Name",
      render: (_n,u) => Utils.formatUsername(u),
      defaultSortOrder: "ascend",
    },
    {
      title: 'Action',
      key: 'action',
      render: (u: User, _) => {
        return <div onClick={e => e.stopPropagation()}>
        <Row style={{marginBottom: "-1rem"}}>
          <CopyContact emailToCopy={u.email} phoneToCopy={u.phoneNumber}/>
        </Row></div>
      },
    }
];

  const handleChangeFilter = useCallback(
    debounce( (e:any) => {
      setFilter( e.target.value )
    }, 400 )
  , [] );

  const datasource = [...userLst || []]?.filter( m => !filter || m.name?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase() ) )
  .sort( (a,b) => a.name.localeCompare(b.name) );

  const handleSelectRow = (record:User) => {
    props.onChange?.(record);
  }


  return <>
    <style>
      {`
        /* don't show error border on filter */
          .userFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
        .userFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
          border-color: #d9d9d9;
        }
        .userLst .ant-table-content { padding: 5px; } /* don't clip corners */
          .userLst .ant-table-cell { border: none !important; } /* remove table cell borders */
          /* add error border to table */
          .ant-form-item-has-error .userLst .ant-table-content {
          border: solid 1px #ff4d4f;
          border-radius: 15px;
        }
        `}
    </style>

    <Input className="userFilter" onChange={handleChangeFilter} placeholder="Filter Owners" allowClear />

    <Table 
      className="userLst"
      showHeader={false}
      columns={columns}
      rowKey="id"
      dataSource={datasource}
      pagination={pagination}
      onChange={(p) => setPagination(p)}
      loading={props.loading ?? users?.isLoading}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: props.value ? [props.value] : [],
      }}
    />

  </>
};

export default UserSelection;

