import {Button, Form, Radio, Space, Table, notification} from "antd";
import {useContext, useEffect, useState} from "react";
import {ConfiguratorContext } from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import {QuoteShare} from "../../api/models";
import UserSelectionMulitple from "../UserSelectionMultiple";
import ModalWizard from "../ModalWizard";
import {useIntl} from "react-intl";
import Utils from "../../util/util";
import {NewQuoteShare} from "../../api";
import {useWatch} from "antd/es/form/Form";
import {useAsyncState} from "../../hook/useAsyncState";
import { WizardInstance } from "../Wizard";
import { useQuoteContext } from "../../contexts/QuoteContext";
import useUsers from "../../swr/useUsers";

interface QuoteShareForm {
  users: string[]
  writePermission: boolean
}
const ShareQuoteButtonModal = (props: Omit<BMButtonProps, "value" | "onChange"> & {
  value?: QuoteShare[]
  onChange?: (v: QuoteShare[] | undefined) => void
}) => {

  //remove other props for button props
  const { id, value, onChange, ...btnProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const { quote } = useQuoteContext();
  const quoteId = quote?.id;

  const userLst = useUsers();

  const [ selectedShareLst, setSelectedShareLst ] = useState<QuoteShare[]>();

  const [quoteShareLst, quoteShareAsync] = useAsyncState<QuoteShare[]>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const newShareUserIdLst = useWatch<string[] | undefined>( 'users', form );
  const newShareUserLst = userLst.data?.filter(u => newShareUserIdLst?.includes(u.id) )

  const newSharePermission = useWatch<boolean | undefined>( 'writePermission', form );

  useEffect(()=> {
    quoteShareAsync.setDone(quote?.quoteShares);
  }, [quote] );

  const loadQuoteShares = async (): Promise<QuoteShare[] | undefined> => {
    if (!quoteId) return;

    quoteShareAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteShares(quoteId)
      quoteShareAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load quote shares. " + errorMsg;
      notification.error({ message: msg });
      quoteShareAsync.setFail(msg);
    }

    return;
  }



  const createShare = async (share:NewQuoteShare) : Promise<QuoteShare | undefined> => {
    if ( !quoteId ) return;

    try {
      var resp = await configurator.api.createQuoteShare( quoteId, share );
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to add share. " + errorMsg });
    }

    return;
  }

  const deleteShare = async (share:QuoteShare) : Promise<QuoteShare | undefined> => {
    if ( !quoteId ) return;

    try {
      await configurator.api.deleteQuoteShare(quoteId, share.id );
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to delete share. " + errorMsg });
    };

    return;
  }

  const handleAddShare = async (nav:WizardInstance) => {
    if (!quoteId) return;

    try {
      const values = await form.validateFields() as QuoteShareForm;
      const addLst = (await Promise.all(values.users.map(userId => ({userId, writePermission: values.writePermission})).map(createShare))).filter(v=>v) as QuoteShare[];
      if ( !!addLst.length ) {
        const shareLst = await loadQuoteShares();
        onChange?.(shareLst)
        nav.nextStep();
      }
    }
    catch(validationErrors) {
      notification.error( { message: "Please fix validation errors. " });
    }
  }


  const handleNew = (nav:WizardInstance) => {
    form.resetFields();
    nav.nextStep();
  }

  const handleDelete = async () => {
    if ( !selectedShareLst?.length )  return;

    await Promise.all( selectedShareLst.map(deleteShare));
    setSelectedShareLst([]);

    const shareLst = await loadQuoteShares();
    onChange?.(shareLst)
  }

  const handleCancel = () => {
      setIsOpen(false);
  }

  const handleSelectRow = (record:QuoteShare) => {

    //toggle previously selected
    const updated = selectedShareLst?.filter( ae => ae.id !== record.id );
    if ( updated?.length === selectedShareLst?.length  ) {
      setSelectedShareLst( (updated || []).concat(record) );
      return;
    }

    setSelectedShareLst(updated);
  }

  return <>
    <BMButton
      {...btnProps}
      onClick={() => setIsOpen(true)}
      children={props.children || "Share Quote"}
    />
    <Form form={form}
      initialValues={{writePermission:false}}
    >
      <ModalWizard
        showSteps={false}
        style={{minWidth: "40rem"}}
        viewHeight="55vh"
        open={isOpen}
        onCancel={handleCancel}
        afterOpenChange={(open) => {
          if (open) {
            form.resetFields();
          }
        }}
        steps={[
          {
            key:1,
            hidden: !quoteShareLst?.length,
            title: "Shares",
            body: (nav) => <div key="listStep">
                <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
                  <Button type="primary" onClick={() => handleNew(nav)} >New</Button>
                  <Button onClick={handleDelete} disabled={!selectedShareLst}>Delete</Button>
                </div>
                <style>
                  {`
                    .dialog-quoteShareLst .ant-table-content { padding: 5px; } /* don't clip corners */
                      .dialog-quoteShareLst .ant-table-cell { border: none !important; } /* remove table cell borders */
                      /* add error border to table */
                      .ant-form-item-has-error .dialog-quoteShareLst .ant-table-content {
                      border: solid 1px #ff4d4f;
                      border-radius: 15px;
                    }
                    `}
                </style>
                <Table 
                  className="dialog-quoteShareLst"
                  showHeader={false}
                  columns={
                    [ {
                    render: (s:QuoteShare) => Utils.formatUsername(s.user)
                  },
                  {
                    title: "Permission",
                    render: (s:QuoteShare) => s.writePermission ? "EDITABLE" : "READ-ONLY"
                  },
                  ]}
                  rowKey="id"
                  dataSource={quoteShareLst}
                  onRow={(record, _rowIndex) => {
                    return {
                      onClick: () => handleSelectRow(record)
                    };
                  }}
                  rowSelection={{
                    type: "checkbox",
                    onSelect: handleSelectRow,
                    selectedRowKeys: (selectedShareLst?.map( ae => ae.id ) || [] ) as number[],
                  }}
                />
            </div>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
            </div>,
          },
          {
            key:2,
            title: "Select User",
            body: (nav) => <div key="selectUserStep">
              <Form.Item name="users">
                <UserSelectionMulitple
                  options={userLst.data || []}
                  loading={userLst.isLoading}
                />
              </Form.Item>
            </div>,
            footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={nav.nextStep} disabled={!newShareUserLst}>Next</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          },
          {
            key:3,
            title: "Allow Edit?",
            body: (nav) => <div key="selectPermissionStep">
              <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
                <Form.Item
                  name="writePermission"
                >
                  <Radio.Group >
                    <div style={{display:"flex", flexDirection: "column", gap: "1rem" }}>
                      <div><Radio value={false} onClick={nav.nextStep}>Read-only view.</Radio> </div>
                      <div><Radio value={true} onClick={nav.nextStep} >Allow quote edits.</Radio></div>
                    </div>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>,
            footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={nav.nextStep}>Next</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          },
          {
            key:4,
            title: "Confirm New Shares",
            body: (_nav) => <div key="confirmStep">
              <div style={{display: "flex", justifyContent: "center", paddingTop: "2rem" }}>
                <Space direction="vertical">
                  <div style={{fontWeight: 600}}>Quote {quote?.quoteId}</div>
                  <div style={{paddingTop: "1rem"}}><span style={{fontWeight: 600}}>Sharing:</span>
                    <ul>
                      {newShareUserLst?.map( u => <li key={u.id}>{Utils.formatUsername(u)}</li>)}
                    </ul>
                  </div>
                  <div><span style={{fontWeight: 600}}>Permission:</span> { newSharePermission ? "EDIT" : "READ-ONLY"}</div>
                </Space>
              </div>
            </div>,
            footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={() => handleAddShare(nav)}>Confirm</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          },

        ]}      
      />
    </Form>
  </>

}

export default ShareQuoteButtonModal
