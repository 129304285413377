import { Checkbox, Modal, notification } from "antd";
import {useContext, useState} from "react";
import {ConfiguratorContext } from "../../context";
import {useIntl} from "react-intl";
import { useQuoteContext } from "../../contexts/QuoteContext";
import useQuote from "../../swr/useQuote";

const ArchiveQuoteSwitch = () => {

  const intl = useIntl();
  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });
  const configurator = useContext(ConfiguratorContext);

  const [showArchivedModal, setShowArchivedModal] = useState<boolean>(false);

  const onArchivedClick = () => {
      setShowArchivedModal(true);
  }
  const onArchivedCancel = () => {
    if (quoteAsync?.isLoading) {
      setShowArchivedModal(false);
    }
  }

  const onArchivedConfirm = async () => {
    if (!quote) {
      console.warn("Unexpected -  quote was undefined.");
      return;
    }

    try {
      const resp = await configurator.api.updateQuoteArchived(quote.id, !quote.archived);
      const archivedQuote = {...quote, archived: resp.data};
      await quoteAsync.mutate(archivedQuote);

      setShowArchivedModal(false);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to archive quote. " + errorMsg });
    }
  }

  return <>
    <div style={{marginLeft: "1rem"}}>
      <label>
        <Checkbox
          onChange={onArchivedClick}
          checked={quote?.archived}
        />
        <span style={{marginLeft: "0.5rem"}}>
          Archived
        </span>
      </label>
    </div> 
    <Modal title="Archive Action"
      okText="Confirm"
      open={showArchivedModal}
      onOk={() => onArchivedConfirm()}
      onCancel={() => onArchivedCancel()}
      confirmLoading={quoteAsync?.isLoading}
      closable={!quoteAsync?.isLoading}>
      <p>{quote?.archived 
        ? "Please confirm if you want to unarchive this quote."
        : "Please confirm if you want to archive this quote."}
      </p>
    </Modal>
  </>
}

export default ArchiveQuoteSwitch;

