import {Button, Carousel, Checkbox, Col, Descriptions, Input, Modal, notification, Row, Spin} from "antd";
import Table, {ColumnType} from "antd/es/table";
import dayjs from "dayjs";
import {debounce} from "lodash";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {PricingConfig, PricingSnapshot} from "../api/models";
import {ConfiguratorContext, PricingSnapshotListContext, PricingSnapshotsContext } from "../context";
import {useAsyncState} from "../hook/useAsyncState";
import Utils from "../util/util";
import useUsers from "../swr/useUsers";

const DATE_DISPLAY_FORMAT = "dddd, MMMM Do YYYY, H:mm:ss";
const DEFAULT_PAGE_SIZE = 7;
const NOT_FOUND = -1;

const PricingConfigurationDetail = (props:{
  pricingConfig:PricingConfig|undefined
}) => {

  const { pricingConfig } = props;

  const userLst = useUsers().data;

  const pricingConfigCreatedBy = userLst?.find( u => u.id === pricingConfig?.createdBy )?.name || pricingConfig?.createdBy;


  return <Descriptions 
    labelStyle={{fontWeight: "bold", color: "black", textAlign: "right", width: "20rem", display: "inline-block"}}
    items={[
      { key: '1'      , span: 6 , label: 'Labor Hourly Cost (Dollars)'                , children: Utils.formatMoney( pricingConfig?.laborHourlyCost )         , } , 
      { key: '2'      , span: 6 , label: 'PDI'                                        , children: Utils.formatMoney( pricingConfig?.pdi)                      , } , 
      { key: '3'      , span: 6 , label: 'Warranty (fixed cost)'                      , children: Utils.formatMoney( pricingConfig?.warranty )                , } , 
      { key: '4'      , span: 6 , label: 'Warranty (Percentage of Material Cost)'     , children: Utils.formatPercent( pricingConfig?.warrantyPercentage)     , } , 
      { key: '5'      , span: 6 , label: 'BEV Warranty (Percentage of Material Cost)' , children: Utils.formatPercent( pricingConfig?.bevWarrantyPercentage)  , } , 
      { key: '6'      , span: 6 , label: 'Extended Warranty Markup'                   , children: Utils.formatPercent( pricingConfig?.extendedWarrantyMarkup) , } , 
      { key: '7'      , span: 6 , label: 'Tarriffs and Surcharges'                    , children: Utils.formatMoney( pricingConfig?.tariffsSurcharges)        , } , 
      { key: '8'      , span: 6 , label: 'Margin'                                     , children: Utils.formatPercent( pricingConfig?.margin)                 , } , 
      { key: '9'      , span: 6 , label: 'Dealer Markup'                              , children: Utils.formatPercent( pricingConfig?.dealerMarkup)           , } , 
      { key: '10'     , span: 6 , label: 'Inbound Freight'                            , children: Utils.formatPercent( pricingConfig?.inboundFreight)         , } , 
      { key: '11'     , span: 6 , label: 'Created At'                                 , children: dayjs(pricingConfig?.createdAt).format(DATE_DISPLAY_FORMAT) , } , 
      { key: '12'     , span: 6 , label: 'Created By'                                 , children: pricingConfigCreatedBy                                      , } , 
    ] } />
};

const PricingHistoryTable = (props:{
  id?:string
  value?:number
  onChange?:(c:PricingSnapshot | undefined)=>void
}) => {

  const userLst = useUsers().data;

  const { pricingSnapshotListAsync, loadPricingSnapshotList } = useContext<PricingSnapshotListContext>(PricingSnapshotsContext);
  const pricingSnapshotList = pricingSnapshotListAsync.val;

  const [invertFilter, setInvertFilter] = useState<boolean>(false);
  const [filter, _setFilter] = useState<string | undefined>();
  const [pagination, setPagination] = useState<any>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });

  useEffect(() => {
    const ndx = datasource?.map( c => c.id ).findIndex( id => id === props.value) || -1;
    const page = ( ndx === NOT_FOUND ) ? 1 : Math.floor( ( ndx / DEFAULT_PAGE_SIZE) + 1 );

    setPagination( { ...pagination, defaultCurrent: page, current:page } );
  }, [pricingSnapshotList, filter]);

  useEffect(()=> {
    if ( ( pricingSnapshotListAsync?.isInitial() || pricingSnapshotListAsync?.isFail() ) && !pricingSnapshotListAsync.isLoading() )  {
      loadPricingSnapshotList?.();
    }
  }, [ pricingSnapshotListAsync?.state ] );

  const setFilter = useCallback( debounce( (s:string | undefined) => { 
    _setFilter( s )
  }, 400 ) , [] );

  const handleChangeFilter = (e:any) => {
    setFilter( e.target.value )
  }

  const handleInvertFilter = (_e:any) => {
    setInvertFilter( !invertFilter );
  }

  const handleSelectRow = (record:PricingSnapshot) => {

    //toggle previously selected
    if ( props.value === record.id ) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(record);
  }


  //prepare data for display
  const datasource = pricingSnapshotList
    ?.filter( d => {
      if ( !filter ) return true;
      const ndx = d.name.toUpperCase().indexOf( filter.toUpperCase() )
      return invertFilter ? ( ndx === NOT_FOUND ) : ( ndx !== NOT_FOUND );
    })
    .sort( (a,b) => dayjs( a.createdAt ).isBefore( b.createdAt ) ? 1 : -1 )
    || [];


    const columns:ColumnType<PricingSnapshot>[] = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render:(txt)=>dayjs(txt).format(DATE_DISPLAY_FORMAT)
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        render:(txt)=>userLst?.find( u => u.id === txt )?.name || txt
      },
    ];

    return <>
      <style>
        {`
          /* don't show error border on filter */
            .pricingSnapshotFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
          .pricingSnapshotFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
            border-color: #d9d9d9;
          }
          .pricingSnapshotLst .ant-table-content { padding: 5px; } /* don't clip corners */
            .pricingSnapshotLst .ant-table-cell { border: none !important; } /* remove table cell borders */
            /* add error border to table */
            .ant-form-item-has-error .pricingSnapshotLst .ant-table-content {
            border: solid 1px #ff4d4f;
            border-radius: 15px;
          }
          `}
      </style>

      <Row gutter={16} style={{marginBottom: "1rem"}}>
        <Col flex={1}>
          <Input className="pricingSnapshotFilter"  onChange={handleChangeFilter} placeholder="Filter Snapshot" allowClear />
        </Col>
        <Col flex={1}>
          <Checkbox style={{marginTop: "4px" }} onChange={handleInvertFilter} checked={invertFilter}>Invert Filter</Checkbox>
        </Col>
      </Row>

      <Table
        className="pricingSnapshotLst"
        loading={pricingSnapshotListAsync.isLoading() }
        columns={columns}
        dataSource={datasource}
        rowKey="id"
        pagination={pagination}
        onChange={setPagination}
        onRow={(record, _rowIndex) => {
          return {
            onClick: () => handleSelectRow(record)
          };
        }}
        rowSelection={{
          type: "radio",
          onSelect: handleSelectRow,
          selectedRowKeys: props.value ? [props.value] : [],
        }}

      />
    </>
};


const PricingSnaphotSelectionModal = (props: {
  open:boolean
  id?:string
  value?:number
  onChange?:(c:PricingConfig)=>void
  onCancel:()=>void
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [selectedPricingSnapshotId, setSelectedPricingSnapshotId] = useState<number>();
  const [selectedPricingConfig, selectedPricingConfigAsync ] = useAsyncState<PricingConfig>();
  const carouselRef = useRef<any>(null);
  const [carouselNdx, setCarouselNdx] = useState<number>(0);

  useEffect(() => {
    setSelectedPricingSnapshotId( props.value );
  }, [props.open]);

  const loadPricingConfigBySnapshotId = async (pricingSnapshotId:number) : Promise<PricingConfig | undefined> => {
    
    try {
      selectedPricingConfigAsync.setLoading();
      const resp = await configurator.api.fetchPricingConfigBySnapshotId(pricingSnapshotId)
      selectedPricingConfigAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load pricing config details. " + errorMsg });
      selectedPricingConfigAsync.setFail(e.message);
    }
    return;
  }

  const handleOk = () => {
    if (!selectedPricingConfig) {
      notification.error({message:"A snapshot must be selected."});
      return;
    }

    props.onChange?.(selectedPricingConfig);
  }

  const handleSelection = async () => {

    if (!selectedPricingSnapshotId) {
      notification.error({message:"A snapshot must be selected."});
      return;
    }

    const pricingConfig = await loadPricingConfigBySnapshotId( selectedPricingSnapshotId );
    if ( pricingConfig) {
      nextSlide();
    }
  }

  const nextSlide = () => {
    carouselRef.current?.next();
    setCarouselNdx( carouselNdx + 1 );
  }
  const prevSlide = ()  =>{
    carouselRef.current?.prev();
    setCarouselNdx( carouselNdx- 1 );
  }
  const resetSlides = () => {
    const ndx = 0;
    setCarouselNdx(ndx);
    carouselRef.current?.goTo(ndx);
  }

  const getSlide = () => {
    const slide = new Array();

    slide.push(
      {title: "Select Pricing",
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={handleSelection} disabled={!selectedPricingSnapshotId}>Next</Button>
            <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
          </div>
      });
      
      slide.push({title: `Confirm Selection - ${selectedPricingConfig?.pricingSnapshot?.name}` ,
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="save" type="primary" onClick={handleOk} disabled={!selectedPricingConfig} >Save</Button>
          <Button key="back" onClick={prevSlide}>Back</Button>
        </div>
      });

    return slide[carouselNdx];
  };


  return <>
    <Modal 
      style={{minWidth: "50rem"}}
      open={props.open}
      onCancel={props.onCancel}
      title={getSlide().title}
      footer={getSlide().footer}
      afterOpenChange={() => {
        resetSlides();
      }}
    >

      <Carousel dots={false} ref={carouselRef}>
        <div>
          <PricingHistoryTable
            value={selectedPricingSnapshotId}
            onChange={(ps) => setSelectedPricingSnapshotId(ps?.id)} />
        </div>
        <div >
          <Spin spinning={selectedPricingConfigAsync.isLoading()}>
            <div style={{paddingTop: "1rem"}}>
              <PricingConfigurationDetail pricingConfig={selectedPricingConfig} />
            </div>
          </Spin>
        </div>
      </Carousel>

    </Modal>
  </>
}

export default PricingSnaphotSelectionModal


