import { useContext, useEffect, useState } from "react";
import { AddressDto, ApprovalRequest, ApproverRole, Customer, DefaultAddress, Quote } from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { Checkbox, Input, Modal, Radio, Row, Space, Tabs, notification } from "antd";
import BMButton, {BMButtonProps} from "../BMButton";
import Utils from "../../util/util";

const GenerateOrderConfirmation = (props: BMButtonProps & {
  quote: Quote | undefined,
  onExporting?: (e: boolean) => void;
}) => {

  const { quote, onExporting, ...btnProps } = props;
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState(false);
  const [customerWithAddress, setCustomerWithAddress] = useState<AddressDto[]>([])
  const [customerId, setCustomerId] = useState<number | undefined>(undefined);
  const [checkedStatus, setCheckedStatus] = useState({});
  const [filter, setFilter] = useState<string>("");

  const latestApproval = quote?.latestApproval;
  const isOrder = Utils.isOrder(quote) ;

  const approvers = new Set(latestApproval?.approvalStep.approvers);
  const isSalesDeskOrderReview = configurator.isSalesDesk() &&
      latestApproval?.approvalType === ApprovalRequest.ORDER && approvers.has(ApproverRole.SALES_DESK) && !latestApproval.action;
  const isEngineeringOrderReview = configurator.isEngineering() && latestApproval?.approvalType === ApprovalRequest.ORDER && approvers.has(ApproverRole.ENGINEERING) && !latestApproval.action;
  const canGenerateOrderConfirmation = quote && ( isSalesDeskOrderReview || isEngineeringOrderReview || isOrder );

  useEffect(() => {
    onExporting?.(isExporting);
  }, [isExporting]);

  useEffect(() => {
    if (quote && open) {
      getAddressOption();
    }
  }, [open]);

  const getAddressOption = async () => {
    try {
      const resp = await configurator.api.getDealerAddressOption(quote?.id || 0);
      setCustomerWithAddress(resp.data);
      setCustomerId(resp.data?.find(a => a.defaultAddress)?.address?.id || undefined);
    }
    catch (e) {
      console.log(e);
    }
  }

  const assignedDefaultAddress = customerWithAddress.filter(addr => addr.defaultAddress).length !== 0;
  const DefaultAddress = assignedDefaultAddress ? customerWithAddress.filter(addr => addr.defaultAddress) : customerWithAddress;
  const OptionalAddress = assignedDefaultAddress ? customerWithAddress.filter(addr => !addr.defaultAddress) : [];

  const generateOrderConfirmationLetter = async () => {
    setOpen(false);

    if ( !quote || !customerId ) return;

    setIsExporting( true );
    try {
      const url = configurator.api.generateConfirmationLetterUrl(quote.quoteId, customerId);
      await configurator.api.downloadPdf(url);
    }
    catch (e) {
      notification["error"]({
        message: "Failed to generate order confirmation letter.",
      });
    }
    setIsExporting( false );
  };

  const changeDefaultOrOptional = (customer: Customer, defaultAddress: boolean) => {

    // See if people want to change multiple row
    setCheckedStatus((prevStatus) => ({
      // ...prevStatus,
      // [customer.id]: !prevStatus[customer.id],
    }));


    let updatedDefaultAddress = DefaultAddress.filter(addr => addr.address.id !== customer.id).map(addr => {return {
      customerId: addr.address.id,
      defaultAddress: assignedDefaultAddress ? true : false, 
    };}); 

    updatedDefaultAddress.push({
      customerId: customer.id,
      defaultAddress,
    });

    setDefaultAddress(updatedDefaultAddress);
  }

  const setDefaultAddress = async (address: DefaultAddress[]) => {
    if (quote?.owner?.dealerId) {
      try {
        await configurator.api.setDefaultAddress(quote?.owner?.dealerId, address);
        getAddressOption();
      }
      catch (e) {
        notification["error"]({
          message: "Failed to set address.",
        });
      }
    }
  }

  const getAddress = (customer: Customer) => {
    const address = Utils.getAddress(customer);
    const contactName = customer.contactName ? customer.contactName : "Name NA";
    const contactEmail = customer.contactEmail ? customer.contactEmail : "Email NA";
    return  `${address} (${contactName},  ${contactEmail})`;
  }

  const addressIncludingFilter = (addr: AddressDto) =>{
    return getAddress(addr.address).includes(filter);
  }

  const items = [
    {
      key: "1",
      label: "Default",
      children: 
      <div style={{ display: 'flex', justifyContent: 'center' }} key="Default-tab">
        {!!DefaultAddress?.length ?
          <Radio.Group onChange={(e) => {setCustomerId(e.target.value)}} value={customerId} key={"default-radio-group"}>
            <Space direction="vertical" size={"large"} style={{marginTop: ".5rem"}}>
              {DefaultAddress?.filter(a => !filter || addressIncludingFilter(a)).map(
                addr => 
                <Row justify={"space-between"} key={'default-row-' + addr.address.id}>
                  <Radio key={'default-radio-button-' + addr.address.id} value={addr.address.id}>{getAddress(addr.address)}</Radio>
                  <Checkbox
                    key={'default-checkbox-' + addr.address.id}
                    onChange={() => {changeDefaultOrOptional(addr.address, (assignedDefaultAddress ? false : true))}}
                    checked={checkedStatus[addr.address.id]}
                  >
                    {assignedDefaultAddress ? 'Optional?' : 'Default?'}
                  </Checkbox>
                </Row>
              )}
            </Space>
          </Radio.Group>
          :
          <span key="span-1">There's no address found to this quote. Please make sure dealer is assigned.</span>
        }
      </div>
    }
  ];

  if (!!OptionalAddress.length) {
    items.push(
      {
        key: "2",
        label: "Optional",
        children: 
          <div style={{ display: 'flex', justifyContent: 'center' }} key="Optional-tab">
            {!!OptionalAddress?.length &&
              <Radio.Group onChange={(e) => {setCustomerId(e.target.value)}} value={customerId} key={"optional-radio-group"}>
                <Space direction="vertical" size={"large"} style={{marginTop: ".5rem"}}>
                  {OptionalAddress?.filter(a => !filter || addressIncludingFilter(a)).map(
                    addr => 
                    <Row justify={"space-between"} key={'optional-row-' + addr.address.id}>
                      <Radio key={'optional-radio-button-' + addr.address.id} value={addr.address.id}>{getAddress(addr.address)}</Radio>
                      <Checkbox
                        key={'optional-checkbox-' + addr.address.id}
                        onChange={() => {changeDefaultOrOptional(addr.address, true)}}
                        checked={checkedStatus[addr.address.id]}
                      >
                        {'Default?'}
                      </Checkbox>
                    </Row>
                  )}
                </Space>
              </Radio.Group>
            }
          </div>
      }
    );
  }

  return (
  <>
    <BMButton {...btnProps}
      disabled={!canGenerateOrderConfirmation} 
      onDisabledClick={() => {notification.warning({message: "Order confirmation letter can be exported after order submission."});}} 
      onClick={() => {setOpen(true)}} 
      loading={isExporting}
    >Generate Order Confirmation</BMButton>
    <Modal
      open={open}
      cancelButtonProps={{style: {display: "none"}}}
      onOk={() => generateOrderConfirmationLetter()}
      onCancel={() => setOpen(false)}
      width={"60rem"}
      title="Address:"
      centered
    >
      <Input addonBefore="Filter" onChange={(e) => setFilter(e.target.value)} placeholder="Search address..."/>
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  </>
  )
}

export default GenerateOrderConfirmation;

