import { Row, Modal, Transfer, notification, Form, Select, ButtonProps } from "antd";
import { useState, useContext, useCallback } from "react";
import { TruckRecord, BaseQuote, SortDirection } from "../../api/models"
import { ConfiguratorContext } from "../../context";
import type { TransferDirection } from 'antd/es/transfer';
import BMButton from "../BMButton";
import {useIntl} from "react-intl";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {debounce} from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";
import useQuote from "../../swr/useQuote";
import useQuoteList from "../../swr/useQuoteList";

const MoveTrucksButton = (props:{
} & ButtonProps) => {

  const {quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [transferTargetKeys, setTransferTargetKeys] = useState<string[]>([]);
  const [transferSelectedKeys, setTransferSelectedKeys] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [dstRevisionId, setDstRevisionId] = useState<number>();
  const [search, setSearch] = useState<string>();

  const truckLst:TruckRecord[] = quote?.trucks
    ?.map(truck => ({key: truck.truckSerialNumberStr, truckSerialNumberStr: truck.truckSerialNumberStr}))
    .sort((a, b) => (a.truckSerialNumberStr.localeCompare(b.truckSerialNumberStr))) || [] ;

  const quoteLst = useQuoteList({
    filter: {
      search,
      ordersOnly: true,
      includingArchived: false,
    },
    current: 0,
    pageSize: 5,
  });

  const onTransferItemsChange = (nextTargetKeys: any[], _direction: TransferDirection, _moveKeys: any[]) => {
    setTransferTargetKeys(nextTargetKeys);
  };

  const onTransferSelectChange = (sourceSelectedKeys: any[], targetSelectedKeys: any[]) => {
    setTransferSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleOkBtn = async () => {
    await moveTruck();
    await quoteAsync.mutate();
    setIsOpen(false) ;

    //reload source quote
  }
  
  const moveTruck = async () => {
    if ( !dstRevisionId ) return;
    if ( !quote?.quoteId ) return;

    try {
      const truckSnLst = transferTargetKeys.map( sn => Number(sn));
      await configurator.api.moveTrucks(truckSnLst, dstRevisionId);
      quoteAsync?.mutate(quote);
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to move trucks. " + errorMsg });
    }
  }

  return <>
    <BMButton {...props}
      onClick={() => {setIsOpen(true)}}
    >Move Trucks</BMButton>

    <Modal title="Move Trucks" 
      width={"40rem"}
      open={isOpen} 
      onOk={handleOkBtn}
      onCancel={() => setIsOpen(false)} 
      confirmLoading={quoteAsync?.isLoading}
    >
      <Row>          
        <Transfer
          style={{margin: "0 auto", marginBottom: "3rem"}}
          dataSource={truckLst}
          titles={['Stay', 'Move']}
          targetKeys={transferTargetKeys}
          selectedKeys={transferSelectedKeys}
          onChange={onTransferItemsChange}
          onSelectChange={onTransferSelectChange}
          render={item => item.truckSerialNumberStr}
          pagination={{pageSize: 5}}
          locale={{itemUnit: 'Truck', itemsUnit: 'Trucks'}}
        />
      </Row>

      <Form.Item label="Destination Quote" >
        <Select 
          value={dstRevisionId}
          onChange={setDstRevisionId}
          allowClear
          showSearch
          style={{width:"100%", minWidth:"10rem"}} 
          optionFilterProp="label"
          onSearch={setSearch}
          loading={quoteLst.isLoading}
          options={quoteLst.data?.content?.map(c => ({label: `${c.quoteId.split("-")[1]} - ${c.name}`, value: c.displayRevisionId}))}
        />
      </Form.Item>
    </Modal>
  </>
}

export default MoveTrucksButton;
