import { Button, ButtonProps, Form, Input, Modal, notification, Select } from "antd";
import { FOURTEEN_WEEK_DAY, Quote } from "../../api/models";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import dayjs from "dayjs";
import { useForm, useWatch } from "antd/es/form/Form";

const CancelOrderModule = ( 
  props:{
    quote: Quote | undefined
  } & ButtonProps
) => {

  const {quote} = props;
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState(false);
  const [needConcessionReview, setNeedConcessionReview] = useState(false);
  const withinFourteenWeeks = quote?.productionDate ? (dayjs(quote?.productionDate).diff(dayjs(), 'day')) <= FOURTEEN_WEEK_DAY : false;
  const [isSubmitted, setSubmitted] = useState(false);
  const [form] = useForm();
  const cancelledSerials = useWatch("cancelledSerials", form);
  const cancelledMessage = useWatch("cancelledMessage", form);

  useEffect(() => {
    if (open) {
      verifyNeedConcessionReview();
    }
  }, [open]);

  const verifyNeedConcessionReview = async () => {
    try {
      if (quote) {
        const resp = await configurator.api.verifyConcessionReview(quote.id);
        setNeedConcessionReview(resp.data);
      }
    }
    catch (e) {
      notification.error({ message: "Failed to verify concession." });
    }
  }

  const cancelOrder = async () => {
    form.validateFields();

    if (!!cancelledSerials?.length && !cancelledMessage) return;

    setSubmitted(true);
    try {
      if (quote) {
        const resp = await configurator.api.cancelOrder(quote.id, form.getFieldsValue());
        setOpen(false);
        if (resp.data.cancelled) {
          notification.success({message:"Order Cancelled Successfully."});
        }
        else {
          notification.success({message:"Cancel Order Request Submitted Successfully."});
        }
        setTimeout(() => window.location.reload(), 800);
      }
    }
    catch (e) {
      notification.error({message:"Failed to cancel quote/order."});
    }
  }

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  }

  const getStatement = () => {
    return (withinFourteenWeeks && needConcessionReview ? 
        <>
          <div>
            This order is within 14-week from production date and with higher concession, it will apply for approval.
          </div>
          <div>
            Cancel order will reject all pending approvals and archive the order, the order will NOT display in any report. continue?
          </div>
        </>
        :
        !!cancelledSerials?.length ? 
          <div>Partial cancellation will reject all pending approvals and archive the order, continue?</div>
          :
          <div>Cancel order will reject all pending approvals and archive the order, the order will NOT display in any report, continue?</div>);
  }

  return (<>
    <style>
      {`
        .custom-height-modal .ant-modal-body {
          height: 300px !important;
        }
      `}
    </style>
    <Button {...props} onClick={() => {setOpen(true)}}>Cancel Order</Button>
    <Modal
      open={open}
      title={!!cancelledSerials?.length ? "Partial Cancellation" : "Cancel Whole Order"}
      okText={!!cancelledSerials?.length ? "Partial Cancellation" : "Order Cancellation"}
      onOk={() => cancelOrder()}
      cancelButtonProps={{style: {display: "none"}}}
      okButtonProps={{disabled: isSubmitted}}
      onCancel={onCancel}
      width={600}
      rootClassName="custom-height-modal"
      centered
    >
      {getStatement()}

      <Form
        form={form}
        layout="vertical"
        style={{marginTop: "1rem"}}
      >
        <Form.Item
          label="Serial Numbers ( Select serials number to partially cancel order )"
          name="cancelledSerials"
        >
          <Select
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="label"
            value={cancelledSerials}
            options={quote?.trucks?.map(t => {
              return {
                label: t.truckSerialNumberStr,
                value: t.truckSerialNumberStr
              };
            }
            )}
            placeholder="Please select serial(s) if only partial order is cancelled."
            style={{width: "100%"}}
          />
        </Form.Item>
        <Form.Item
          label="Cancellation Message"
          name="cancelledMessage"
          hidden={!cancelledSerials?.length}
          rules={[
            {
              required: true,
              message: "Message is required on partial cancellation."
            }
          ]}
        >
          <Input onChange={(value) => {if (!!value) setSubmitted(false)}}/>
        </Form.Item>

      </Form>

    </Modal>
  </>);
}

export default CancelOrderModule;
