import { useCallback, useContext, useEffect, useState, } from "react";
import { ConfiguratorContext, PricingSnapshotListContext, PricingSnapshotsContext } from "../context";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {PricingConfig, PricingSnapshot} from "../api/models";
import {Button, Checkbox, Col, Descriptions, Input, message, Row, Space, Spin} from "antd";
import {debounce} from "lodash";
import dayjs from "dayjs";
import Table, {ColumnType} from "antd/es/table";
import PinnableDrawer from "./PinnableDrawer";
import Utils from "../util/util";
import useUsers from "../swr/useUsers";
type Placement = 'left' | 'top' | 'right' | 'bottom';

const DATE_DISPLAY_FORMAT = "dddd, MMMM Do YYYY, H:mm:ss";


const PricingConfigurationDetail = (props:{
  pricingConfig:PricingConfig|undefined
}) => {

  const { pricingConfig } = props;
  const userLst = useUsers().data;


  const pricingConfigCreatedBy = userLst?.find( u => u.id === pricingConfig?.createdBy )?.name || pricingConfig?.createdBy;

  return <Descriptions 
    labelStyle={{fontWeight: "bold", color: "black", textAlign: "right", width: "20rem", display: "inline-block"}}
    items={[
      { key: '1'      , span: 6 , label: 'Labor Hourly Cost (Dollars)'                , children: Utils.formatMoney( pricingConfig?.laborHourlyCost )         , } , 
      { key: '2'      , span: 6 , label: 'PDI'                                        , children: Utils.formatMoney( pricingConfig?.pdi)                      , } , 
      { key: '3'      , span: 6 , label: 'Warranty (fixed cost)'                      , children: Utils.formatMoney( pricingConfig?.warranty )                , } , 
      { key: '4'      , span: 6 , label: 'Warranty (Percentage of Material Cost)'     , children: Utils.formatPercent( pricingConfig?.warrantyPercentage)     , } , 
      { key: '5'      , span: 6 , label: 'BEV Warranty (Percentage of Material Cost)' , children: Utils.formatPercent( pricingConfig?.bevWarrantyPercentage)  , } , 
      { key: '6'      , span: 6 , label: 'Extended Warranty Markup'                   , children: Utils.formatPercent( pricingConfig?.extendedWarrantyMarkup) , } , 
      { key: '7'      , span: 6 , label: 'Tarriffs and Surcharges'                    , children: Utils.formatMoney( pricingConfig?.tariffsSurcharges)        , } , 
      { key: '8'      , span: 6 , label: 'Margin'                                     , children: Utils.formatPercent( pricingConfig?.margin)                 , } , 
      { key: '9'      , span: 6 , label: 'Dealer Markup'                              , children: Utils.formatPercent( pricingConfig?.dealerMarkup)           , } , 
      { key: '10'     , span: 6 , label: 'Inbound Freight'                            , children: Utils.formatPercent( pricingConfig?.inboundFreight)         , } , 
      { key: '11'     , span: 6 , label: 'Created At'                                 , children: dayjs(pricingConfig?.createdAt).format(DATE_DISPLAY_FORMAT) , } , 
      { key: '12'     , span: 6 , label: 'Created By'                                 , children: pricingConfigCreatedBy                                      , } , 
    ] } />
};


const PricingHistoryTable = (props:{
  onSelect:(ps:PricingSnapshot)=>void
  }) => {
  const { onSelect } = props;

  const userLst = useUsers().data;

  const { pricingSnapshotList, pricingSnapshotListAsync, loadPricingSnapshotList } = useContext<PricingSnapshotListContext>(PricingSnapshotsContext);
  const [invertFilter, setInvertFilter] = useState<boolean>(false);
  const [filter, _setFilter] = useState<string | undefined>();
  const [isFiltering, setFiltering] = useState<boolean>(false);

  useEffect(()=> {
    if ( ( pricingSnapshotListAsync?.isInitial() || pricingSnapshotListAsync?.isFail() ) && !pricingSnapshotListAsync.isLoading() )  {
      loadPricingSnapshotList?.();
    }
  }, [ pricingSnapshotListAsync?.state ] );

  const setFilter = useCallback(
    debounce( (s:string | undefined) => { 
      _setFilter( s )
      setFiltering( false );
    }, 400 )
  , [] );

const handleChangeFilter = (e:any) => {
  setFiltering( true );
  setFilter( e.target.value )
}

const handleInvertFilter = (_e:any) => {
  setInvertFilter( !invertFilter );
}

//show failure message
useEffect(()=> {
  if ( pricingSnapshotListAsync.isFail() )  {
    message.error("Assemblies failed to load. " + pricingSnapshotListAsync.err );
  }
}, [ pricingSnapshotListAsync.state ] );


//prepare data for display
const NOT_FOUND = -1;
const data = pricingSnapshotList
  ?.filter( d => {
    if ( !filter ) return true;
    const ndx = d.name.toUpperCase().indexOf( filter.toUpperCase() )
    return invertFilter ? ( ndx === NOT_FOUND ) : ( ndx !== NOT_FOUND );
  })
  .sort( (a,b) => dayjs( a.createdAt ).isBefore( b.createdAt ) ? 1 : -1 )
  || [];


  const columns:ColumnType<PricingSnapshot>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render:(txt,rec)=><Button className="ghostBmButton" type="link" onClick={() => onSelect(rec)}>{txt}</Button>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render:(txt)=>dayjs(txt).format(DATE_DISPLAY_FORMAT)
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      render:(txt)=>userLst?.find( u => u.id === txt )?.name || txt
    },
  ];

  return <>
    <Space direction="vertical" size="middle"
        style={{width: "100%"}}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Input onChange={handleChangeFilter} placeholder="Filter Snapshot" allowClear />
        </Col>
        <Col flex={1}>
          <Checkbox style={{marginTop: "4px" }} onChange={handleInvertFilter} checked={invertFilter}>Invert Filter</Checkbox>
        </Col>
      </Row>

      <Table
        key="id"
        bordered
        loading={pricingSnapshotListAsync.isLoading() || isFiltering }
        scroll={{ x: "1500" }}
        columns={columns}
        dataSource={data}
        rowKey="id"
      />
    </Space>
  </>
};

const PricingConfigurationDrawer = (props:{
  pricingConfigAsync:AsyncState<PricingConfig>
  onClose:()=>void
  modal?:boolean
  placement?:Placement
  width?:string
  hidePin?:boolean
}) => {

  const { pricingConfigAsync, onClose, modal, placement, width, hidePin } = props;

  const handleCloseDrawer = () => {
    onClose();
  }

  const pricingConfig = pricingConfigAsync.val;

  const w = width || "42em";

  return <PinnableDrawer
    title="Pricing Configuration"            
    placement={placement}
    onClose={handleCloseDrawer}
    open={!pricingConfigAsync.isInitial()}
    width={w}
    modal={modal}
    hidePin={hidePin}
  >
    <Spin spinning={pricingConfigAsync.isLoading()}>
      <style>
      {`
        .ant-descriptions .ant-descriptions-row > th, .ant-descriptions .ant-descriptions-row > td {
          padding-bottom: 5px;
        }
      `}
      </style>
      <div style={{ fontWeight: "600", fontSize: "12pt", marginBottom: "1rem"}}>{pricingConfig?.pricingSnapshot?.name}</div>
      <PricingConfigurationDetail pricingConfig={pricingConfig} />
    </Spin>

  </PinnableDrawer>

};

const PricingHistory = () => {

  const configurator = useContext(ConfiguratorContext);
  const [_pricingConfig, pricingConfigAsync ] = useAsyncState<PricingConfig>();

  const handleClickPricingSnapshot = (pricingSnapshot:PricingSnapshot) => {
    pricingConfigAsync.setLoading();
    configurator.api.fetchPricingConfigBySnapshotId(pricingSnapshot.id)
    .then(resp => pricingConfigAsync.setDone(resp.data),
          reason => pricingConfigAsync.setFail(reason));
  }

  const handleCloseDrawer = () => {
    pricingConfigAsync.setInit();
  }

  return <>

      <PricingHistoryTable 
        onSelect={handleClickPricingSnapshot}
      />

      <PricingConfigurationDrawer 
        pricingConfigAsync={pricingConfigAsync}
        onClose={handleCloseDrawer}
      />

  </>
};

export default PricingHistory;

