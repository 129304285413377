import {Button, Drawer, Input, notification, Radio, Result, Space, Spin} from "antd";
import Title from "antd/lib/typography/Title";
import {ValidateFields} from "rc-field-form/lib/interface";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Quote, QuoteRevisionDiff, RevisionChangeDto, RevisionType} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import QuoteRevisionDiffTable from "../Table/QuoteRevisionDiffTable";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import {useQuoteContext} from "../../contexts/QuoteContext";
import {RequestPurchaseOrderFormValue, useRequestPoSteps} from "../RequestPoModalButton";
import DocumentSignUI from "../DocusignUI";
import {PoNumberRequest, RequestPurchaseOrder} from "../../api";
import {WizardInstance, WizardStep} from "../Wizard";
//import _ from "lodash";
import {useForm} from "antd/es/form/Form";
import {buildPoNumberRequest, PoNumberForm, PoNumberFormValues} from "./PoNumberEditor";
import {FEATURE_DOCUSIGN} from "../../api/features";
import useQuoteReview from "../../swr/useQuoteReview";
import useQuote from "../../swr/useQuote";
import useComputePricing from "../../swr/useComputePricing";

/*
const EMPTY_DIFF = {
  "assembliesDiff": {
    "removedAssemblies": [],
    "addedAssemblies": []
  },
  "customOptionsDiff": {
    "removedCustomOptions": [],
    "addedCustomOptions": [],
  },
};
 */

const SubmitChangeOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
  onValidate?: () => Promise<ValidateFields | undefined>
}) => {

  const { onChange:a, onValidate:b, ...btnProps } = props;

  const configurator = useContext(ConfiguratorContext);

  const {quote, adminView, setQuoteFormValues } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });
  const quoteState = Utils.getQuoteState(configurator, quote, quoteAsync.isLoading);
  const {
    isEngineeringChangeOrder,
    hasEngineeringChangePermission,
  } = quoteState;

  const isDocusignEnabled = configurator.hasFeature(FEATURE_DOCUSIGN);

  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [docusignLoading, setDocusignLoading] = useState<boolean>(false);
  const [revisionChanges, revisionChangesAsync] = useAsyncState<RevisionChangeDto>();
  const [purchaseOrderRequest, setPurchaseOrderRequest] = useState<RequestPurchaseOrder>();
  //const [forceErpRevision, setForceErpRevision] = useState<boolean>(false);
  const [changeSummary, setChangeSummary] = useState<string>();
  const [isPriceProtected, setIsPriceProtected] = useState<boolean>(isEngineeringChangeOrder);
  //const isReleaseEngineeringOrAdmin = configurator.isReleaseEngineering() || configurator.isAdmin();
  const [poNumberForm] = useForm();
  const hidePrice = (!configurator.isAdmin() && !configurator.isEngineering() && !configurator.isSalesDesk() ) || isEngineeringChangeOrder

  //const [selectedPoNumber, setSelectedPoNumber] = useState<PoNumberFormValues>();

  const review = useQuoteReview({
    quoteRevisionId: quote?.displayRevisionId,
    options: {
      dealerView: !adminView
    }
  })

  const submitChangeOrder = async () : Promise<Quote|undefined> => {

    if ( !quote ) return;
    if ( !changeSummary?.length) return;

    try {

      const resp = await configurator.api.submitChangeOrder(quote.displayRevisionId, {
        changeSummary,
        //forceErpRevision,
      } );

      //todo - move to onclick handler
      await quoteAsync.mutate(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to create change order. " + errorMsg;

      notification.error( { message: msg });
    }

    return;
  };

  const loadDiff = async () : Promise<QuoteRevisionDiff | undefined> => {
    if ( !quote ) return;

    try{
      revisionChangesAsync.setLoading();
      const resp = await configurator.api.verifyRevisionChange(
        {
          quoteId: quote.id,
          verifyingRevisionId: quote.displayRevisionId,
          compareCurrentRevision: true,
        }
      );
      revisionChangesAsync.setDone(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response.data.message });
      const msg = "Failed to get change difference. " + errorMsg;

        notification.error( { message: msg });
        revisionChangesAsync?.setFail(e.message);
    }     
  }

  const requestPurchaseOrder = async (quoteRevisionId: number | undefined, req:RequestPurchaseOrderFormValue) : Promise<void> => {
    if ( !quoteRevisionId) return;

    setDocusignLoading(true);
    try {
      await configurator.api.requestPo(quoteRevisionId, req)
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
    }
    setDocusignLoading(false);
  }

  const savePoNumber = async (quoteRevisionId:number | undefined, req:PoNumberRequest) :Promise<Quote | undefined> => {
    if ( !quoteRevisionId) return;

    try {
      const resp = await configurator.api.savePoNumber(quoteRevisionId, req );
      await setQuoteFormValues?.({
        ...resp.data,
      });
      await quoteAsync.mutate(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
    }
    return;
  }

  const updateRevisionType = async (quoteRevisionId:number | undefined, revisionType:RevisionType) :Promise<Quote | undefined> => {
    if ( !quoteRevisionId) return;

    try {
      const resp = await configurator.api.updateRevisonType(quoteRevisionId, revisionType );
      await quoteAsync.mutate(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
    }
    return;
  }

  const handleChangePriceProtected = async (nav:WizardInstance) => {

    if ( isPriceProtected != isEngineeringChangeOrder ) {

      const revisionType = isPriceProtected
          ? RevisionType.ENGINEERING_CHANGE
          : RevisionType.CHANGE_ORDER;

      await updateRevisionType(quote?.displayRevisionId, revisionType);

      computedPricing.mutate();

      //effectively mutate
      //await loadQuoteOnly?.();
    }

    nav.nextStep();
  }

  const handleSelectPoNumber = async () => {

    const formValues = poNumberForm.getFieldsValue(true) as PoNumberFormValues;
    const req = buildPoNumberRequest(formValues);

    await savePoNumber(quote?.displayRevisionId, req);

    submit();
  }

  const handleChangeRequest = (nav:WizardInstance) => {

    if ( missingPo || requiresNewPo ) {
      nav.nextStep();
      return;
    }

    submit();
  }

  const handleConfirmSubmit = async () => {

    await Promise.all([
          loadDiff(),
    ]);

    setIsOpen( true );
  }

  const submit = async () => {
    const quote = await submitChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleFinished = async (formValues:RequestPurchaseOrderFormValue) => {
    setIsOpen(false);

    //dealers sign the PO in-app
    const isDealer = configurator.isDealerSales();
    if ( isDealer ) {
      setPurchaseOrderRequest(formValues)
      setShowDrawer(true);
    }
    else {
      await requestPurchaseOrder( quote?.displayRevisionId, formValues );
      submit();
    }
  }

  const handleDoneSigning = () => {
    setShowDrawer(false);
    submit();
  }

  const handleSkipPo = () => {
    submit();
  }

  const isWorking = !!props.loading || quoteAsync?.isLoading || review.isLoading || revisionChangesAsync.isLoading() || docusignLoading || showDrawer;
  //const hasChanges = !_.isEqual(revisionChanges?.diffDto, EMPTY_DIFF);

  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  const missingPo = !hasPoDocuments && !isEngineeringChangeOrder

  const computedPricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  });

  const unitPrice = computedPricing.data?.dealerPrice || 0;
  const totalPrice = (quote?.quantity || 0) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;
  const requiresNewPo = !isPoAmountMatch && !isEngineeringChangeOrder

  const {steps: docusignSteps} = useRequestPoSteps({
    loading: isWorking,
    onFinished: handleFinished,
    onSkip: handleSkipPo
  })

  const steps:WizardStep[] = [
    {
      key:"quoteReview",
      body: (_nav) => <Result status="warning"
                              title={"The following issues with the order have been identified."}
                              subTitle={<Title level={5}>Continue to submit for approval?</Title> }
      >
        <QuoteReviewDetail
            review={review.data}
            ignoreDashDrawing={true}
        />
      </Result>,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
        <Button key="back" onClick={() => setIsOpen(false)} >Cancel</Button>
      </div>,
      hidden: !Utils.reviewHasErrors(review.data)
    },
    {
      key:"priceProtected",
      hidden: !hasEngineeringChangePermission,
      body:(nav) =>
          <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            alignItems: "center",
            gap: "1rem"
          }}>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
              <Title level={5}>Is this change price protected?</Title>
              <Radio.Group value={isPriceProtected} onChange={(e) => setIsPriceProtected( e.target.value) } >
                <Radio value={true} style={{marginBottom: ".5rem"}}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

            </div>
          </div>,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" onClick={() => handleChangePriceProtected(nav)} >Next</Button>
        <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
      </div>,
    },
    /*
  {
    key:"forceErp",
    hidden: hasChanges || !isReleaseEngineeringOrAdmin, // Admin and Release Engineering can force ERP revision increase
    body:(_nav) => <div>
      <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
        <Title level={5}>There are no changes.  Force an ERP update?</Title>
        <Checkbox value={forceErpRevision} onChange={(e) => setForceErpRevision(e.target.checked)} />
      </div>
    </div>,
    footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Button key="next" type="primary" onClick={() => nav.nextStep} >Next</Button>
      <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
    </div>,
  },
     */
    {
      key:"changeRequest",
      body: (_nav) =>
          <div style={{marginTop: "1rem"}}>
            <Space direction={"vertical"} style={{width: "100%"}}>
              <QuoteRevisionDiffTable diff={revisionChanges?.diffDto} hidePriceChange={hidePrice}/>
              <Input data-testid={"input-changeSummary"} value={changeSummary} onChange={(e) => setChangeSummary(e.target.value)} placeholder={'Please enter the change reason here.'} />
            </Space>
          </div>,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" disabled={!changeSummary?.length} onClick={() => handleChangeRequest(nav)} >
          {(requiresNewPo || missingPo) ? "Next": "Submit" }
        </Button>
        <Button key="back" onClick={nav.prevStep} >Back</Button>
      </div>,
    },
    {
      key: "poWarningStep",
      body: (_nav) => {
        const msg = missingPo
            ? "This order is missing a Purchase Order."
            : "A new purchase order is required.";
        return <Result status="warning" title={msg} />
      },
      footer: (nav) => <div
          style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem"}}>
        <Button key="next" type="primary" onClick={() => nav.nextStep()}>Next</Button>
        <Button key="cancel" onClick={() => nav.prevStep()}>Back</Button>
      </div>,
    }
  ];

  if (isDocusignEnabled) {
    docusignSteps.forEach(s => steps.push(s))
  }
  else {
    steps.push({
      key: "poNumber",
      body: (_nav) => <div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "2rem",
          alignItems: "center",
          gap: "1rem"
        }}>
          <Title level={5}>Is the Product Order (PO) information accurate?</Title>
          <PoNumberForm
              form={poNumberForm}
              initialValues={quote?.poNumber}
          />
        </div>
      </div>,
      footer: (nav) => <div
          style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem"}}>
        <Button key="next" type="primary" loading={isWorking} onClick={() => handleSelectPoNumber()}>Submit</Button>
        <Button key="cancel" type="primary" onClick={() => nav.prevStep()}>Back</Button>
      </div>,
    });
  }

  return <>
    <BMButton
      type="primary"
      onClick={handleConfirmSubmit}
      {...btnProps}
      loading={isWorking}
    >
      Submit
    </BMButton>
    <ModalWizard
      title={'Change Order Submission'}
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      steps={steps}
      width={"80%"}
      style={{
        maxWidth: "70rem",
        minWidth: "50rem"
      }}
    />
    <Drawer
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={"100%"}
      destroyOnClose={true}
    >
      <Spin spinning={drawerLoading} tip="One moment.  Preparing your order." >
        <div style={{display: "flex", justifyContent: "center", padding: "1rem"}}>
          <DocumentSignUI quoteRevisionId={quote?.displayRevisionId} details={purchaseOrderRequest} onDone={handleDoneSigning} onLoad={(l) => setDrawerLoading(l) } />
        </div>
      </Spin>
    </Drawer>

  </>
}

export default SubmitChangeOrderButton;
