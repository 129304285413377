import styles from './InventoryDetail.module.css'
import Title from "antd/lib/typography/Title";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  Modal,
  notification,
  Result,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip
} from "antd";
import axios, {CancelTokenSource} from "axios";
import {throttle} from "lodash";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useParams} from "react-router-dom";
import {NotFoundError} from "../api/errors";
import {
  Approval,
  QuoteRevisionDiff,
  ApproverRole,
  AssemblyInfo,
  AXIOS_CANCEL_MSG,
  CategoryTags,
  CommentTopic,
  CustomOptionType,
  DEFAULT_THROTTLE,
  DocusignPoRequestDetail,
  Performance,
  Quote,
  QuoteAssemblyException,
  RevisionType, RevisionApprovalStatus
} from "../api/models";
import QuoteCommentList from "../components/Quote/QuoteCommentList";
import {VfdReviewPanel} from "../components/Quote/QuoteInfoTab";
import {
  ComponentLocationContents,
  ConfigurationTabContents,
  DetailsTabContents,
  PerformanceContents,
  PricingTabContents,
  QuoteHeader
} from "../components/Quote/QuoteQuickView";
import {ConfiguratorContext } from "../context";
import QuoteContextProvider, {useQuoteContext} from "../contexts/QuoteContext";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {CheckOutlined} from "@ant-design/icons";
import Utils from '../util/util';
import dayjs from 'dayjs';
import ApprovalTransitionInfo from '../components/ApprovalTransitionInfo';
import BMButton, {BMButtonProps} from '../components/BMButton';
import {useForm} from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import EditCustomOptionButtonModal from '../components/EditCustomOptionButtonModal';
import ApprovalResult from '../components/ApprovalResult';
import RequestPoModalButton from '../components/RequestPoModalButton';
import ModalWizard from '../components/ModalWizard';
import {FEATURE_DOCUSIGN} from '../api/features';
import useLatestApprovals from "../swr/useLatestApprovals";
import QuoteRevisionDiffTable from "../components/Table/QuoteRevisionDiffTable";
import useModelCategories from "../swr/useModelCategories";
import useCustomOptions from "../swr/useCustomOptions";
import useAssemblyExceptions from "../swr/useAssemblyExceptions";
import useQuoteComments from "../swr/useQuoteComments";
import useQuoteHistory from "../swr/useQuoteHistory";
import useQuote from "../swr/useQuote";
import useComputePricing from "../swr/useComputePricing";

const ReleaseEngineeringRoles = [ ApproverRole.RELEASE_ENGINEERING, ApproverRole.PROCUREMENT ];

const ApprovalDetailPage = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const cancelLoadApprovalTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadPoRequestDetailSourceRef = useRef<CancelTokenSource>();
  const params = useParams<{approvalId?:string|undefined}>();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [showApprovalResults, setShowApprovalResults] = useState<boolean>(false);

  const [approval, approvalAsync] = useAsyncState<Approval>();
  const topics = [ CommentTopic.UserComment, CommentTopic.SystemActivity ]
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [componentLocations, componentLocationsAsync] = useAsyncState<Record<string, string>>();
  const [poRequestDetail, poRequestDetailAsync] = useAsyncState<DocusignPoRequestDetail>();

  const quoteAsync = useQuote({ quoteId: approval?.quoteInfo.quoteId, revision: approval?.quoteInfo.revision });
  const quote = quoteAsync.data;

  const quoteHistory = useQuoteHistory({
    quoteId: quote?.quoteId,
    showAbandoned: false
  });

  const approvals = useLatestApprovals({
    quoteRevisionId: quote?.displayRevisionId,
  });

  useEffect(() => {
    loadQuoteApproval(approvalAsync, params.approvalId)
      ?.then(approval => {
        if ( approval ) {
          Promise.all([
            loadPerformance( approval.quoteInfo.quoteId),
            loadPoRequestDetails( poRequestDetailAsync, approval.quoteInfo.quoteRevisionId),
            loadComponentLocations( quote?.displayRevisionId )
          ]);
        }
      });
    return () => loadQuoteApproval.cancel()
  }, [params.approvalId]);

  const loadPoRequestDetails = useCallback(throttle( async (poRequestDetailAsync:AsyncState<DocusignPoRequestDetail>, quoteRevisionId: number | undefined ): Promise<DocusignPoRequestDetail | undefined> => {
    if (!quoteRevisionId) return;

    if ( cancelLoadPoRequestDetailSourceRef.current ) {
      cancelLoadPoRequestDetailSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadPoRequestDetailSourceRef.current = cancelSource;

    try {
      poRequestDetailAsync.setLoading();
      const resp = await configurator.api.getDocusignRequestDetail(quoteRevisionId, cancelSource.token);
      cancelLoadPoRequestDetailSourceRef.current = undefined;

      poRequestDetailAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get po request details. " + errorMsg, duration: 500 });
          poRequestDetailAsync.setFail(errorMsg);
        }
    }
    return;

  }, DEFAULT_THROTTLE), []);

  const loadQuoteApproval = useCallback(throttle( async (quoteAsync:AsyncState<Approval>, approvalId:string | undefined ): Promise<Approval | undefined> => {  
    if (!approvalId) return;

    if ( cancelLoadApprovalTokenSourceRef.current ) {
      cancelLoadApprovalTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadApprovalTokenSourceRef.current = cancelSource;

    try {
      approvalAsync.setLoading();
      const resp = await configurator.api.getQuoteApproval(approvalId, cancelSource.token);
      cancelLoadApprovalTokenSourceRef.current = undefined;

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( "Approval not found." );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote approval. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }
    return;

  }, DEFAULT_THROTTLE), []);

  const customOptions = useCustomOptions({
    quoteRevisionId: quote?.displayRevisionId
  });

  const assemblyExceptions = useAssemblyExceptions({
    quoteId: quote?.quoteId
  })

  const quoteComments = useQuoteComments({
    quoteId: quote?.quoteId,
    options: {topic: topics}
  })

  const loadPerformance = async (quoteId:string | undefined, rev?:number | undefined) : Promise<Performance | undefined> => {
    if ( !quoteId?.length ) return;

    try {
      const resp = await configurator.api.fetchQuotePerformance(quoteId, rev);
      performanceAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get performance statistics. " + errorMsg });
      performanceAsync.setFail( e.message );
    }

    return;
  }
  const loadComponentLocations = async (quoteRevisionId:number | undefined) : Promise<Record<string, string> | undefined> => {
    if ( !quoteRevisionId ) return;

    componentLocationsAsync.setLoading()

    try {
      const resp = await configurator.api.fetchComponentLocations(quoteRevisionId);
      componentLocationsAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load component locations. " + errorMsg });
      componentLocationsAsync.setFail(e.message);
    }

    return;
  }

  const handleApprove = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvals.mutate();
      quoteHistory.mutate();
      customOptions.mutate();

      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }
  const handleReject = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvals.mutate();
      quoteHistory.mutate();

      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }


  const handleCustomOptionChange = () => {
    customOptions.mutate();
  }
  const {
    isSalesChangeOrder,
    isEngineeringChangeOrder,
  } = Utils.getQuoteState(configurator, quote, quoteAsync.isLoading, false);

  const isApproverRole = configurator.isAdmin() || approval?.approver;
  const isChangeRequest = isSalesChangeOrder || isEngineeringChangeOrder || approvalAsync?.val?.quoteInfo.revisionType === RevisionType.SPLIT_ORDER;

  const releaseRoles = new Set(Object.values(ReleaseEngineeringRoles).map(r => r.toString()));
  const isReleaseEngineeringView = !!(approval && approval.approvalStep.approvers.some( a => releaseRoles.has(a) ) );

  const isApplicationEngineeringView = !!(approval && approval.approvalStep.approvers.some( a => ApproverRole.ENGINEERING.toString() === a ) );
  const isSalesView = approval && !isReleaseEngineeringView;
  const hasAdminView = configurator.isEngineering() || configurator.isSalesDesk() || configurator.isAdmin();

  const getApproveDisabledMsg = () => {
    return !isApproverRole  ? `This approval requires a role of ${ approval?.approvalStep.approvers.map( a => Utils.snakeCaseToFirstLetterCapitalized( a ) ).join(" or ")}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const getRejectDisabledMsg = () => {
    return !isApproverRole  ? `This approval requires a role of ${ approval?.approvalStep.approvers.map( a => Utils.snakeCaseToFirstLetterCapitalized( a ) ).join(" or ")}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const tabItems:any[] = [];
  if (isChangeRequest) tabItems.push({
    key:"changes",
    label: "Changes",
    children: <ChangeOrderDiff quote={quote} approvalAsync={approvalAsync}/>
  });

  tabItems.push( {
    key:"spec",
    label: "Specifications",
    children: <ConfigurationTabContents />
  });

  return <div className="site-layout-background">
    <Title level={2}>Approval Request - <span style={{textTransform: "uppercase"}}>{Utils.formatApprovalType(approval?.approvalType, approval?.quoteInfo.reservation)}</span></Title>

    <Skeleton active loading={approvalAsync.isLoading()} >
    <QuoteContextProvider value={{ quote, adminView: hasAdminView }}>
    <Space direction="vertical" style={{width: "100%"}}>

      <PendingSplitAlert />

      <MissingPoAlert approval={approval} poRequestDetail={poRequestDetail} />

      <PoAmountAlert approval={approval} poRequestDetail={poRequestDetail} />

      <div style={{display: "flex", gap: ".5rem 2rem", flexWrap: "wrap"}}>

        <div className={styles["section"]}>
          <div >Submitted:</div>
          <div ><span>{Utils.formatUsername(approval?.submittedBy)}</span></div>
          <div ><span>{approval?.submittedAt ? dayjs(approval?.submittedAt).format("MMMM Do YYYY") : ""}</span></div>
        </div>

      </div>

      <ApprovalTransitionInfo
        approvals={approvals.data}
        approval={approval}
        isSingleAction={isChangeRequest}
      />

      <ApprovalResult  
        approval={approval}
        quoteStatus={quote?.displayStatus}
        showApprovalResults={showApprovalResults}
        setShowApprovalResults={setShowApprovalResults}
      />

      <Divider />

        <Row gutter={[20, 20]}>
          <Col span={22}>
            <div style={{display: "flex", flexDirection: "row-reverse", gap: "1rem"}} >
              <ApproveButton type="primary"
                data-testid="approve-btn"
                disabled={!!getApproveDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getApproveDisabledMsg())}
                value={approval}
                onChange={handleApprove} >
                Approve 
              </ApproveButton>
              <RejectButton danger type="primary" 
                data-testid="reject-btn"
                disabled={!!getRejectDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getRejectDisabledMsg())}
                value={approval} 
                onChange={handleReject} >
                Reject
              </RejectButton>

              <Button type="primary" onClick={() => setShowComments(true)} style={{marginRight: "2rem"}} >
                Comments<Badge count={quoteComments.data?.length} size="small" >&nbsp;</Badge>
              </Button>
            </div>
          </Col>
          <Col span={15}>
            <Space direction='vertical' size="middle">
              <QuoteHeader  hidePricing={true} />
              <DetailsTabContents isReleaseView={isReleaseEngineeringView}  /> 
              <Card title="Engineering Review" size="small" >
                <VfdReviewPanel includeAllCustomOptions={false} />
              </Card>
            <Row gutter={[20, 20]}>
              {(performance && !isReleaseEngineeringView) && <Col >
                <PerformanceContents performance={performance} /> 
              </Col> }
              {(componentLocations && isApplicationEngineeringView ) && <Col >
                <ComponentLocationContents componentLocations={componentLocations} /> 
              </Col> }
              </Row>
              {( !!assemblyExceptions.data?.length && isApplicationEngineeringView) &&
                <Card styles={{body:{padding: 0}}} >
                  <QuoteAssemblyExceptionsReview value={quote?.quoteId} />
                </Card>
              }
              {(!!customOptions.data?.length && !isReleaseEngineeringView) &&
                <Card styles={{body:{padding: 0}}} >
                  <CustomOptionsReview value={quote?.displayRevisionId} onChange={handleCustomOptionChange} />
                </Card>
              }
              {isChangeRequest ? <Tabs items={tabItems} /> : <ConfigurationTabContents /> }
            </Space>
          </Col>
          {isSalesView && 
          <Col style={{maxWidth: "25rem"}} >
            <Space direction='vertical'>
              <Card >
                <PricingTabContents />
              </Card>
            </Space>
          </Col>}
          <CommentsDrawer 
            quote={quote}
            topics={topics}
            open={showComments} 
            onClose={() => setShowComments(false)} />
        </Row>
    </Space>
    </QuoteContextProvider>
    </Skeleton>
  </div>
}

const ChangeOrderDiff = (props:{
  quote:Quote | undefined
  approvalAsync: AsyncState<Approval>
}) => {

  const [approvalDiff, approvalDiffAsync] = useAsyncState<QuoteRevisionDiff>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const cancelLoadApprovalDiffTokenSourceRef = useRef<CancelTokenSource>();
  const quoteId = props.quote?.quoteId;
  const revision = props.quote?.revision;

  const isLoading = approvalDiffAsync.isLoading() || !approvalDiffAsync.val;
  
  const loadApprovalDiff = useCallback(throttle( async (approvalDiffAsync:AsyncState<QuoteRevisionDiff>, quoteId: string | undefined, revision: number | undefined): Promise<QuoteRevisionDiff | undefined> => {
    if (!quoteId) return;
    if (!revision) return;

    approvalDiffAsync.isLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadApprovalDiffTokenSourceRef, (token) => 
        configurator.api.diffRevisions(quoteId, revision, undefined, token)
      );

      approvalDiffAsync.setDone(resp?.data);

      return resp?.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to fetch approval differences. " + errorMsg });
      approvalDiffAsync.setFail(e.message);
    }

    return;
  }, DEFAULT_THROTTLE), []);



  useEffect(() => {
    loadApprovalDiff( approvalDiffAsync, quoteId, revision ) 
  }, [quoteId, revision]);

  if ( Utils.isEmptyDeep(approvalDiff) ) {
    return <>There are no changes in this revision.</>
  }


  const change = props.quote;
  const revisionType = props.quote?.revisionType;
  return <Spin spinning={isLoading}>
      <Space direction='vertical' style={{width: "100%"}}>
        <LeadTimeWarning assemblies={approvalDiff?.assembliesDiff?.addedAssemblies} />
        <LayoutWarning assemblies={approvalDiff?.assembliesDiff?.addedAssemblies} />

        {(!!change?.changeSummary?.length) &&
          <Descriptions layout="vertical" className={styles['quoteQuickView-description']}  column={1}
            items={[
              {
                children: <>
                  <div key="changeSummary">
                    <Title level={5}>{revisionType === RevisionType.ENGINEERING_CHANGE
                        ? "Change Summary"
                        : "Cancellation Message"}
                    </Title>
                    <p>{change?.changeSummary}</p>
                  </div>
                </>
              }
            ]} 
            size="small" colon={false} 
            style={{padding: 0}}
          />}

        <React.Fragment key={quoteId || 'main-approval-diff'}>
          <QuoteRevisionDiffTable
              diff={approvalDiff}
              quoteId={quoteId}
          />
        </React.Fragment>

      </Space>
    </Spin>
}

const CommentsDrawer = (props:DrawerProps & {
  quote:Quote | undefined
  topics:CommentTopic[]
}) => {

  return <>
    <Drawer
      {...props}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{props.quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
      <QuoteCommentList topics={props.topics} />
    </Drawer>
  </>
}


const RejectButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
}) => {

  const {value:b, onChange:a, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();
  const actionNotes = Form.useWatch<string | undefined>('actionNotes', form);

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleReject = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await reject( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const reject = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.rejectAction(approvalId, {
        ...values,
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to reject. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >
      Reject
    </BMButton>
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      footer={[
        <Button key="cancel" 
          onClick={() => setIsOpen(false)} >
          Cancel
        </Button>,  
        <BMButton danger key="reject" type="primary" 
          onClick={handleReject} 
          disabled={!actionNotes?.length}
          onDisabledClick={() => form.validateFields()}
        >
          Reject
        </BMButton>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="actionNotes"
          label="Reject Reason (Public)}"
          rules={[{ required: true, message: "A reason is required." }]}
        >
          <TextArea placeholder={'Please provide a reason.'} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const ApproveButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
}) => {

  const {value:b, onChange:a, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quote} = useQuoteContext();

  const pricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  }).data;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleApprove = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await approve( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const approve = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.approveAction(approvalId, {
        ...values,
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to approve. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  const releaseRoles = new Set(Object.values(ReleaseEngineeringRoles).map(r => r.toString()));
  const isReleaseEngineeringView = approval && approval.approvalStep.approvers.some( a => releaseRoles.has(a) );
  const isSalesView = approval && !isReleaseEngineeringView;
  const isOrderApproval = !!quote?.trucks?.length;
  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  
  const showPoWarnings = (isSalesView && isOrderApproval);
  const missingPo = (isSalesView && isOrderApproval) && !hasPoDocuments;

  const unitPrice = pricing?.dealerPrice || 0;
  const totalPrice = ( quote?.quantity || 0 ) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;


  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >Approve
    </BMButton>
    <ModalWizard
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      showSteps={false}
      steps={[
        {
          key:"poWarningStep",
          hidden: !(showPoWarnings && missingPo),
          body: (_nav) => <Result status="warning" 
            title={"This order is missing a Purchase Order."}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
            <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
        },
        {
          key:"poAmountMatchStep",
          hidden: !(showPoWarnings && !isPoAmountMatch),
          body: (_nav) => <Result status="warning" 
            title={"Incorrect Purchase Order amount."}
            subTitle={`A purchase order amount ${Utils.formatMoney(poAmount)} does not match the unit price ${Utils.formatMoney(unitPrice)} or total price ${Utils.formatMoney(totalPrice)}.`}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
            <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
        },
        {
          key:"reasonStep",
          body: (_nav) => <>
            <Form
              form={form}
              layout="vertical"
            >
              <Form.Item
                name="actionNotes"
                label="Notes (Public, Optional)"
              >
                <TextArea rows={4} />
              </Form.Item>
            </Form>
          </>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <BMButton key="approve" type="primary" loading={approvalAsync.isLoading()} onClick={handleApprove}>Approve</BMButton>
              {(showPoWarnings && (missingPo || !isPoAmountMatch))
              ? <Button key="back" onClick={() => nav.prevStep()} >Back</Button> 
              : <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button> }
            </div>,
        }
        ]}
    >
    </ModalWizard>
  </>
}

const CustomOptionsReview = (props:{
  value: number | undefined
  onChange: (co:CustomOptionType | undefined) => void
}) => {

  const configurator = useContext(ConfiguratorContext);

  const customOptions = useCustomOptions({
    quoteRevisionId: props.value
  });

  const isReadOnly = !(configurator.isEngineering() || configurator.isAdmin());

  const btnStyle = isReadOnly
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <div key="customOptionsReview">
    <style>
      {`
      .dialog-customOptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-customOptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-customOptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-customOptionLst"
      columns={ [ 
        {
          title: "Custom Option",
          render: (co:CustomOptionType) =>
            <EditCustomOptionButtonModal 
              type="text" className="ghostBmButton"
              style={{padding:0}}
              onChange={props.onChange}
              disabled={isReadOnly}
              value={co}
              categoryId={co.category?.id}
            >
              <span style={{...btnStyle}}>{co.content}</span>
            </EditCustomOptionButtonModal>
        },
        {
          title: "Category",
          render: (co:CustomOptionType) => Utils.stripSortingPrefix(co.category?.name)
        },
        {
          title: "Price",
          render: (co:CustomOptionType) => <>{Utils.formatMoney(co.price, "")} {co.disableUpcharge ? undefined : <Tooltip title={"This includes a 25% upcharge on custom options."}>+</Tooltip>}</>
        },
        {
          title: "Selected",
          render: (co:CustomOptionType) => co.included ? <CheckOutlined /> : undefined
        },
      ]}
      rowKey="id"
      loading={customOptions.isLoading}
      dataSource={customOptions.data}
    />
  </div>;
}

const QuoteAssemblyExceptionsReview = (props:{
  value: string | undefined
  onChange?: (co:QuoteAssemblyException) => void
}) => {

  const assemblyExceptions = useAssemblyExceptions({
    quoteId: props.value
  })

  const getAssemblyLabel = (a:AssemblyInfo) : string | undefined => (!!a.label?.length ? a.label : a.bomDescription);

  return <div key="quoteAssemblyExceptionReview">
    <style>
      {`
      .dialog-quoteAssemblyExceptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-quoteAssemblyExceptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-quoteAssemblyExceptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-quoteAssemblyExceptionLst"
                columns={
                  [ {
                  title: "Assembly Exception",
                  render: (ae:QuoteAssemblyException) => <>
                    <div style={{fontWeight: 600}}>{getAssemblyLabel(ae.assembly)}, <span style={{whiteSpace:"nowrap"}}>{ae.assembly.bom}</span></div>
                    <div >{ae.reason} by {Utils.formatUsername(ae.createdBy)} on {dayjs(ae.createdAt).format("MM/DD/YYYY")}</div>
                  </>
                },
                {
                  title: "Category",
                  render: (ae:QuoteAssemblyException) => Utils.stripSortingPrefix(ae.assembly.categoryName)
                },
                {
                  title: "Type",
                  render: (ae:QuoteAssemblyException) => Utils.snakeCaseToFirstLetterCapitalized(ae.type)
                },
                ]}
      rowKey="id"
      loading={assemblyExceptions?.isLoading}
      dataSource={assemblyExceptions.data}
    />
  </div>;
}

const LayoutWarning = (props:{
  assemblies:AssemblyInfo[] | undefined
}) => {

  const { quote } = useQuoteContext();
  const modelCategoriesAsync = useModelCategories({
    modelId: quote?.model.id
  })
  const modelCategories = modelCategoriesAsync.data
      ?.filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

  const hasCategoryLayout = props.assemblies?.map( asm => asm.category )
  .map( cat => modelCategories?.find( c => c.id === cat?.id ) )
  .some( cat => cat?.tags?.some( t => t === CategoryTags.LayoutReview ) );

  if ( !hasCategoryLayout ) return <></>;

  return <Alert type="error" message={`Dependent categories have changed.  Layout needs to be updated!`} />
}

const LeadTimeWarning = (props:{
  assemblies:AssemblyInfo[] | undefined
}) => {

  const { quote } = useQuoteContext();
  const modelCategoriesAsync = useModelCategories({
    modelId: quote?.model.id
  })
  const modelCategories = modelCategoriesAsync.data
      ?.filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

  const changeCategories = new Set(props.assemblies?.map(a => a.category?.id ) || [] );
  const maxLeadTime = modelCategories?.filter( c => changeCategories.has(c.id) )
    .map( m => m.leadTimeDays )
    .reduce( (acc, v ) => v > acc ? v : acc, 0 ) || 0;
  const daysTillProduction = quote?.productionDate ? dayjs(quote.productionDate).diff(dayjs(), 'day') : 0;

  if ( !quote?.productionDate || maxLeadTime < daysTillProduction ) return <></>;

  return <Alert type="error" message={`${daysTillProduction} days till production. Required Lead Time is ${maxLeadTime} days.`} />
}

const PendingSplitAlert = () => {

  const { quote } = useQuoteContext();

  if ( !quote?.approvalGroups?.length ) return <></>;

  if ( quote?.approvalGroups?.every(ag => ag.approvalStatus === RevisionApprovalStatus.APPROVED) ) return <></>;

  return <Alert
      type={"warning"}
      message={<>
        This quote is part of pending a split change. It depends on the following quotes:
        <ul>
          {quote.approvalGroups
              .flatMap(ag => ag.quoteRevisions)
              .filter( qr => qr.quoteId !== quote.quoteId)
              .map(partner => (
                  <li key={partner.quoteId}>
                    <Link style={{ marginLeft: "5px", marginRight: "5px" }} to={"/configurator/" + encodeURI(partner.quoteId)} target={"_blank"}>
                      {partner.quoteId}
                    </Link>
                  </li>
              ))}
        </ul>
      </>}
  />
}

const PoAmountAlert = (props:{
  approval:Approval | undefined
  poRequestDetail: DocusignPoRequestDetail | undefined
}) => {

  const { approval, poRequestDetail } = props;

  const {quote} = useQuoteContext();

  const pricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  }).data;

  //don't bother if there isn't a PO
  const hasPoDocuments = !!quote?.poNumber?.documents;
  if ( !hasPoDocuments ) return <></>;

  if ( !approval ) return <></>;
  if ( !pricing ) return <></>;

  const isOrderApproval = !!quote?.trucks?.length;
  if ( !isOrderApproval ) return <></>;

  const unitPrice = pricing?.dealerPrice;
  const totalPrice = ( quote?.quantity || 0 ) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;

  if (!isPoAmountMatch ) {
    return <Alert 
      type="warning"
      message={<>
        <div>
        A purchase order amount {Utils.formatMoney(poAmount)} does not match the unit price {Utils.formatMoney(unitPrice)} or total price {Utils.formatMoney(totalPrice)}. 
        </div>
        {poRequestDetail?.requestedAt && <div style={{marginTop: "1rem"}}>
          A purchase order was requested on {dayjs(poRequestDetail?.requestedAt).format("MMM Do YY, HH:mm") } but has not been completed.
        </div>}
      </>}
    />
  }

  return <></>;
}

const MissingPoAlert = (props:{
  approval:Approval | undefined
  poRequestDetail: DocusignPoRequestDetail | undefined
}) => {
  const { approval, poRequestDetail } = props;

  const configurator = useContext(ConfiguratorContext);
  const {quote } = useQuoteContext();
  const isDocusignEnabled = configurator.hasFeature(FEATURE_DOCUSIGN);

  const releaseRoles = new Set(Object.values(ReleaseEngineeringRoles).map(r => r.toString()));
  const isReleaseEngineeringView = !!(approval && approval.approvalStep.approvers.some( a => releaseRoles.has(a) ) );

  const isSalesView = approval && !isReleaseEngineeringView;
  const isOrderApproval = !!quote?.trucks?.length;

  const showPoWarnings = (isSalesView && isOrderApproval);
  if ( !showPoWarnings ) return <></>;

  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  if ( hasPoDocuments ) return <></>;

  if ( !poRequestDetail?.requestedAt ) {
    return <Alert 
      type="warning"
      message={<>
        This order is missing a Purchase Order. 
        {isDocusignEnabled &&
        <RequestPoModalButton type="text"><span style={{textDecoration: "underline"}}>Request Purchase Order</span></RequestPoModalButton> }
      </>}
    />
  }
  
  return <Alert 
    type="warning"
    message={<>
      A purchase order was requested on {dayjs(poRequestDetail?.requestedAt).format("MMM Do YY, HH:mm") } but has not been completed.
    </>}
  />
 
}

export default ApprovalDetailPage;

