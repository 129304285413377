import styles from "./category_view.module.css";
import "github-markdown-css/github-markdown.css"
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col, Descriptions,
  Dropdown,
  Form,
  FormListFieldData, Image,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Result,
  Row,
  Select, Space,
  Spin,
  Tabs,
  Upload, UploadProps,
} from "antd";
import Title from "antd/lib/typography/Title";
import {CSSProperties, ReactElement, useContext, useEffect, useState} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  MenuOutlined,
  InboxOutlined,
  UploadOutlined,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ConfiguratorContext } from "../context";
import {
  NON_DISCOUNTED_TYPE,
  Permission,
  DEFAULT_CATEGORY_ID,
  User,
  CategoryMetadata,
  EngineeringTeamInfo,
  CategoryTags,
  MISSING_IMAGE
} from "../api/models";
import {useForm} from "antd/lib/form/Form";
import {useIntl} from "react-intl";
import {StringParam, useQueryParam} from "use-query-params";
import DealerMultipleSelector from "../components/DealerMultipleSelector";
import {closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import BMReadOnly from "../components/BMReadOnly";
import SelectEngineeringTeamsButtonModal from "../components/Quote/SelectEngineeringTeamsButtonModal";
import UserSingleSelector from "../components/widgets/UserSingleSelector";
import { UpdateCategoryRequest } from "../api";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import useCategory from "../swr/useCategory";
import useOperations from "../swr/useOperations";
import Utils from "../util/util";

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}
enum FIELD_TYPES  {
  TEXT = "text", 
  BOOL = "bool", 
  NUMERIC = "numeric", 
  DECIMAL = "decimal", 
  SELECT = "select"
};

interface AssemblyImportFailure {
  id: number
  message: string
}

interface CategoryFormValues {
  metadata: CategoryMetadata[];
  allowMultiple: boolean;
  name: string
  sortPrefix: string
  dependencyRules?: string; //CategoryRuleConfig[]
  configuratorSection: string
  quoteSection?: string;
  nonDiscounted: boolean;
  markup?: number;
  nonDiscountedType?: NON_DISCOUNTED_TYPE;
  operationId: string | undefined;
  excludeERPExport: boolean;
  hidden?: boolean;
  authorizedCustomers?: string[];
  stock: boolean
  leadTimeDays: number
  applyDesignProcurementTime?: boolean;
  designTimeWeeks: number
  procurementTimeWeeks: number
  engineeringTeam?:EngineeringTeamInfo | undefined
  primaryEngineer?:User | undefined
  bomLocation?:boolean | undefined
  tags?:string[] | undefined
  notes?: string;
  fullImageUrl?: string;
  thumbnailImageUrl?: string;
}


const CategoryView = () => {
  const params = useParams<Record<string,string>>();
  const categoryId = Number(params.id);
  const configurator = useContext(ConfiguratorContext);
  const [exportProgress, setExportProgress] = useState<number|undefined>();
  const [uploadFile, setUploadFile] = useState<{status:string, response?:AssemblyImportFailure[]}>({status: UPLOADING_STATUS.INIT});
  const [tabKeyParam, setTabKey] = useQueryParam<string | undefined | null >("tab", StringParam);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const history = useHistory();
  const intl = useIntl();

  const [form] = useForm();

  //todo - remove this when dependencies or shouldUpdate works
  const metadata = Form.useWatch("metadata", form);
  const hidden = Form.useWatch("hidden", form);
  const nonDiscounted = Form.useWatch("nonDiscounted", form);
  const applyDesignAndProcurementTime = Form.useWatch("applyDesignProcurementTime", form);

  const categoryAsync = useCategory({id:categoryId});
  const category = categoryAsync.data;

  const operationLstAsync = useOperations();
  const operationLst = operationLstAsync.data;

  useEffect(() => {
      form.resetFields();
  }, [category]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (fields:FormListFieldData[], move: (from: number, to: number) => void ) => {

    return (event:DragEndEvent) => {
      const {active, over} = event;

      //do nothing if over is null/undefined
      if ( !over ) return;

      //if same spot do nothing
      if (active.id === over.id) return;

      const oldIndex = fields.findIndex(f => f.key == active.id);
      const newIndex = fields.findIndex(f => f.key == over.id);
      move( oldIndex, newIndex );
    }
  }


  //todo - this is dependent on the database instance, remove DEFAULT_CATEGORY_ID
  //don't allow modifications to Default category
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE) && categoryId !== DEFAULT_CATEGORY_ID;

  const onSaveForm = async (values:CategoryFormValues) => {
    if ( !category ) return;

    const { primaryEngineer, engineeringTeam, ...v } = values;

    const prefix = ("000" + v.sortPrefix).slice( -3 );
    const name = [prefix, v.name].filter( v=>v ).join("-");

    const dto:UpdateCategoryRequest = {
      ...v,
      primaryEngineerId: primaryEngineer?.id,
      engineeringTeamId: engineeringTeam?.id,
      name,
    }

    //update sort order on save
    dto.metadata.forEach( (md, ndx) => {
      md.sortOrder = ndx;
    });

    dto.isCab = !!values.bomLocation;

    setIsSaving(true);
    try {
      const resp = await configurator.api.updateCategory(category.id, dto );
      categoryAsync.mutate(resp.data);
      notification.success({message:"Saved successfully."});
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Failed to save at this time. " + errorMsg});
    }
    setIsSaving(false);
  };


  const onUploadChange = ({file}) => {
    setUploadFile( file );
    return file.response;
  };
  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;

  const handleExportCsv = () => {
    if ( !category ) return;

    const url = configurator.api.getAssemblyCategoryCSVExportUrl(category.id)
    configurator.api.downloadCsv( url, undefined, setExportProgress )
    .catch((e) => {
      console.log( e );
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error( {message: "Export failed. " + errorMsg } );
    })
    .finally( () => {
      setExportProgress( undefined );
    });
  }

  const handleUpload = () : string  => {
    if (!category) return "";

    return configurator.api.getAssemblyCategoryCSVImportUrl(category.id);
  }

  if ( isNaN( categoryId )  ) {
    return <div className="site-layout-background">
      <Result
        status="error"
        title={"The category requested does not exist." }
        extra={ <Button onClick={history.goBack} type="primary" key="console"> Back </Button>
        }
      />
    </div>
  }

  const dropdownItems = [
    {
      key: 1,
      label:
          <Button icon={<DownloadOutlined />}
                  type="text"
                  onClick={handleExportCsv}
                  loading={exportProgress != undefined}
          >
            Export Assemblies
          </Button> }
  ];

  if ( canWrite ) {
    dropdownItems.push({
      key: 2,
      label:
          <Upload
              name="file"
              action={handleUpload}
              onChange={onUploadChange}
              withCredentials={true}
              showUploadList={false}
          >
            <Button type="text"
                    icon={<UploadOutlined />}
                    loading={isUploading}
                    disabled={!category?.id || !canWrite}
                    title={!canWrite ? "You need permission to upload." : ""}
            >
              Import Assemblies
            </Button>
          </Upload> });
  }

  const isMetadataSelect = (ndx:number) => {
    const key = ["metadata", ndx, "fieldType"];
    const fieldType = form.getFieldValue(key);
    return fieldType === FIELD_TYPES.SELECT
  }

  const isMetadataNew = (ndx:number) => {
    const key = ["metadata", ndx, "id"];
    const id = form.getFieldValue(key);
    return !!id;
  }

  const pageLoading = categoryAsync.isLoading ||  operationLstAsync.isLoading || isSaving;
  return <div className="site-layout-background">
    <Space direction={"vertical"}>
      <div>
        <Title level={2}>View Category</Title>
      </div>
      <div>
        <Space>
          <Dropdown trigger={["click"]}
                    menu={{items:dropdownItems}}
          >
            <Button type="primary"
                    icon={<MoreOutlined />}
            >Options</Button>
          </Dropdown>

          <Button type="primary"
                  onClick={()=>form.submit()}
                  loading={categoryAsync.isLoading}
                  disabled={!canWrite}
                  title={!canWrite ? "You need permission to save." : ""}
          >
            Save
          </Button>
        </Space>
      </div>
    </Space>
    <div className={styles.categoryView}>
      <Spin spinning={pageLoading} >
        <Form form={form}
              name="category"
              labelCol={{ flex: '13rem' }}
              labelAlign="right"
              labelWrap
              onFinish={onSaveForm}
              disabled={!canWrite}
              initialValues={{
                ...category,
                //sort the metadata according to the sort order
                metadata: category?.metadata.sort((a,b) => (a.sortOrder || 0 ) - (b.sortOrder || 0) ),
                engineeringTeam: category?.engineeringTeam,
                name: Utils.stripSortingPrefixOpt(category?.name),
                sortPrefix: Utils.getSortingPrefixOpt(category?.name) || 999,
              }}
        >
          <Row gutter={15} style={{marginTop: "1rem", width: "100%"}}>
            <Col >
              <UploadImage
                  categoryId={category?.id}
                  thumbnailImageUrl={category?.thumbnailImageUrl}
                  onChange={() => categoryAsync.mutate()}
              />
            </Col>
            <Col>
              <Descriptions
                  size="small"
                  column={1}
                  className={"bm-descriptions"}
                  labelStyle={{width: "10rem", textAlign: "right", display: "block" }}
                  items={[
                    {
                      key: 'categoryId',
                      label: 'Id',
                      children: category?.categoryId
                    },
                    {
                      key: 'sortPrefix',
                      label: 'Sort Order',
                      children:
                          <Form.Item
                              style={{width: "25rem", margin: 0}}
                              name="sortPrefix"
                          >
                            <InputNumber />
                          </Form.Item>
                    },
                    {
                      key: 'categoryName',
                      label: 'Name',
                      children:
                          <Form.Item
                              style={{width: "25rem", margin: 0}}
                              rules={[{ required: true, message: "Name is required" }]}
                              name="name"
                          >
                            <Input/>
                          </Form.Item>
                    },
                    {
                      key:"configuratorSection",
                      label:"Configurator Section",
                      children:
                          <Form.Item
                              style={{width: "25rem", margin: 0}}
                              rules={[{ required: true, message: "Section is required" }]}
                              name="configuratorSection"
                          >
                            <Input/>
                          </Form.Item>
                    },
                    {
                      key:"quoteSection",
                      label:"Quote Section",
                      children:
                          <Form.Item
                              style={{width: "25rem", margin: 0}}
                              name="quoteSection"
                          >
                            <Input/>
                          </Form.Item>
                    },
                  ]}
              >
              </Descriptions>
            </Col>
          </Row>

          <Tabs defaultActiveKey={tabKeyParam || undefined}
                onChange={setTabKey}>
            <Tabs.TabPane tab="Info" key="info" forceRender>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                      label="Markup"
                      name="markup"
                      style={!nonDiscounted ? {display: 'none'} : {}}
                  >
                    <InputNumber formatter={(value) => `${value}%`.replace('-', '')} parser={(value) => value!.replace('%', '')} style={{width: "100%"}}/>
                  </Form.Item>
                  <Form.Item
                      label="Non-discounted Type"
                      name="nonDiscountedType"
                      style={!nonDiscounted ? {display: 'none'} : {}}
                  >
                    <Radio.Group disabled={!nonDiscounted}>
                      <Radio value={NON_DISCOUNTED_TYPE.NET} style={{marginBottom: ".5rem"}}>Net Price</Radio>
                      <Radio value={NON_DISCOUNTED_TYPE.POST_NET}>Post Net Price</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                      name="operationId"
                      label="Operation"
                  >
                    <Select
                        showSearch
                        optionFilterProp="label"
                        options={operationLst?.map(o => ({label:o.operationId, value:o.operationId }))}
                    />
                  </Form.Item>

                  <Form.Item
                      label="Authorized Dealer"
                      name="authorizedCustomers"
                  >
                    <DealerMultipleSelector disabled={!hidden}/>
                  </Form.Item>

                  {/*
                      //todo
                    <div>Standard Assembly!</div>
                    */}
                  <Form.Item
                      label="Lead Time (Days)"
                      name="leadTimeDays"
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>

                  {/*
                      //todo
                    <div>Custom Options and Missing Selections!</div>
                    */}
                  <Form.Item
                      label="Design Time (Weeks)"
                      name="designTimeWeeks"
                  >
                    <InputNumber style={{ width: "100%" }} disabled={!applyDesignAndProcurementTime}/>
                  </Form.Item>
                  <Form.Item
                      label="Procurement Time (Weeks)"
                      name="procurementTimeWeeks"
                  >
                    <InputNumber style={{ width: "100%" }} disabled={!applyDesignAndProcurementTime}/>
                  </Form.Item>
                  <Form.Item
                      label="Primary Engineer"
                      name="primaryEngineer"
                  >
                    <UserSingleSelector />
                  </Form.Item>
                  <Form.Item
                      label="Engineering Team"
                      name="engineeringTeam"
                  >
                    <SelectEngineeringTeamsButtonModal category={category} />
                  </Form.Item>

                  <Form.Item
                      label="Tags"
                      name="tags"
                  >
                    <Select mode="tags"
                            options={Object.values(CategoryTags).map(t => ({layout: t, value:t}))}
                    />
                  </Form.Item>

                  <Form.Item
                      label="Notes"
                      name="notes"
                  >
                    <Input.TextArea rows={4} style={{fontFamily: "monospace"}}/>
                  </Form.Item>
                  <Form.Item
                      label={"Preview"}
                      dependencies={["notes"]}>
                    {(f) => <div className={"markdown-body"}><Markdown remarkPlugins={[remarkGfm]}>{form.getFieldValue("notes")}</Markdown></div> }
                  </Form.Item>

                </Col>
                <Col span={12}>
                  <Form.Item
                      valuePropName="checked"
                      label="Allow multiple"
                      name="allowMultiple"
                      labelCol={{span: 15}}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                      valuePropName="checked"
                      label="Non-discounted"
                      name="nonDiscounted"
                      labelCol={{span: 15}}
                  >
                    <Checkbox/>
                  </Form.Item>
                  <Form.Item
                      valuePropName="checked"
                      label="Exclude from ERP export?"
                      name="excludeERPExport"
                      labelCol={{span: 15}}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                      label="Apply Design/Procurement Time"
                      name="applyDesignProcurementTime"
                      valuePropName="checked"
                      labelCol={{span: 15}}
                  >
                    <Checkbox/>
                  </Form.Item>
                  <Form.Item
                      label="Cab?"
                      name="bomLocation"
                      valuePropName="checked"
                      labelCol={{span: 15}}
                  >
                    <Checkbox/>
                  </Form.Item>

                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Metadata" key="metadata" forceRender>
              <Form.List name="metadata" >
                {(fields, { add, remove, move }) => <>
                  <Title level={4}>
                    Metadata
                    <PlusCircleOutlined onClick={(_e) => add({}, 0 )} style={{paddingLeft: ".5rem"}}  />
                  </Title>
                  <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd(fields, move)}
                  >
                    <SortableContext
                        items={fields.map(f => f.key.toString())}
                        strategy={verticalListSortingStrategy}
                    >
                      {fields.map((f, fieldNdx) => <SortableItem key={f.key.toString()} id={f.key.toString()} remove={() => remove(fieldNdx)}>
                        <>
                          <Form.Item
                              name={[f.name, "id"]}
                              hidden={true}
                          >
                            <Input  style={{ width: 300 }} />
                          </Form.Item>

                          <Form.Item
                              name={[f.name, "sortOrder"]}
                              hidden={true}
                          >
                            <Input  style={{ width: 300 }} />
                          </Form.Item>

                          <Form.Item
                              rules={[{ required: true, message: "Name is required" }]}
                              label="Metadata Name"
                              name={[f.name, "name"]}
                          >
                            <BMReadOnly readOnly={isMetadataNew(f.name)} >
                              <Input  style={{ width: 300 }} />
                            </BMReadOnly>
                          </Form.Item>
                          <Form.Item
                              label="Field Type"
                              name={[f.name, "fieldType"]}
                              rules={[{ required: true, message: "Field Type is required" }]}
                          >
                            <BMReadOnly readOnly={isMetadataNew(f.name)} >
                              <Select
                                  style={{ width: 300 }}
                                  optionFilterProp="label"
                                  options={Object.values(FIELD_TYPES)?.map(fd => ({label:fd, value:fd }))}
                              />
                            </BMReadOnly>
                          </Form.Item>
                          <Form.Item shouldUpdate
                                     label="Options"
                                     name={[f.name, "selectOptions"]}
                                     hidden={!isMetadataSelect(f.name)}
                          >
                            <Select
                                style={{ width: 300 }}
                                optionFilterProp="label"
                                mode="tags" />
                          </Form.Item>
                          <Form.Item
                              valuePropName="checked"
                              label="Visible in Configurator"
                              name={[f.name, "visibleInConfigurator"]}
                          >
                            <Checkbox />
                          </Form.Item>
                        </>
                      </SortableItem>)}
                    </SortableContext>
                  </DndContext>

                </>}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Rules" key="rules" forceRender>
              <Title level={3}>Category Rules </Title>
              <Alert type="info" message={<>The rules have been moved <Link to="/rule-sets">here</Link></>}/>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Spin>
    </div>

  <Modal
      open={uploadFile.status == UPLOADING_STATUS.DONE || uploadFile.status === UPLOADING_STATUS.ERROR}
      onOk={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      onCancel={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      cancelButtonProps={{style :{display : 'none'}}}
      style={{minWidth: "40rem"}}
  >
    <Result
        status={uploadFile.status == UPLOADING_STATUS.DONE ? "success" : "error"}
        title={uploadFile.status == UPLOADING_STATUS.DONE ? "Upload Successful" : "Error"}
        subTitle={ !!uploadFile?.response?.length && <div style={{textAlign: "left"}}>
          <div>The following records had errors:</div>
          <ul>
            {uploadFile?.response?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
          </ul>
        </div> }
    />
  </Modal>
</div>
};

export const SortableItem = (props:{
  id:string
  children:ReactElement
  remove: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}) => {

  const {id, children, remove} = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card ref={setNodeRef} style={{...style, marginBottom: "1rem", width:"40rem"}} {...attributes}>
      <Row justify={"space-between"} style={{width: "35rem"}}>
        <Col>
          <span className="drag-handle" {...listeners} style={{ cursor: "grab" }}>
            <MenuOutlined />
          </span>
        </Col>
        <Col><DeleteOutlined onClick={remove} /></Col>
      </Row>
      <div>{children}</div>
    </Card>
  );
}


interface UploadImageResponse {
  status:string | undefined,
  response?:{
    thumbnailUrl:string
    fullSizeUrl:string
  }
}
const UploadImage = ( props:{
  style?: CSSProperties
  categoryId: number | undefined
  thumbnailImageUrl: string | undefined
  onChange?: () => void
} ) => {
  const { categoryId, thumbnailImageUrl } = props;

  const configurator = useContext(ConfiguratorContext);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);

  const [uploadFile, setUploadFile] = useState<UploadImageResponse>({status: UPLOADING_STATUS.INIT});

  useEffect(()=> {
    if( uploadFile.status == UPLOADING_STATUS.DONE ) {
      notification.success( {message: "Upload complete."} );
      props.onChange?.();
    }
    else if( uploadFile.status == UPLOADING_STATUS.ERROR ) {
      notification.error(  {message: "There was an error uploading the image."});
    }

  }, [ uploadFile.status] )


  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;
  const uploadImageUrl = categoryId ? configurator.api.baseUrl + "/v1/assembly/categories/" + encodeURIComponent(categoryId) + "/image" : "";
  const onUploadChange: UploadProps['onChange'] = ({file}) => {
    setUploadFile( {status: file.status, response: file.response } );
  };

  return <Upload.Dragger
      name="image"
      action={uploadImageUrl}
      withCredentials={true}
      showUploadList={false}
      accept=".jpg,.jpeg,.png,.gif,.tiff"
      style={props.style}
      maxCount={1}
      onChange={onUploadChange}
      disabled={!canWrite}
  >
    <Spin spinning={isUploading}>
      {thumbnailImageUrl
          ? <Image
              src={ uploadFile.response?.thumbnailUrl || thumbnailImageUrl || MISSING_IMAGE }
              fallback={MISSING_IMAGE}
              style={{paddingBottom: ".5rem"}}
              width={200}
              preview={false}
          />
          :<p className="ant-upload-drag-icon">
            <InboxOutlined  />
          </p>

      }
    </Spin>
    <p className="ant-upload-text" style={{fontSize:"small"}}>{!canWrite ? "You need permission to upload image" : "Click or drag file to upload image"}</p>
  </Upload.Dragger>

}


export default CategoryView;

