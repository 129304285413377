import {
  Button,
  ButtonProps,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Dropdown,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Switch,
  Timeline,
  Tooltip
} from "antd";
import dayjs from 'dayjs'
import {ReactNode, useContext, useState} from "react";
import {
  ApprovalRequest,
  AssemblyInfo,
  ParentQuoteHistoryDto,
  Quote,
  QuoteRevisionHistory,
  RevisionApprovalStatus,
  RevisionType
} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import QuoteRevisionDiffTable from "../Table/QuoteRevisionDiffTable";
import {Utils} from "../../util/util";
import Title from "antd/lib/typography/Title";
import {Link} from "react-router-dom";
import WorkflowProgress from "../WorkflowProgress";
import {CopyOutlined, ExportOutlined, MoreOutlined, PartitionOutlined} from "@ant-design/icons";
import {useIntl} from "react-intl";
import {useQuoteContext} from "../../contexts/QuoteContext";
import BMButton from "../BMButton";
import {MenuItemType} from "antd/es/menu/interface";
import useQuoteHistory from "../../swr/useQuoteHistory";
import {DescriptionsItemType} from "antd/es/descriptions";

const QuoteHistoryTab = (props: {
  tabKey?: string;
}) => {
  const configurator = useContext(ConfiguratorContext);

  const { quote } = useQuoteContext();

  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();
  const [filter, setFilter] = useState<string>();
  const [showAbandoned, setShowAbandoned] = useState<boolean>(false);
  const [historyActiveKey, setHistoryActiveKey] = useState<string[]>();

  const history = useQuoteHistory({
    quoteId: quote?.quoteId,
    showAbandoned
  });

  const EventTitle = (props:{ change: QuoteRevisionHistory, idx: number}) => {
    if (!quote) return <></>;

    const {change, idx} = props;

    const firstApproval = change.firstApproval;
    const items:DescriptionsItemType[] = [
      {
        key: "requestedBy",
        label: "Submitted By",
        children: Utils.formatUsername(firstApproval?.submittedBy || change.revision.createdBy)
      },
      {
        key: "requestedAt",
        label: "Submitted At",
        children: (firstApproval?.createdAt || change.revision.createdAt)
            ? dayjs( firstApproval?.createdAt || change.revision.createdAt  ).format("M/DD/YY, h:mm a") : ''
      },
      {
        key: "epicorRev",
        label: "Epicor Rev",
        children: change.revision.erpRevisionNum ? String(change.revision.revisionString) : "-",
      },
    ];

    if ( change.revision.parent ) {
      items.push({
        key: "priceChange",
        label: "Total Price Change",
        children: getPriceDiffStr(change),
      });
    }

    const id = (!!change.revision.reservation) ? "RESERVATION"
        : !!change.latestApproval?.approvalType ? `approvalType.${change.latestApproval.approvalType}`
        : "approvalType.QUOTE_APPROVAL";

    const approvalType = id ? intl.formatMessage({id}) : undefined;

    return <div style={{marginBottom: "-2rem", marginTop: "-.7rem"}} key={`${idx}-panel`}>
      Revision {[change.revision.revision, approvalType, change.revision.abandoned ? "Abandoned" : undefined].filter(v=>v).join(" - ")}
      &nbsp;
      <Link to={`/configurator/${encodeURI(quote?.quoteId)}?rev=${change.revision.revision}`} target={"_blank"} >
        <ExportOutlined />
      </Link>
      <Descriptions
          layout="horizontal"
          items={items}
          style={{marginBottom: "1rem"}}
          column={5}
      />
    </div>
  }

  const getPriceDiffStr = (change: QuoteRevisionHistory): string => {

    if (change.revision.revisionType === RevisionType.ENGINEERING_CHANGE) return "Price Protected";

    const priceDiff = change?.diff?.priceDiff;
    if (!priceDiff?.before || !priceDiff?.after) return "NA";
    const oldValue = priceDiff?.before.replace(/,/g, '');
    const newValue = priceDiff?.after.replace(/,/g, '');
    return Utils.formatMoneyWithSign(Number(newValue) - Number(oldValue));
  };

  const hidePrice = (change: QuoteRevisionHistory): boolean => {
    return change?.revision.revisionType === RevisionType.ENGINEERING_CHANGE || configurator.isDealerSales();
  }

  const getHistoryItem = (props: {
    key: React.Key
    change: QuoteRevisionHistory,
    idx: number,
    filter?: string | undefined
  }) => {

    const { change, idx, filter } = props;

    const approvalStatus = change.revision.revisionApprovalStatus;

    const isSplit = !!change.approvalGroups?.length;
    const isCopy = !isSplit && !!change.revision.parent && change.revision.parent.id !== quote?.id

    const panelStyle: React.CSSProperties = {
      paddingTop: ".4rem",
      paddingBottom: ".4rem",
      backgroundColor: "rgba(255,255,255)",
      border: "2px solid #1890ff",
      borderRadius: "8px",
      marginBottom: ".6rem"
    };

   return {
       key: props.key,
        label: <EventTitle change={change} idx={idx} />,
        style: panelStyle,
        children: <>
          {!!change.revision?.changeSummary?.length &&
              <div key="changeSummary">
                <Title level={5}>{`${change.latestApproval?.approvalType === ApprovalRequest.CANCEL_ORDER ? "Cancellation Message" : "Change Summary" }:` }</Title>
                <p>{change.revision?.changeSummary}</p>
              </div>}

          {(!change.approvals?.length && approvalStatus === RevisionApprovalStatus.DRAFT) && <p style={{color: 'blue', fontWeight: "bold"}}>{Utils.snakeCaseToFirstLetterCapitalized(approvalStatus)}</p>}

          { isSplit &&
              <Title level={5}>The other quotes in this split change:
                {change.approvalGroups
                  ?.flatMap( ag => ag.quoteRevisions )
                  .filter(qr => qr.quoteId !== change.revision.quoteId )
                  .map(qr =>
                      (<Link style={{marginLeft:"5px", marginRight: "5px"}} to={`/configurator/${encodeURI(qr.quoteId)}`} target="_blank">{qr.quoteId}</Link>))}
              </Title>}

          {isCopy &&
              <Title level={5}>
                This was copied from
                <Link style={{marginLeft:"5px", marginRight: "5px"}} to={`/configurator/${encodeURI(change.revision.parent.quoteId)}?rev=${change.revision.parent.revision})`} target="_blank">{change.revision.parent.quoteId}</Link>
              </Title>}

          <WorkflowProgress
              approvals={change.approvals}
              screenChangeWidthPx={1300}
              style={{paddingLeft: ".5rem", paddingRight: ".5rem"}}
          />
          <br/>
          {change.diff && <QuoteRevisionDiffTable diff={change.diff} hidePriceChange={hidePrice(change)} hideCustomOption={!filter} hideSummaryPrice={true} />}
        </>
      };

  }

  const includesFilter = (text: string | undefined, filter: string) =>
    !!text?.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  
  const assembliesIncludeFilter = (assemblies: AssemblyInfo[] | undefined, filter: string) => 
    assemblies?.some(a => includesFilter(a.label, filter) || includesFilter(a.bomDescription, filter) || includesFilter(a.bom, filter));
  
  const filtering = (h: QuoteRevisionHistory) => {
    if (!filter) return true;
  
    const normalizedFilter = filter.replace(/ /g, "_").toLocaleLowerCase();
  
    return (
      includesFilter(h.revision.revisionType, normalizedFilter) ||
      includesFilter(h.revision.changeSummary, filter) ||
      assembliesIncludeFilter(h.diff?.assembliesDiff?.addedAssemblies, filter) ||
      assembliesIncludeFilter(h.diff?.assembliesDiff?.removedAssemblies, filter)
    );
  };

  const handleClickHistory = (key:string[]) => {
    setHistoryActiveKey(key);
  }
  const handleExpandAllHistory = (e) => {
      const key = e
          ? history.data
              ?.filter(h => filtering(h))
              .map(change => "history-" + change.revision.id)
          : [];

    setHistoryActiveKey(key);
  }

  return <>
      <Space direction={"vertical"}>
      {isAdminOrEngineeringOrSalesDesk && <Row justify={"space-between"}>
        <Col>
          {!!history.data?.length &&
            <Input allowClear key={"search-area"} style={{width: "350px"}} placeholder="Search change type, assembly, change summary..." onChange={(event) => {setFilter(event.target.value)}}/>
          }
        </Col>
        <Col>
          {!!history.data?.length && <Tooltip title="Expand All History Panels">
            <Switch checkedChildren="Expanded"
                    unCheckedChildren="Collapsed"
                    style={{marginRight: "250px"}}
                    onChange={handleExpandAllHistory}
            />
          </Tooltip>}
        </Col>
        <Col>
          <OptionsDropdown
              quote={quote}
              isAdminOrEngineeringOrSalesDesk={isAdminOrEngineeringOrSalesDesk}
              showAbandonedRevisions={showAbandoned}
              handleShowAbandonedRevisions={setShowAbandoned}
          />
        </Col>
      </Row>}
      <Spin spinning={history.isLoading}>
        <Collapse
            style={{
              marginBottom: ".6rem",
              backgroundColor: "rgba(250,250,250)",
              border: "none",
            }}
            activeKey={historyActiveKey}
            onChange={handleClickHistory}
            className="history-collapse"
            bordered={false}
            items={history.data
                ?.filter(h => filtering(h))
                .map((change: QuoteRevisionHistory, idx: number) => getHistoryItem({
                  key: "history-" + change.revision.id,
                  change, idx, filter
                }))}
        />
      </Spin>
      </Space>
    </>;
}

const OptionsDropdown = (props: {
  quote: Quote | undefined
  isAdminOrEngineeringOrSalesDesk: boolean
  showAbandonedRevisions: boolean
  handleShowAbandonedRevisions: (b:boolean) => void
}) => {
  const items : MenuItemType[] = [
    { key:"showAbandoned",
      label: <div style={{textAlign: "center"}}>
        <label >
          <Checkbox
              {...props}
              checked={props.showAbandonedRevisions}
              onChange={(e) => props.handleShowAbandonedRevisions(e.target.checked)}
              style={{padding:0}}
          />
          <span style={{marginLeft: "0.5rem"}}>Show Abandoned Revisions</span>
        </label>
      </div>
    },
  ];

  if ( props.isAdminOrEngineeringOrSalesDesk) {
    items.unshift( { key:"parentQuoteHistory",
      label: <ParentQuoteHistory quote={props.quote} type="text" className={"ghostBmButton"} />
    });
  }
  return <Dropdown
      trigger={["click"]}
      menu={{items:items}}
  >
    {/* this div is to avoid a warning with strict mode */}
    <div>
      <BMButton
          type="primary"
          icon={<MoreOutlined/>}
          data-testid="create-change-dropdown"
      >Options</BMButton>
    </div>
  </Dropdown>

}

const ParentQuoteHistory = (props: ButtonProps & {quote: Quote | undefined  }) => {

  const {quote, ...buttonProps} = props;
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState<boolean>(false);
  const [quoteHistory, setQuoteHistory] = useState<ParentQuoteHistoryDto[]>([]);
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();

  const getParentHistory = async () => {
    if (!quote?.id) return;
    setOpen(true);
    try {
      const resp = await configurator.api.getParentQuotes(quote.id);
      setQuoteHistory(resp.data);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get parent history " + errorMsg });
    }
  }

  const getItemTitle = (qh: ParentQuoteHistoryDto): ReactNode => {
    const quoteName = qh.quoteName + `, ${qh.quoteId} (Rev: ${qh.rev})`;
    const createdAt = qh.createdAt ? dayjs(qh.createdAt).format("MMM Do YYYY, hh:mm") : "";
    const quoteOwner = `At: ${createdAt}, By: ${qh.createdBy || ''}`
    return (
      <div key={qh.id + "-his"}>
        {isAdminOrEngineeringOrSalesDesk ? 
          <Link to={"/configurator/" + encodeURI(qh.quoteId)} target="_blank">
            {quoteName}
          </Link>
          :
          <div key={qh.id + "-child-detail"} style={{marginBottom: "2rem"}}>{quoteName}</div>
        }
        <div key={qh.id + "-child-owner"} style={{marginBottom: "2rem"}}>{quoteOwner}</div>
      </div>
      )
  }


  return (<>
      <Button {...buttonProps} onClick={getParentHistory}>Get Parent History</Button>
      <Modal
        title="Quote Parent History"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        {!!quoteHistory.length ? 
        <Timeline
          mode="left"
          style={{marginTop: "4rem", marginLeft: "-24rem"}}
          items={
            quoteHistory.map(qh => {return {
              key: qh.id,
              color: qh.type === "copy" ? "blue" : "green",
              label: qh.type.toLocaleUpperCase(),
              children: getItemTitle(qh),
              dot: qh.type === "copy" ? <CopyOutlined style={{fontSize: "20px"}}/> : <PartitionOutlined style={{fontSize: "20px"}}/>
            }})
          }
        />
      : <span>There's no parent history to this quote.</span>
      }
      </Modal>
  </>);
}

export default QuoteHistoryTab;

