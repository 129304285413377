import {Button, Form, Input, InputNumber, Modal, notification, Select, Space} from "antd";
import BMButton, {BMButtonProps} from "../BMButton";
import {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {PricingOption} from "../../api/models";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";

export const PricingOptionCustomOption = "Custom";
type PricingOptionFormValues = PricingOption & {
  customLabel: string | undefined
};

const defaultPricingOption = {
  key: crypto.randomUUID()
} as PricingOptionFormValues;


const PricingOptionButtonModal = (props: Omit<BMButtonProps, "value" | "onChange"> & {
  value?:PricingOption
  onChange?: (v: PricingOption | undefined) => void
  title: string,
  optionsLst: string[]
  allowNegative?: boolean
}) => {

  const { id, value, onChange, title, optionsLst, allowNegative, ...btnProps } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const optionLabel = Form.useWatch('label', form);
  const [initialValues, setInitialValues] = useState<PricingOptionFormValues>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues])

  const predefinedOptions = props.optionsLst;

  const handleCancelBtn = () => {
    setIsOpen(false);
  }
  const handleDeleteBtn = () => {
    setIsOpen(false);

    props.onChange?.(undefined);

  }
  const handleSaveBtn = async () => {
      try {
          const values = await form.validateFields() as PricingOptionFormValues;
          setIsOpen(false);

          const option = {
              ...values,
              label: values.label === PricingOptionCustomOption ? values.customLabel : values.label,
          }
          props.onChange?.(option);
      }
      catch(e:any) {
          //already visible in the form
      }
  }

  const handleOpen = () => {
    setInitialValues( {
      ...defaultPricingOption, 
      ...props.value
    });
  }

    const validateValue = async (rule: any, value: string, cb: any) => {
        const val = Number( value );
        if ( isNaN(val) ) {
            throw new Error("Invalid number");
        }

        if ( allowNegative ?? true ) return true;

        if ( val < 0 ) {
            throw new Error("Only positive values are allowed.")
        }
    };

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black", ...props.style}
    : {borderBottom: "1px solid black", ...props.style};

  const btnLbl =  (props.value) ? props.value.label
    : "Add " + props.title;

  const modalTitle = `${ (props.value) ? "Edit" : "Add" } ${props.title}`

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      {...btnProps}
      title={btnLbl}
      onClick={() => setIsOpen(true)} 
      style={{...props.style, padding: "0"}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <Modal 
      title={modalTitle}
      open={isOpen} 
      onOk={handleSaveBtn} 
      onCancel={handleCancelBtn}
      footer={<div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="cancelBtn" onClick={handleCancelBtn}>Cancel</Button>
          <Button key="doneBtn" onClick={handleSaveBtn} type="primary">Done</Button>
        </Space>
        {!!props.value && <Button key="deleteBtn" className="deleteBtn" onClick={handleDeleteBtn}>Remove</Button>}
      </div>}
      afterOpenChange={(open) => {
        if( open ) {
          handleOpen();
        }
      }}

    >

      <Form 
        form={form} labelCol={{span:5}}
        initialValues={initialValues}
      >
          <Form.Item name="id" 
            label="id"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item name="key" 
            label="key"
            hidden={true}
          >
            <Input />
          </Form.Item>

          <Form.Item name="label" label="Option" colon rules={[{ required: true}]}>
            <Select placeholder="Select a predefined option or Custom" 
              optionFilterProp="label"
              options={predefinedOptions?.map(d => ({ label: d, value:d }))}
            />
          </Form.Item>

          <Form.Item name="customLabel" label=" " 
            colon={false} className="customLabel" 
            rules={[{ required: (optionLabel === PricingOptionCustomOption)}]}
            hidden={ optionLabel !== PricingOptionCustomOption}
          >
            <Input type="text" placeholder="Type a description" ></Input>
          </Form.Item>

          <Form.Item name="value" label="Value" colon
                     rules={[{
                         required: true,
                         validator: validateValue
                     }]}>
            <InputNumber
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value!.replace(/\$\s?|(,*)/g, '')}
              placeholder="Type dollar amount" style={{width: "calc(100% - 120px)"}}
              controls={false}
            />
          </Form.Item>

          <Form.Item name="notes" label="Notes" colon >
            <TextArea placeholder="Provide any helpful information." rows={2} />
          </Form.Item>

      </Form>

    </Modal>

  </>;
}

export default PricingOptionButtonModal;

