import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {ComputeOptionsRequest,} from '../api';
import {
  AXIOS_CANCEL_MSG,
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface SelectAssemblyPriceDifferenceProps {
  assemblyId:number | undefined,
  ctx:ComputeOptionsRequest
}

export const useSelectAssemblyPriceDifference = (props: SelectAssemblyPriceDifferenceProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:SelectAssemblyPriceDifferenceProps  ) : Promise<number | undefined> => {

    const { assemblyId, ctx } = props;
    if (!assemblyId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchSelectAssemblyPriceDifference(assemblyId, ctx, cancelSource.token );

      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'fetchSelectAssemblyPriceDifference',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useSelectAssemblyPriceDifference;

