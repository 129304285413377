import { Checkbox, Col, Divider, Row, Space, Spin, Table } from "antd";
import { ChangeOrderDiff, DealerAdjustment, NonDiscountOption, NewOrOld, RevisionType, MajorTableExcludedDiff, QuoteRevisionDiff, QuoteDiffItem, AssemblyInfo, CustomOptionType } from "../../api/models";
import { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {ColumnType} from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import Utils from "../../util/util";
import { useIntl } from "react-intl";
import { isAssembly } from "../../pages/configurator";
import { Link } from "react-router-dom";


interface TableRow {
  changeItem: string;
  before: string;
  after: string;
  beforePrice?: number | undefined;
  afterPrice?: number | undefined;
  priceChange?: string;
  key: string;
}

interface TableRowForOption {
  removed: Array<AssemblyInfo | CustomOptionType> | undefined;
  added: Array<AssemblyInfo | CustomOptionType> | undefined;
  categoryName: string;
}

const HideCustomOptionContext = createContext(true);
const useHideCustomOption = () => useContext(HideCustomOptionContext);

const PropertiesDiff = (props:{
  diff: QuoteRevisionDiff;
  hidePriceChange?: boolean;
  columnTitles?: string[]
  type?: string
}) => {


  const nonDiscountOptionsEmpty = !( ( props.diff.nonDiscountOptionsDiff?.newNonDiscountOptions || [] ).concat(
    props.diff.nonDiscountOptionsDiff?.oldNonDiscountOptions || [] ).length );

  const dealerAdjustmentsEmpty = !( ( props.diff.dealerAdjustmentsDiff?.newDealerAdjustments || [] ).concat(
    props.diff.dealerAdjustmentsDiff?.oldDealerAdjustments || [] ).length );

  const majorTableNotIncludedDiff = Object.values(MajorTableExcludedDiff).map(key => String(key));
  const includesKeys = Object.keys(props.diff).some(key => !majorTableNotIncludedDiff.includes(key) ) 
  const showTable = includesKeys || !nonDiscountOptionsEmpty || !dealerAdjustmentsEmpty;
  const intl = useIntl();
  const columnTitles = (props.columnTitles?.length || 0 ) > 1 
    ? props.columnTitles 
    : [ "Before", "After" ];

  if (!showTable ) return <></>;

  const columns:ColumnType<TableRow>[] = [
    {
      title: "Change Item",
      dataIndex: 'changeItem',
      key: "changeItem",
      width: '20%',
    },
    {
      title: columnTitles?.[0],
      dataIndex: 'before',
      key: "before",
      width: '30%',
      render: (_, row, _i) => 
        getArrayRender(row, NewOrOld.OLD, props.diff, props.hidePriceChange)
    },
    {
      title: columnTitles?.[1],
      dataIndex: 'after',
      key: "after",
      width: '30%',
      render: (_, row, _i) => 
        getArrayRender(row, NewOrOld.NEW, props.diff, props.hidePriceChange)
    },
  ];

  if (!(props.hidePriceChange ?? false)) {
    columns.push(
      {
        title: "Price Change",
        dataIndex: 'priceChange',
        key: "priceChange",
        width: '20%',
        render: (priceChange, _row, _i) => props.hidePriceChange ? "" : priceChange,
      },
    );
  }

  const getItemName = (str: string) : string => {
    const ret = str.replace(/([A-Z])/g, " $1").replace('Diff', '').trim();
    return ret.charAt(0).toUpperCase() + ret.slice(1);
  }

  const getFormatedDate = (date: string) => {
    return date === "NA" ? date : intl.formatDate(date);
  }

  const getDataSource = () : TableRow[] => {

    let dataSource: TableRow[] = [];
    Object.values(ChangeOrderDiff)
    .filter( p => p !== 'assembliesDiff' )
    .filter( p => p !== 'truckDiff' )
    .filter( p => p in props.diff )
    .forEach( p => {
      let row: TableRow = {changeItem: getItemName(p), before: 'NA', after: 'NA', key: p};
      if (p !== 'nonDiscountOptionsDiff' && p !== 'dealerAdjustmentsDiff') {
        const diffVal = props.diff[ p ] as QuoteDiffItem;

        const priceChangeDiff = Number(diffVal.afterPrice) - Number(diffVal.beforePrice);
        const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);

        row = {...row, 
          // Do not show the hh:mm:ss in production/shipping date
          before : (p === 'productionDateDiff' || p === 'shippingDateDiff') ? getFormatedDate(diffVal.before.split(' ')[0]) : diffVal.before,
          after : (p === 'productionDateDiff' || p === 'shippingDateDiff') ? getFormatedDate(diffVal.after.split(' ')[0]) : diffVal.after,
          beforePrice: diffVal.beforePrice,
          afterPrice: diffVal.afterPrice,
          priceChange,
        };
      }
      else {
        const priceChangeDiff = Number(props.diff[ p ]?.newPrice) - Number(props.diff[ p ]?.oldPrice);
        const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);
        row = {
          ...row,
          priceChange,
        };
      }
      if ( !(p === 'nonDiscountOptionsDiff' && nonDiscountOptionsEmpty) && !(p === 'dealerAdjustmentsDiff' && dealerAdjustmentsEmpty) ) {
        dataSource.push(row);
      }  
    });

    // Doesn't need to show these items when it's SPLIT_ORDER
    if (RevisionType.SPLIT_ORDER === props.diff?.revisionType) {
      dataSource = dataSource.filter(row => {return row.changeItem !== 'Non Discount Options' && row.changeItem !== 'Dealer Adjustments'})
    }

    return dataSource;
  }

  const quoteChangeDataSource = getDataSource();
  if ( !quoteChangeDataSource.length ) return <></>;

  return <div style={{marginBottom: "1rem"}}>
    <Title level={5}>Quote Change</Title>
    <Table
      size="small"
      columns={columns}
      dataSource={quoteChangeDataSource}
      pagination={false}
    />
  </div>
}

export function asAssembly(option: AssemblyInfo | CustomOptionType | undefined) : AssemblyInfo | undefined {
  return isAssembly(option) ? option as AssemblyInfo : undefined;
}
export function asCustomOption(option: AssemblyInfo | CustomOptionType | undefined) : CustomOptionType | undefined {
  return !isAssembly(option) ? option as CustomOptionType : undefined;
}

const OptionsDiff = (props: {
  addedAssemblies?: Array<CustomOptionType | AssemblyInfo>
  removedAssemblies?: Array<CustomOptionType | AssemblyInfo>
  hidePriceChange?: boolean
  columnTitles?: string[]
}) => {
  const { addedAssemblies, removedAssemblies } = props;
  
  const hideCustomOption = useHideCustomOption();

  const [hideCustomBom, setHideCustomBom] = useState<boolean>(hideCustomOption);

  useEffect(() => {
    setHideCustomBom(hideCustomOption);
  }, [hideCustomOption]);

  const columnTitles = (props.columnTitles?.length || 0 ) > 1 
    ? props.columnTitles 
    : [ "Removed", "Added" ];

  const isCustomBom = (a:AssemblyInfo | CustomOptionType | undefined) =>  asAssembly(a)?.bom.startsWith("9999");
  const filterCustomBom = (a:AssemblyInfo | CustomOptionType) => !hideCustomBom || !isCustomBom(a);

  const allAssemblies = ( addedAssemblies || [] ).concat( removedAssemblies || []);
  const hasCustomBom = allAssemblies.some( isCustomBom );

  const addedBySection = _.groupBy(addedAssemblies?.filter( filterCustomBom ), (a) => a.category?.configuratorSection );
  const removedBySection = _.groupBy(removedAssemblies?.filter( filterCustomBom ), (a) => a.category?.configuratorSection );
  //order sections by catgoryName....
  const sectionNames = Object.keys( _.groupBy( allAssemblies.filter( filterCustomBom ).sort( (a,b) => (a.category?.name.toUpperCase() || "").localeCompare( (b.category?.name || "").toUpperCase() ) ), (a) => a.category?.configuratorSection ) );

  if ( allAssemblies.length === 0 ) return <></>;

  const sumPrice = (asms) => {
    if (!asms?.length) return 0;
    return asms?.reduce((sum, item) => {
      const price = item.optionDealerPrice || item.dealerPrice || 0;
      return sum + price;
    }, 0);
  }

  const columnsForOptions:ColumnType<TableRowForOption>[] = [
    {
      title: columnTitles?.[0],
      dataIndex: 'removed',
      key: "removed",
      width: 500,
      onCell: (_record) => ({ 
        style: { verticalAlign: "top" }
      }),
      render: (d) => <ListOptions assemblies={d} added={false} filterCustomBom={filterCustomBom} hidePriceChange={props.hidePriceChange} />

    },
    {
      title: columnTitles?.[1],
      dataIndex: 'added',
      key: "added",
      width: 500,
      onCell: (_record) => ({ 
        style: { verticalAlign: "top" }
      }),
      render: (d) => <ListOptions assemblies={d} added={true} filterCustomBom={filterCustomBom} hidePriceChange={props.hidePriceChange} />
    },
  ];

  if (!props.hidePriceChange) {
    columnsForOptions.push( {
        title: "Price Change",
        key: "priceChange",
        width: '20%',
        render: (d) => {
          const removedPrice = sumPrice(d?.removed);
          const addedPrice = sumPrice(d?.added);

          const priceChangeDiff = addedPrice - removedPrice;
          const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);

          return props.hidePriceChange ? "" : priceChange
        }
      });
  }

  const getData = (section:string) => {
    const addedByCategory =  _.groupBy( addedBySection[ section ], (a) => a.category?.name  );
    const removedByCategory = _.groupBy( removedBySection[ section ], (a) => a.category?.name );
    const categorySet = Array.from( new Set( Object.keys( addedByCategory ).concat( Object.keys( removedByCategory ) ) ) )
      .sort( (a,b) => a.toUpperCase().localeCompare( b.toUpperCase() ) );

    return categorySet.map( c => ({
      categoryName: c,
      added: addedByCategory[c],
      removed: removedByCategory[c]
    }));
  };


  return <>
    <Title level={5}>Assemblies Change</Title>
    {hasCustomBom && <>
      <Checkbox checked={!hideCustomBom} onClick={() => setHideCustomBom((prevState) => { return !prevState; })}>Show Configurator-only BOM</Checkbox>
      <Divider style={{margin: "1rem 0 1rem 0"}}  />
    </>
    }
    {sectionNames.map( section => <div style={{marginBottom: "1.5rem"}} key={"section-" + section + "-category"}>
      <div style={{fontWeight: "500"}} key={"section-" + section + "-name"}>{section}</div>
      <Table
        size="small"
        columns={columnsForOptions}
        dataSource={getData(section)}
        pagination={false}
        rowKey={"categoryName"}
      />
    </div>)}
  </>;

}

const QuoteRevisionDiffTable = (props: {
  diff: QuoteRevisionDiff | undefined
  hidePriceChange?: boolean;
  hideSummaryPrice?: boolean;
  loading?:boolean
  columnTitles?: string[]
  hideCustomOption?: boolean | undefined
  quoteId?: string | undefined
}) => {

  if ( !props.diff ) return <></>;

  return <>
  <HideCustomOptionContext.Provider value={props.hideCustomOption || false}>
    <Spin spinning={!!props.loading}>
      {!!props.quoteId && <Title level={5}><Link to={"/configurator/" + encodeURI(props.quoteId)} target="_blank">{props.quoteId}</Link></Title> }
      <Space direction="vertical" size={"large"} style={{width: "100%"}}>
        <PropertiesDiff diff={props.diff} hidePriceChange={props.hidePriceChange} columnTitles={props.columnTitles} />
        <OptionsDiff 
            hidePriceChange={props.hidePriceChange} 
            columnTitles={props.columnTitles}
            addedAssemblies={[...(props.diff.assembliesDiff?.addedAssemblies || []), ...(props.diff.customOptionsDiff?.addedCustomOptions || [])]} 
            removedAssemblies={[...(props.diff.assembliesDiff?.removedAssemblies || []), ...(props.diff.customOptionsDiff?.removedCustomOptions || [])]} 
          />
          {!(props.hidePriceChange || props.hideSummaryPrice) && <DealerPriceDiff columnTitles={props.columnTitles} diff={props.diff.priceDiff} />}
      </Space>
    </Spin>
  </HideCustomOptionContext.Provider>
  </>
}

export default QuoteRevisionDiffTable;


const getArrayRender = (row: TableRow, sectionName: string, diff: QuoteRevisionDiff, hidePriceChange: boolean | undefined ) => {

  const getItemPriceChange = (price: number | undefined) => {
    if (price == undefined || price === 0) return "";
    if (hidePriceChange) return "";

    const priceStr = Utils.formatMoney(price);
    return  `  ${priceStr}`
  }

  const normalizeName = (str: string) : string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getItemContent = (sectionName:string, row:TableRow) => {
    return sectionName === NewOrOld.OLD ? 
    (row.before ?? "") + getItemPriceChange(row.beforePrice) 
    : 
    (row.after ?? "") + getItemPriceChange(row.afterPrice)
  }

  const str = row.key.replace('Diff', '');
  const section =  sectionName + str.charAt(0).toUpperCase() + str.slice(1);
  if (row.changeItem === 'Non Discount Options' && diff[row.key][section].length > 0) {
    return <>
      { diff[row.key][section].map((item: NonDiscountOption, i: number) =>
      <div key={row.key + '_section_' + item.id}>
        {
          Object.keys(diff[row.key][section][i])
          .filter(key => key.toUpperCase() !== 'KEY')
          .filter(key => key.toUpperCase() !== 'ID')
          .map( key => {
            if (key.toUpperCase() !== "VALUE") {
              return <div key={row.key + `_${key}_` + item[key]} style={{maxWidth: "20rem"}}>{normalizeName(key)}: {item[key] || ''}</div>
            }
            else {
              return <div key={row.key + `_${key}_` + item[key]}>{normalizeName(key)}: {Utils.formatMoney(item[key]) || ''}</div>
            }
          })
        }
        <br/>
      </div>)
      }
    </>
  }
  else if (row.changeItem === 'Dealer Adjustments' && diff[row.key][section].length > 0) {
    return <>
      { diff[row.key][section].map((item: DealerAdjustment, i: number) =>
      <div key={row.key + '_section_' + item.id}>
        {
          Object.keys(diff[row.key][section][i])
          .filter(key => key.toUpperCase() !== 'KEY')
          .filter(key => key.toUpperCase() !== 'ID')
          .map( key => {
            if (key.toUpperCase() !== "VALUE") {
              return <div key={row.key + `_${key}_` + item[key]} style={{maxWidth: "20rem"}}>{normalizeName(key)}: {item[key] || ''}</div>
            }
            else {
              return <div key={row.key + `_${key}_` + item[key]}>{normalizeName(key)}: {Utils.formatMoney(item[key]) || ''}</div>
            }
          })
        }
        <br/>
      </div>)
      }
    </>
  }
  else {
    return <>{getItemContent(sectionName, row)}</>
  }
}

const ListOptions = (props:{
  assemblies: Array<AssemblyInfo | CustomOptionType> | undefined
  added: boolean
  filterCustomBom: (a: AssemblyInfo | CustomOptionType) => boolean
  hidePriceChange?: boolean
  type?: string
}) => {

  const lst = props.assemblies?.filter(props.filterCustomBom);

  const getDealerPriceChange = (value: number | undefined) => {
    return Utils.formatMoney(value);
  }
  const getPriceWarning = (selection: AssemblyInfo | CustomOptionType): string => {
    const asm = asAssembly(selection);

    if (!asm?.bom.startsWith("9999") && !asm?.optionDealerPrice) {
      return "Non-custom selection with zero price"
    }
    return "";
  }

  const asmLbl = (a:AssemblyInfo) => (!!a.label?.length ? a.label : a.bomDescription);

  const toDto = (a:AssemblyInfo | CustomOptionType) => {
    
    const asm = asAssembly(a);
    const co = asCustomOption(a);
    if ( asm ) {
      return {
        key: asm.bom,
        bom: asm.bom,
        categoryName: asm.category?.name,
        label: asmLbl(asm),
        dealerPrice: !!asm.optionDealerPrice && getDealerPriceChange(asm.optionDealerPrice),
        priceWarning: getPriceWarning(asm),
      }
    }
    else if( co ) {
      return {
        key: co.key,
        bom: "Custom Option",
        label: co.content,
        categoryName: co.category?.name,
        dealerPrice: !!co.dealerPrice && getDealerPriceChange(co.dealerPrice)
      }
    }
    return;
  }

  return <ul style={{marginBottom: "0.1rem"}}>
    {lst?.map(toDto).map(a => 
      <li key={'option-' + a?.key}>
        <div style={{fontWeight: "600"}} >
          {Utils.stripSortingPrefix(a?.categoryName)}: {a?.bom}
        </div>
        <div style={{wordBreak: "break-word"}} >{a?.label}</div>
        {!(props.hidePriceChange ?? false) && 
          <Row gutter={12}>
            <Col >{a?.dealerPrice}</Col>
            <Col ><span style={{color: "red"}}>{a?.priceWarning}</span></Col>
          </Row>
        }
      </li>
    )}
  </ul>;
}


const DealerPriceDiff = (props: {
  diff?: QuoteDiffItem | undefined
  loading?:boolean
  columnTitles?: string[]
}) => {

  if ( !props.diff ) return <></>;

  return <>
    <div style={{fontWeight: "500"}} key={"section-total"}>Dealer Price</div>
    <Table
      loading={props.loading}
      size="small"
      columns={[
        {
          title: props.columnTitles?.[0],
          width: 500,
          onCell: (_record) => ({ 
            style: { verticalAlign: "top" }
          }),
          render: (d) => Utils.formatMoney( d.beforePrice)
        },
        {
          title: props.columnTitles?.[1],
          width: 500,
          onCell: (_record) => ({ 
            style: { verticalAlign: "top" }
          }),
          render: (d) => Utils.formatMoney( d.afterPrice)
        },
        { 
          width: "20%",
          title: "Price Change",
          render: (d) => Utils.formatMoneyWithSign( d.afterPrice - d.beforePrice)
        },
      ]}
      dataSource={[props.diff]}
      pagination={false}
    />
  </>

}
