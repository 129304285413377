import "../util/mobile-table.css";
import styles from "./orders.module.css";
import Title from "antd/lib/typography/Title";
import {Table, Button, Space, Card, Tooltip,} from "antd";
import  { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext, } from "../context";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import {
  BaseQuote,
  DateFilterType, DEFAULT_THROTTLE,
  ORDER_TABLE,
  PAGINATION_MAX_PAGE_SIZE,
  QuoteFilter
} from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import { getCSVRow } from "../helpers/csv";
import {SortOrder} from "antd/es/table/interface";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import QuoteFilterControls from "../components/QuoteFilterControls";
import useQuoteList from "../swr/useQuoteList";
import {TableProps} from "antd/lib";
import {ListQuotesResponse} from "../api";
import {throttle} from "lodash";
import {TruckOutlined} from "@ant-design/icons";
import {BaseQuoteDescription} from "./quotes";

type  BaseQuoteSort = SorterResult<BaseQuote> | SorterResult<BaseQuote>[]

const getProductionDateStr = (date?: Date | undefined ) => {
  return date ? dayjs(date).format("MMMM Do YYYY") : 'Not Available';
};

const DEFAULT_PAGE_SIZE = 20;
const Orders = () => {
  const isMobile = useCheckMobileScreen();

  const configurator = useContext(ConfiguratorContext);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [myQuotesParam, setMyQuotesParam] = useQueryParam<boolean | undefined | null>("my", BooleanParam);
  const [archivedParam, setArchivedParam] = useQueryParam<boolean | undefined | null>("includingArchived", BooleanParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [quoteStatus, setQuoteStatus] = useQueryParam("status", ArrayParam);
  const [quoteSalespersonsParam, setQuoteSalespersonsParam] = useQueryParam("salespersons", ArrayParam);
  const [quoteEngineersParam, setQuoteEngineersParam] = useQueryParam("engineers", ArrayParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [modelsParam, setModelsParam] = useQueryParam("models", NumericArrayParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerIds", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationIds", NumericArrayParam);
  const [includingCancelledParam, setIncludingCancelledParam] = useQueryParam<boolean | undefined | null>("includingCancelledOrder", BooleanParam);
  const [draftOnlyParam, setDraftOnlyParam] = useQueryParam<boolean | undefined | null>("hasDraftRevision", BooleanParam);

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: myQuotesParam || undefined,
    includingArchived: archivedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    filterAssemblies: filterAssemblies as number[] || undefined,
    quoteStatus: [quoteStatus as (string|null) || []].flat() || undefined,
    engineers: [quoteEngineersParam as (string|null) || []].flat(),
    salespersons: [quoteSalespersonsParam as (string|null) || []].flat(),
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
    includingCancelledOrder: includingCancelledParam || false,
    models: [modelsParam as (number | null) || []].flat(),
    hasDraftRevision: draftOnlyParam || false,
    // ...props.defaultFilter,
  });

  const defaultSort = {
    columnKey: 'latestRevision.summary.productionDate',
    order: 'descend' as SortOrder
  };
  const [sort, setSort] = useState<BaseQuoteSort>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const quoteLst = useQuoteList({
    filter: {
      ...filter,
      stock: filter.search?.length ? undefined : false,
      ordersOnly: true,
    },
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const handleTableChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: BaseQuoteSort) => {
    setPagination(pagination);

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = useCallback(throttle( (_values: Record<string, any>, filter:QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setArchivedParam(filter.includingArchived);
    setMyQuotesParam(filter.myQuotes);
    setDealerFilterParam( filter.dealerLst );
    setAssembliesFilter(filter.filterAssemblies);
    setQuoteStatus(filter.quoteStatus);
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setQuoteSalespersonsParam(filter.salespersons);
    setQuoteEngineersParam(filter.engineers);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    setIncludingCancelledParam(filter.includingCancelledOrder);
    setDraftOnlyParam(filter.hasDraftRevision);
    setModelsParam(filter.models);
    setFilter(filter);
  }, DEFAULT_THROTTLE), []);

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!archivedParam && setArchivedParam(undefined);
    !!myQuotesParam && setMyQuotesParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!filterAssemblies?.length && setAssembliesFilter(undefined);
    !!quoteStatus?.length && setQuoteStatus(undefined);
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!quoteSalespersonsParam?.length && setQuoteSalespersonsParam(undefined);
    !!quoteEngineersParam?.length && setQuoteEngineersParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
    !!draftOnlyParam && setDraftOnlyParam(undefined);
    !!modelsParam?.length && setModelsParam(undefined);
    setIncludingCancelledParam(undefined);
  }

  const exportOrders = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.listQuotes({
        ...filter,
        ordersOnly: true,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'desc',
          field: 'latestRevision.summary.productionDate',
        }
      })).data;

      var csv = [[
        'Name',
        'QuoteID',
        'Status',
        'Salesperson',
        'Model',
        'Quantity',
        'Truck Description',
        'Engineer',
        'EstimatedProductionDate',
      ].join(',')];

      resp.content.forEach((quote: BaseQuote) => {
        csv.push(getCSVRow([
          quote.name,
          quote.quoteId,
          Utils.formatQuoteDisplayStatusStr(quote) || '',
          quote.owner?.name || '',
          quote.model.name,
          String(quote.quantity),
          quote.truckDescription || '',
          quote.salesTeam?.engineers?.map(u => u.name).join( " + " ) || '',
          getProductionDateStr(quote.productionDate),
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'orders-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>Orders</Title>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <QuoteFilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
          tableName={ORDER_TABLE}
        />

        {isMobile 
          ? <MobileTable
            dataSource={quoteLst.data?.content}
            loading={quoteLst.isLoading}
            onChange={handleTableChange}
            pagination={{...pagination, total: quoteLst.data?.totalElements}}
            sort={sort}
            isExporting={isExporting}
            exportOrders={exportOrders}
          />
          : <DesktopTable
            dataSource={quoteLst.data?.content}
            loading={quoteLst.isLoading}
            onChange={handleTableChange}
            pagination={{...pagination, total: quoteLst.data?.totalElements}}
            sort={sort}
            isExporting={isExporting}
            exportOrders={exportOrders}
          />
        }
      </Space>
    </div>
  );
};

const DesktopTable = (props: TableProps<ListQuotesResponse> & {
  sort:BaseQuoteSort
  isExporting: boolean
  exportOrders:()=>void
}) => {

  const { sort, isExporting, exportOrders, ...tableProps  } = props;

  const configurator = useContext(ConfiguratorContext);
  const hasComparePermissions = configurator.isSalesDesk() || configurator.isAdmin() || configurator.isEngineering();

  const firstSort = Array.isArray(sort) ? sort[0] : sort;
  const warningTextStyle = {color: "red", fontWeight: 'bold'};

  let columns:ColumnType<BaseQuote>[] = [
    {
      title: "Quote Details",
      key: "quoteId",
      render: (q) => <BaseQuoteDescription quote={q} />,
      sorter: true,
    },
    {
      title: "Status",
      key: "status",
      render: (q) => <>
        <Space style={{minWidth: "5.5rem"}}>
          {Utils.formatQuoteDisplayStatus(q)}
          {!!q.archived && <div style={warningTextStyle}>(Archived)</div>}
        </Space>
        {q.shippingDate && <Tooltip title={`Shipped on ${dayjs(q.shippingDate).format("M/DD/YY")}`}><TruckOutlined style={{fontSize: "1.2rem"}} /></Tooltip>}
      </>,
    },
    {
      title: "Model",
      key: "modelName",
      dataIndex: "modelName",
      render: (_, q) =>
          <span style={{whiteSpace: "nowrap"}} > {q.model.name}</span>,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "latestRevision.quantity",
    },
    {
      title: "Estimated Production Date",
      key: "latestRevision.summary.productionDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (q:BaseQuote) => (
        <span style={{whiteSpace: "nowrap"}} >{getProductionDateStr(q.productionDate)}</span>
      ),
    },
  ];

  if ( configurator.isInternalSales() ) {
    const SALES_COL_NDX = 3;
    columns = columns.slice(0,SALES_COL_NDX)
    .concat( {
      title: "Engineer",
      key: "salesTeam.engineerMembers.user.name",
      render: (_owner, q) =>
          <span style={{whiteSpace: "nowrap"}} >
          {q.salesTeam?.engineers?.map( u => u.name).join(", ")}
          </span>,
      sorter: true,
    })
    .concat( columns.slice(SALES_COL_NDX) );
  }


  return <Space direction="vertical" size="small" style={{ display: 'flex' }}>
    <div style={{display: "flex", flexDirection: "row-reverse"}}>
      <Space direction="horizontal">
        <Button type="primary" loading={isExporting} onClick={exportOrders}>Export</Button>
        {hasComparePermissions && <DiffRevisionModalButton btnLabel="Compare" title="Quote Comparison" className="" type="primary"  /> }
      </Space>
    </div>
    <Table
        {...tableProps}
        data-testid="quoteListTable"
        bordered
        columns={columns}
        rowKey="quoteId"
    />
  </Space>

}

const MobileTable = (props: TableProps<ListQuotesResponse> & {
  sort:BaseQuoteSort
  isExporting: boolean
  exportOrders:()=>void
}) => {
  const { sort, isExporting, exportOrders, ...tableProps } = props;

  const firstSort = Array.isArray(sort) ? sort[0] : sort;

  const columns:ColumnType<BaseQuote>[] = [
    {
      key: "latestRevision.summary.productionDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (_val, quote, _ndx) => {
        return <>
            <Card 
              hoverable={true}
              title={<>
                <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.name}</div>
                <div className={styles["quote-id"]}>({quote.quoteId})</div>
              </>
              } 
            >
              <div className="section" style={{maxWidth: "17rem"}}>
                {Utils.formatQuoteDisplayStatus(quote)}
              </div>

              <div style={{display: "flex", flexWrap: "wrap", gap: "3rem"}}>
                <div className="section">
                  <div>Salesperson:</div>
                  <div><span>{quote?.owner?.name}</span></div>
                  <div><span>{dayjs(quote.updatedAt).format("MMMM Do YYYY")}</span></div>
                </div>

                {quote?.serialNumberStr &&
                  <div className="section">
                    <div>Serial No:</div>
                    <div><span>{quote?.serialNumberStr}</span></div>
                  </div>
                }

              </div>
            </Card>
        </>;
      },
    },

  ];

  return <>
    <div style={{display: "flex", flexDirection: "row-reverse"}}>
      <Button type="primary" loading={isExporting} onClick={exportOrders}>Export</Button>
    </div>

      <Table
        {...tableProps}
        columns={columns}
        rowKey="id"
        className="mobile-table"
      />
  </>
}



export default Orders;
