import {Button, Form, Input, InputNumber, Modal, notification, Row, Space, Spin, Upload} from "antd";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PoNumber} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import type { UploadFile, UploadProps } from 'antd/es/upload';
import { PlusOutlined, ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import BMButton, {BMButtonProps} from "../BMButton";
import {useAsyncState} from "../../hook/useAsyncState";
import { useQuoteContext } from "../../contexts/QuoteContext";
import {FormProps, useForm} from "antd/es/form/Form";
import { PoNumberRequest } from "../../api";
import useComputePricing from "../../swr/useComputePricing";
import useQuote from "../../swr/useQuote";

export interface PoDocumentAssetFormValue {
  id?  : number
  name  : string
  type : string
  assetUri: string
}
export type PoNumberFormValues =  { 
  id?        : number
  poNumber?  : string
  amount?    : number | undefined
  documents? : PoDocumentAssetFormValue[]
}

export const buildPoNumberForm = (poNumber:PoNumber | undefined, s3UrlMap?:Record<string, string> | undefined) : PoNumberFormValues | undefined =>  {
  return poNumber && {
    ...poNumber,
    documents: poNumber?.documents?.map( d => ({
      id: d.id,
      assetUri: d.assetUri,
      name: d.filename,
      type: d.contentType,
      status: 'done',
      downloadUrl: s3UrlMap?.[ d.assetUri ],
      url: s3UrlMap?.[ d.assetUri ],
    }))
  }
}

  //convert poNumber to form format
export const buildPoNumberRequest = (values:PoNumberFormValues): PoNumberRequest => {

  return { ...values, 
    amount: values.amount || undefined,  //fix issue with null amount
    documents: values.documents?.map( (d:Record<string, any>) => d.response ? d.response : d ) 
    .map( (d:PoDocumentAssetFormValue) => ({
      id: d.id,
      assetUri: d.assetUri,
      filename: d.name,
      contentType: d.type,
    }) ) 
  }
}



const PoNumberEditor = (props: Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?: string
  value?: PoNumber
  onChange?: (v: PoNumber | undefined) => void
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [isOpen, setIsOpen] = useState<boolean>();
  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });
  const [s3UrlMap, s3UrlMapAsync] = useAsyncState<Record<string, string>>();
  const [initialValues, setInitialValues] = useState<PoNumberFormValues>();

  const [form] = useForm();

  useEffect( () => {
    const formValues = buildPoNumberForm(props.value, s3UrlMap );
    setInitialValues(formValues);
  }, [props.value?.id, s3UrlMap]);

  useEffect( () => {
    form.resetFields();
  }, [initialValues]);

  const loadS3Url = async () => {
    if ( !quote ) return;
    const poNumberId = props.value?.id;
    if ( !poNumberId ) return;


    s3UrlMapAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuotePoDocumentUrls( poNumberId );
      s3UrlMapAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      s3UrlMapAsync.setFail(e.message);
    }
  }


  const handleOpen = async () => {

    setIsOpen(true)

    await loadS3Url();
  }

  const handleSave = async () => {
    if ( !quote) return;

    const formValues = form.getFieldsValue(true) as PoNumberFormValues;
    const req = buildPoNumberRequest(formValues);

    try {
      const resp = await configurator.api.savePoNumber(quote.displayRevisionId, req );
      await quoteAsync.mutate(resp.data);
      props.onChange?.(resp.data.poNumber);
      setIsOpen(false);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
    }
  }

  const handleDelete = async () => {
    if ( !quote) return;
    if ( !props.value?.id) return;

    try {
      const resp = await configurator.api.deletePoNumber(props.value?.id);
      await quoteAsync.mutate(resp.data);
      props.onChange?.(undefined);
      setIsOpen(false);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
    }
  }


  const handleCancel = () => {
    setIsOpen(false);
  }


  const btnLbl =  ( initialValues?.poNumber ) ? initialValues.poNumber
    : ( initialValues?.documents?.length || initialValues?.amount ) ? "Edit PO Number"
    : props.disabled ? "None"
    : "Add PO Number";

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  //remove other props for button props
  const { id, value, onChange, ...btnProps } = props;

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      {...btnProps}
      data-testid="poNumber-button"
      onClick={handleOpen}
      style={{padding: "0"}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <Modal
      title={initialValues?.poNumber ? "Edit PO Number" : "Add PO Number"}
      open={isOpen}
      onCancel={handleCancel}
      footer={ <div style={{ display: 'flex', justifyContent: 'space-between', padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="delete" danger onClick={handleDelete} disabled={quoteAsync?.isLoading || !props.value?.id}>Remove</Button>

        <Space>
          <Button key="cancel" onClick={handleCancel} disabled={quoteAsync?.isLoading}>Cancel</Button>
          <Button key="done" type="primary" onClick={handleSave} disabled={quoteAsync?.isLoading} >Save</Button>
        </Space>
        </div>}
    >
      <Spin spinning={quoteAsync?.isLoading || s3UrlMapAsync.isLoading() }>
        <PoNumberForm 
          form={form} 
          initialValues={initialValues}
        />
      </Spin>
    </Modal>
  </>;
}

export const PoNumberForm = (props:FormProps) => {

  const {form,  ...formProps } = props;

  const configurator = useContext(ConfiguratorContext);

  const { quote } = useQuoteContext();

  const pricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision
  }).data;

  const dealerPrice = pricing?.dealerPrice;

  const uploadDocumentUrl = quote?.quoteId && configurator.api.getUploadPoDocumentUrl(quote?.quoteId);

  const handleUploadChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    return newFileList;
  }

  const handleRefreshAmount = () => {
    form?.setFieldValue("amount", dealerPrice );
  }

  const handleDownloadDocument = (file:UploadFile) => {
    const document = file.response || file;
    if ( document.url ) {
      configurator.api.downloadFile( document.url, {
        mimeType: document.type,
        filename: document.name
      })
    }
  }

  return <Form 
    labelAlign="right"
    form={form}
    {...formProps}
  >

    <Form.Item
      name="id"
      hidden
    >
      <Input type="hidden" />
    </Form.Item>

    <Space direction="vertical" size="small">

      <Form.Item
        label="PO Number"
        name="poNumber"
        labelCol={{span: 10}}
        style={{width: "13rem"}}
      >
        <Input />
      </Form.Item>

      <Row>
        <Form.Item
          label="Amount"
          name="amount"
          labelCol={{span: 10}}
          style={{width: "13rem"}}
        >
          <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Type dollar amount."
            controls={false}
          />
        </Form.Item>
        <Button type="text" style={{marginLeft: "-2rem"}} icon={<ReloadOutlined />} title="Refresh with latest price." 
          data-testid="poNumber-refresh-amount-btn"
          onClick={handleRefreshAmount}/>
      </Row>

      <Title level={5}>Documents</Title>

      <Form.Item
        name="documents"
        getValueFromEvent={handleUploadChange}
        valuePropName="fileList"
        noStyle
      >
        <Upload
          action={uploadDocumentUrl}
          name="document"
          listType="picture-card"
          withCredentials={true}
          showUploadList={ {
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined />,
            showPreviewIcon: false,
            showRemoveIcon: true,
          }}
          onDownload={handleDownloadDocument}
          isImageUrl={(f) => !!f.type?.startsWith("image")  }
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

    </Space>

  </Form>
}


export default PoNumberEditor;
