import {
    Button,
    Card,
    Checkbox,
    Form,
    FormListFieldData, FormListOperation,
    FormProps,
    Input,
    InputNumber,
    Select,
    Tooltip,
} from "antd";
import {useEffect } from "react";
import {
    ApprovalRequest,
    Condition,
    ApproverRole, PAGINATION_MAX_PAGE_SIZE, NotificationEvent
} from "../../api/models";
import {DeleteOutlined, InfoCircleTwoTone, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Utils from "../../util/util";
import useNotificationEvents from "../../swr/useNotificationEvents";
import useUsers from "../../swr/useUsers";

export interface ApprovalStepFormValues  {
    id?: number;
    name?: string;
    approvalType?: ApprovalRequest
    approvers?: string[]
    requiredConditions?: Condition[]
    skipConditions?: Condition[]
    orderKey?: number
    disabled?: boolean
    hidden?: boolean
    notificationEvents?: string[]
}

export const ApprovalStepForm = (props: FormProps & { }) => {
    const {...formProps} = props;
    useEffect(() => {
        props.form?.resetFields();
    }, [props.initialValues] );

    const users = useUsers();
    const approvers = [
        Object.keys(ApproverRole).map( r => ({
            label: Utils.snakeCaseToFirstLetterCapitalized(r),
            value: r
        })),
        users.data?.map( u => ({
            label: u.name,
            value: u.id
        })) || []
    ].flat();

    //if approver isn't in users or roles, add
    var approverValueSet = new Set(approvers.map(a => a.value) );
    console.log( approverValueSet );
    console.log( props.initialValues?.approvers );
    props.initialValues?.approvers
        .filter(a =>  !approverValueSet.has(a) )
        .map( u => ({
            label: u,
            value: u
        }))
        .forEach(a => approvers.push(a));

    const notificationEventLst = useNotificationEvents({
        pageSize: PAGINATION_MAX_PAGE_SIZE
    });


    return (
        <Form
            {...formProps}
            layout="vertical"
        >
            <Form.Item name="id" label="Id" hidden={true}>
                    <Input />
            </Form.Item>
            <Form.Item name="approvalType" label="Approval Type" hidden={true}>
                <Input />
            </Form.Item>
            <Form.Item name="name" label="Name">
                <Input />
            </Form.Item>
            <Form.Item label={"Approvers"} name={"approvers"} >
                <Select
                    mode={"tags"}
                    optionFilterProp="label"
                    allowClear
                    options={approvers}
                />
            </Form.Item>
            <Form.Item
                label={<>Notifications&nbsp;
                    <Tooltip title="This notification is sent with approval request."
                    ><InfoCircleTwoTone /></Tooltip>
                </>}
                name={"notificationEvents"} >
                <Select
                    mode={"multiple"}
                    optionFilterProp="label"
                    allowClear
                    options={notificationEventLst.data?.content?.map(r => ({label: r.name, value: r.id})) }
                />
            </Form.Item>
            <Form.List name="requiredConditions" >
                {(fields, operations, _meta) => <>
                    <div style={{display:"flex", gap: ".5rem", marginBottom: ".5rem"}}>Required Condition <Button icon={<PlusOutlined/>} type="primary" shape="circle" size="small" onClick={() => operations.add()} /></div>
                    {fields?.map((fld, ndx) => <ConditionForm key={"requiredCondition-" + ndx} fld={fld} ndx={ndx} operations={operations} /> )}
                </>}
            </Form.List>
            <Form.List name="skipConditions" >
                {(fields, operations, _meta) => <>
                        <div style={{display:"flex", gap: ".5rem", marginBottom: ".5rem"}}>Skip Condition <Button icon={<PlusOutlined/>} type="primary" shape="circle" size="small" onClick={() => operations.add()} /></div>
                        {fields?.map((fld, ndx) => <ConditionForm key={"skipCondition-" + ndx} fld={fld} ndx={ndx} operations={operations} /> )}
                    </>}
            </Form.List>
            <Form.Item name="orderKey" label="Order Key">
                <InputNumber />
            </Form.Item>
            <Form.Item valuePropName="checked" label="Disabled" name="disabled">
                <Checkbox />
            </Form.Item>
        </Form>
    );
}

const ConditionForm = (props: {
    key: React.Key
    fld: FormListFieldData
    ndx: number
    operations: FormListOperation
}) => {
    const {fld, ndx, operations} = props;
    return <Card key={props.key} size={"small"} style={{marginBottom: ".5rem"}} >
        <div style={{display: "flex", alignItems:"center", gap: "1rem"}}>
            <Form.Item
                name={[fld.name, "id"]}
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={[fld.name, "name"]}
                rules={[{
                    required: true,
                    message: `A condition name is required.`,
                }]}
                style={{width: "80%", marginBottom: ".5rem"}}
            >
                <Input placeholder={"Condition Name"} />
            </Form.Item>
            <Button icon={<DeleteOutlined/>} type="text" shape="circle" size="small" onClick={() => operations.remove(ndx)} />
        </div>
        <div>
            <Form.Item
                name={[fld.name, "condition"]}
                rules={[{
                    required: true,
                    message: `A condition is required.`,
                }]}
                style={{width: "100%", marginBottom: ".5rem"}}
            >
                <TextArea placeholder={"Condition"} />
            </Form.Item>
        </div>
    </Card>;

}

export default ApprovalStepForm;
