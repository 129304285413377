import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Assemblies from "../pages/assemblies";
import { ConfiguratorContext } from "../context";
import AssemblyImport from "../pages/assembly_import";
import ViewAssembly from "../pages/view_assembly";
import ViewModel from "../pages/view_model";
import ModelList from "../pages/model_list";
import NotFoundPage from "../pages/not_found";
import AssemblyCategoriesPage from "../pages/assembly_categories";
import CategoryView from "../pages/category_view";
import Quotes from "../pages/quotes";
import { useContext } from "react";
import Approvals from "../pages/approvals";
import Reporting from "../pages/reporting";
import OperationsPage from "../pages/operations";
import ShippingDestinationListPage from "../pages/shipping_destination";
import ShippingDestinationView from "../pages/shipping_destination_view";
import DealerList from "../pages/dealer/dealer";
import SystemConfigPage from "../pages/system_config";
import PendingAssemblies from "../pages/pending_assemblies";
import AssemblyCostReviewPage from "../pages/AssemblyCostReviewPage";
import EpicorBulkExport from "../pages/epicor_bulk_export";
import EpicorSyncStatus from "../pages/epicor_sync_status";
import ListUsers from "../pages/users/list_users";
import Orders from "../pages/orders";
import PricingPage from "../pages/pricing";
import { Permission } from "../api/models";
import MasterSchedule from "../pages/production/master_schedule";
import SalesOrdersReport from "../pages/reports/salesOrdersReport";
import DashComponents from "../pages/dash_component_list";
import DashComponentView from "../pages/dash_component_view";
import AssignDashComponent from "../pages/dash_component_assign_assemblies";
import DashTemplateList from "../pages/dash_template_list";
import DashTemplateView from "../pages/dash_template_view";
import ListRuleSets from "../pages/rule_sets/list";
import ViewRuleSet from "../pages/rule_sets/view";
import ApprovalsReport from "../pages/reports/approvalsReport";
import CustomerList from "../pages/customer";
import Inventory from "../pages/inventory";
import PricingReport from "../pages/reports/pricingReport";
import InternalUserPage from "../pages/internal_user";
import Configurator from "../pages/configurator";
import TruckReport from "../pages/reports/truckReport";
import SourcewellQuotes from "../pages/SourcewellQuotes";
import LifeCycleNotification from "../pages/LifeCycleNotification";
import VFDReviewPage from "../pages/VfdReviewPage";
import CustomWorkReviewPage from "../pages/CustomWorkReviewPage";
import EngineeringTeamsPage from "../pages/EngineeringTeamsPage";
import BtsPage from "../pages/bts";
import SalesSupport from "../pages/sales_support";
import InventoryDetail from "../pages/inventoryDetail";
import MasterScheduleView from "../pages/production/master_schedule_view";
import UserSalesTeamsPage from "../pages/UserSalesTeamsPage";
import ApprovalDetailPage from "../pages/ApprovalDetailPage";
import LogisticsPage from "../pages/logistics";
import ConcessionCriteriaPage from "../pages/ConcessionCriteriaPage";
import MasterScheduleSlots from "../pages/production/master_schedule_slots";
import MasterScheduleKpi from "../pages/production/master_schedule_kpi";
import ImageLibraryPage from "../pages/imageLibrary";
import NotificationEventsPage from "../pages/NotificationEventsPage";
import ApprovalStepsPage from "../pages/ApprovalStepsPage";

const SiteRouter = () => {
  const context = useContext(ConfiguratorContext);

  const isDealerManagement = ( context.isDealerManagement() || context.isSalesDesk() || context.isAdmin() || context.isEngineering() || context.isReleaseEngineering() ) && !context.isDealerSales()
  const isEngineering = context.isEngineering() || context.isEngineeringReadOnly();
  let routes: React.ReactNode[] = [];

  if (context.isSalesManager() || context.isSalesDesk() || context.isProcurement() || context.isInternalSales() || context.isFinance()) {
    routes = routes.concat([
      (<Route path="/approvals" exact key="route-1-0">
        <Approvals />
      </Route>),
      (<Route path="/approvals/:approvalId" key="route-1-1">
        <ApprovalDetailPage />
      </Route>),
    ]);
  }

  if (context.isFinance() || isEngineering || context.isAdmin()) {
    routes = routes.concat([
      (<Route path="/assemblies" exact key="route-2-0">
        <Assemblies key="imported" />
      </Route>),
      (<Route path="/pending-assemblies" exact key="route-2-1">
        <PendingAssemblies key="pending" />
      </Route>),
      (<Route path="/assembly-cost-review" exact key="route-2-2">
        <AssemblyCostReviewPage key="assembly-cost-review" />
      </Route>),
      (<Route path="/concession-criteria" exact key="concession-criteria">
        <ConcessionCriteriaPage key="concession-criteria" />
      </Route>),
    ]);
  }

  if (isDealerManagement) {
    routes = routes.concat([
      <Route path="/dealer" exact key="route-3-16">
        <DealerList />
      </Route>,
    ])
  }

  if ((context.isDealerManagement() || context.isAdmin() ) && !context.isDealerSales() ) {
    routes = routes.concat([
      (<Route path="/users" exact key="/users">
        <ListUsers/>
      </Route>)
    ])
  }

  if (isDealerManagement) {
    routes = routes.concat([
      <Route path="/customer" exact key="route-customer">
        <CustomerList />
      </Route>,
      <Route path="/salesSupport" exact key="route-sales-support">
        <SalesSupport />
      </Route>,
    ])
  }

  if (context.isAdmin() || context.isReleaseEngineering()) {
    routes = routes.concat([
      (<Route path="/epicor-bulk-export" exact key="/epicor-bulk-export">
        <EpicorBulkExport/>
      </Route>),
      (<Route path="/epicor-sync-status" exact key="/epicor-sync-status">
        <EpicorSyncStatus/>
      </Route>),
    ])
  }

  if ( context.hasAnyPermission([Permission.RULES_WRITE, Permission.RULES_READ]) ) {
    routes = routes.concat([
      (<Route path="/rule-sets" key="rule-sets-list" exact>
        <ListRuleSets/>
      </Route>),
      (<Route path="/rule-sets/:id" key="rule-set-view" exact>
        <ViewRuleSet/>
      </Route>),
    ]);
  }

  routes = routes.concat([
    (<Route path="/master-schedule-view" exact key="/master-schedule-view">
      <MasterScheduleView key="/master-schedule/view"/>
    </Route>)
  ]);

  if(context.hasPermission(Permission.MASTER_SCHEDULE_WRITE)) {
    routes = routes.concat([
      (<Route path="/master-schedule-upload" exact key="/master-schedule-upload">
        <MasterSchedule/>
      </Route>),
    ]);
  }
  if(context.isInternalSales()) {
    routes = routes.concat([
      (<Route path="/master-schedule-slots" exact key="/master-schedule-slots">
        <MasterScheduleSlots/>
      </Route>),
      (<Route path="/master-schedule-kpi" exact key="/master-schedule-kpi">
        <MasterScheduleKpi/>
      </Route>),
    ]);
  }

  if (isEngineering || context.isAdmin()) {
    routes = routes.concat([
      (<Route path="/system-config" exact key="route-3-2">
        <SystemConfigPage />
      </Route>),
      (<Route path="/assemblies/:id" exact key="route-3-3">
        <ViewAssembly />
      </Route>),
      (<Route path="/assemblies/import/:id" exact key="route-3-6">
        <AssemblyImport />
      </Route>),
      (<Route path="/models" exact key="route-3-7">
        <ModelList />
      </Route>),
      (<Route path="/models/:id" exact key="route-3-8">
        <ViewModel />
      </Route>),
      (<Route path="/categories" exact key="route-3-9">
        <AssemblyCategoriesPage />
      </Route>),
      (<Route path="/operations" exact key="route-3-10">
        <OperationsPage />
      </Route>),
      (<Route path="/approvals" exact key="route-3-11">
        <Approvals />
      </Route>),
      (<Route path="/approvals/:approvalId" key="route-3-12">
        <ApprovalDetailPage />
      </Route>),
      (<Route path="/categories/:id" exact key="route-3-13">
        <CategoryView />
      </Route>),
      (<Route path="/shipping-destinations" exact key="route-3-14">
        <ShippingDestinationListPage />
      </Route>),
      (<Route path="/shipping-destination/:id" exact key="route-3-15">
        <ShippingDestinationView />
      </Route>),
      (<Route path="/dash-component" exact key="route-3-16">
        <DashComponents />
      </Route>),
      (<Route path="/dash-component/:id" exact key="route-3-17">
        <DashComponentView />
      </Route>),
      (<Route path="/assembly-dash-component" exact key="route-3-18">
        <AssignDashComponent />
      </Route>),
      (<Route path="/dash-template" exact key="route-3-19">
        <DashTemplateList />
      </Route>),
      (<Route path="/dash-template/:id" exact key="route-3-20">
        <DashTemplateView />
      </Route>),
      (<Route path="/internal-user" exact key="route-3-21">
        <InternalUserPage />
      </Route>),
      (<Route path="/vfd-review" exact key="vfd-review">
        <VFDReviewPage />
      </Route>),
      (<Route path="/custom-work-review" exact key="custom-work-review">
        <CustomWorkReviewPage />
      </Route>),
      (<Route path="/engineering-teams" exact key="engineering-teams">
        <EngineeringTeamsPage />
      </Route>),
    ]);
  }

  if (context.isSalesDesk() || context.isAdmin() || context.isEngineering() ) {
    routes.push(<Route path="/user-sales-teams" exact key="user-sales-teams">
        <UserSalesTeamsPage />
      </Route>);
  }

  if (context.isSalesDesk() || context.isAdmin() ) {
    routes.push(
      (<Route path="/shipping-destinations" exact key="route-3-14">
        <ShippingDestinationListPage />
      </Route>),
      (<Route path="/shipping-destination/:id" exact key="route-3-15">
        <ShippingDestinationView />
      </Route>),
      (<Route path="/internal-user" exact key="route-3-22">
        <InternalUserPage />
      </Route>),
        (<Route path="/notification-events" exact key="notification-events">
          <NotificationEventsPage />
        </Route>),
        (<Route path="/approval-steps" exact key="approval-steps">
          <ApprovalStepsPage />
        </Route>),
    );
  }

  if (context.isAdmin() || context.isReporting() || context.isSalesDesk()) {
    routes.push(<Route path="/reporting" exact key="route-4-0">
      <Reporting />
    </Route>);
    routes.push(<Route path="/reports/salesOrdersReport" exact key="salesOrdersReport"><SalesOrdersReport /></Route>);
    routes.push(<Route path="/reports/approvalsReport" exact key="approvalsReport"><ApprovalsReport /></Route>);
    routes.push(<Route path="/reports/pricingReport" exact key="pricingReport"><PricingReport /></Route>);
    routes.push(<Route path="/reports/truckReport" exact key="truckReport"><TruckReport /></Route>);
  }

  if (context.isFinance()) {
    routes.push((<Route path="/pricing" exact key="route-5-0">
      <PricingPage/>
    </Route>));
  }

  if (context.isInternalSales()) {
    routes.push((<Route path="/lifeCycleNotification" exact key="route-7-2">
    <LifeCycleNotification/>
  </Route>));
    routes.push((<Route path="/image-library" exact key="image-library">
    <ImageLibraryPage />
  </Route>));
  }

  routes = routes.concat([
    (<Route path="/" exact key="route-6-0">
      <Redirect to="/quotes" />
    </Route>),
    (<Route path="/quotes" exact key="route-6-1">
      <Quotes />
    </Route>),
    (<Route path="/orders" exact key="orders">
      <Orders />
    </Route>),
    (<Route path="/logistics" exact key="amp">
      <LogisticsPage />
    </Route>),
    (<Route path="/bts" exact key="amp">
      <BtsPage />
    </Route>),
    (<Route path="/configurator/:quoteId?" key="route-6-2">
      <Configurator />
    </Route>),
    (<Route path="/sourcewellQuotes" exact key="sourcewellQuotes">
      <SourcewellQuotes />
    </Route>),
    (<Route path="/inventory" exact key="inventory">
      <Inventory />
    </Route>),
    (<Route path="/inventory/:quoteId?" key="inventoryDetail">
      <InventoryDetail />
    </Route>),
    (<Route path="/internal-user" exact key="internalUser">
      <InternalUserPage />
    </Route>),
    (<Route key="route-6-3">
      <NotFoundPage />
    </Route>),
  ]);



  return <Switch>
    {routes}
  </Switch>
}
export default SiteRouter;


