import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {
  AXIOS_CANCEL_MSG, CustomOptionType
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface CustomOptionProps {
  quoteRevisionId:number | undefined,
  selections?:number[] | undefined
}

export const useCustomOptions = (props?: CustomOptionProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props?:CustomOptionProps  ) : Promise<CustomOptionType[] | undefined> => {

    const { quoteRevisionId, selections } = props || {};
    if ( !quoteRevisionId ) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getCustomOptions(quoteRevisionId, selections, cancelSource.token)
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'getCustomOptions',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useCustomOptions;

