import { useContext, useState } from "react";
import {
  Button,
  Row,
  ButtonProps,
  Form,
  Descriptions,
  Result,
  Col,
  Spin,
  notification,
  Input,
  Drawer,
  Checkbox,
  Tooltip
} from "antd";
import { ConfiguratorContext } from "../context";
import Title from "antd/lib/typography/Title";
import {Link} from "react-router-dom";
import BMButton from "../components/BMButton";
import ModalWizard from "./ModalWizard";
import AssemblySelector from "./assembly_selector";
import QuoteReviewDetail from "./QuoteReviewDetail";
import { WizardInstance } from "./Wizard";
import { useAsyncState } from "../hook/useAsyncState";
import {BacklogUpdateReview, BaseQuote, BatchBacklogUpdateResult,} from "../api/models";
import { useIntl } from "react-intl";
import UploadButton from "./UploadButton";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import _ from "lodash";
import { listParam } from "../api";
import SubPageTable from "./SubPageTable";

const AssemblyBacklogUpdateButtonModal  = (props: ButtonProps) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [backlogUpdateLst, setBacklogUpdateLst] = useState<BatchBacklogUpdateResult[]>();
  const [reviewLst, reviewLstAsync] = useAsyncState<BacklogUpdateReview[]>();
  const [addAssemblyIdLst, setAddAssemblyIdLst] = useState<number[]>();
  const [deleteAssemblyIdLst, setDeleteAssemblyIdLst] = useState<number[]>();
  const [reason, setReason] = useState<string>();
  const [review, setReview] = useState<BacklogUpdateReview>();
  const [selectedQuotes, setSelectedQuotes] = useState<string[] | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleBacklogUpdate = async (nav:WizardInstance) => {

    if (!selectedQuotes?.length) return;
    if (!reason?.length) return;

    nav.nextStep();

    let lst = new Array<BatchBacklogUpdateResult>();
    for (const quoteId of selectedQuotes || []) {
      try {

        const resp = await configurator.api.updateAssemblies(quoteId, deleteAssemblyIdLst || [], addAssemblyIdLst || [], reason)
        lst = [...lst, resp.data];
        setBacklogUpdateLst(lst);
      }
      catch(e:any) {
        const id = e.response?.data?.message || e.message;
        const errorMsg = intl.formatMessage({id});
        notification.error({message: "Failed to update quote. " + errorMsg});

        const backlog = reviewLst?.find(b => b.quote.quoteId === quoteId);
        if (backlog) {
          lst = [...lst, {quote: backlog.quote, error: errorMsg}];
          setBacklogUpdateLst(lst);
        }
      }
    }
  }

  const handleReset = () => {
    setBacklogUpdateLst(undefined);
    reviewLstAsync.setInit();
    setAddAssemblyIdLst(undefined);
    setDeleteAssemblyIdLst(undefined);
    setSelectedQuotes(undefined)
    setReason(undefined);
    setReview(undefined);
  }

  const handleDone = () => {
    setIsOpen(false);
  }

  const handleChangeAddAssembly = (value: number[] ) => {
    setAddAssemblyIdLst(value);
  }

  const handleChangeDeleteAssembly = (value: number[] ) => {
    setDeleteAssemblyIdLst(value);
  }

  const handleSelectQuote = (q:BaseQuote) => {
    const lst = new Set(selectedQuotes);
    if ( lst.has(q.quoteId)) lst.delete(q.quoteId)
    else lst.add(q.quoteId);

    setSelectedQuotes([...lst]);
  }
  const isQuoteSelected = (q:BaseQuote):boolean => {
    const lst = new Set(selectedQuotes);
    return lst.has(q.quoteId);
  }

  const hasAssemblySelected = ((addAssemblyIdLst?.length || 0) + (deleteAssemblyIdLst?.length || 0 )) > 0;

  return <>
    <BMButton
      className="ghostBmButton"
      type="text"
      {...props}
      onClick={() => setIsOpen(true)}
    >Backlog Update</BMButton>

    <ModalWizard 
      open={isOpen}
      onCancel={handleDone}
      afterOpenChange={(open) => {
        if( open ) {
          handleReset();
        }
      }}
      width={"70rem"}
      steps={[
        {
          key:"assemblies",
          title: "Select Assemblies",
          body: (_nav) => <div>
            <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
              <Form style={{width: "100%"}}>
                <Form.Item 
                  label="Delete"
                >
                  <AssemblySelector style={{ width: '100%' }} onChange={handleChangeDeleteAssembly} />
                </Form.Item>
                <Form.Item 
                  label="Add"
                >
                  <AssemblySelector style={{ width: '100%' }} onChange={handleChangeAddAssembly} />
                </Form.Item>
              </Form>
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!hasAssemblySelected} onClick={() => nav.nextStep()} >Next</Button>
            <Button key="back" onClick={() => nav.prevStep()} >Back</Button>
          </div>
        },
        {
          key:"uploadLst",
          title: "Upload",
          body: (nav) => 
            <div>
              <Row style={{marginTop: "3rem", marginBottom: "1rem", justifyContent: "center"}}>
                <UploadButton type="primary"
                  showResults={false}
                  action={configurator.api.getBacklogUpdateCSVUrl()}
                  data={{
                    delete: deleteAssemblyIdLst?.length ? listParam(deleteAssemblyIdLst) : [],
                    add: addAssemblyIdLst?.length ? listParam(addAssemblyIdLst) : []
                  }}
                  onDone={(v) => {
                    reviewLstAsync.setDone(v.response);
                    setSelectedQuotes( v.response
                        ?.filter(r => !r.error)
                        .map(r => r.quote.quoteId));
                    nav.nextStep();
                  }}
                  onUploading={reviewLstAsync.setLoading}
                >Upload File</UploadButton>
              </Row>
              <Row style={{marginBottom: "1rem", justifyContent: "center"}}>
                <div style={{fontStyle:"italic", fontSize: "smaller"}}>Note: This expects a .csv file with a column name "Quote ID", similar to exporting the quote list.</div>
              </Row>
            </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!reviewLstAsync.isDone()} onClick={() => nav.nextStep()} >Next</Button>
            <Button key="back" onClick={() => nav.prevStep()} >Back</Button>
          </div>
        },
        {
          key:"review",
          title: "Review Updates",
          body: (_nav) => <div>
            <SubPageTable
              rowKey={(r) => r.quote?.quoteId}
              style={{minHeight: "20rem"}}
              dataSource={reviewLst}
              pagination={{
                pageSize: 20,
                hideOnSinglePage: true,
                showLessItems: true,
              }}
              columns={[
                { 
                  render: (page:BacklogUpdateReview[]) => 
                    <Descriptions
                      column={4}
                      items={ page.map(q => ({
                        key: "review-" + q.quote.quoteId,
                        children:  <div>
                          <Checkbox checked={isQuoteSelected(q.quote)} onClick={() => handleSelectQuote(q.quote)} />
                          <span style={{marginLeft: "0.5rem"}}>
                          <Link target="_blank" to={"/configurator/" + encodeURI(q.quote.quoteId)} >{q.quote.partNumberString || q.quote.quoteId}</Link>
                          {(!_.isEqual(q.before, q.after) ) && <Button onClick={() => setReview(q)} type="text" shape="circle" icon={<InfoCircleOutlined style={{color: "blue"}} />} />}
                          {(!!q.error?.length ) && <Button onClick={() => setReview(q)} type="text" shape="circle" icon={<WarningOutlined style={{color: "red"}} />} />}
                          </span>
                        </div>
                      })) }
                    />
                }
              ]}
            />
            <div>
              <Input onChange={(e) => setReason(e.target.value)} placeholder="Provide a reason for this change." />
            </div>
            <Drawer open={!!review} 
              onClose={() => setReview(undefined)}
              getContainer={false}
              width="100%"
              title={<Link target="_blank" to={"/configurator/" + encodeURI(review?.quote.quoteId || "")} >{review?.quote.partNumberString || review?.quote.quoteId}</Link>} 
              >
                {!review?.error?.length
                  ?<Row>
                    <Col span={12}>
                      <Title level={5}>Before</Title>
                      <QuoteReviewDetail review={review?.before} ignoreDashDrawing={true} />
                    </Col>
                    <Col span={12}>
                      <Title level={5}>After</Title>
                      <QuoteReviewDetail review={review?.after} ignoreDashDrawing={true} />
                    </Col>
                  </Row>
                  :<div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
                    { intl.formatMessage({ id: review.error, defaultMessage: "An error occurred loading this quote."}) }
                  </div> }
            </Drawer>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <BMButton key="next" type="primary" 
              loading={reviewLstAsync.isLoading()} 
              disabled={!reason?.length || !selectedQuotes?.length}
              onDisabledClick={() => {
                const msg = ( !reason?.length ) ? "Please provide a reason for this change."
                  : "No quotes to update."
                notification.error( { message: msg });
              }}
              onClick={() => handleBacklogUpdate(nav)} >Next</BMButton>
            <Button key="back" onClick={() => { setReview(undefined); nav.prevStep(); }} >Back</Button>
          </div>
        },
        {
          key:"summary",
          title: "Summary",
          body: (_nav) =>
              <Result title={"Update Results"}>
                <Row style={{marginBottom: "1rem", justifyContent: "center"}}>
                  <SubPageTable
                      rowKey={"quoteId"}
                      className="backlogUpdateTable"
                      showHeader={false}
                      size="small"
                      dataSource={selectedQuotes?.map(quoteId => ({quoteId}))}
                      pagination={{
                        pageSize: 20,
                        hideOnSinglePage: true,
                        showLessItems: true,
                      }}
                      columns={[
                        {
                          render: (page:{quoteId: string}[]) =>
                              <Descriptions
                                  column={4}
                                  items={ page.map(q => {
                                    const backlogUpdate = backlogUpdateLst?.find(b => b.quote.quoteId === q.quoteId);
                                    return {
                                      key: "backlogUpdate-" + q.quoteId,
                                      children: <div style={{display: "flex", justifyContent: "center"}} >
                                        <Spin spinning={!backlogUpdate} size={"small"} >
                                          {(!!backlogUpdate?.error?.length ) &&
                                              <Tooltip title={intl.formatMessage({ id: backlogUpdate?.error, defaultMessage: "An error occurred loading this quote."})}>
                                                <WarningOutlined style={{color: "red"}} />
                                              </Tooltip>}
                                        </Spin>
                                        <span style={{marginLeft: "0.5rem"}}>
                                        <Link target="_blank" to={"/configurator/" + encodeURI(q.quoteId)} >{q.quoteId}</Link>
                                        </span>
                                      </div>
                                    }
                                  })}
                              />
                        }
                      ]}
                  />
                </Row>
              </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={handleDone} >Done</Button>
            <Button key="back" onClick={() => nav.prevStep()} >Back</Button>
          </div>
        }

      ]}
    />
  </>;
}

export default AssemblyBacklogUpdateButtonModal;
