import {useContext, useRef, useState} from "react";
import {
  Quote,
  MISSING_IMAGE,
  UnassignedComponent,
  DashDrawingUrl,
  CategoryIdAssembliesIdMap,
  AXIOS_CANCEL_MSG
} from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { Button, Modal, Row, notification, Alert, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import axios, {CancelTokenSource} from "axios";
import useSWR from "swr";

const QuoteDashTab = (props: {quote: Quote}) => {

  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const {quote} = props;
  const [loading, setLoading] = useState(false);

  const dashDrawings = useDashDrawings({
    quoteId: quote.id,
    quoteRevisionId: quote.displayRevisionId
  });
  const data = dashDrawings.data;

  const regenerateDrawing = async () => {
    setLoading(true);
    if (quote) {
      try {
        const resp = await configurator.api.regenerateDashDrawing(quote.id, quote.displayRevisionId);
        if (resp.data.url) {
          notification.success({
            message: `Successfully re-generate drawing!`,
          });
        }
      }
      catch (e) {
        console.log(e);
      }
      finally {
        setLoading(false);
      }
    }
  };

  return (<>
    {loading ? <Spin/> :
      (<div key={"dash-drawing"}>
        {data?.url ?
          <div key={"alert-image"}>
            <MissingAssignmentAlert
              unassignedComponents={data?.unassignedComponents}
              uploadDashImage={data?.uploadDashImage || false}
              selections={quote.selections}
            />
            <br/>
            <img
              id='fullImage'
              src={ data?.url || MISSING_IMAGE }
              alt=""
              style={{ width: '100%', height: '100%', boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.25)" }}
            />
          </div> : 
          <p>{"No dash image has been assigned to this revision yet. Please check the template for the drawing assembly."}</p>
        }
        {isAdminOrEngineering &&
        <Row gutter={10} style={{marginTop: "1rem"}} justify={"center"}>
            <Button type="primary" onClick={() => regenerateDrawing()}>Regenerate Drawing</Button>
        </Row>}
      </div>)
    }
  </>);
}

export default QuoteDashTab;

const MissingAssignmentAlert = (props: {unassignedComponents: UnassignedComponent[] | undefined, uploadDashImage: boolean, selections: CategoryIdAssembliesIdMap | undefined}) => {

  const {unassignedComponents, uploadDashImage, selections} = props;
  const [open, setOpen] = useState(false);
  const allSelections = Object.values(selections || {}).flat();

  return (<>
    <div  key="errorAlertButton">
      {
        (uploadDashImage ?? false) ?
        <Alert 
          type="info" 
          closable={true}
          message={"This is a custom dash layout."}
        />
        : 
        (!!unassignedComponents?.length && 
        <Alert 
          type="error" 
          closable={true}
          message={"Detected missing assignment, please review."}
          action={
            <Button size="small" onClick={() => {setOpen(true)}}>
              Detail
            </Button>
          }
        />)
      }
    </div>
    <Modal 
      title="Component to be assigned: "
      open={open}
      onCancel={() => {setOpen(false)}}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width="80%"
      centered
      style={{maxWidth: "70rem"}}
    >
      {unassignedComponents?.map((e, di) => 
      <Row style={{marginBottom: "5px"}} key={e.component + e.quantity}>
        <div style={{marginRight: "5px"}} >{e.component} - Quantity: {e.quantity},  Assemblies: </div>
        {Object.entries(e.assigningAssemblies).filter(v => allSelections.includes(v[1])).map((e, ei) =>
            <Paragraph key={`para-${di}-${ei}`} copyable={{text: e[0]}} style={{marginRight: "10px"}}>{e[0]}</Paragraph>
        )}
      </Row>
      )}
    </Modal>
  </>)
}

interface DashDrawingProps {
  quoteId: number | undefined
  quoteRevisionId: number | undefined
}
export const useDashDrawings = (props?: DashDrawingProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props?:DashDrawingProps  ) : Promise<DashDrawingUrl | undefined> => {

    const { quoteId, quoteRevisionId } = props || {};
    if ( !quoteId ) return;
    if ( !quoteRevisionId ) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getDashDrawing(quoteId, quoteRevisionId, cancelSource.token);
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
        'getDashDrawings',
        props
      ],
      ([_k, p]) => fetcher(p),
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval:1
      }
  );
};

