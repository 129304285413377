import { useEffect } from "react";
import { Select, SelectProps } from "antd";
import Utils from "../util/util";
import useBaseCategories from "../swr/useBaseCategories";

const CategorySelector = (props: SelectProps) => {

  const categories = useBaseCategories().data;

  return (
    <Select
      allowClear
      showSearch
      {...props}
      optionFilterProp="label"
      options={categories?.map(cat => ({value:cat.id, label: Utils.stripSortingPrefix(cat.name)}))}
    />
  );
};

export default CategorySelector;
