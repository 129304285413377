import { Button, Form, Input, InputNumber, message, Modal, Result, Space, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext, PricingSnapshotListContext, PricingSnapshotsContext } from "../context";
import dayjs from 'dayjs'
import {useAsyncState} from "../hook/useAsyncState";
import {PricingConfig} from "../api/models";
import {useForm} from "antd/lib/form/Form";
import Utils from "../util/util";
import useUsers from "../swr/useUsers";

const DATE_DISPLAY_FORMAT = "MMM Do YYYY, H:mm";
const trimZero = (val:string|undefined) => {
  const rslt = val?.replace(/^0+/, '').replace(/\.*0+%$/, '%') || '';
  return rslt === '%' ? '0%' : rslt;
}

const moneyInputFormatter = (value:string|undefined) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const moneyInputParser = (value:string|undefined) => value!.replace(/\$\s?|(,*)/g, '');

const percentInputFormatter = (value:string|undefined) => trimZero( ((parseFloat(String(value)) || 0.0) * 100).toFixed(4)+"%" )
const percentInputParser = (value:string|undefined) => String( parseFloat( value!.replace(/\%\s?|(,*)/g, '') ) / 100 ) 

const validateRange = (min: number, max: number) => {
  return async (_rule: any, value: string) => {
    const n = Number(value);
    if (n >= min && n <= max) {
      return;
    } else {
      throw new Error("invalid");
    }
  };
};

/*************************************************
 *************************************************/

const SaveReminderModal = (props:{
  saveCnt:number 
}) => {
  const {saveCnt} = props;
  const [dismissCnt, setDismissCnt] = useState<number>(0)

  const SHOW_SAVE_REMINDER = 1;

  useEffect(()=> {
    if ( saveCnt > SHOW_SAVE_REMINDER  ) {
      message.success("Successfully updated.");
    }
  }, [ saveCnt ] );

  const handleDismissReminder = () => {
    setDismissCnt( saveCnt );
  }

  const showModal = saveCnt == SHOW_SAVE_REMINDER && saveCnt != dismissCnt;
  return <Modal 
    open={showModal}
    onOk={handleDismissReminder}
    cancelButtonProps={{ style: { display: 'none' } }} 
  >
    <Result title="Changes are not active."
      subTitle="Pricing configuration changes must be activated before they will affect quotes."
    />
  </Modal>
}

/*************************************************
 *************************************************/
const ConfirmActivateModal = (props: {
  showConfirmActivate:boolean
  loading:boolean
  handleConfirm:(n:string)=>void
  handleCancel:()=>void
}) => {
  const {loading, showConfirmActivate, handleCancel, handleConfirm} = props;
  const [form] = useForm();

  const handleFinish =  (values:Record<string,string>) => {
    handleConfirm( values.snapshotName );
  }

  return <Form 
    name="confirmActivate" 
    initialValues={{ snapshotName:dayjs().format(DATE_DISPLAY_FORMAT) }}
    form={form}
    onFinish={handleFinish}
  >
    <Modal 
      title="Name the configuration"
      open={showConfirmActivate}
      onCancel={handleCancel}
      footer={[
        (<Button key="2" onClick={handleCancel}>Cancel</Button>),
        (<Button key="3" type="primary" loading={loading} onClick={()=>form.submit()} >Save</Button>)
      ]}
    >
      <Form.Item
        name="snapshotName"
      >
        <Input/>
      </Form.Item>
    </Modal>
  </Form>
}
/*************************************************
 *************************************************/
const PricingConfigForm = (props:{
  loading:boolean
  onFinish:(values:Record<string,string>)=>void
  pricingConfig:PricingConfig | undefined
  handleShowConfirmActivate: ()=>void
}) => {

  const userLst = useUsers().data;
  const {loading, onFinish, pricingConfig, handleShowConfirmActivate } = props;
  const [isFormDirty, setFormDirty] = useState<boolean>(false)
  const [form] = useForm();

  useEffect(()=> {
    resetForm();
  }, [ pricingConfig ] );

  const handleValuesChange = (_changes:Record<string,string>, allValues:Record<string,string>) => {
    const diff = Utils.diff( allValues, pricingConfig );
    if( diff.length ) {

      //console.log( Utils.diff( allValues, pricingConfig ) );
      setFormDirty( true );
      return;
    }
    setFormDirty(false );
  }

  const resetForm = () => {
    form.resetFields();
    setFormDirty( false );
  }
  const handleReset = () => {
    resetForm();
  };

  const createdBy = userLst?.find( u => u.id === pricingConfig?.createdBy )?.name || pricingConfig?.createdBy;

  return <>
    <Spin spinning={loading}>

      <Space direction="vertical" size="middle" >
        <Form
          name="pricingConfig"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{flex: "200px"}}
          labelWrap={true}
          labelAlign="right"
          onValuesChange={handleValuesChange}
          initialValues={pricingConfig}
        >

          <div style={{display: "flex", justifyContent: "space-between", gap:"12px", marginBottom: "20px"}}>
            <div style={{flex:"1 2", paddingRight: "40px" }}>
              <span style={{marginRight:"4px", fontWeight: "600"}}>Updated By:</span>
              <span style={{marginRight:"4px"}}>{createdBy}</span>
              on {dayjs(pricingConfig?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </div>
            <Button onClick={handleShowConfirmActivate} disabled={isFormDirty}>
              Activate 
            </Button>
            <Button onClick={handleReset} disabled={!isFormDirty}>
              Reset
            </Button>
            <Button type="primary" disabled={!isFormDirty} onClick={()=>form.submit()}>
              Save
            </Button>
          </div>


          <Form.Item
            label="Labor Hourly Cost (Dollars)"
            name="laborHourlyCost"
            rules={[
              { required: true, message: "Labor Hourly Cost is required" },
              {
                type: "number",
                validator: validateRange(0, 1000),
                message: "Must be between 0 and 1,000.00",
              },
            ]}
          >
            <InputNumber
              formatter={moneyInputFormatter}
              parser={moneyInputParser}
              placeholder="Type dollar amount"
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="PDI"
            name="pdi"
            rules={[
              { required: true, message: "PDI is required" },
              {
                type: "number",
                validator: validateRange(0, 10000),
                message: "Must be between 0 and 10,000.00",
              },
            ]}
          >
            <InputNumber
              formatter={moneyInputFormatter}
              parser={moneyInputParser}
              placeholder="Type dollar amount"
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Warranty (fixed cost)"
            name="warranty"
            rules={[
              { required: true, message: "Warranty is required" },
              {
                type: "number",
                validator: validateRange(0, 10000),
                message: "Must be between 0 and 10,000.00",
              },
            ]}
          >
            <InputNumber
              formatter={moneyInputFormatter}
              parser={moneyInputParser}
              placeholder="Type dollar amount"
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Warranty (Percentage of Material Cost)"
            name="warrantyPercentage"
            rules={[
              { required: true, message: "Warranty (Percentage) is required" },
              {
                type: "number",
                validator: validateRange(0, 1.0),
                message: "Must be between 0 and 1.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>
          <Form.Item

            label="BEV Warranty (Percentage of Material Cost)"
            name="bevWarrantyPercentage"
            rules={[
              { required: true, message: "BEV Warranty (Percentage) is required" },
              {
                type: "number",
                validator: validateRange(0, 1.0),
                message: "Must be between 0 and 1.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item

            label="Extended Warranty Markup"
            name="extendedWarrantyMarkup"
            rules={[
              { required: true, message: "Extended Warranty Markup (Percentage)" },
              {
                type: "number",
                validator: validateRange(0, 99999.0),
                message: "Must be between 0 and 99999.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Tariffs and Surcharges"
            name="tariffsSurcharges"
            rules={[
              {
                required: true,
                message: "Tariffs and Surcharges is required",
              },
              {
                type: "number",
                validator: validateRange(0, 10000),
                message: "Must be between 0 and 10,000.00",
              },
            ]}
          >
            <InputNumber
              formatter={moneyInputFormatter}
              parser={moneyInputParser}
              placeholder="Type dollar amount"
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Margin"
            name="margin"
            rules={[
              { required: true, message: "Margin is required" },
              {
                type: "number",
                validator: validateRange(0, 0.999999),
                message: "Must be between 0 and 1.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Dealer Markup"
            name="dealerMarkup"
            rules={[
              { required: true, message: "Dealer Markup is required" },
              {
                type: "number",
                validator: validateRange(0, 0.999999),
                message: "Must be between 0 and 1.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>

          <Form.Item
            label="Inbound Freight"
            name="inboundFreight"
            rules={[
              { required: true, message: "Inbound Freight is required" },
              {
                type: "number",
                validator: validateRange(0, 1.0),
                message: "Must be between 0 and 1.0",
              },
            ]}
          >
            <InputNumber
              formatter={percentInputFormatter}
              parser={percentInputParser}
              placeholder="Type percent" 
              controls={false}
              style={{width:"100%"}}
            />
          </Form.Item>
        </Form>
      </Space>
    </Spin>
  </>
}
/*************************************************
 *************************************************/
const PricingConfigView = () => {
  const configurator = useContext(ConfiguratorContext);
  const { loadPricingSnapshotList } = useContext<PricingSnapshotListContext>(PricingSnapshotsContext);

  const [pricingConfig, pricingConfigAsync ] = useAsyncState<PricingConfig>();
  const [showConfirmActivate, setShowConfirmActivate] = useState<boolean>(false)
  const [saveCnt, setSaveCnt] = useState<number>(0)

  //show failure message
  useEffect(()=> {
    if ( pricingConfigAsync.isFail() )  {
      message.error("Pricing list failed to load. " + pricingConfigAsync.err );
    }
    if ( pricingConfigAsync.isInitial() )  {
      loadActivePricing();
    }
  }, [ pricingConfigAsync.state ] );

  const loadActivePricing = () => {
    pricingConfigAsync.setLoading();
    configurator.api.getActivePricingConfig()
    .then(resp => {
      pricingConfigAsync.setDone(resp.data)},
      reason => pricingConfigAsync.setFail(reason));
  }

  const handleSavePricingConfig = async (values: any) => {

    pricingConfigAsync.setLoading();
    configurator.api.updatePricing(values)
    .then(resp => {
      pricingConfigAsync.setDone(resp.data)

      setSaveCnt( saveCnt + 1 );
    },
    reason => pricingConfigAsync.setFail(reason));

  }

  const handleActivatePricingConfig = (snapshotName:string) => {
    setShowConfirmActivate( !showConfirmActivate );

    pricingConfigAsync.setLoading();
    configurator.api.createSnapshot( snapshotName )
    .then(resp => {
      pricingConfigAsync.setDone(resp.data)

      //reload pricing snapshots
      loadPricingSnapshotList();

      message.success("The pricing configuration is now active.");
    },
    reason => pricingConfigAsync.setFail(reason));
  }

  const handleShowConfirmActivate = () => {
    setShowConfirmActivate( !showConfirmActivate );
  }


  return <>
    <SaveReminderModal saveCnt={saveCnt} />

    <ConfirmActivateModal 
      loading={pricingConfigAsync.isLoading()}
      showConfirmActivate={showConfirmActivate} 
      handleConfirm={handleActivatePricingConfig}
      handleCancel={handleShowConfirmActivate}
    />

    <PricingConfigForm
      loading={pricingConfigAsync.isLoading()}
      onFinish={handleSavePricingConfig}
      pricingConfig={pricingConfig}
      handleShowConfirmActivate={handleShowConfirmActivate}
    />
  </>
}
export default PricingConfigView;
