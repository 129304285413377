import axios, {CancelTokenSource} from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {AXIOS_CANCEL_MSG, UploadHistory} from '../api/models';
import { ConfiguratorContext } from '../context';

export const useMasterScheduleUploadHistory = () => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async () : Promise<UploadHistory[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getMasterScheduleUploadHistory(cancelSource.token);
      cancelTokenSourceRef.current = undefined;
      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
        'getMasterScheduleUploadHistory',
//        props
      ],
      //([_k, p]) => fetcher(p),
      ([_k]) => fetcher(),
      {
      dedupingInterval:1
    }
  );
};


export default useMasterScheduleUploadHistory;

