import styles from './QuoteQuickView.module.css'
import { useIntl } from "react-intl";
import {Card, Checkbox, Col, Descriptions, Divider, Dropdown, Form, Image, Input, notification, Row, Skeleton, Space, Table} from "antd";
import {useContext, useEffect, useState } from "react";
import { AssemblyInfo, Quote, CustomOptionType, Performance, CategoryTags, CategoryIdAssembliesIdMap, MISSING_IMAGE, BaseCategory } from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import PricingView from "./PricingView";
import dayjs from 'dayjs'
import Title from "antd/lib/typography/Title";
import { DescriptionsItemType } from 'antd/es/descriptions';
import React from 'react';
import { useCabStyleImage, useModelSelection } from '../../hook/useModelInfo';
import { MoreOutlined } from "@ant-design/icons";
import BMButton from '../BMButton';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { Link } from "react-router-dom";
import { useQuoteContext } from '../../contexts/QuoteContext';
import { TotalPriceDisplay } from './QuoteInfoTab';
import SelectPricingSnapshot from '../SelectPricingSnapshot';
import { isAssembly } from '../../pages/configurator';
import { ItemType } from 'antd/es/menu/interface';
import useBaseCategories from "../../swr/useBaseCategories";
import useComputePricing from "../../swr/useComputePricing";
import useQuote from "../../swr/useQuote";

type SelectionInfo = AssemblyInfo | CustomOptionType
type SectionAssemblyMap = Record<string, Array<SelectionInfo>>;

export function asAssembly(option: AssemblyInfo | CustomOptionType | undefined) : AssemblyInfo | undefined {
  return isAssembly(option) ? option as AssemblyInfo : undefined;
}
export function asCustomOption(option: AssemblyInfo | CustomOptionType | undefined) : CustomOptionType | undefined {
  return !isAssembly(option) ? option as CustomOptionType : undefined;
}

export const QuoteHeader = (props:{
  quote?:Quote
  hidePricing?: boolean
}) => {

  const configurator = useContext(ConfiguratorContext);

  const quoteContext = useQuoteContext();
  const quote = props.quote || quoteContext.quote;


  const computedPricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision,
    options: quoteContext.quoteDetails
  });

  const imageBorderStyle = {
  };
  return <Space>
      <div style={{...imageBorderStyle, marginBottom: "1rem", marginRight: "1rem" }} >
        <TruckImage />
      </div>
      <div>
        <Row>
          <Col><Title level={4} style={{marginBottom: ".3rem"}}>{quote?.name}</Title></Col>
        </Row>
        <Row justify="space-between" style={{width: "100%"}}>
          <Col><Title level={5} style={{marginBottom: ".3rem"}}>
            {configurator.isInternalSales() 
              ? <span>(<Link to={"/configurator/" + encodeURI(quote?.quoteId || "")} target="_blank">{quote?.quoteId}</Link>)</span>
              : <span>({quote?.quoteId})</span>}
          </Title>
          </Col>
          {!props.hidePricing &&
          <Col><Title level={4} style={{marginBottom: ".3rem"}}>{Utils.formatMoney(computedPricing.data?.totalPrice)}</Title></Col>
          }
        </Row>
        {!quote?.stock &&
          <Row>
            <div className='ellipsis' style={{maxWidth: "100%"}}><span className={styles.label} >Status:</span>{quote?.displayStatus.major}</div>
          </Row> }
      </div>
    </Space>

}

const TruckImage = () => {

  const { quote } = useQuoteContext();

  const [modelInfo] = useModelSelection();
  const [imageUri, setImageUri] = useState<string>();

  const cabFuelImageLst = useCabStyleImage();

  useEffect(() => {
    if ( !quote ) return;

    getTruckImage( quote.selections );
  }, [quote?.id]);

  const getTruckImage = async (selections:CategoryIdAssembliesIdMap) => {

    const [cabStyle, fuelType] = await Promise.all([
      modelInfo.fetchCabStyle( selections ),
      modelInfo.fetchFuelType( selections )
    ]);

    if ( !cabStyle || !fuelType ) return;

    const truckImg = cabFuelImageLst?.filter( cfi => cfi.fuelType === fuelType && cfi.cabStyle === cabStyle ).find(v=>v);

    setImageUri(truckImg?.imageUri);
  }

  return <Image src={imageUri} 
    preview={false} 
    width={120}  
    fallback={MISSING_IMAGE}
  />
}

export const ConfigurationTabContents = ( props: {
  hidePricing?:boolean
}) => {

  const { quote } = useQuoteContext();

  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = ( configurator.isAdmin() || configurator.isEngineering() );

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [showHiddenParam, setShowHiddenParam] = useQueryParam<boolean | undefined | null>("showHidden", BooleanParam);
  const [showConfiguratorOnlyParam, setShowConfiguratorOnlyParam] = useQueryParam<boolean | undefined | null>("showConfiguratorOnly", BooleanParam);
  const [showMajorsOnlyParam, setShowMajorsOnlyParam] = useQueryParam<boolean | undefined | null>("showMajorsOnly", BooleanParam);

  const [selectionInfo, selectionInfoAsync] = useAsyncState<AssemblyInfo[]>();
  const [filter, setFilter] = useState<string | undefined>(searchFilterParam || undefined);
  const [isShowHiddenCategories, setShowHiddenCategories] = useState<boolean>(!!showHiddenParam);
  const [isShowConfiguratorOnly, setShowConfiguratorOnly] = useState<boolean>(showConfiguratorOnlyParam ?? true);
  const [isShowMajorsOnly, setShowMajorsOnly] = useState<boolean>(showMajorsOnlyParam ?? false);
  const [hidePricing, setHidePricing] = useState<boolean>();
  const [customOptionLst, customOptionLstAsync] = useAsyncState<CustomOptionType[]>();
  const selectedCustomOptions = customOptionLst?.filter(co => co.included );

  const intl = useIntl();

  const categories = useBaseCategories();


  const categoryMap:Record<number,BaseCategory> | undefined = categories.data?.reduce( (acc, v ) => {
      acc[v.id] = v
      return acc;
    }, {} );

  //load data one showing modal and data is init/fail
  useEffect(() => {

    const refetch = selectionInfoAsync.isInitial()
    if ( refetch ) {
      loadSelectedOptions(quote?.displayRevisionId);
      loadCustomOptions(quote?.displayRevisionId);
    }
    setHidePricing( props.hidePricing || !!quote?.stock );
  }, [quote?.quoteId]);

  const loadSelectedOptions = async (quoteRevisionId:number | undefined) : Promise<AssemblyInfo[] | undefined> => {
    if ( !quoteRevisionId ) return;

    selectionInfoAsync.setLoading()

    try {
      const resp = await configurator.api.fetchSelectionsByQuoteRevisionId( quoteRevisionId, { hideNoOption: true } )
      selectionInfoAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get selection info. " + errorMsg });
      selectionInfoAsync.setFail( e.message );
    }
    return;
  }

  //note: selections is required for pricing
  const loadCustomOptions = async (quoteRevisionId:number | undefined) : Promise<CustomOptionType[] | undefined> => {
    if ( !quoteRevisionId ) return;

    customOptionLstAsync.setLoading()
    try {
      const resp = await configurator.api.getCustomOptions(quoteRevisionId)
      customOptionLstAsync.setDone(resp.data);
    
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch custom options. " + errorMsg;
      notification.error( { message: msg });
      customOptionLstAsync.setFail(msg);
    }

    return;
  }

  const handleChangeFilter = (filter:string) => {
    setSearchFilterParam(filter);
    setFilter(filter);
  }

  const handleShowConfiguratorOnly = (enable:boolean) => {
    setShowConfiguratorOnlyParam(enable);
    setShowConfiguratorOnly(enable);
  }

  const handleHidePricing = (hide:boolean) => {
    setHidePricing(hide);
  }

  const handleShowHidden = (enable:boolean) => {
    setShowHiddenParam(enable);
    setShowHiddenCategories(enable);
  }

  const handleShowMajorsOnly = (enable:boolean) => {
    setShowMajorsOnlyParam(enable);
    setShowMajorsOnly(enable);
  }

  const isViewableSection = (s:SelectionInfo) : boolean => {
    const categoryId = s.category?.id
    if (!categoryId) return false;

    const category = categoryMap?.[categoryId];
    if ( !category ) return false;

    if ( isAdminOrEngineering ) return true;
    if( category.hidden ) return false;
    return true;
  }

  const searchFilter = Utils.splitByWhitespacePreservingQuotes(filter);
  const isSelectionHidden = (s:SelectionInfo) : boolean => {
    const categoryId = s.category?.id
    if (!categoryId) return false;

    const category = categoryMap?.[categoryId];
    if ( !category ) return false;

    const asm = asAssembly(s);
    if ( !isShowHiddenCategories && asm && category.hidden ) return false;

    if ( !isShowConfiguratorOnly && asm?.bom.startsWith('9999-CUSTOM') ) return false;

    if ( isShowMajorsOnly && !asm?.category?.tags?.includes( CategoryTags.Major ) ) return false;

    const asmSearch = asm && [
      category.name,
      asm.bom,
      asm.label,
      asm.bomDescription,
    ]
      .filter( v => v )
      .join( " ");

    const co = asCustomOption(s);
    const coSearch = co && [
      category.name,
      co.content,
    ]
      .filter( v => v )
      .join( " ");

    const searchTxt = asmSearch || coSearch;

    return Utils.searchValue( searchFilter, searchTxt );
  }

  const sections:SectionAssemblyMap | undefined = [
    ...(selectionInfo || []),
    ...(selectedCustomOptions  || [])
  ]
  ?.filter( isViewableSection )
  ?.filter( isSelectionHidden )
  .reduce( (acc, s ) => {

    const categoryId = s.category?.id;
    if (!categoryId) return acc;

    const category = categoryMap?.[categoryId];
    if ( !category ) return acc;

    const section = category.configuratorSection;
    acc[ section ] = (acc[ section ] || [] ).concat( [ s ] );
    return acc;
  }, {} );

  const sectionTitles = categories.data
  ?.map( c => c.configuratorSection )
  //filter out sections with no options
  ?.filter( s => sections?.[ s ] )
  .reduce( (acc, s) => {
    if( !acc.includes(s) ) {
      acc.push(s);
    }
    return acc;
  },new Array<string>());
  Object.values( sections || {} ).forEach(s => s.sort( (a,b) => (a.category?.name || "").localeCompare((b.category?.name || ""))) );

  const optionActionItems = new Array<ItemType>();

  if ( isAdminOrEngineering ) {
    optionActionItems.push(
      {
        key: "showHiddenCategories",
        label:
        <div >
          <label >
            <Checkbox
              onChange={(e) => handleShowHidden(e.target.checked)}
              checked={isShowHiddenCategories}
              style={{padding:0}}
            />
            <span style={{marginLeft: "0.5rem"}}>
              Show Hidden Categories
            </span>
          </label>
        </div>
      });

    optionActionItems.push( {
      key: "showConfiguratorOnly",
      label:
      <div >
        <label >
          <Checkbox
            onChange={(e) => handleShowConfiguratorOnly(e.target.checked)}
            checked={isShowConfiguratorOnly}
            style={{padding:0}}
          />
          <span style={{marginLeft: "0.5rem"}}>
            Show Configurator-Only BOM
          </span>
        </label>
      </div>
    });
  }

  if( !quote?.stock ) {
    optionActionItems.push( {
      key: "hidePricing",
      label:
      <div >
        <label >
          <Checkbox
            onChange={(e) => handleHidePricing(e.target.checked)}
            checked={hidePricing}
            style={{padding:0}}
          />
          <span style={{marginLeft: "0.5rem"}}>
            Hide Pricing
          </span>
        </label>
      </div>
    });
  }

  optionActionItems.push( {
    key: "showMajorsOnly",
    label:
    <div >
      <label >
        <Checkbox
          onChange={(e) => handleShowMajorsOnly(e.target.checked)}
          checked={isShowMajorsOnly}
          style={{padding:0}}
        />
        <span style={{marginLeft: "0.5rem"}}>
          Show Majors Only
        </span>
      </label>
    </div>
  });

  return <>
    <Skeleton active loading={selectionInfoAsync.isLoading()}>
      <Space direction='vertical'>

      <div style={{display: "flex", gap: ".5rem" }} data-html2canvas-ignore={true} >
        <Input onChange={(e) => handleChangeFilter(e.target.value)}
          placeholder="Filter assemblies" allowClear
        />

          {!!optionActionItems.length &&
            <Dropdown trigger={["click"]}
              menu={{items:optionActionItems}}
            >
              {/* this div is to avoid a warning with strict mode */}
              <div>
                <BMButton 
                  icon={<MoreOutlined/>} 
                  data-testid="quote-options-btn"
                >Options</BMButton>
              </div>
            </Dropdown>
          }

      </div>

      <div style={{display: "flex", justifyContent:"space-between", flexWrap: "wrap" }}>
      
        {sectionTitles?.map( (title, ndx) => {
          const assemblies = sections?.[ title ]
          if ( !assemblies?.length ) return <></>

          return <React.Fragment key={[title, ndx].join("-")}>
              <Table style={{minWidth: "20rem", width: "48%", marginBottom: "1rem"}}
                rowKey="id"
                size="small"
                columns={[
                  {
                    title,
                    render: (selection) => {


                    const asm = asAssembly(selection);
                    const asmInfo = asm && {
                      categoryName: asm.category?.name,
                      bom: asm.bom,
                      label: asm.label || asm.bomDescription,
                      upgradeDealerPrice: asm.upgradeDealerPrice,
                      notes: undefined
                    };

                    const co = asCustomOption(selection);
                    const coInfo = co && {
                      categoryName: co.category?.name,
                      bom: <span style={{color:"blue", fontWeight: 600}}>Custom Option</span>,
                      label: co.content,
                      upgradeDealerPrice: co.upgradeDealerPrice,
                      notes: co.note,
                    };

                    const info = asmInfo || coInfo;
                    if ( !info ) return <></>;

                    return <div>
                      <div><span style={{fontWeight: 600}}>{Utils.stripSortingPrefix(info.categoryName)}:</span> {info.bom}</div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                          <div>
                            <div>{info.label}</div>
                            {info.notes && <div style={{fontStyle: "italic", fontSize: ".8rem"}}>Notes: {info.notes}</div>}
                          </div>

                        {!hidePricing &&
                          <div style={{textAlign: "right"}}>
                            {(info.upgradeDealerPrice || 0) !== 0 ? Utils.formatMoneyWithSign( info.upgradeDealerPrice ) : "Standard"}
                          </div>
                        }
                      </div>
                    </div>;
                  }
                  }
                ]}
                pagination={false}
                dataSource={assemblies}
              />
          </React.Fragment>
      })}
      </div>

      </Space>
     
    </Skeleton>
  </>

}

export const PricingTabContents = () => {
  const configurator = useContext(ConfiguratorContext);

  const { quote, quoteDetails } = useQuoteContext();

  const computedPricing = useComputePricing({
    quoteId: quote?.quoteId,
    rev: quote?.revision,
    options: quoteDetails
  });

  const isDealer = configurator.isDealerSales();

  return <Skeleton active loading={!computedPricing.data || computedPricing?.isLoading} style={{minWidth: "20rem"}}>
    <Title level={5} style={{marginBottom: "1rem"}}> <TotalPriceDisplay quotePricingDetails={computedPricing.data}/></Title>

    <Divider />

    {!isDealer &&
        <Form.Item
            label={<strong>Pricing Configuration</strong>}
        >
          <SelectPricingSnapshot
              value={quote?.pricingConfig?.pricingSnapshot?.id}
              disabled={true}
          />
        </Form.Item>}

    <PricingView
        pricingDetails={computedPricing.data}
        disabled={true}
    />
  </Skeleton>

}

export const DetailsTabContents = (props: {
  isReleaseView?: boolean
  isStockView?: boolean
}) => {

  const { quote } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const incentivePrograms = quote?.incentivePrograms.map(i => i.name ).join(", ");

  const items:DescriptionsItemType[] = [];

  items.push( {
      label: "Model",
      children: [quote?.modelYear, quote?.model.name].filter(v=>v).join(" ")
    }
  );

  if ( quote?.partNumberString ) {
    items.push( {
      label: "Part No",
      children: quote?.partNumberString
    })
  }

  if ( quote?.serialNumberStr ) {
    items.push( {
      label: "Serial Numbers",
      children: quote?.serialNumberStr
    })
  }




  if( !!quote?.incentivePrograms.length && !props.isReleaseView ) {
    items.push({
      label: "Incentive Program",
      children: incentivePrograms
    })
  }

  if( !quote?.stock ) {
    items.push({
      label: "Quantity",
      children: quote?.quantity
    })
  }

  if( !props.isStockView  && !props.isReleaseView ) {
    items.push({
      label: "Concession",
      children: Utils.formatPercent( ( quote?.percentDiscount || 0 ) / 100)
    })
  }

  if( quote?.poNumber && !props.isStockView && !props.isReleaseView ) {
    items.push({
      label: "PO Number",
      children: quote?.poNumber?.poNumber
    })
  }

  if( quote?.dealerCustomer && !props.isStockView && !props.isReleaseView ) {
    const dealerCustomer = quote.dealerCustomer;
    items.push({
      label: "Dealer",
      children: <div>
                  <div>
                    <div>{quote.owner?.dealerName}</div>
                    {!!dealerCustomer?.addressLine1 && <div>{dealerCustomer.addressLine1}</div>}
                    {!!dealerCustomer?.addressLine2 && <div>{dealerCustomer.addressLine2}</div>}
                    <div>{[[dealerCustomer?.city, dealerCustomer?.stateProvince].filter(v=>v).join(", "), dealerCustomer?.postalCode].filter(v=>v).join(" ")}</div>
                  </div>
                  <div>
                    {!!dealerCustomer?.contactName && <div>{[dealerCustomer.contactName, dealerCustomer.contactRole && ["(", dealerCustomer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                    {!!dealerCustomer?.contactPhone && <div>{dealerCustomer.contactPhone}</div>}
                    {!!dealerCustomer?.contactEmail && <div>{dealerCustomer.contactEmail}</div>}
                  </div>
                </div>
    })
  }

  if( quote?.endCustomer && !props.isStockView && !props.isReleaseView ) {
    const customer = quote.endCustomer;
    items.push({
      label: "End Customer",
      children: <div>
              <div>
                <div>{customer?.name}</div>
                {!!customer?.addressLine1 && <div>{customer.addressLine1}</div>}
                {!!customer?.addressLine2 && <div>{customer.addressLine2}</div>}
                <div>{[[customer?.city, customer?.stateProvince].filter(v=>v).join(", "), customer?.postalCode].filter(v=>v).join(" ")}</div>
              </div>
              <div>
                {!!customer?.contactName && <div>{[customer.contactName, customer.contactRole && ["(", customer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                {!!customer?.contactPhone && <div>{customer.contactPhone}</div>}
                {!!customer?.contactEmail && <div>{customer.contactEmail}</div>}
              </div>
            </div>
    })
  }

  if( quote?.shippingDestination  && !props.isStockView && !props.isReleaseView ) {
    items.push({
      label: "Destination",
      children: quote?.shippingDestination.name
    })
  }


  if( quote?.productionDate ) {
    items.push({
      label: "Production Date",
      children: dayjs(quote?.productionDate).format("MMMM Do YYYY")
    })
  }

  if( quote?.shippingDate && !props.isStockView) {
    items.push({
      label: "Shipping Date",
      children: dayjs(quote?.shippingDate).format("MMMM Do YYYY")
    })
  }

  if( quote?.owner && !props.isStockView && !props.isReleaseView  ) {
    items.push({
      span: 5,
      label: "Salesperson",
      children: [quote.owner.name, quote.owner.dealerName].filter(v=>v).join(" - ")
    })
  }
  if( !!quote?.salesTeam?.sales?.length && !props.isReleaseView ) {
    items.push({
      span: 5,
      label: "BM Representative",
      children: quote?.salesTeam.sales.map(s => s.name).join(", ")
    })
  }
  if( quote?.notes?.trim().length && !props.isReleaseView ) {
    items.push({
      label: "Notes",
      children:quote?.notes
    })
  }

  const loading = quoteAsync?.isLoading;
  return <>
      <Skeleton active loading={loading} >
       <div className={styles["details-tab-contents"]}>
        <Descriptions className={styles['quoteQuickView-description']}  column={3}
          items={items} 
          layout="vertical" size="small" colon={false} 
          style={{padding: 0}}
        />
      </div>
    </Skeleton>
  </>

}


const parseNumber = (str:string | undefined) => {
  const n = str?.replace(/,/, "")
  return n && Number(n);
}
export const PerformanceContents = (props: {
  performance:Performance | undefined
}) => {

  const { performance } = props;

  if ( !performance ) return <>Incomplete performance data.</>;

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const rearAxleWeight = ( performance.performanceWeight?.axle.lastDrive )
    ? formatter.format( ( parseNumber( performance.performanceWeight?.axle.firstIntermediate ) || 0 ) + ( parseNumber(performance.performanceWeight?.axle.lastDrive) || 0 ) )
    : undefined;

  const weightItems:DescriptionsItemType[] = [
    {
      key: "frontAxleWeight",
      label: "Front Axle Weight (lbs.)",
      children: 
      performance.performanceWeight?.axle.front
        ? performance.performanceWeight?.axle.front
        : "Incomplete"
    },
    {
      key: "rearAxleWeight",
      label: "Rear Axle Weight (lbs.)",
      children: 
      rearAxleWeight
        ? rearAxleWeight
        : "Incomplete"
    },
    {
      key: "truckGVWR",
      label: "Truck GVWR (lbs.)",
      children: 
      performance.performanceWeight?.truckGVWR
        ? performance.performanceWeight?.truckGVWR
        : "Incomplete"
    },
  ];

  const dimensionItems:DescriptionsItemType[] = [
    {
      key: "wheelbase-dimension",
      label: "Wheelbase(WB) (inch)",
      children: 
      performance.performanceDimension?.wheelbase 
        ? performance.performanceDimension?.wheelbase
        : "Incomplete"
    },
    {
      key: "frameSillLength",
      label: "Frame Sill Length (ft)",
      children: 
      performance.performanceDimension?.frameSillLength
        ? performance.performanceDimension?.frameSillLength
        : "Incomplete"
    },
    {
      key: "afterFrame",
      label: "Afterframe(AF) (inch)",
      children: 
      performance.performanceDimension?.afterFrame
        ? performance.performanceDimension?.afterFrame
        : "Incomplete"
    },
    {
      key: "cabToAxle",
      label: "Cab to Axle(CA) (inch)",
      children: 
      performance.performanceDimension?.cabToAxle
        ? performance.performanceDimension?.cabToAxle
        : "Incomplete"
    },
  ];

  return <Card title="Performance" 
    size="small"
      style={{maxWidth: "35rem"}}
  >
    <Row gutter={40}>
      <Col style={{maxWidth: "16rem"}}>
        <Descriptions
          size="small"
          column={1}
          className={styles['performance-description']}
          contentStyle={{textAlign: "right", display: "inline-block"}}
          items={dimensionItems}
        />
      </Col>
      <Col style={{maxWidth: "17rem"}}>
        <Descriptions
          size="small"
          column={1}
          className={styles['performance-description']}
          contentStyle={{textAlign: "right", display: "inline-block"}}
          items={weightItems}
        />
      </Col>
    </Row>
  </Card>
}

export const ComponentLocationContents = (props: {
  componentLocations:Record<string, string> | undefined
}) => {

  const { componentLocations } = props;

  if ( !componentLocations ) return <>Incomplete component location data.</>;

  const items = componentLocations && Object.keys(componentLocations).map(cl => 
    ({
      key: cl,
      label: cl,
      children: componentLocations[ cl ],
    })
  );

  return <Card title="Component Locations" 
    size="small"
      style={{maxWidth: "30rem"}}
  >
    <Descriptions
      size="small"
      column={1}
      items={items}
      className={styles['performance-description']}
      labelStyle={{flexGrow: 1, maxWidth: "10rem"}}
    />
  </Card>;
}

