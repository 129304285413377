import {Button, Drawer, notification, Result, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Quote, } from "../../api/models";
import {ConfiguratorContext} from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import Utils from "../../util/util";
import StatesSelector from "../StatesSelector";
import { WizardInstance } from "../Wizard";
import { useQuoteContext } from "../../contexts/QuoteContext";
import DocumentSignUI from "../DocusignUI";
import { useRequestPoSteps, RequestPurchaseOrderFormValue } from "../RequestPoModalButton";
import { RequestPurchaseOrder } from "../../api";
import useLatestApprovals from "../../swr/useLatestApprovals";
import useQuoteReview from "../../swr/useQuoteReview";
import useQuote from "../../swr/useQuote";


const SubmitDocusignOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quote, setQuoteFormValues, adminView } = useQuoteContext();
  const quoteAsync = useQuote({ quoteId: quote?.quoteId, revision: quote?.revision });

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [docusignLoading, setDocusignLoading] = useState<boolean>(false);
  const [selectedStateRegistration, setSelectedStateRegistration] = useState<string>();
  const [purchaseOrderRequest, setPurchaseOrderRequest] = useState<RequestPurchaseOrder>();

  const approvals = useLatestApprovals({
    quoteRevisionId: quote?.displayRevisionId,
  });

  const review = useQuoteReview({
    quoteRevisionId: quote?.displayRevisionId,
    options: {
      dealerView: !adminView
    }
  })

  const getInvalidStateRegistrationMsg = (v:string | undefined) : string | undefined => {
    return v === 'TBD' ? "Please select a valid state registration before continuing."
        : undefined;
  }


  const addTrucks = async (quoteId:string | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId) return;

    try {
      const resp = await configurator.api.addTrucks(quoteId);
      await quoteAsync.mutate(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
    }
    return;
  }

  const requestPurchaseOrder = async (quoteRevisionId: number | undefined, req:RequestPurchaseOrderFormValue) : Promise<void> => {
    if ( !quoteRevisionId) return;

    setDocusignLoading(true);
    try {
      await configurator.api.requestPo(quoteRevisionId, req)
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
    }
    setDocusignLoading(false);
  }


  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOpen = async () => {
    if (!quote) return;

    setSelectedStateRegistration(quote?.stateRegistration);

    setIsOpen( true );
  }

  const handleSelectStateRegistration = async (nav:WizardInstance) => {
    if (!quote) return;

    if ( quote.stateRegistration !== selectedStateRegistration ) {
      await setQuoteFormValues?.({
        ...quote,
        stateRegistration: selectedStateRegistration
      });
    }

    await addTrucks(quote.quoteId);

    nav.nextStep();
  }

  const handleFinished = async (formValues:RequestPurchaseOrderFormValue) => {
    setIsOpen(false);

    //dealers sign the PO in-app
    const isDealer = configurator.isDealerSales();
    if ( isDealer ) {
      setPurchaseOrderRequest(formValues)
      setShowDrawer(true);
    }
    else {
      await requestPurchaseOrder( quote?.displayRevisionId, formValues );

      const saved = await submit();
      if ( saved ) {
        props.onChange?.(saved);
      }
    }
  }

  const handleDoneSigning = async () => {
    setShowDrawer(false);
    const quote = await submit();
    if ( quote ) {
      props.onChange?.(quote);
    }
  }

  const handleSkipPo = async () => {
    const quote = await submit();
    if ( quote ) {
      props.onChange?.(quote);
    }
    setIsOpen(false);
  }

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    try {
      const resp = await configurator.api.submitOrder(quote.displayRevisionId);

      notification["success"]({
        message: "Submitted",
      });

      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit order. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };

  const isWorking = !!props.loading || quoteAsync?.isLoading || review.isLoading || docusignLoading || showDrawer;

  const {steps: docusignSteps} = useRequestPoSteps({
    loading: isWorking,
    onFinished: handleFinished,
    onSkip: handleSkipPo
  })

  return <>
    <BMButton
      type="primary"
      onClick={handleOpen}
      {...btnProps}
      loading={isWorking}
    >
      Submit Order
    </BMButton>
    <ModalWizard
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={"80%"}
      style={{
        maxWidth: "70rem",
        minWidth: "50rem"
      }}
      steps={[
        {
          key:"review",
          hidden: !Utils.reviewHasErrors(review.data),
          body: (_nav) => <Result status="warning"
            title={"The following issues with the order have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail
              review={review.data}
              ignoreDashDrawing={true}
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"stateRegistration",
          body:(_nav) => <div>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
            <Title level={5}>Is the state of registration correct?</Title>
              <StatesSelector data-testid="submitStateRegistrationSelector" includeTbd={false} allowClear={false} value={selectedStateRegistration} 
                onChange={setSelectedStateRegistration} 
                style={{width: "10rem"}}
              />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <BMButton key="next" type="primary"
                      disabled={!!getInvalidStateRegistrationMsg(selectedStateRegistration)}
                      onDisabledClick={() => Utils.notifyDisabled(getInvalidStateRegistrationMsg(selectedStateRegistration))}
                      loading={isWorking}
                      onClick={() => handleSelectStateRegistration(nav)} >Next</BMButton>
            <Button key="back" type="primary" onClick={() => nav.prevStep() } >Back</Button>
          </div>,
        },
        ...docusignSteps,
      ]}    
    />
    <Drawer
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={"100%"}
      destroyOnClose={true}
    >
      <Spin spinning={drawerLoading} tip="One moment.  Preparing your order." >
        <div style={{display: "flex", justifyContent: "center", padding: "1rem"}}>
          <DocumentSignUI quoteRevisionId={quote?.displayRevisionId} details={purchaseOrderRequest} onDone={handleDoneSigning} onLoad={(l) => setDrawerLoading(l) } />
        </div>
      </Spin>

    </Drawer>
  </>
}


export default SubmitDocusignOrderButton;
