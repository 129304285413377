import {Select, SelectProps} from "antd";

export enum QuoteStatusSelections {
  QUOTE =           'Quote',
  APPROVED ='Quote (Approved)',
  ORDER =           'Order',
  SHIPPED =         'Shipped',
  CANCELLED =       'Cancelled',
  Expired =  'Quote (Expired)',
  Reservation =  'Reservation'
}

const QuoteStatusSelector = (props: SelectProps & {
  includingCancelledOrder?:boolean | undefined
}) => {

  const { includingCancelledOrder, ...selectProps } = props;

  const options = Object.values(QuoteStatusSelections)
      .filter(v => includingCancelledOrder || v !== QuoteStatusSelections.CANCELLED )
      .map(status => ({
        value: status,
        label: status
      }));

  return <Select
          {...selectProps}
          allowClear
          showSearch={true}
          optionFilterProp="label"
          mode="multiple"
          options={options}
    />
};

export default QuoteStatusSelector;
