import {
  notification,
  Button,
  Table,
  Space,
} from "antd";
import { useContext, useEffect, useState, } from "react";
import { ConfiguratorContext, S3UrlMapContext, } from "../context";
import { QuoteComment, CommentTopic } from "../api/models"
import _ from "lodash";
import { useIntl } from "react-intl";
import {  PlusOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import QuoteCommentCard, {EditQuoteCommentCard} from "./QuoteCommentCard";
import Utils from "../util/util";
import BmMentions from "./BmMentions";
import useUsers from "../swr/useUsers";
import useQuoteComments from "../swr/useQuoteComments";
import useQuoteCommentDocumentUrls from "../swr/useQuoteCommentDocumentUrls";

export interface CommentActivityListProps {
  topic: CommentTopic
  quoteId:string | undefined
  tags?:string[] | undefined
  topicTags?: string[] | undefined
  hideSearch?:boolean
}
const CommentActivityList = (props: CommentActivityListProps) => {

  const { quoteId } = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [editCommentId, setEditCommentId] = useState<number | undefined>();
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>();

  const commentOptions = {
    showHidden: configurator.isAdmin(),
    topic: [ props.topic ],
  };

  const users = useUsers().data;

  const quoteComments = useQuoteComments({
    quoteId: props.quoteId,
    options: commentOptions,
  })

  const s3UrlMap = useQuoteCommentDocumentUrls({
    quoteId: props.quoteId,
    options: commentOptions,
  })

  useEffect(() => {
    setSearch(props.tags?.join(" "));
  }, [props.tags] );

  useEffect(() => {
    setShowAddComment(false);
  }, [props.tags, props.quoteId, props.topic] );

  const handleShowAddComment = () => {
    setEditCommentId(undefined);
    setShowAddComment(true);
  }

  const handleEditComment = (commentId:number) => {
    setEditCommentId(commentId);
    setShowAddComment(false);
  }

    useEffect(() => {
        if ( quoteId?.length ) {
              Promise.all( quoteComments.data?.map( c => {
                updateQuoteCommentLastViewed(quoteId, c.id);
              } ) || [] );
        }
      }, [props.quoteId, commentOptions.showHidden, props.topic, quoteComments.isLoading] );


  const updateQuoteCommentLastViewed = async (quoteId:string, commentId:number) : Promise<QuoteComment | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.updateQuoteCommentLastViewed(quoteId, commentId);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update quote comment last viewed. " + errorMsg });
    }
    return;
  }

  const handleAddedComment = (comment:QuoteComment) => {
    quoteComments.mutate();
    setShowAddComment(false);
  }

  const handleEditedComment = (comment:QuoteComment) => {
    quoteComments.mutate();
    setEditCommentId(undefined);
  }

  const datasource = quoteComments.data
    ?.filter( c => {
      if ( !search ) return true;
      const searchFilter = Utils.splitByWhitespacePreservingQuotes(search);
      const searchTxt = [c.text].concat(c.tags?.flat()).join(" ");
      return Utils.searchValue( searchFilter, searchTxt );
    })
    .sort((a,b) => dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1);

  const pageSize = 7;
  const pagination = ((datasource?.length || 0) > pageSize) ? { pageSize } : false; 



  return <>
    <S3UrlMapContext.Provider value={{s3UrlMap: s3UrlMap.data}}>
      <Space direction="vertical" style={{width: "100%"}} size="small">

        <div style={{display:"flex", flexDirection: "row-reverse", alignItems: "center" }} >
          {!showAddComment &&
            <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small" style={{marginLeft: "8px", marginRight: "8px"}}
              onClick={handleShowAddComment}
            />}

        {(!props.hideSearch && !showAddComment) &&
          <BmMentions 
            allowClear
            rows={1}
            value={search} 
            placeholder="Search Comments" 
            onChange={(txt) => setSearch( txt)} 
            mentions={{
              '@': users?.map( u => ({value: _.upperFirst(_.camelCase( u.name)), label: u.name })),
              '#': props.tags?.map( t => ({value: _.upperFirst(_.camelCase( t )), label: t }))
              }}
          /> }

        </div> 

        {showAddComment &&
          <EditQuoteCommentCard 
            quoteId={quoteId}
            comment={{
              topic: props.topic,
              internal: true,
              tags: props.tags,
            }}
            reset={showAddComment}
            topicTags={props.topicTags}
            onCancel={() => setShowAddComment(false)}
            onSave={handleAddedComment}
          />}

        <Table
          className="mobile-table"
          size="small"
          showHeader={false}
          bordered={false}
          rowKey="id"
          loading={quoteComments.isLoading}
          dataSource={datasource}
          pagination={pagination}
          columns={[{ render: (comment) => 
            (editCommentId === comment.id ) 
              ? <EditQuoteCommentCard comment={comment} 
                quoteId={quoteId}
                topicTags={props.topicTags}
                onCancel={() => setEditCommentId(undefined)}
                onSave={handleEditedComment}
              /> 
              : <QuoteCommentCard comment={comment} 
                onEdit={handleEditComment} 
              />
          }]}
        />
      </Space>
      </S3UrlMapContext.Provider>
  </>
}



export default CommentActivityList;
