import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {CategoryOptionsFilter,  ComputeOptionsRequest,} from '../api';
import {
  AssemblyOption,
  AXIOS_CANCEL_MSG, ModelComputeOptionsResponse,
  Page
} from '../api/models';
import { ConfiguratorContext } from '../context';
import {SorterResult} from "antd/es/table/interface";
import {SelectionInfo} from "../components/Quote/AssemblySelectionTable";


interface CategoryOptionsProps {
  modelId:number | undefined,
  categoryId: number | undefined,
  selections?: number[],
  customOptions?: number[],
  quoteRevisionId?: number,
  percentDiscount?: number,
  debugRules?: boolean,
}

export const useCategoryOptions = (props: CategoryOptionsProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:CategoryOptionsProps  ) : Promise<ModelComputeOptionsResponse | undefined> => {

    const { modelId, categoryId, ...requestOptions } = props;
    if (!categoryId) return;
    if (!modelId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchCategoryOptions(modelId, categoryId, requestOptions, cancelSource.token );

      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'fetchCategoryOptions',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useCategoryOptions;

