import "../util/mobile-table.css";
import styles from "./quotes.module.css";
import Title from "antd/lib/typography/Title";
import {
  Button,
  ButtonProps,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Row,
  Space,
  Table,
  TablePaginationConfig
} from "antd";
import {createContext, ReactElement, useContext, useEffect, useState} from "react";
import {ConfiguratorContext} from "../context";
import {Link} from "react-router-dom";
import {NumberParam, NumericArrayParam, StringParam, useQueryParam} from "use-query-params";
import {Page, QuoteFilter, Truck, TruckTags} from "../api/models";
import {debounce,} from 'lodash';
import {ColumnType} from 'antd/lib/table';
import {FilterValue, SorterResult} from 'antd/lib/table/interface';
import useCheckMobileScreen from '../hook/useCheckMobileScreen';
import AssemblySelector from "../components/assembly_selector";
import ExportQuotesModal from "../components/Quote/ExportQuotesModal";
import {useForm} from "antd/es/form/Form";
import IncentiveProgramSelector from "../components/incentive_program_selector";
import {useIntl} from "react-intl";
import dayjs from "dayjs";
import {AdvancedSearchConfig} from "../components/QuoteFilterControls";
import {ListQuotesResponse, TruckRequest} from "../api";
import {SortOrder} from "antd/es/table/interface";
import Utils from "../util/util";
import useQuoteList from "../swr/useQuoteList";
import {TableProps} from "antd/lib";
import {ListQuotesResponseSort} from "./quotes";
import {SWRResponse} from "swr";

type QuoteSort = SorterResult<ListQuotesResponse> | SorterResult<ListQuotesResponse>[]

const DEFAULT_SORT = {
    columnKey: 'latestRevision.summary.productionDate',
    order: 'ascend' as SortOrder
};

interface QuoteListContextType {
  quoteLst?:SWRResponse<Page<ListQuotesResponse> | undefined>
}

const QuoteListContext = createContext<QuoteListContextType>({
});
const useQuoteListContext = () : QuoteListContextType => {
  return useContext(QuoteListContext);
}

// Data provider component
const QuoteListContextProvider = (props:{ value:QuoteListContextType, children: ReactElement | ReactElement[] }) => {

  const quoteLstContext = props.value;
  return <QuoteListContext.Provider value={quoteLstContext}>{props.children}</QuoteListContext.Provider>;
};


const Inventory = () => {
  const isMobile = useCheckMobileScreen();
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [sort, setSort] = useState<QuoteSort>(DEFAULT_SORT);

  const [filter, setFilter] = useState<QuoteFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    filterAssemblies: filterAssemblies as number[] || undefined,
  });


  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const quoteLst = useQuoteList({
    filter: { ...filter,
      truckTags:[TruckTags.Fgi],
      rowPerUnit: true
    },
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);

  const handleTableChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: ListQuotesResponseSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const onFilterChange = debounce( (_values: Record<string, any>, filter:QuoteFilter) => {
    setSearchFilterParam(filter.search);
    setAssembliesFilter(filter.filterAssemblies);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setFilter(filter);
  }, 300);

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <div>
          <Title level={2}>Inventory</Title>
          <Title level={5} style={{marginTop: 0}}>If any of these trucks meet your needs, reach out to your regional sales manager for more information.</Title>
        </div>
        <FilterControls
          filter={filter}
          onFilterChange={onFilterChange}
        />
        <QuoteListContextProvider value={{quoteLst}}>
        {isMobile
          ? <MobileTable
                dataSource={quoteLst.data?.content}
                loading={quoteLst.isLoading}
                onChange={handleTableChange}
                pagination={{...pagination, total: quoteLst.data?.totalElements}}
          />
          : <DesktopTable
                dataSource={quoteLst.data?.content}
                loading={quoteLst.isLoading}
                onChange={handleTableChange}
                pagination={{...pagination, total: quoteLst.data?.totalElements}}
                filter={filter}
                sort={sort}
          />
        }
        </QuoteListContextProvider>
      </Space>
    </div>
  );
};

const FilterControls = (props: {
  filter?: QuoteFilter
  onFilterChange: (_values: Record<string, any>, filter:QuoteFilter) => void
}) => {
  const { filter } = props;

  const [filterForm] = useForm();
  const showAdvancedPanel = filter?.filterAssemblies?.length ||
    filter?.quoteStatus ||
    filter?.salespersons?.length ||
    filter?.engineers?.length ||
    filter?.ordersOnly ||
    filter?.incentivePrograms?.length ||
    filter?.includingCancelledOrder;

  return <>
    <Form 
      initialValues={props.filter}
      form={filterForm} 
      onValuesChange={props.onFilterChange}
    >
    <div className={styles["filter-controls"]}>
      <div className={styles["line1"]}>
        <Form.Item name="search" >
        <Input
          allowClear
          value={filter?.search}
          placeholder="Search by quote name, ID, and more."
        />
        </Form.Item>
      </div>

      <Collapse
        style={{ width: '100%', marginTop: "-1rem" }} size="small"
        defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
        {...AdvancedSearchConfig}
        items={[{
          key: "advanced-search",
          label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
          forceRender: true,
          children: <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Row gutter={[32, 8]}>
              <Col xs={22} xxl={7}>
                <Form.Item
                  name="filterAssemblies"
                  label="BOM Filter"
                >
                  <AssemblySelector style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="incentivePrograms"
                  label="Incentive Programs"
                >
                  <IncentiveProgramSelector style={{ width: '100%' }} />
                </Form.Item>

              </Col>

            </Row>

          </Space>
        }]}
      />
    </div>
    </Form>
  </>
}

const gotoQuote = (q:ListQuotesResponse) => window.open("/inventory/" + encodeURI(q.quoteId), "_blank");

const DesktopTable = (props: TableProps<ListQuotesResponse> & {
  filter:QuoteFilter
  sort:ListQuotesResponseSort
}) => {
  const {  filter:a, sort:b, ...tableProps } = props;

  const configurator = useContext(ConfiguratorContext);

  //adjust quantity
  const dataSource =  props.dataSource?.map( q => {
    const t = q.trucks
        ?.filter( t => t.tags?.includes(TruckTags.Fgi) )
        ?.filter( t => configurator.hasGlobalViewAccess() || !t.tags?.includes(TruckTags.FgiSold) )
    return {...q, quantity: t?.length || 0 }
  })
  .filter( i => !!i.quantity )

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      title: "Truck",
      key: "quoteId",
      sorter: true,
      render: (q:ListQuotesResponse) => {
        const trucks = q.trucks
            ?.filter( t => t.tags?.includes(TruckTags.Fgi) )
            ?.filter( t => configurator.hasGlobalViewAccess() || !t.tags?.includes(TruckTags.FgiSold) )

        return <><Link to={"/inventory/" + encodeURI(q.quoteId)}
                onClick={(e) => {
                  gotoQuote(q);
                  e.preventDefault();
                }}
        >{q.stock ? q.name : `${Utils.buildSerialNumberStr(trucks?.map(t => t.truckSerialNumberStr))} ${q.model.name}`}</Link></>
      },
    },
    {
      title: "Quantity",
      width: "4rem",
      dataIndex: "quantity"
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumberStr",
      width: "15rem"
    },
    {
      title: "Production",
      dataIndex: "productionDate",
      sorter: true
    },
  ];

  return <>
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      <div style={{width: "100%", display: "flex", flexDirection:"row-reverse", padding: "0rem .3rem 0rem .3rem" }}>
        <Space>
        {(configurator.hasGlobalViewAccess()) && <ExportMarketingButton type="primary" />}
        <ExportQuotesModal sort={props.sort} filter={{...props.filter, stock: true}}/>
        </Space>
      </div>
      <Table
          {...tableProps}
          dataSource={dataSource}
          data-testid="quoteListTable"
          bordered
          columns={columns}
          rowKey="quoteId"
          expandable={{
            expandedRowRender: (q:ListQuotesResponse) => {
              return <TruckListDetail trucks={q.trucks} />
            }
          }}
      />
    </Space>
  </>
}

const MobileTable = (props: TableProps<ListQuotesResponse> ) => {

  const configurator = useContext(ConfiguratorContext);

  //adjust quantity
  const dataSource =  props.dataSource?.map( q => {
    const t = q.trucks
        ?.filter( t => t.tags?.includes(TruckTags.Fgi) )
        ?.filter( t => configurator.hasGlobalViewAccess() || !t.tags?.includes(TruckTags.FgiSold) )
    return {...q, quantity: t?.length || 0 }
  })
  .filter( i => !!i.quantity )

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      render: (_val, quote ) => {
        return <>
          <Card
            title={<>
              <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.truckDescription}</div>
              <div className={styles["quote-id"]}><Link to={"/inventory/" + encodeURI(quote.quoteId)} >({quote.quoteId})</Link></div>

            </>
            }
          >
              <TruckListDetail trucks={quote.trucks} />
          </Card>
        </>;
      },
    },

  ];

  return <>
    <div style={{ position: "relative" }}>
      <Table
          {...props}
          dataSource={dataSource}
          data-testid="quoteListTable"
          columns={columns}
          rowKey="id"
          className="mobile-table"
      />
    </div>
  </>


}

const TruckListDetail = (props:{
  trucks:Truck[] | undefined
}) => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const { quoteLst } = useQuoteListContext();

  const handleChangeFgiSold =  async (truck:Truck, fgiSold:boolean) : Promise<void> => {
    var tags = Utils.toggleTag(truck.tags, TruckTags.FgiSold, fgiSold)
    return updateFgiTruck( truck.id, {tags} );
  }

  const updateFgiTruck =  async (truckId:number, req:TruckRequest) : Promise<void> => {

    try {
      await configurator.api.updateTruck( truckId, req )

      quoteLst?.mutate();
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update truck. " + errorMsg });
    }

    return;
  };

  const datasource = props.trucks
      ?.filter( t => t.tags?.includes(TruckTags.Fgi) )
      ?.filter( t => configurator.hasGlobalViewAccess() || !t.tags?.includes(TruckTags.FgiSold) )

  return <div style={{display: "flex", gap: "10rem", marginBottom: "1rem" }}>
    <Table key={"expandedInfo"}
           className={styles['expandedInfo-description']}
           size="small"
           pagination={{hideOnSinglePage: true, pageSize: 10}}
           columns={[
             { key: "vin",             title: "VIN", dataIndex: "vin", },
             { key:"productionDate",   title: "Est Production Date",           render: (t) => (t.productionDate ? dayjs(t.productionDate).format("M/DD/YY") : "N/A") },
             { key:"shipDate",         title: "Est Ship Date",                 render: (t) => (t.shipDate ? dayjs(t.shipDate).format("M/DD/YY") : "N/A") },
             { key:"physicalLocation", title: "Physical Location",             render: (t) => (t.physicalLocation || "N/A") },
             { key:"fgiSold",          title: "Is Sold",
               hidden: !configurator.hasGlobalViewAccess(),
               render: (t) =>
                   <Checkbox
                       defaultChecked={t.tags.includes(TruckTags.FgiSold)}
                       onChange={(e) => handleChangeFgiSold( t, e.target.checked )} />},
           ]}
           dataSource={datasource}
    />
  </div>
}

const ExportMarketingButton = (props: ButtonProps &  {
  onExporting?:(b:boolean)=>void
}) => {

  const configurator = useContext(ConfiguratorContext);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleExportMarketing = async () => {

    setIsExporting( true );
    try {
      const url = configurator.api.getMarketingInventoryCsvUrl();
      await configurator.api.downloadPdf(url);
    }
    catch (e) {
      notification["error"]({
        message: "Failed to export at this time.",
      });
    }
    setIsExporting( false );
  };

  return <Button {...props}
    loading={isExporting}
    onClick={handleExportMarketing}
  >Export Marketing</Button>
}

export default Inventory;
