import React, {ReactElement, useContext} from "react";
import { AsyncState } from "../hook/useAsyncState";
import { CategoryIdAssembliesIdMap, CustomOptionType, PricingBreakdown, Quote } from "../api/models";
import { SelectedModelInfo } from "../components/Quote/ModelSelectionWizard";
import {ValidateFields} from "rc-field-form/lib/interface";
import {ComputeOptionsRequest} from "../api";


export interface QuoteContextType {
  quoteId?:string
  revision?:number
  quote?:Quote
  userActivity?:()=>void
  adminView?:boolean
  isLocked?: boolean,
  selectedModel?:SelectedModelInfo
  selectedOptions?:CategoryIdAssembliesIdMap
  selectedCustomOptions?:CategoryIdAssembliesIdMap | undefined
  setQuoteFormValues?:(q:Quote | undefined, disableSave?: boolean)=>Promise<void>
  validateQuoteForm?:() => Promise<ValidateFields | undefined>
  reviseQuote?: () => Promise<Quote | void>
  quoteDetails?:ComputeOptionsRequest
}
const QuoteContext = React.createContext<QuoteContextType>({
});

export const useQuoteContext = () : QuoteContextType => {
    return useContext(QuoteContext);
}

// Data provider component
const QuoteContextProvider = (props:{ value:QuoteContextType, children: ReactElement | ReactElement[] }) => {

  const quoteContext = props.value;
  return <QuoteContext.Provider value={quoteContext}>{props.children}</QuoteContext.Provider>;
};
  
export default QuoteContextProvider;

