import {Button, Form, Result, notification, Descriptions, FormProps, Input, } from "antd";
import {useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import {useAsyncState} from "../../hook/useAsyncState";
import { Category, CategoryInfo, EngineeringTeam, User } from "../../api/models";
import { NewEngineeringTeamRequest, EngineeringTeamRequest } from "../../api";
import UserMultiSelector from "../UserMultiSelector";
import CategoryMultiSelector from "../CategoryMultiSelector";
import useUsers from "../../swr/useUsers";

function isNewEngineeringTeamRequest(vt: EngineeringTeamRequest | NewEngineeringTeamRequest | undefined) : boolean {
  if ( !vt ) return false;
  return !('id' in vt) || vt.id === undefined;
}

const EditEngineeringTeamButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?:EngineeringTeam | undefined
  onChange?: (v:EngineeringTeam | undefined) => void
  open?:boolean
  category?:CategoryInfo | undefined
}) => {

  const {value:a, onChange:b, open:c, category:d, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(props.open || false);
  const [form] = Form.useForm();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [_selectedEngineeringTeam, selectedEngineeringTeamAsync] = useAsyncState<EngineeringTeam>();

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleSave = async () => {

    try {
      const values = await form.validateFields() as EngineeringTeamFormValues;
      const team = {
        ...values,
        categories: values.categories?.map( c => c.id ),
        members: values.members?.map( u => u.id ),
      };

      const saved = isNewEngineeringTeamRequest(team)
        ? await createEngineeringTeam(team) 
        : await editEngineeringTeam(team as EngineeringTeamRequest) ;
        setIsOpen(false);
        props.onChange?.(saved);
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleConfirmDelete = async () => {
    if ( !props.value?.id ) return;

    deleteEngineeringTeam(props.value.id);
    setIsOpen(false);
    props.onChange?.(props.value);
  }

  const createEngineeringTeam = async (engineeringTeam:NewEngineeringTeamRequest) : Promise<EngineeringTeam | undefined> => {

    selectedEngineeringTeamAsync.setLoading()
    try {
      const resp = await configurator.api.createEngineeringTeam(engineeringTeam)
      selectedEngineeringTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create engineering Team. " + errorMsg;
      notification.error( { message: msg });
      selectedEngineeringTeamAsync.setFail(msg);
    }

    return;
  }

  const editEngineeringTeam = async (engineeringTeam:EngineeringTeamRequest) : Promise<EngineeringTeam | undefined> => {

    selectedEngineeringTeamAsync.setLoading()
    try {
      const resp = await configurator.api.updateEngineeringTeam(engineeringTeam)
      selectedEngineeringTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit engineering Team. " + errorMsg;
      notification.error( { message: msg });
      selectedEngineeringTeamAsync.setFail(msg);
    }

    return;
  }

  const deleteEngineeringTeam = async (engineeringTeamId:number) : Promise<boolean> => {

    try {
      await configurator.api.deleteEngineeringTeam(engineeringTeamId)
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete engineering Team. " + errorMsg;
      notification.error( { message: msg });
    }

    return false;
  }

  const btnTitle = props.children || props.value?.name || "None Assigned";
  const initialValues = props.value || { categories: props.category ?  [props.category] : [] };

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    >{btnTitle}</BMButton>
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      afterOpenChange={(open) => {
        if (open) {
          form.resetFields();
        } } }
      steps={[
        {
          key:1,
          title: props.value ? "Edit " + props.value.name : "Add engineering Team",
          body: () => <div key="formStep">
            <EngineeringTeamForm form={form} initialValues={initialValues} />
          </div>,
          footer:(nav) => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
            <Button danger key="delete" onClick={() => nav.nextStep()}>Delete</Button>
            <div style={{display: "lex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="cancel" onClick={handleCancel}>Cancel</Button>
              <Button key="save" type="primary" onClick={() => handleSave()}>Save</Button>
            </div>
          </div>
        },
        {
          key:2,
          body: () =>  <div key="confirmStep" >
            <ConfirmDeleteEngineeringTeamResult value={props.value} />
          </div>,
          footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button type="primary" danger onClick={() => handleConfirmDelete()}>Delete</Button>
            <Button onClick={() => nav.prevStep()}>Back</Button>
          </div>
        }
      ] }
    />
  </>;
}

export const ConfirmDeleteEngineeringTeamResult = (props:{
  value:EngineeringTeam | undefined
}) => {
  return  <Result
    status="warning"
    title="The following engineering Team will be deleted:"
    style={{padding: "0"}}
  >
    <Descriptions
      column={1}
      className="dialog-engineeringTeam"
      items={ [ {
        label: "Name",
        children: props.value?.name
      },
      {
        label: "Members",
        children: props.value?.members?.map(u => u.name ).join(", ")
      },
      {
        label: "Categories",
        children: props.value?.categories?.map(c => c.name).join(", ")
      }]}
      size="small"
    />
  </Result>

}

export interface EngineeringTeamFormValues {
  id:number | undefined
  name: string | undefined
  categories:Category[] | undefined
  members:User[] | undefined
}

export const EngineeringTeamForm = (props:FormProps & {
  category?: CategoryInfo
}) =>  {

  const {category, ...formProps} = props;

  const users = useUsers().data;

  const internalUserLst = users?.filter(u => !u.dealerId );

  return <Form 
    {...formProps}
    layout="vertical"
  >
    <Form.Item name="id" 
      label="Team Id"
      hidden={true}>
      <Input />
    </Form.Item>
    <Form.Item name="name" 
      label="Team Name (optional)" >
      <Input />
    </Form.Item>
    <Form.Item name="members" 
      label="Members"
    >
      <UserMultiSelector userLst={internalUserLst}/>
    </Form.Item>
    <Form.Item name="categories" 
      label="Categories" 
    >
      <CategoryMultiSelector />
    </Form.Item>
  </Form>
}


export default EditEngineeringTeamButtonModal;
