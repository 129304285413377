import { Button, Col, Collapse, DatePicker, Divider, Form, Input, Row, Select, Space, Table, TablePaginationConfig, Tooltip, notification } from "antd";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { FilterValue, SortOrder, SorterResult } from "antd/lib/table/interface";
import Title from "antd/lib/typography/Title";
import { debounce } from "lodash";
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import { EntityType, PAGINATION_MAX_PAGE_SIZE, TRUCK_REPORT, TruckDateFilterType, TruckFilter, ShippingDestination } from "../../api/models";
import Utils, {ExportableColumn} from "../../util/util";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import DealerMultipleSelector from "../../components/DealerMultipleSelector";
import SaveFilter from "../../components/widgets/SaveFilter";
import useDateTypeAndRangeHook from "../../hook/useDateTypeAndRangeHook";
import { AdvancedSearchConfig } from "../../components/QuoteFilterControls";

type  TruckReportSort = SorterResult<TruckReportDto> | SorterResult<TruckReportDto>[]

export interface TruckReportDto {
  serialNumber?: number
  engineSerialNumber?: string
  transmissionSerialNumber?: string
  partNumber?: string
  customer?: string
  dealer?: string
  salesPerson?: string
  bmSalesReps?: string[]
  productionDate? : Date
  shipDate? : Date
  readyToShip? : Date
  totalPrice?: number
  entityType?: EntityType
  shippingDestination?: string
  steerAxle1?: string
  steerAxle2?: string
  steerAxle3?: string
  pusherAxle1?: string
  driveAxle1?: string
  driveAxle2?: string
  driveAxle3?: string
  tagAxle?: string
}

const { RangePicker } = DatePicker;

const TruckReport = () => {
  const [_, truckReportAsync] = useAsyncState<TruckReportDto[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [entityTypeFilterParam, setEntityTypeFilterParam] = useQueryParam<any>("entityTypeList", ArrayParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<TruckFilter>({
    search: searchFilterParam || undefined,
    entityTypeList: [entityTypeFilterParam as (string|null) || []].flat(),
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    dateFilterType: dateFilterTypeParam as TruckDateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
  });

  const defaultSort = {
    columnKey: 'serialNumber',
    order: 'descend' as SortOrder
  };
  const [sort, setSort] = useState<TruckReportSort>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(()=> {
    if ( truckReportAsync.isFail() )  {
      notification.error({ message:"Quotes failed to load. " + truckReportAsync.err });
    }
  }, [ truckReportAsync.state ] );

  useEffect(() => {
    loadTrucks( truckReportAsync, pagination, filter , sort );
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const loadTrucks = useCallback(debounce((truckReportAsync:AsyncState<TruckReportDto[]>, pagination: TablePaginationConfig, filter: TruckFilter, sorter:any ) => {

    truckReportAsync.setLoading();
      configurator.api.fetchTruckReport({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || 20,
        sort: {
          field: sorter.columnKey?.toString() || defaultSort.columnKey,
          direction: sorter.order === 'descend' ? 'desc' : 'asc',
        }
      })
      .then( resp => {
      truckReportAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    },
    (e:any) => {
      console.log(e);
      truckReportAsync.setFail( e.message );
    });
  }, 400 ), [ ] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<TruckReportDto> | SorterResult<TruckReportDto>[]) => {
    setPagination(pagination);

    const firstSort = [ sorter ].flatMap(v => v)[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    // setSort(sorter);
  };

  const getFilterWithDateRange = (filter: TruckFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = (_values: Record<string, any>, filter: TruckFilter ) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setPagination({ ...pagination, current: 1 });
    setEntityTypeFilterParam(filter.entityTypeList);
    setDealerFilterParam( filter.dealerLst );
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    setFilter(filter);
  };

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!entityTypeFilterParam && setEntityTypeFilterParam(undefined);
  }


  const handleExportTrucks = async () => {
    setIsExporting(true);
    const resp = await configurator.api.fetchTruckReport({
      ...filter,
      page: 0,
      size: PAGINATION_MAX_PAGE_SIZE,
      sort: {
        field: [sort].flat()[0].columnKey?.toString() || defaultSort.columnKey,
        direction: [sort].flat()[0].order === 'descend' ? 'desc' : 'asc',
      }
    })

    const trucks = resp.data.content;
    if ( !trucks.length ) return;

    const csvFileNameComponents = [ "trucks", Utils.buildSerialNumberStr(trucks.map(t=> t.serialNumber + "")) ];

    const csvFileName = csvFileNameComponents.join( "_" )

    Utils.exportDataAsCSV(csvFileName, trucks, columns);
    setIsExporting(false);
  };


  const columns:ExportableColumn<TruckReportDto>[] = [
    {
      title: "Serial Number",
      key: "truck.serialNumber",
      render: (t) => t.serialNumber,
      renderCSV: (t) => String(t.serialNumber),
    },
    {
      title: "Engine Serial Number",
      render: (t) => t.engineSerialNumber,
      renderCSV: (t) => t.engineSerialNumber,
    },
    {
      title: "Transmission Serial Number",
      render: (t) => t.transmissionSerialNumber,
      renderCSV: (t) => t.transmissionSerialNumber,
    },
    {
      title : 'Steer Axle Sn',
      key: "steerAxle",
      render: (t) => [t.steerAxle1, t.steerAxle2, t.steerAxle3].filter(v=>v).join(","),
      renderCSV: (t) => [t.steerAxle1, t.steerAxle2, t.steerAxle3].filter(v=>v).join(",")
    },
    {
      title : 'Drive Axle Sn',
      key: "driveAxle",
      render: (t) => [t.driveAxle1, t.driveAxle2, t.driveAxle3].filter(v=>v).join(","),
      renderCSV: (t) => [t.driveAxle1, t.driveAxle2, t.driveAxle3].filter(v=>v).join(",")
    },
    {
      title : 'Pusher Axle Sn',
      key: "pusherAxle",
      render: (t) => t.pusherAxle1,
      renderCSV: (t) => t.pusherAxle1
    },
    {
      title : 'Tag Axle Sn',
      key: "tagAxle",
      render: (t) => t.pusherAxle1,
      renderCSV: (t) => t.pusherAxle1
    },
    {
      title: "Part Number",
      key: "partNumber",
      render: (t) => t.partNumber,
      renderCSV: (t) => t.partNumber,
    },
    {
      title: "Customer",
      key: "customer",
      render: (t) => t.customer,
      renderCSV: (t) => t.customer,
    },
    {
      title: "Entity Type",
      key: "entityType",
      render: (t) => t.entityType,
      renderCSV: (t) => t.entityType,
    },
    {
      title: "Destination",
      dataIndex: "shippingDestination",
      renderCSV: (t) => t.shippingDestination
    },
    {
      title: "Dealer",
      dataIndex: "dealer",
      renderCSV: (t) => t.dealer
    },
    {
      title: "Sales Person",
      dataIndex: "salesPerson",
      renderCSV: (t) => t.salesPerson
    },
    {
      title: "BM Sales Reps",
      dataIndex: "bmSalesReps",
      render: (reps) => reps?.join(", "),
      renderCSV: (t) => t.bmSalesReps?.join(", "),
    },
    {
      title: "Total Price",
      key: "totalPrice",
      render: (t) => Utils.formatMoney( t.totalPrice ),
      renderCSV: (t) => Utils.formatMoney( t.totalPrice ),
    },
    {
      title: "Production date",
      key: "productionDate",
      render: (t) =>
          t?.productionDate ? dayjs(t?.productionDate).format("MM/DD/YYYY") : "",
      renderCSV: (t) =>
          t?.productionDate ? dayjs(t?.productionDate).format("MM/DD/YYYY") : "",
    },
    {
      title: "Ship Date",
      key: "shipDate",
      render: (t) =>
          t?.shipDate ? dayjs(t?.shipDate).format("MM/DD/YYYY") : "",
      renderCSV: (t) =>
          t?.shipDate ? dayjs(t?.shipDate).format("MM/DD/YYYY") : ""
    },
    {
      title: "Ready To Ship",
      key: "readyToShip",
      render: (t) =>
          t?.readyToShip ? dayjs(t?.readyToShip).format("MM/DD/YYYY") : "",
      renderCSV: (t) =>
          t?.shipDate ? dayjs(t?.shipDate).format("MM/DD/YYYY") : ""
    },
  ];

  const newButtonStyle:any = pagination.total
      ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
      : { float: "right", marginBottom: "1rem" };


  return (
    <div className="site-layout-background">
      <Title level={2}>Truck Report</Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <FilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
        />
        <div style={{ position: "relative" }}>
          <Row gutter={12} justify={"end"} style={newButtonStyle}>
            <Col>
              <Button type="primary" loading={isExporting} onClick={handleExportTrucks}>Export</Button>
            </Col>
          </Row>
          <Table
              loading={truckReportAsync.isLoading()}
              onChange={tableOnChange}
              bordered
              pagination={pagination}
              dataSource={truckReportAsync.val}
              columns={columns}
              rowKey={(t) => t.serialNumber + "-" + t.partNumber}
              scroll={{ x: "120%" }}
          />
        </div>
      </Space>
    </div>
  );
};

export default TruckReport;

const FilterControls = (props:{
  filter?: TruckFilter
  onFilterChange: (_values: Record<string, any>, filter: TruckFilter) => void
  resetQueryParam?: () => void
}) => {
  const { filter, onFilterChange } = props;

  const getDateRange = (filter: TruckFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }

  const filterWithDateRange = {...filter, dateRange: getDateRange(filter)}

  const [form] = useForm();

  const showClearFilter = !!(Object.values(form.getFieldsValue()).filter(v => v).flat().length);

  const clearFilter = () => {
    let setObject = {...form.getFieldsValue()};
    form.resetFields();

    Object.keys(setObject).forEach(key => {
      setObject[key] = undefined;
    });

    form.setFieldsValue(setObject);
    props.onFilterChange({}, setObject);
    props.resetQueryParam?.();
  }

  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(form, "dateFilterType", "dateRange", onFilterChange);

  return <>
    <style>
      {`
        .clear-filter-button {
          margin-top: 3px;
          border: 1px solid red !important;
          color: red !important;
        }

        .clear-filter-button:hover {
          margin-top: 3px;
          border: 1px solid red !important;
          color: white !important;
          background-color: red !important;
        }

        .custom-form-item .ant-form-item {
          margin-bottom: 15px !important;
        }
      `}
    </style>
    <Form
      form={form}
      onValuesChange={filterChange}
      initialValues={filterWithDateRange}
      layout="vertical"
      className="custom-form-item"
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Row gutter={[32, 8]}>
          <Col xs={11} xxl={9}>
            <Form.Item name="search">
              <Input
                allowClear
                value={filter?.search}
                placeholder="Search by serial number, quote name, customer, and more."
              />
            </Form.Item>
          </Col>

          <Col xs={11} xxl={9}>
            <Form.Item name="dealerLst" >
              <DealerMultipleSelector style={{width: "100%"}} placeholder="Filter by Dealer" />
            </Form.Item>
          </Col>
          <div>
            <SaveFilter tableName={TRUCK_REPORT} size="small" style={{marginTop: "3px", marginRight: "10px"}} />
          </div>
          <div>
            {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
          </div>

        </Row>

        <Collapse
          style={{ width: '100%', marginTop: "-2rem" }}
          {...AdvancedSearchConfig}
          items={
            [{
              key: "advanced-search",
              label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
              forceRender: true,
              children: 
              <>
              <Row gutter={[32, 8]}>
                <Col xs={22} xxl={7}>
                  <Form.Item
                    name="entityTypeList"
                    label="Customer Entity Type"
                  >
                    <Select
                      options={Object.values(EntityType).map(val => {return {
                        label: Utils.snakeCaseToFirstLetterCapitalized(val),
                        value: String(val),
                      }})}
                      allowClear
                      optionFilterProp="label"
                      mode={"multiple"}
                    />
                  </Form.Item>
                </Col>

                <Col xs={8} xxl={5}>
                  <Form.Item
                    name="dateFilterType"
                    label="Date Filter"
                    rules={[{ validator: validateDateFilterType }]}
                  >
                    <Select
                      options={Object.values(TruckDateFilterType).map(e => ({ label: Utils.snakeCaseToFirstLetterCapitalized(e), value:e }))}
                      allowClear
                      style={{width: "100%"}}
                    />
                  </Form.Item>
                </Col>
                <Col xs={14} xxl={10} >
                  <Form.Item 
                    name="dateRange"
                    label="Date Filter Range"
                    rules={[{ validator: validateRangePicker }]}
                  >
                    <RangePicker
                      name="range"
                      style={{width: "100%"}}
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Divider/>
              </>
            }]
        } 
        />
      </Space>
    </Form>
  </>
}

