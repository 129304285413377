import Title from "antd/lib/typography/Title";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
  Button,
  ButtonProps,
  Dropdown,
  Input,
  InputNumber,
  InputRef,
  notification,
  Popover,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip
} from "antd";
import {ConfiguratorContext} from "../context";
import {
  Assembly,
  ASSEMBLY_COST_LIST,
  AssemblyInfo,
  AssemblyTags,
  DEFAULT_THROTTLE,
  PAGINATION_MAX_PAGE_SIZE,
  Permission,
} from "../api/models";
import {AssemblyFilterOptions, AssemblyRequest, ColumnFilterValues} from "../api";
import {useForm} from "antd/es/form/Form";
import useAssemblyInfoList from "../swr/useAssemblyInfoList";
import {FilterValue, SorterResult, SortOrder} from "antd/es/table/interface";
import AssemblyFilter, {AssemblyFilterFormValues} from "../components/Filter/AssemblyFilter";
import _, {throttle} from "lodash";
import Utils, {ExportableColumn, formatCSVDecimal} from "../util/util";
import {DownloadOutlined, ExclamationCircleTwoTone} from "@ant-design/icons";
import {BooleanParam, JsonParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import {useIntl} from "react-intl";
import SaveFilter from "../components/widgets/SaveFilter";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

type AssemblySort = SorterResult<AssemblyInfo> | SorterResult<AssemblyInfo>[];

const PricingTags = [ AssemblyTags.StrategicPrice, AssemblyTags.PricePendingSupplierQuote ];
const NoneColumnValue = { id: "none", text: "None", value: PricingTags.map( t => "!" + t ).join(",") };

const mapTagTitle = (t:string | AssemblyTags) =>
    t === AssemblyTags.StrategicPrice ? "Strategic"
        : t === AssemblyTags.PricePendingSupplierQuote ? "Pending"
            : _.startCase(t);

const getEpicorPercentDiff = (p:number | undefined) => {

  return Math.round( ( ( (p || 0) * 100 ) ) ) / 100;
}

const DEFAULT_FILTER :AssemblyFilterOptions = {
  imported: true,
  isDashAssembly: false,
  hideConfiguratorOnly: true,
  hideObsolete: true,
  hideZeroDiff: true,
  columnFilterValues: { tags: [ NoneColumnValue.value ] }
}

const DEFAULT_PAGINATION:TablePaginationConfig = {
  current: 1,
  pageSize: 10,
}

const DEFAULT_SORT:AssemblySort = [
  {
    columnKey: "epicorAssembly.standardMaterialCostPercentDiff",
    order: 'descend' as SortOrder
  }
]

const AssemblyCostReviewPage = () => {
  const intl = useIntl();

  const [filterForm] = useForm();

  const [filterQueryParam, setFilterQueryParam] = useQueryParam<string | null | undefined>("filter", StringParam);
  const [filterCategoryIdParam, setFilterCategoryIdParam] = useQueryParam<number | null | undefined>("category", NumberParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | null | undefined>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | null | undefined>("p", NumberParam);
  const [obsoleteParam, setObsoleteParam] = useQueryParam<boolean | undefined | null>("obsolete", BooleanParam);
  const [hideZeroDiffParam, setHideZeroDiffParam] = useQueryParam<boolean | undefined | null>("hideZero", BooleanParam);
  const [columnFilterValuesParam, setColumnFilterValuesParam] = useQueryParam<ColumnFilterValues | null | undefined>("columns", JsonParam);

  const defaultFilterOptions = {
    filterQuery: filterQueryParam || DEFAULT_FILTER.filterQuery,
    categoryId: filterCategoryIdParam || DEFAULT_FILTER.categoryId,
    hideObsolete: obsoleteParam || DEFAULT_FILTER.hideObsolete,
    hideZeroDiff:  hideZeroDiffParam || DEFAULT_FILTER.hideZeroDiff,
    columnFilterValues: columnFilterValuesParam || DEFAULT_FILTER.columnFilterValues,
  }

  const [assemblyFilterOptions, setAssemblyFilterOptions] = useState<AssemblyFilterFormValues | undefined>( defaultFilterOptions );
  const [columnFilters, setColumnFilters] = useState<ColumnFilterValues | undefined>( defaultFilterOptions.columnFilterValues || undefined);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    ...DEFAULT_PAGINATION,
    current: currentPageParam || DEFAULT_PAGINATION.current,
    pageSize: pageSizeQueryParam || DEFAULT_PAGINATION.pageSize,
  });

  const [sorter, setSorter] = useState<AssemblySort | undefined>(DEFAULT_SORT || undefined);

  useEffect(() => {
    if ( assemblyFilterOptions === DEFAULT_FILTER ) {
      filterForm.resetFields();
    }
  }, [assemblyFilterOptions]);

  const configurator = useContext(ConfiguratorContext);

  const filterOptions:AssemblyFilterOptions = {
    imported: true,
    isDashAssembly: false,
    ...assemblyFilterOptions,
    columnFilterValues: columnFilters
  }

  const assemblies = useAssemblyInfoList({
    current: pagination?.current ? pagination.current - 1 : undefined,
    pageSize: pagination?.pageSize,
    sorter,
    filterOptions: {
      ...filterOptions,
      columnFilterValues: {
        ...filterOptions.columnFilterValues,
      }
    }
  });

  const handleChangeAssemblyFilter =  useCallback(throttle(async (_changedValues: Record<string, any>, allValues: any) => {

    setAssemblyFilterOptions(allValues);
    setPagination(DEFAULT_PAGINATION);

  }, DEFAULT_THROTTLE), [] );

  const handleTableChange =  useCallback(throttle( (pagination:TablePaginationConfig, filters:Record<string, FilterValue | null>, sorter: AssemblySort) => {

    //remove nulls
    const columnFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== null)
    );

    setColumnFilters( columnFilters);

    setSorter(sorter);
    setPagination(pagination);
  }, DEFAULT_THROTTLE), [] );

  const handleChangeStandardMaterialCost =  async (assemblyId:number, standardMaterialCost: number) : Promise<void> => {
    updateAssembly(assemblyId, { standardMaterialCost: standardMaterialCost ?? null });
    assemblies.mutate();
  }

  const handleChangePricingTag =  async (assemblyId:number, tags:AssemblyTags[], pricingNote: string | undefined) : Promise<void> => {
    updateAssembly(assemblyId, { tags: tags ?? null, pricingNote: pricingNote ?? null });
    assemblies.mutate();
  }

  useEffect(() => {
    updateFilterParams(pagination, filterOptions, sorter);
  }, [assemblyFilterOptions, columnFilters, sorter]);

  const handleReset = () => {
    setPagination(DEFAULT_PAGINATION);
    setSorter(DEFAULT_SORT);
    setAssemblyFilterOptions(DEFAULT_FILTER);
    setColumnFilters(DEFAULT_FILTER.columnFilterValues);
  }

  const handleClearFilters = () => {
    setPagination(DEFAULT_PAGINATION);
    setAssemblyFilterOptions(undefined);
    setColumnFilters(undefined);
  }

  const updateFilterParams = (pagination:TablePaginationConfig,  filterOptions: AssemblyFilterOptions, sort:AssemblySort | undefined) => {
    setFilterQueryParam( filterOptions.filterQuery );
    setFilterCategoryIdParam( filterOptions.categoryId );
    setColumnFilterValuesParam(filterOptions.columnFilterValues);
    setObsoleteParam(filterOptions.hideObsolete);
    setHideZeroDiffParam(filterOptions.hideZeroDiff);
    setCurrentPageParam(pagination.current);
    setPageSizeQueryParam(pagination.pageSize);
    const sorter = sort && [sort].flat().filter(v=>v).map( x => {
      //remove annoying parameters
      return { columnKey: x?.columnKey, order: x?.order};
    })
  };


  const handleExportCsv = async () => {
    if ( assemblies.data?.content.length === 0 ) return;

    const csvFileNameComponents = [ "AssemblyCostReview" ];
    if ( filterOptions?.filterQuery ) csvFileNameComponents.push( filterOptions?.filterQuery );

    const csvFileName = csvFileNameComponents.join( "_" )

    const columnFilterValues = filterOptions?.columnFilterValues
        ? JSON.stringify( {
          ...filterOptions.columnFilterValues,
        })
        : undefined

    const resp = await configurator.api.fetchAssemblyInfoList({
      pageable: {
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE,
        // @ts-ignore
        sorter
      },
      filterOptions: {
        ...filterOptions,
        columnFilterValues
      }
    })

    Utils.exportDataAsCSV(csvFileName, resp.data.content, columns);
  }

  const getDisplayLabel = (asm:AssemblyInfo):string => {
    return asm.label || asm.bomDescription || "";
  }

  const updateAssembly =  async (assemblyId:number, req:AssemblyRequest) : Promise<Assembly | undefined> => {
    try {
      const resp = await configurator.api.updateAssembly( assemblyId, req )
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update assembly. " + errorMsg });
    }
    return;
  };

  const errorTextStyle = {color: "red", fontWeight: 'bold'};
  const columns: ExportableColumn<AssemblyInfo>[] = [
    {
      title: 'BOM',
      key: "bom",
      sorter: true,
      fixed: "left",
      render: (rec) => <span style={{whiteSpace: "nowrap"}}>{rec.bom}</span>,
      renderCSV: (rec) => rec.bom
    },
    {
      title: 'Label / Description',
      key: 'label',
      sorter: true,
      render: (rec) =>
          <Link to={"/assemblies/" + encodeURIComponent(rec.id)} target={"_blank"}>
            {getDisplayLabel(rec)}
            {rec.obsoletedAt &&
            <Tooltip title={`Obsoleted on ${dayjs(rec.obsoletedAt).format("MM/DD/YYYY")}`}>
              <div style={errorTextStyle}>(Obsolete)</div>
            </Tooltip>}
          </Link>,
      renderCSV: (rec) => [getDisplayLabel( rec ), (rec.obsoletedAt ? "Obsolete" : "")].join(" - "),
      width: "60%",
    },
    {
      title: 'Material Cost',
      align: "right",
      sorter: true,
      key: "standardMaterialCost",
      render: (r) => <div style={{display: "flex", justifyContent: "center"}} >
        <StandardMaterialCostInput assembly={r} onChange={handleChangeStandardMaterialCost} >
          {Utils.formatUsDollarCents(r.standardMaterialCost, "")}
        </StandardMaterialCostInput>
      </div>,
      renderCSV: (rec) => formatCSVDecimal(rec.standardMaterialCost),
    }];

  if (configurator.isAdmin() || configurator.isFinance()) {
    columns.push( {
      title: 'Epicor Cost',
      align: "right",
      key: 'epicorAssembly.standardMaterialCost',
      sorter: true,
      render: (rec) => Utils.formatUsDollarCents( rec.epicorStandardMaterialCost, "" ),
      renderCSV: (rec) => formatCSVDecimal( rec.epicorStandardMaterialCost ) ,
    });

    columns.push( {
      title: 'Diff $',
      align: "right",
      key: "epicorAssembly.standardMaterialCostDiff",
      sorter: true,
      render: (rec) => Utils.formatUsDollarCents( rec.epicorStandardMaterialCostDiff, "" ),
      renderCSV: (rec) => formatCSVDecimal( rec.epicorStandardMaterialCostDiff ) ,
    });

    columns.push( {
      title: 'Diff %',
      align: "right",
      key: "epicorAssembly.standardMaterialCostPercentDiff",
      defaultSortOrder: "descend",
      sorter: true,
      render: (rec) => Utils.formatPercent( getEpicorPercentDiff(rec.epicorStandardMaterialCostPercentDiff), "" ),
      renderCSV: (rec) => Utils.formatPercent( getEpicorPercentDiff(rec.epicorStandardMaterialCostPercentDiff), "" ) ,
    });
  }

  columns.push( {
    title: 'Total Labor Cost',
    align: "right",
    key: 'standardLaborCost',
    sorter: true,
    render: (rec) => Utils.formatUsDollarCents(rec.laborCost, ""),
    renderCSV: (rec) => formatCSVDecimal(rec.laborCost),
  });
  if (configurator.isAdmin() || configurator.isFinance()) {
    columns.push({
      title: "Tags",
      align: "left",
      key: 'tags',
      sorter: true,
      render: (rec) => <PricingTagToggle assembly={rec} onChange={handleChangePricingTag} />,
      renderCSV: (rec) => rec.tags?.filter( a => PricingTags.includes(a)).join(",") || "",
      filterSearch: true,
      filterMultiple: true,
      filteredValue: columnFilters?.[ "tags" ],
      filters: [
        ...PricingTags
            .sort( (a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map( t => ( { id:t, text: mapTagTitle(t), value: t }) ),
        NoneColumnValue
      ]
    });
  }

  return <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>

        <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
          <Title level={2}>Assembly Cost Review</Title>
        </div>


        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

          <div style={{display:"flex", justifyContent:"space-between", alignItems: "start"}} >

            <Space align={"start"}>
            <AssemblyFilter
                form={filterForm}
                initialValues={assemblyFilterOptions}
                onValuesChange={handleChangeAssemblyFilter}
                isCostReview={true}
            />
            <Tooltip title="Download CSV">
              <Button icon={<DownloadOutlined />} shape="circle" onClick={handleExportCsv} />
            </Tooltip>
            </Space>

            <Space>
              <Dropdown.Button
                  type="primary"
                  onClick={handleReset}
                  menu={{ items: [
                      {
                        key: "clear",
                        label: <Button type={"text"} onClick={handleClearFilters}>Clear Filters</Button>
                      }
                    ],
                  }}
              >Reset</Dropdown.Button>
              <SaveFilter tableName={ASSEMBLY_COST_LIST}/>
            </Space>
          </div>

        <Table
            key="bom-details"
            bordered
            onChange={handleTableChange}
            pagination={{...pagination, total: assemblies.data?.totalElements}}
            loading={assemblies.isLoading}
            columns={columns}
            dataSource={assemblies.data?.content}
            rowKey="id"
        />
      </Space>
  </Space>
</div>;
}

const StandardMaterialCostInput = (props:Omit<ButtonProps, 'onChange'> & {
  assembly:AssemblyInfo | undefined
  onChange?: (id:number, s:number) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const btnStyle = {borderBottom: "1px solid black"};
  const [standardMaterialCost, setStandardMaterialCost] = useState<string>();
  const {assembly:a, onChange:b, ...btnProps} = props;

  const handleOpen = () => {
    setStandardMaterialCost(props.assembly?.standardMaterialCost?.toString());
    setIsOpen(true);
  }
  const handleBlur = () => {

    setIsOpen(false);

    if ( !props.assembly?.id ) return;

    props.onChange?.( props.assembly.id,
        standardMaterialCost ? Number(standardMaterialCost) : 0 );
  }

  const configurator = useContext(ConfiguratorContext);
  if ( !configurator.hasPermission(Permission.ASSEMBLY_COST_WRITE) ) {
    return <span style={btnStyle}>{btnProps.children}</span>
  }

  return <Popover
      trigger={"click"}
      afterOpenChange={(open) => {
        if( open ) {
          inputRef.current?.focus();
        }
      }}
      open={isOpen}
      content={<InputNumber
          ref={inputRef}
          value={standardMaterialCost}
          onChange={(value) => setStandardMaterialCost( value?.toString() )}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          placeholder="Type dollar amount."
          controls={false}
          onBlur={handleBlur}
          onPressEnter={() => inputRef.current?.blur()}
      /> }
      arrow={false}
  >
    {props.assembly?.standardMaterialCost
        ? <Button className="ghostBmButton" type="text" onClick={handleOpen}><span style={btnStyle}>{btnProps.children}</span></Button>
        : <Button style={{backgroundColor: "rgba( 0, 0, 0, 0.05 )"}} shape="circle" type="text" onClick={handleOpen} />}
  </Popover>;
}

const PricingTagToggle = (props:{
  assembly:AssemblyInfo | undefined
  onChange?: (id:number, tags:AssemblyTags[], pricingNote:string | undefined) => void
}) => {

  const btnStyle = {borderBottom: "1px solid black"};
  const [selectedTag, setSelectedStatus] = useState<AssemblyTags>();
  const pricingNoteInputRef = useRef<InputRef>(null);

  const handleSelectStatus = (s:any) => {
    if ( !props.assembly?.id ) return;

    if ( !!s.key ) {
      setSelectedStatus(s.key);
    }
    else {
      const tags = props.assembly.tags?.filter(t => !PricingTags.includes(t)) || [];
      props.onChange?.(props.assembly.id, tags, undefined );
    }
  }

  const focusSelected = () => {
    if ( !!selectedTag ) {
      pricingNoteInputRef.current?.focus();
    }
  }

  const handleBlur = () => {
    if ( !props.assembly?.id ) return;

    setSelectedStatus(undefined);

    const pricingNote =  pricingNoteInputRef.current?.input?.value;
    if (!pricingNote?.length ) {
      notification.error( { message: "A reason is required for pricing tags." });
      return;
    }

    const tags = new Set<AssemblyTags>(props.assembly.tags);
    PricingTags.forEach(t => tags.delete(t));

    if ( selectedTag && PricingTags.includes(selectedTag)) {
      tags.add(selectedTag);
    }

    props.onChange?.( props.assembly.id, [...tags], pricingNote );

  }


  const items = PricingTags?.map( t => ({label: mapTagTitle(t), key: t as React.Key})) || [];
  items.push({label: "None", key: "" as React.Key});

  const tags = props.assembly?.tags?.filter(t => PricingTags.includes(t));
  const tagTitles = tags?.map( mapTagTitle );

  const configurator = useContext(ConfiguratorContext);
  if ( !configurator.hasPermission(Permission.ASSEMBLY_COST_WRITE) ) {
    return <div style={{whiteSpace: "nowrap"}}>
      <div style={{textAlign: "left"}}>
        <span style={btnStyle}>{tagTitles}</span>
      </div>
      {!!tags?.length &&
          <Tooltip title={props.assembly?.pricingNote} trigger={"click"}>
            <ExclamationCircleTwoTone/>
          </Tooltip> }
    </div>
  }

  return <Popover
      open={!!selectedTag}
      afterOpenChange={(open) => {
        if( open ) focusSelected();
      }}
      content={ <>
        {( !!selectedTag ) && <Input placeholder="Provide Tag Reason" ref={pricingNoteInputRef} allowClear
                                     onBlur={handleBlur} onPressEnter={() => pricingNoteInputRef.current?.blur()} />}
      </>}
  >
    <Dropdown trigger={["click"]}
              menu={{
                items,
                selectable: true,
                selectedKeys: props.assembly?.tags,
                multiple: false,
                onSelect:handleSelectStatus ,
                onDeselect:handleSelectStatus,
              }}
    >
      {!!tags?.length
          ?<div style={{whiteSpace: "nowrap"}}>
            <Button className="ghostBmButton" type="text">
            <div style={{textAlign: "left"}}>
              <span style={btnStyle}>{tagTitles}</span>
            </div>
          </Button>
          {!!tags?.length &&
              <Tooltip title={props.assembly?.pricingNote} trigger={"hover"}>
                <ExclamationCircleTwoTone/>
              </Tooltip> }
          </div>
          : <div style={{display: "flex", justifyContent:"center"}}>
          <Button style={{backgroundColor: "rgba( 0, 0, 0, 0.05 )"}} shape="circle" type="text" />
          </div>}
    </Dropdown>
  </Popover>
}

export default AssemblyCostReviewPage;
