import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {ComputeOptionsRequest} from '../api';
import {
  AXIOS_CANCEL_MSG, PricingBreakdown,
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface ComputePricingProps {
  quoteId:string | undefined,
  rev:number | undefined,
  options?:ComputeOptionsRequest
}

export const Key = 'computePricing';
export const useComputePricing = (props: ComputePricingProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:ComputePricingProps  ) : Promise<PricingBreakdown | undefined> => {

    const { quoteId, rev, options } = props;
    if (!quoteId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.computePricing(quoteId, rev, options, cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    Key,
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useComputePricing;

