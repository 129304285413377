import {Button, Form, Input, notification, Radio, Select} from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {
  AssemblyInfo,
  BaseCategory,
  PAGINATION_MAX_PAGE_SIZE,
  QuoteAssemblyException,
  AssemblyExceptionOperation,
  AssemblyExceptionType
} from "../../api/models";
import {ConfiguratorContext } from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import { WizardInstance } from "../Wizard";
import SelectModelCategory from "../SelectModelCategory";
import useAssemblyExceptions from "../../swr/useAssemblyExceptions";
import useAssemblyInfoList from "../../swr/useAssemblyInfoList";

const AssemblyExceptionButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  selectedCategory?:BaseCategory | undefined
  onAdd?:(ae:QuoteAssemblyException) => void
  onDelete?:(ae:QuoteAssemblyException[]) => void
}  ) => {

  const {selectedCategory, onAdd, onDelete, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const categoryId = Form.useWatch("categoryId", form);
  const quoteAssemblyExceptionType = Form.useWatch("type", form); 
  const [selectedAssemblyExceptionLst, setSelectedAssemblyExceptionLst] = useState<QuoteAssemblyException[]>();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const { quote } = useQuoteContext();
  const quoteId = quote?.quoteId;

  const assemblyExceptions = useAssemblyExceptions({quoteId: quote?.quoteId})

  const assemblies = useAssemblyInfoList( {
    filterOptions: {
      categoryId: categoryId,
    },
    pageSize: PAGINATION_MAX_PAGE_SIZE
  } );

  useEffect(() => {
    form.resetFields();
    form.setFieldValue( "categoryId", selectedCategory?.id );
    form.setFieldValue( "type", quoteAssemblyExceptionType );
  }, [quoteAssemblyExceptionType])


  const handleCancel = () => {
    setIsOpen(false);
  }

  const saveAssemblyException = async (exception:QuoteAssemblyException) : Promise<QuoteAssemblyException | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.saveQuoteAssemblyException(quoteId, exception );
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to add assembly exception. " + errorMsg });
    }

    return;
  }

  const deleteAssemblyException = async (exceptionLst:QuoteAssemblyException[]) : Promise<QuoteAssemblyException[] | undefined> => {
    if ( !quoteId ) return;

    try {
      await Promise.all(
        exceptionLst.map(async (ae) => {
          if ( !ae.id ) return;

          return configurator.api.deleteQuoteAssemblyException(quoteId, ae.id );
        }).filter(v=>v)
      );

      return exceptionLst;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to remove assembly exception. " + errorMsg });
    }

    return;

  }

  const handleAddAssemblyException = async (nav:WizardInstance) => {
    if (!quoteId) return;

    try {
      const values = await form.validateFields();
      const savedException = await saveAssemblyException(values);
      if ( savedException ) {
        await assemblyExceptions.mutate();
        onAdd?.(savedException)
        nav.nextStep();
      }
    }
    catch(validationErrors) {
      notification.error( { message: "Please fix validation errors. " });
    }
  }

  const handleNew = (nav:WizardInstance) => {
    form.resetFields();
    form.setFieldValue( "categoryId", selectedCategory?.id );
    nav.nextStep();
  }

  const handleDelete = async () => {
    if (!quoteId) return;
    if ( !selectedAssemblyExceptionLst ) return;

    const exceptionLst = await deleteAssemblyException(selectedAssemblyExceptionLst);
    if ( exceptionLst?.length ) {
      onDelete?.(exceptionLst)
      await assemblyExceptions.mutate();

      //uncheck
      setSelectedAssemblyExceptionLst( selectedAssemblyExceptionLst?.filter( ae => !exceptionLst.some( e => e.id === ae.id ) ) );
    }
  }

  //for add, show all assemblies in the category except the assemblies already available
  //for remove, show available assemblies
  // console.log( "categoryOptionLst", categoryOptionLst );
  // console.log( "assemblyLstAsync", assemblyLstAsync.val );
  const assemblyLst = quoteAssemblyExceptionType === AssemblyExceptionType.OBSOLETE
      ? assemblies.data?.content?.filter(a => a.obsoletedAt )
      : assemblies.data?.content;

  const getAssemblyLabel = (a:AssemblyInfo) : string | undefined => (!!a.label?.length ? a.label : a.bomDescription);

  const handleSelectRow = (record:QuoteAssemblyException) => {

    //toggle previously selected
    const updated = selectedAssemblyExceptionLst?.filter( ae => ae.id !== record.id );
    if ( updated?.length === selectedAssemblyExceptionLst?.length  ) {
      setSelectedAssemblyExceptionLst( (updated || []).concat(record) );
      return;
    }

    setSelectedAssemblyExceptionLst(updated);
  }

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
      children={btnProps.children || "Assembly Exceptions"}
    />

    <Form form={form} initialValues={{operation: AssemblyExceptionOperation.ADD}} >
      <ModalWizard
        showSteps={false}
        open={isOpen}
        onCancel={handleCancel}
        style={{minWidth: "60rem"}}
        steps={[
          {
            key:1,
            title: "Select Exception",
            body: (nav) => <div key="step1">
              <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
                <Button type="primary" onClick={() => handleNew(nav)} >New</Button>
                <Button onClick={handleDelete} disabled={!selectedAssemblyExceptionLst}>Delete</Button>
              </div>
              <style>
                {`
                  .dialog-assemblyExceptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
                    .dialog-assemblyExceptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
                    /* add error border to table */
                    .ant-form-item-has-error .dialog-assemblyExceptionLst .ant-table-content {
                    border: solid 1px #ff4d4f;
                    border-radius: 15px;
                  }
                  `}
              </style>
              <Table 
                className="dialog-assemblyExceptionLst"
                showHeader={false}
                columns={
                  [ {
                  title: "Assembly",
                  render: (ae:QuoteAssemblyException) => <>
                    <div style={{fontWeight: 600}}>{getAssemblyLabel(ae.assembly)}, <span style={{whiteSpace:"nowrap"}}>{ae.assembly.bom}</span></div>
                    <div >{ae.reason} by {Utils.formatUsername(ae.createdBy)} on {dayjs(ae.createdAt).format("MM/DD/YYYY")}</div>
                  </>
                },
                {
                  title: "Category",
                  render: (ae:QuoteAssemblyException) => Utils.stripSortingPrefix(ae.assembly.categoryName)
                },
                {
                  title: "Type",
                  render: (ae:QuoteAssemblyException) => Utils.snakeCaseToFirstLetterCapitalized(ae.type)
                },
                ]}
                rowKey="id"
                loading={assemblyExceptions?.isLoading}
                dataSource={assemblyExceptions.data}
                onRow={(record, _rowIndex) => {
                  return {
                    onClick: () => handleSelectRow(record)
                  };
                }}
                rowSelection={{
                  type: "checkbox",
                  onSelect: handleSelectRow,
                  selectedRowKeys: (selectedAssemblyExceptionLst?.map( ae => ae.id ) || [] ) as number[],
                }}
              />
            </div>,
            footer: (_nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
            </div>,
          },
          {
            key:2,
            title: "Select Exception Type",
            body: (nav) => <div key="step2">
              <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
                <Form.Item
                  name="type"
                >
                  <Radio.Group >
                    <div style={{display:"flex", flexDirection: "column", gap: "1rem" }}>
                      <div><Radio value={AssemblyExceptionType.RULE_OVERRIDE} onClick={nav.nextStep} >Add BOM</Radio></div>
                      <div><Radio value={AssemblyExceptionType.OBSOLETE} onClick={nav.nextStep}>Enable Obsolete BOM</Radio> </div>
                      <div><Radio value={AssemblyExceptionType.TEMP_DEMAND} onClick={nav.nextStep}>Add Temporary Demand Indicator</Radio> </div>
                    </div>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>,
            footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={nav.nextStep} disabled={!quoteAssemblyExceptionType}>Next</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          },
          {
            key:3,
            title: "Add Exception" ,
            body:  (_nav) => <div  key="step3">
              <div style={{paddingTop: "1rem" }}>

                <Form.Item
                  name="categoryId"
                  hidden={!!selectedCategory}
                >
                  <SelectModelCategory modelId={quote?.model.id} options={{quoteRevisionId: quote?.displayRevisionId}} />
                </Form.Item>

                <Form.Item
                  name="assemblyId"
                  rules={[{
                    required: true,
                    message: `An assembly is required.`,
                  }]}
                >
                  <Select
                      placeholder="Select BOM"
                      loading={assemblies.isLoading}
                      showSearch
                      optionFilterProp="label"
                      options={assemblyLst?.map(asm => ({ label: `${asm.bom} ${asm.label || asm.bomDescription}`, value:asm.id }))}
                  />
                </Form.Item>

                <Form.Item
                  name="operation"
                  hidden={true}
                >
                  <Input />
                </Form.Item>


                <Form.Item
                  name="reason"
                  rules={[{
                    required: true,
                    message: `Please add a reason for this exception.`,
                  }]}
                >
                  <Input placeholder={"Add a reason for this exception"} />
                </Form.Item>
              </div>
            </div>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="save" type="primary" onClick={() => handleAddAssemblyException(nav)} >Save</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          }
        ]}    
      />
    </Form>
  </>;

}

export default AssemblyExceptionButtonModal;
