import {Input, Modal, notification, Result, Space} from "antd";
import {useContext, useState} from "react";
import BMButton, {BMButtonProps} from "../BMButton";
import {Quote} from "../../api/models";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import {useQuoteContext} from "../../contexts/QuoteContext";

const RemoveSalesOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
    onChange?: () => void
} ) => {

    const [isOpen, setIsOpen] = useState(false);

    const { onChange:a, ...btnProps} = props;

    const intl = useIntl();
    const configurator = useContext(ConfiguratorContext);
    const {quote} = useQuoteContext();

    const [confirmTxt, setConfirmTxt] = useState<string>();
    const [validationError, setValidationError] = useState<boolean>();

    const removeSalesOrder = async () : Promise<Quote|undefined> => {
        if ( !quote ) return;

        try {
            const resp = await configurator.api.updateQuoteRevision(quote.displayRevisionId, { salesOrderNumber: null});
            return resp.data;
        } catch (e:any) {
            const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
            const msg = "Failed to remove sales order. " + errorMsg;

            notification.error( { message: msg });
        }

        return;
    };

    const handleClick = async (e:any) => {
        if ( String(quote?.salesOrderNumber) === confirmTxt ) {
            await removeSalesOrder();
            props.onChange?.();
            setIsOpen(false);
        }
        else {
            setValidationError(true);
        }
    }

    return <>
    <BMButton {...btnProps} onClick={() => setIsOpen(true)} />
    <Modal
        open={isOpen}
        okText="Confirm"
        onOk={handleClick}
        onCancel={() => setIsOpen(false)}
        centered
    >
        <Result
            status="warning"
            title="Removing Sales Order Number"
        >
            <Space direction={"vertical"}>
                <div>Please type the sales order number to confirm:</div>
                <div style={{fontStyle: "italic", fontWeight: "bold", textAlign: "center"}} >
                    {String(quote?.salesOrderNumber).substring(0, 3)}&nbsp;{String(quote?.salesOrderNumber).substring(3)}
                </div>
                <div><Input value={confirmTxt} onChange={(e) => {
                    setConfirmTxt(e.target.value);
                    setValidationError(false);
                }} /></div>
                { validationError &&
                    <div style={{color: "#ff4d4f"}} >Does not match sales order number. </div>}
        </Space>
    </Result>
    </Modal>
</>
}

export default RemoveSalesOrderButton;
