import axios, { CancelTokenSource } from 'axios';
import { throttle } from 'lodash';
import { useCallback, useContext, useRef } from 'react';
import useSWR from 'swr';
import {ComputeOptionsRequest, ListQuotesResponse} from '../api';
import {
  AXIOS_CANCEL_MSG, BaseCategory,
  CategoryIdAssembliesIdMap,
} from '../api/models';
import { ConfiguratorContext } from '../context';

export interface ModelCategoryRequestOptions {
  quoteRevisionId?:number | undefined,
  assemblyFilter?: string,
  dealerView?: boolean
}

interface ModelCategoriesProps {
  modelId?:number,
  options?:ModelCategoryRequestOptions
}

export const useModelCategories = (props: ModelCategoriesProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:ModelCategoriesProps  ) : Promise<BaseCategory[] | undefined> => {

    const { modelId, options } = props;
    if (!modelId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getModelCategories(modelId, options, cancelSource );
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'getModelCategories',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      shouldRetryOnError: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useModelCategories;

