import axios, {CancelTokenSource} from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {AXIOS_CANCEL_MSG} from '../api/models';
import { ConfiguratorContext } from '../context';
import {QuoteCommentRequestOptions} from "../api";

export type S3Map = Record<string, string>

interface QuoteCommentDocumentUrlsProps {
  quoteId: string | undefined,
  options?: QuoteCommentRequestOptions
}

export const useQuoteCommentDocumentUrls = (props: QuoteCommentDocumentUrlsProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:QuoteCommentDocumentUrlsProps  ) : Promise<S3Map | undefined> => {

    if ( !props.quoteId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchQuoteCommentDocumentUrls( props.quoteId, props.options, cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }

  // Use SWR for data fetching
  return useSWR([
    'listQuoteCommentDocumentUrls',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useQuoteCommentDocumentUrls;

