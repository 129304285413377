import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {
  AXIOS_CANCEL_MSG, Dealer, PAGINATION_MAX_PAGE_SIZE, SortRequestParam, Page
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface DealerProps {
  page?: number,
  size?: number,
  sort?: SortRequestParam | SortRequestParam[],
  search?: string
  primarySalesId?: string
}

export const useDealers = (props?: DealerProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props?:DealerProps  ) : Promise<Page<Dealer> | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchDealerList({
        ...props,
        page: props?.page ?? 0,
        size: props?.size ?? PAGINATION_MAX_PAGE_SIZE
      }, cancelSource.token);
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'fetchDealerList',
        props
  ],
      ([_k, p]) => fetcher(p),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useDealers;

