import {Select, SelectProps} from "antd";
import useCustomers from "../swr/useCustomers";

const QuoteEndCustomerSelector = (props: SelectProps) => {

  const customers = useCustomers();

  return <Select
      {...props}
      allowClear
      showSearch={true}
      optionFilterProp="label"
      options={ customers.data?.map(d => ({
        value: d.id, label:d.name
      }))}
      mode="multiple"
    />
};

export default QuoteEndCustomerSelector;
