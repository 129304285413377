import { Select, SelectProps } from "antd";
import useDealers from "../swr/useDealers";

const DealerMultipleSelector = (props: SelectProps) => {

  const dealers = useDealers()

  return <Select
    {...props}
      loading={dealers.isLoading}
      mode="multiple"
      showSearch
      allowClear
      optionFilterProp="label"
      style={props.style}
      options={dealers.data?.content.map(d => ({value: d.id, label: d.name}))}
    />
};

export default DealerMultipleSelector;
