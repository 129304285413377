import { useContext, useEffect, useState } from "react";
import {Dealer, PAGINATION_MAX_PAGE_SIZE} from "../api/models";
import { ConfiguratorContext } from "../context";
import {Select, SelectProps, Spin} from "antd";
import useDealers from "../swr/useDealers";

const DealerSelector = (props: SelectProps) => {

  const dealers = useDealers()

  return <Select
      {...props}
      loading={dealers.isLoading}
      showSearch={true}
      optionFilterProp="children"
      allowClear={true}
      filterOption={(input, option) => {
        if (option && option.children) {
          return option.children
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return false;
        }
      }}
    >
      {dealers.data?.content.map((dealer) => (
        <Select.Option key={dealer.id} value={dealer.id}>
          {dealer.name}
        </Select.Option>
      ))}
    </Select>
};

export default DealerSelector;
