import {Checkbox, Col, Form, Input, Row} from "antd";
import CategorySelector from "../category_selector";
import {FormProps} from "antd/es/form/Form";

export interface AssemblyFilterFormValues {
  hideObsolete?: boolean
  hideZeroDiff?: boolean
  filterQuery?: string
  categoryId?: number
}


const AssemblyFilter = (props:FormProps & {
  isCostReview?: boolean
} ) => {

  const {isCostReview, ...formProps} = props;

  return <Form {...formProps}>
    <Row gutter={16}>
      <Col>
        <Form.Item label={"Category"} name={"categoryId"} >
          <CategorySelector style={{width: "20rem"}} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label={"Filter"} name={"filterQuery"} >
          <Input
              allowClear
              style={{ width: "250px", marginLeft: "1rem" }}
              placeholder="Enter text to filter list"
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
            name="hideObsolete"
            valuePropName="checked"
        >
          <Checkbox>Hide Obsolete</Checkbox>
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
            name="hideZeroDiff"
            valuePropName="checked"
            hidden={!isCostReview}
        >
          <Checkbox>Hide Zero Diff</Checkbox>
        </Form.Item>
      </Col>

    </Row>
  </Form>

}

export default AssemblyFilter
