import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {
  AXIOS_CANCEL_MSG, BaseCategory
} from '../api/models';
import { ConfiguratorContext } from '../context';


interface CategoryProps {
}

export const useBaseCategories = (props?: CategoryProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props?:CategoryProps  ) : Promise<BaseCategory[] | undefined> => {

    //const { quoteId, revision } = props;
    //if (!revision) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getBasicCategories(cancelSource.token);
      cancelTokenSourceRef.current = undefined;

      return resp.data.sort((a,b) => a.name.localeCompare(b.name));
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'basicCategories',
    //props
  ],
      //([_k, p]) => fetcher(p),
    ([_k]) => fetcher(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useBaseCategories;

