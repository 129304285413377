import axios, {CancelTokenSource} from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {
  Approval,
  AXIOS_CANCEL_MSG,
} from '../api/models';
import { ConfiguratorContext } from '../context';

interface LatestApprovalsProps {
  quoteRevisionId:number | undefined
}

export const useLatestApprovals = (props: LatestApprovalsProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:LatestApprovalsProps  ) : Promise<Approval[] | undefined> => {

    const { quoteRevisionId } = props;
    if (!quoteRevisionId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchLatestApprovals(quoteRevisionId, cancelSource.token);
      cancelTokenSourceRef.current = undefined;
      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
        'fetchLatestApprovals',
        props
      ],
      ([_k, p]) => fetcher(p),
      {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval:1
    }
  );
};


export default useLatestApprovals;

