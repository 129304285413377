import Title from "antd/lib/typography/Title";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button, Col, Row, TablePaginationConfig, Dropdown, notification, Table, Tooltip} from "antd";
import { ConfiguratorContext } from "../context";
import {BooleanParam, JsonParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import AssemblyFilter, {AssemblyFilterFormValues} from "../components/Filter/AssemblyFilter";
import {throttle} from "lodash";
import {
  ASSEMBLY_LIST,
  Permission,
  PAGINATION_MAX_PAGE_SIZE,
  DEFAULT_THROTTLE, CategoryMetadata, Assembly, AssemblyWithMetadata, AssemblyInfo
} from "../api/models";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import BatchAssemblyReplacementButtonModal from "../components/BatchAssemblyReplacementButtonModal";
import {AssemblyFilterOptions, ColumnFilterValues,} from "../api";
import { useIntl } from "react-intl";
import SaveFilter from "../components/widgets/SaveFilter";
import NewAssemblyModal from "../components/new_assembly_modal";
import Utils, {ExportableColumn} from "../util/util";
import { MoreOutlined } from "@ant-design/icons";
import AssemblyBacklogUpdateButtonModal from "../components/AssemblyBacklogUpdateButtonModal";
import useCategories from "../swr/useCategories";
import {ColumnType} from "antd/lib/table";
import {Link} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {useAssembliesWithMetadata} from "../swr/useAssembliesWithMetadata";
import dayjs from "dayjs";

const MetadataPrefix = "metadata.";

const getLabel = (asm:Assembly | AssemblyWithMetadata | AssemblyInfo) => {
  return asm.label || asm.bomDescription || "";
};

const DEFAULT_PAGINATION:TablePaginationConfig = {
  current: 1,
  pageSize: 20,
  position: ["topLeft", "bottomLeft"],
  pageSizeOptions: [10, 20, 50, 100, 200, 500],
}

const DEFAULT_FILTER :AssemblyFilterOptions = {
  imported: true,
  isDashAssembly: false,
}

type AssemblySort = SorterResult<AssemblyWithMetadata> | SorterResult<AssemblyWithMetadata>[];

const Assemblies = () => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const [filterForm] = useForm();

  //parameters from the query string
  const [filterQueryParam, setFilterQueryParam] = useQueryParam<string | null | undefined>("filter", StringParam);
  const [filterCategoryIdParam, setFilterCategoryIdParam] = useQueryParam<number | null | undefined>("category", NumberParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | null | undefined>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | null | undefined>("p", NumberParam);
  const [obsoleteParam, setObsoleteParam] = useQueryParam<boolean | undefined | null>("obsolete", BooleanParam);
  const [columnFilterValuesParam, setColumnFilterValuesParam] = useQueryParam<ColumnFilterValues | null | undefined>("columns", JsonParam);
  const [sortParam, setSortParam] = useQueryParam<AssemblySort | null | undefined>("sort", JsonParam);

  const defaultFilterOptions = {
    filterQuery: filterQueryParam || DEFAULT_FILTER.filterQuery,
    categoryId: filterCategoryIdParam || DEFAULT_FILTER.categoryId,
    hideObsolete: obsoleteParam || DEFAULT_FILTER.hideObsolete,
    columnFilterValues: columnFilterValuesParam || DEFAULT_FILTER.columnFilterValues,
  }

  const [assemblyFilterOptions, setAssemblyFilterOptions] = useState<AssemblyFilterFormValues>(defaultFilterOptions);
  const [columnFilters, setColumnFilters] = useState<ColumnFilterValues | undefined>( defaultFilterOptions.columnFilterValues || undefined);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    ...DEFAULT_PAGINATION,
    current: currentPageParam || DEFAULT_PAGINATION.current,
    pageSize: pageSizeQueryParam || DEFAULT_PAGINATION.pageSize,
  });
  const [sorter, setSorter] = useState<AssemblySort | undefined>(sortParam || undefined);

  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);

  const [isExporting, setIsExporting] = useState(false);

  const categories = useCategories().data;

  const filterOptions:AssemblyFilterOptions = {
    ...DEFAULT_FILTER,
    filterQuery: assemblyFilterOptions.filterQuery,
    categoryId: assemblyFilterOptions.categoryId,
    hideObsolete: assemblyFilterOptions.hideObsolete,
    columnFilterValues: columnFilters
  }
  const selectedCategory = categories?.find(c => c.id === filterOptions.categoryId);

  const assemblies = useAssembliesWithMetadata( {
    current: pagination?.current ? pagination.current - 1 : undefined,
    pageSize: pagination?.pageSize,
    sorter,
    filterOptions,
  } );

  const columnFilterOptions = useAssembliesWithMetadata( {
    current: 1,
    pageSize: PAGINATION_MAX_PAGE_SIZE,
    sorter,
    filterOptions,
  } );

  const columnFilterValues = useMemo( () =>

          columnFilterOptions.data?.content.map( a => {

            const asm = {'name': getLabel(a)};

            const metadata = a.metadata
                .map( md => {
                  const v = Utils.getMetadataValue(md);
                  const columnName = MetadataPrefix + md.name;
                  return v ? ({ [columnName]: String(v) }) : undefined;
                })
                .filter(v => !!v)
                .reduce( (acc, val) => Object.assign(acc, val), {})

            return Object.assign(asm, metadata);
          })
              .reduce( (acc, val) => {
                //merge array of objects into one object with values as sets (to remove duplicates)
                //eg [ {a, b}, {a, c}, {a, c}, {d, e} ] => {a: [a, c], d: [e]}
                Object.keys(val).forEach( k => {
                  //console.log( "reduce", k );
                  if ( !acc[k] ) acc[k] = new Set<string>();
                  acc[k].add(val[k]);
                });
                return acc;
              }, {}),
      [columnFilterOptions.data?.content]);

  useEffect(() => {
    updateFilterParams(pagination, filterOptions, sorter);
  }, [assemblyFilterOptions, columnFilters, sorter]);


  const handleReset = () => {
    setPagination(DEFAULT_PAGINATION);
    setSorter(undefined);
    setAssemblyFilterOptions(DEFAULT_FILTER);
    setColumnFilters(undefined);
    setTimeout(filterForm.resetFields, 1);
  }

  const handleTableChange =  useCallback(throttle( (pagination:TablePaginationConfig, filters:Record<string, FilterValue | null>, sorter: AssemblySort) => {

    setSorter(sorter);

    //remove nulls
    const columnFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== null)
    );

    setColumnFilters( columnFilters);
    setPagination(pagination);

  }, DEFAULT_THROTTLE), [] );

  const handleChangeAssemblyFilter =  useCallback(throttle(async (changedValues: Record<string, any>, allValues: any) => {

    if ( 'categoryId' in changedValues) {
      setColumnFilters(undefined);
    }

    setAssemblyFilterOptions(allValues);
    setPagination(DEFAULT_PAGINATION);

  }, DEFAULT_THROTTLE), [] );

  const handleExportWeight = async () => {
    setIsExporting(true);
    try {
      const url = configurator.api.getAssemblyWeightExportUrl();
      await configurator.api.downloadZip(url);
    }
    catch (e) {
    notification["error"]({
      message: "Failed to export.",
    });
    }
    setIsExporting(false);
  };

  const updateFilterParams = (pagination:TablePaginationConfig,  filterOptions: AssemblyFilterOptions, sort:AssemblySort | undefined) => {
    setFilterQueryParam( filterOptions.filterQuery );
    setFilterCategoryIdParam( filterOptions.categoryId );
    setObsoleteParam(filterOptions.hideObsolete);
    setCurrentPageParam(pagination.current);
    setPageSizeQueryParam(pagination.pageSize);
    setColumnFilterValuesParam(filterOptions.columnFilterValues);
    const sorter = sort && [sort].flat().filter(v=>v).map( x => {
      //remove annoying parameters
      return { columnKey: x?.columnKey, order: x?.order};
    })
    setSortParam(sorter);
  };

  const getColumnFilterProperties = (columnName:string | undefined) : ColumnType<AssemblyWithMetadata> | undefined => {
    if ( !columnName?.length ) return;

    const values = columnFilterValues?.[ columnName ];
    const filters = values ? [...values]
        .sort( (a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map( v => ({ id:columnName, text: v, value: v })) : undefined;


    //console.log(columnName, columnFilters?.[ columnName ] );
    const filteredValue =  columnFilters?.[ columnName ];
    return {
      filters,
      sorter: true,
      /*
      sorter: {
        multiple: 1
      },
       */
      filterSearch: true,
      filterMultiple: true,
      filteredValue
    }
  }

  const getColumnForMetadata = ( md:CategoryMetadata ) : ExportableColumn<AssemblyWithMetadata> => ({
    ...getColumnFilterProperties(MetadataPrefix + md.name),
    key: MetadataPrefix + md.name,
    title: md.name,
    render: (s) => {
      const m = s.metadata?.find( md1 => md1.categoryMetadataId == md.id);
      return Utils.getMetadataValue(m) || "";
    },
    renderCSV: (s) => {
      const m = s.metadata?.find( md1 => md1.categoryMetadataId == md.id);
      const v = Utils.getMetadataValue(m);
      return v ? String(v) : ""
    },
  });

  const metadataColumns:ExportableColumn<AssemblyWithMetadata>[] =
      selectedCategory?.metadata
          ?.sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
          .map( md  => getColumnForMetadata( md ) ) || [];

  const errorTextStyle = {color: "red", fontWeight: 'bold'};
  const columns:ExportableColumn<AssemblyWithMetadata>[] = [
    {
      title: "BOM",
      key: "bom",
      sorter: true,
      fixed: "left",
      /*
      sorter: {
        multiple: 1
      },
       */
      render: (asm) => <span style={{whiteSpace: "nowrap"}}>{asm.bom}</span>,
      renderCSV: (asm) => asm.bom,
    },
    {
      title: "Name",
      key: "label",
      sorter: true,
      /*
      sorter: {
        multiple: 1
      },
       */
      render: (asm) => (
          <Link to={"/assemblies/" + encodeURIComponent(asm.id)}>
          <div style={{width: "15rem"}}>
            {getLabel(asm)}
            {asm.obsoletedAt &&
                <Tooltip title={`Obsoleted on ${dayjs(asm.obsoletedAt).format("MM/DD/YYYY")}`}>
                  <div style={errorTextStyle}>(Obsolete)</div>
                </Tooltip>}
          </div>
          </Link>
      ),
      renderCSV: (rec) => [getLabel( rec ), (rec.obsoletedAt ? "Obsolete" : "")].join(" - "),
    },
    ...metadataColumns
  ];

  const handleExportAssemblies = async () => {
    try {

      setIsExporting(true);

      const assemblies = columnFilterOptions.data?.content;

      if ( !assemblies?.length ) throw new Error("No assemblies to export.");

      Utils.exportDataAsCSV( "assembly-export", assemblies, columns );
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Export failed. " + errorMsg });
    }

    setIsExporting(false);
  };


  const dropdownItems  = [
    {
      key: "AssemblyBacklogUpdateBtn",
      label: <AssemblyBacklogUpdateButtonModal />,
    },
    {
      key: "batchAssemblyReplacementBtn",
      label: <BatchAssemblyReplacementButtonModal />,
    },
  ];

  if (configurator.isAdmin()) {
    dropdownItems.push({
      key: "exportWeightBtn",
      label:<Button
          type="text"
          loading={isExporting}
          onClick={handleExportWeight}
      >
        Export Weight CSV
      </Button>,
    });
    dropdownItems.push({
      key: "exportAssembliesBtn",
      label:<Button
          type="text"
          loading={isExporting}
          onClick={handleExportAssemblies}
      >
        Export Assemblies
      </Button>,
    });
  }

  return <div className="site-layout-background">
    <Title level={2}>Assembly List</Title>
    <Row gutter={12} style={{marginBottom: "1rem"}}>
        <Col>
          <Dropdown trigger={["click"]}
            menu={{items: dropdownItems}}
          >
            {/* this div is to avoid a warning with strict mode */}
            <div>
              <Button type="primary" icon={<MoreOutlined/>} loading={isExporting} >Options</Button>
            </div>
          </Dropdown>
      </Col>
      {canWrite &&
      <Col>
        <NewAssemblyModal/>
      </Col>}
    </Row>
    <Row style={{marginBottom: "1rem"}} justify={"space-between"} gutter={[8, 16]}>
      <Col>
        <AssemblyFilter
            form={filterForm}
            initialValues={defaultFilterOptions}
            onValuesChange={handleChangeAssemblyFilter}
      />
      </Col>
      <Col>
        <Button type="primary" onClick={handleReset} style={{marginRight: "10px"}} >Reset</Button>
        <SaveFilter tableName={ASSEMBLY_LIST}/>
      </Col>
    </Row>

      <Table
          bordered
          loading={assemblies.isLoading}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={assemblies.data?.content}
          pagination={{...pagination, total: assemblies.data?.totalElements}}
          onChange={handleTableChange}
          scroll={{ x: true }}
      />
  </div>
                                                       
};

export default Assemblies;
