import {Checkbox, Form, FormProps, Input, Select,} from "antd";
import {useEffect } from "react";
import BMReadOnly from "../BMReadOnly";

export interface NotificationEventFormValues  {
  id: string;
  name?: string;
  templateId: string;
  disabled?: boolean;
  recipients: string[]
}

export const NotificationEventForm = (props:FormProps & {
}) =>  {

  const {...formProps} = props;

  useEffect(() => {
    props.form?.resetFields();
  }, [props.initialValues] );

  return <Form 
    {...formProps}
    layout="vertical"
  >
    <Form.Item name="id" 
      label="Id"

    >
        <BMReadOnly readOnly={true}>
            <Input />
        </BMReadOnly>
    </Form.Item>

    <Form.Item name="name"
               label="Name"
    >
      <Input />
    </Form.Item>

    <Form.Item name="templateId"
               label="Template Id"
               rules={[{
                 required: true,
                 message: `This is the sendgrid template id.`,
               }]}
    >
      <Input />
    </Form.Item>

    <Form.Item name="recipients"
               label="Recipients"
               rules={[{
                   required: true,
                   message: `At least one recipient must be specified`,
               }]}
    >
      <Select
          mode="tags"
          allowClear
          options={[
              { value: 'SalesDeskEmail'},
              { value: 'SalesManagerEmail'},
              { value: 'SalesDirectorEmail'},
              { value: 'SalesEmail'},
              { value: 'EngineeringEmail'},
              { value: 'ReleaseEngineeringEmail'},
              { value: 'OrderSubmittedEmail'},
              { value: 'ExcoEmail'},
              { value: 'ProcurementEmail'},
              { value: 'SalesConcessionNotificationEmail'},
              { value: 'FinanceEmail'},
              { value: 'ProductionLeadEmail'},
              { value: 'SalesSupportEmail'},
              { value: 'OrderSupportEmail'},
              { value: 'ShipmentStatusUpdateEmail'},
              { value: 'SalesTeam'},
              { value: 'SalesTeamSupport'},
              { value: 'SalesTeamEngineers'},
              { value: 'SalesTeamViewers'},
              { value: 'QuoteOwner'},
              { value: 'Approvers'}
          ]}
      />
    </Form.Item>

    <Form.Item
        valuePropName="checked"
        label="Disabled"
        name="disabled"
    >
      <Checkbox />
    </Form.Item>



  </Form>


}


export default NotificationEventForm;
