import {Badge, Button, Result, notification, Form, Modal } from "antd";
import Table from "antd/es/table";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {CategoryIdCustomOptionIdMap, CustomOptionType} from "../../api/models";
import {ConfiguratorContext } from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import Utils from "../../util/util";
import {useAsyncState} from "../../hook/useAsyncState";
import { CheckOutlined } from "@ant-design/icons";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../Wizard";
import useCustomOptionWizardSteps from "../useCustomOptionWizardSteps";
import { useForm } from "antd/es/form/Form";
import { useQuoteContext } from "../../contexts/QuoteContext";
import useCustomOptions from "../../swr/useCustomOptions";
import useQuote from "../../swr/useQuote";

const CustomOptionButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  selectedCustomOptions:CategoryIdCustomOptionIdMap | undefined
  onChange?: (lst:CustomOptionType[] | undefined) => void
}) => {

  const {selectedCustomOptions, onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const { quote, selectedOptions } = useQuoteContext();

  const { isReadOnly } = Utils.getQuoteState(configurator, quote);

  //note: selections is required for pricing
  const customOptions = useCustomOptions({
    quoteRevisionId: quote?.displayRevisionId,
    selections: Object.values(selectedOptions || {}).flat()
  })

  const [selectedRowLst, setSelectedRowLst] = useState<CustomOptionType[] | undefined>();
  const [focusCustomOption, focusCustomOptionAsync] = useAsyncState<CustomOptionType>();

  const [editCustomOptionForm] = useForm();

  const [activeKey, setActiveKey] = useState<React.Key>();
  const [wizardInstance, setWizardInstance] = useState<WizardInstance>();

  const [editCustomOptionActiveKey, setEditCustomOptionActiveKey] = useState<React.Key>();
  const [editCustomOptionWizardInstance, setEditCustomOptionWizardInstance] = useState<WizardInstance>();

  const handleEditCustomOptionCancel = () => {
    wizardInstance?.nextStep();
  }

  const handleEditCustomOptionChange = async (_co:CustomOptionType | undefined) => {
    const lst = await customOptions.mutate();
    wizardInstance?.nextStep();
    props.onChange?.(lst);
  }

  const {
    initialValues:editCustomOptionInitialValues, 
    handleValuesChange:editCustomOptionValuesChange, 
    steps:editCustomOptionSteps, 
    hasMetadataPermissions:editCustomOptionHasMetadataPermissions
  } = useCustomOptionWizardSteps( {
    ...props,
    isOpen,
    value: focusCustomOption,
    onCancel:handleEditCustomOptionCancel,
    onChange:handleEditCustomOptionChange,
    form:editCustomOptionForm,
  })

  const handleEditCustomOptionStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setEditCustomOptionActiveKey(step?.key);
    setEditCustomOptionWizardInstance(instance);
  };


  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    setWizardInstance(instance);
  };

  const handleNew = (nav:WizardInstance | undefined) => {
    setSelectedRowLst([]);
    focusCustomOptionAsync.setInit();
    editCustomOptionForm.resetFields();

    editCustomOptionWizardInstance?.resetSteps();

    nav?.nextStep();
  }

  const handleEdit = (nav:WizardInstance | undefined, co:CustomOptionType) => {
    setSelectedRowLst([]);
    focusCustomOptionAsync.setDone(co);

    editCustomOptionWizardInstance?.resetSteps();

    nav?.nextStep();
  }

  const handleDelete = (nav:WizardInstance | undefined) => {
    focusCustomOptionAsync.setInit();
    nav?.nextStep();
  }

  const handleConfirmDelete = async (nav:WizardInstance | undefined) => {
    await Promise.all( selectedRowLst?.map( co => deleteCustomOption(co.id as number) ) || []);
    await customOptions.mutate();
    props.onChange?.(selectedRowLst)
    nav?.resetSteps();
  }

  const handleCancel = () => {
    setIsOpen(false);
  }


  const deleteCustomOption = async (customOptionId:number) : Promise<boolean> => {

    try {
      await configurator.api.deleteCustomOption(customOptionId)
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete custom option. " + errorMsg;
      notification.error( { message: msg });
    }

    return false;
  }

  const handleSelectAll = (_selected:boolean, selectedRows:CustomOptionType[]) => {
    if (isReadOnly ) return;

    setSelectedRowLst( selectedRows );
  }

  const handleSelectRow = (record:CustomOptionType) => {
    if (isReadOnly ) return;

    //toggle previously selected
    const updated = selectedRowLst?.filter( ae => ae.id !== record.id );
    if ( updated?.length === selectedRowLst?.length  ) {
      setSelectedRowLst( (updated || []).concat(record) );
      return;
    }

    setSelectedRowLst(updated);
  }

  const hideDeleteStep = !selectedRowLst?.length;
  const hideEditStep = !!selectedRowLst?.length;

  //hide border when disabled
  const btnStyle = isReadOnly
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};


  const activeEditCustomOptionStep = editCustomOptionSteps?.find( s => s.key === editCustomOptionActiveKey );
  const editCustomOptionTitle = editCustomOptionHasMetadataPermissions === false ? getWizardTitle( editCustomOptionWizardInstance, activeEditCustomOptionStep) : undefined;
  const editCustomOptionFooter = getWizardFooter( editCustomOptionWizardInstance, activeEditCustomOptionStep);


  const steps:WizardStep[] = [
    {
      key: 1,
      title: "Custom Options",
      body: (nav) => 
        <div key="customOptionSelection">
          <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
            <Button type="primary" onClick={() => handleNew(nav)} disabled={isReadOnly} >New</Button>
            <Button onClick={() => handleDelete(nav)} danger disabled={!selectedRowLst?.length}>Delete</Button>
          </div>

          <style>
            {`
              .dialog-customOptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
              .dialog-customOptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
              /* add error border to table */
              .ant-form-item-has-error .dialog-customOptionLst .ant-table-content {
              border: solid 1px #ff4d4f;
              border-radius: 15px;
              }
            `}
          </style>
          <Table 
            pagination={{
              hideOnSinglePage:true,
              pageSize: 5,
            }}
            className="dialog-customOptionLst"
            columns={ [ 
              {
                title: "Custom Option",
                render: (co:CustomOptionType) =>
                  <BMButton type="text"
                  onClick={() => handleEdit(nav, co)}
                  className="ghostBmButton"
                  disabled={isReadOnly}
                  style={{padding:0 }}
                ><span style={{...btnStyle, whiteSpace:"pre-wrap"}}>{co.content}</span></BMButton> 
              },
              {
                title: "Category",
                render: (co:CustomOptionType) => Utils.stripSortingPrefix(co.category?.name)
              },
              {
                title: "Price",
                render: (co:CustomOptionType) => Utils.formatMoney(co.price, "")
              },
              {
                title: "Selected",
                render: (co:CustomOptionType) => co.included ? <CheckOutlined /> : undefined
              },
            ]}
            rowKey="id"
            size="small"
            loading={customOptions?.isLoading}
            dataSource={customOptions.data}
            rowSelection={{
              type: "checkbox",
              onSelect: handleSelectRow,
              onSelectAll: handleSelectAll,
              selectedRowKeys: (selectedRowLst?.map( ae => ae.id ) || [] ) as number[],
              getCheckboxProps: (_record) => {
                return { 
                  disabled: isReadOnly
                };
              }
            }}
          />
          <div style={{fontSize: "smaller", fontStyle:"italic", textAlign: "center"}}>note: there is an additional 25% markup on custom options in addition to standard margin and markup.</div>
        </div>,
      footer:() => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
      </div>
    },
    {
      key: 2,
      hidden: hideEditStep,
      body: () => 
        <Wizard 
          key="editCustomOptionWizard"
          steps={editCustomOptionSteps} 
          onStep={handleEditCustomOptionStep}
          showSteps={editCustomOptionHasMetadataPermissions}
          viewHeight="500px"
        />,
      title: () => editCustomOptionTitle,
      footer: () => editCustomOptionFooter 
    },
    {
      key: 3,
      hidden: hideDeleteStep,
      body: () =>  
        <Result
          key="deleteWarningStep"
          status="warning"
          title="The following custom options will be deleted:"
          subTitle={<Table 
            className="dialog-customOptionLst"
            pagination={{hideOnSinglePage:true}}
            columns={
              [ {
                title: "Custom Option",
                render: (co:CustomOptionType) =>co.content
              },
                {
                  title: "Category",
                  render: (co:CustomOptionType) => Utils.stripSortingPrefix(co.category?.name)
                }]}
            rowKey="id"
            loading={customOptions?.isLoading}
            dataSource={selectedRowLst}
            size="small"
          />}
          style={{padding: "0"}}
        />,
      footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button type="primary" danger onClick={() => handleConfirmDelete(nav)}>Delete</Button>
        <Button onClick={() => nav?.prevStep()}>Back</Button>
      </div>
    }
  ];


  const bodyStyle = {paddingTop: "2rem"}
  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance, activeStep);
  const footer = getWizardFooter( wizardInstance, activeStep);

  const customOptionCnt = Object.values(quote?.selectedCustomOptions || {}).flat().length;
  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    >Custom Options
      <Badge count={customOptionCnt} size="default" style={{marginLeft: ".4rem"}} />
    </BMButton>
    <Form
      layout="vertical"
      form={editCustomOptionForm}
      initialValues={editCustomOptionInitialValues}
      onValuesChange={editCustomOptionValuesChange}
    >
      <Modal 
        open={isOpen}
        width={"50rem"}
        onCancel={handleCancel}
        title={title}
        footer={footer}
        styles={{body: bodyStyle}}
        destroyOnClose={true}
        afterOpenChange={(open) => {
          if (open) {
            wizardInstance?.resetSteps();
          }
        } }
      >
        <Wizard 
          showSteps={false}
          steps={steps}
          onStep={handleStep}
        />
      </Modal>
    </Form>

  </>;
}

export default CustomOptionButtonModal;
