import "../util/mobile-table.css";
import styles from "./QuoteFilterControls.module.css";
import { Input, Space, Checkbox, Form, Col, Row, Collapse, Select, DatePicker, Button, Tooltip, notification, Divider } from "antd";
import { useContext, useEffect } from "react";
import { ConfiguratorContext} from "../context";
import {DateFilterType, ORDER_TABLE, Permission, QuoteFilter, User} from "../api/models";
import { useForm } from "antd/es/form/Form";
import IncentiveProgramSelector from "../components/incentive_program_selector";
import DealerMultipleSelector from "../components/DealerMultipleSelector";
import AssemblySelector from "./assembly_selector";
import QuoteStatusSelector from "./quote_status_selector";
import QuoteShippingDestinationSelector from "./quote_shippingDestination_selector";
import QuoteEndCustomerSelector from "./quote_endCustomer_selector";
import QuoteEngineerSelector from "./QuoteEngineerSelector";
import _ from "lodash";
import UserMultiSelector, { UserMultiSelectorProps } from "./UserMultiSelector";
import { useIntl } from "react-intl";
import { useAsyncState } from "../hook/useAsyncState";
import dayjs from "dayjs";
import useDateTypeAndRangeHook from "../hook/useDateTypeAndRangeHook";
import SaveFilter from "./widgets/SaveFilter";
import ModelMultipleSelector from "./ModelMultipleSelector";
import { RightOutlined } from "@ant-design/icons";
import StatesSelector from "./StatesSelector";
import useUsers from "../swr/useUsers";

const { RangePicker } = DatePicker;

export const AdvancedSearchConfig = {
  ghost: true,
  expandIcon: ({ isActive = false }) => (
    <RightOutlined
      style={{ color: "#1677FF", marginLeft: "-10px" }}
      rotate={isActive ? 90 : 0}
    />
  ),
};

const QuoteFilterControls = (props: {
  filter?: QuoteFilter
  onFilterChange: (_values: Record<string, any>, filter:QuoteFilter) => void
  resetQueryParam?: () => void
  tableName?: string
}) => {
  const { filter, tableName } = props;

  const getDateRange = (filter: QuoteFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }

  const filterWithDateRange = {...filter, dateRange: getDateRange(filter)}

  const [filterForm] = useForm();
  const configurator = useContext(ConfiguratorContext);
  const showAdvancedPanel = filterWithDateRange?.filterAssemblies?.length ||
    filterWithDateRange?.quoteStatus?.length ||
    filterWithDateRange?.salespersons?.length ||
    filterWithDateRange?.engineers?.length ||
    filterWithDateRange?.ordersOnly ||
    filterWithDateRange?.hasCustomOptions ||
    filterWithDateRange?.incentivePrograms?.length ||
    filterWithDateRange?.includingCancelledOrder ||
    filterWithDateRange?.dateFilterType ||
    filterWithDateRange?.endCustomerId?.length ||
    filterWithDateRange?.shippingDestinationId?.length ||
    filterWithDateRange?.filterAssemblies?.length ||
    filterWithDateRange?.dateFilterStart ||
    filterWithDateRange?.models?.length ||
    filterWithDateRange?.hasDraftRevision ||
    filterWithDateRange?.stateRegistration?.length
  ;

  const includingCancelledOrder = Form.useWatch('includingCancelledOrder', filterForm);

  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(filterForm, "dateFilterType", "dateRange", props?.onFilterChange);

  useEffect(() => {
    if (includingCancelledOrder != undefined) {
      filterForm.setFieldValue('includingArchived', includingCancelledOrder)
    }
  }, [includingCancelledOrder]);

  const showClearFilter = !!(Object.values(filterForm.getFieldsValue()).filter(v => v).flat().length);

  const clearFilter = () => {
    let setObject = {...filterForm.getFieldsValue()};
    filterForm.resetFields();

    Object.keys(setObject).forEach(key => {
      setObject[key] = undefined;
    });

    filterForm.setFieldsValue(setObject);
    props.onFilterChange({}, setObject);
    props.resetQueryParam?.();
  }

  return <>
    <style>
      {`
        .clear-filter-button {
          margin-top: 3px;
          border: 1px solid red !important;
          color: red !important;
        }

        .clear-filter-button:hover {
          margin-top: 3px;
          border: 1px solid red !important;
          color: white !important;
          background-color: red !important;
        }

        .custom-form-item .ant-form-item {
          margin-bottom: 0px !important;
        }
      `}
    </style>
    <Form
      initialValues={filterWithDateRange}
      form={filterForm} 
      onValuesChange={filterChange}
      layout="vertical"
      className="custom-form-item"
    >
    <div className={styles["filter-controls"]}>
      <div className={styles["line1"]}>
        <Form.Item name="search" >
          <Input
            allowClear
            value={filter?.search}
            placeholder="Search by quote name, ID, and more."
          />
        </Form.Item>
      </div>
      {((!configurator.isDealerSales()) || configurator.hasPermission(Permission.DEALER_ADMIN_READ)) &&
          <div className={styles["line1"]} >
            <Form.Item name="dealerLst" >
              <DealerMultipleSelector data-testid="searchDealer" style={{ width: "100%" }} placeholder="Filter by Dealer" />
            </Form.Item>
          </div> }

      <div className={styles["line2"]}>
        <div>
            <Form.Item
              name="ordersOnly"
              valuePropName="checked"
              hidden={props.tableName === ORDER_TABLE}
            >
              <Checkbox>Orders Only</Checkbox>
            </Form.Item>
        </div>
        {tableName && <div>
          <SaveFilter tableName={tableName} size="small" style={{marginTop: "3px"}}/>
        </div>}
        <div>
          {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
        </div>
      </div>
      <Collapse
        style={{ width: '100%', marginTop: "-2rem" }} size="small"
        defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
        {...AdvancedSearchConfig}
        items={[{
          key: "advanced-search",
          label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
          forceRender: true,
          children: <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Row gutter={[32, 8]}>
              <Col xs={22} xxl={7}>
                <Form.Item
                  name="filterAssemblies"
                  label="BOM Filter"
                >
                  <AssemblySelector style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="quoteStatus"
                  label="Status"
                >
                  <QuoteStatusSelector style={{ width: '100%' }} includingCancelledOrder={filter?.includingCancelledOrder}/>
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="salespersons"
                  label="Salesperson"
                >
                  <QuoteSalesSelector  style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              {configurator.isInternalSales() &&
              <Col xs={22} xxl={7}>
                <Form.Item
                  name="engineers"
                  label="Engineer"
                >
                  <QuoteEngineerSelector  style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              }

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="shippingDestinationId"
                  label="Shipping Destination"
                >
                  <QuoteShippingDestinationSelector  style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="endCustomerId"
                  label="End Customer"
                >
                  <QuoteEndCustomerSelector  style={{ width: '100%' }} />
                </Form.Item>
              </Col>



              <Col xs={22} xxl={7}>
                <Form.Item
                  name="models"
                  label="Models"
                >
                  <ModelMultipleSelector  style={{ width: '100%' }}/>
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="incentivePrograms"
                  label="Incentive Programs"
                >
                  <IncentiveProgramSelector style={{ width: '100%' }} />
                </Form.Item>

              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="stateRegistration"
                  label="State Registration"
                >
                  <StatesSelector mode="multiple" />
                </Form.Item>
              </Col>

              <Col xs={8} xxl={7}>
                <Form.Item
                  name="dateFilterType"
                  label="Date Filter"
                  rules={[{ validator: validateDateFilterType }]}
                >
                  <Select
                    options={Object.keys(DateFilterType)
                      .filter(t => t !== _.capitalize(DateFilterType.Approval))
                      .map(e => ({ label: _.capitalize(e), value: e }))}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col xs={14} xxl={7}>
                <Form.Item
                  name="dateRange"
                  label="Date Filter Range"
                  rules={[{ validator: validateRangePicker }]}
                >
                  <RangePicker
                    name="range"
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>

            </Row>

              <Row gutter={12}>

                <Col xs={7} xxl={4}>
                  <Form.Item
                    name="includingArchived"
                    valuePropName="checked"
                  >
                    <Checkbox> Including archived quotes </Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={7} xxl={4}>
                  <Form.Item
                    name="hasDraftRevision"
                    valuePropName="checked"
                  >
                    <Checkbox>Has Draft Revision</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={7} xxl={4}>
                  <Form.Item
                    name="includingCancelledOrder"
                    valuePropName="checked"
                  >
                    <Checkbox>Including Canceled Order</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={7} xxl={4}>
                  <Form.Item
                      name="myQuotes"
                      valuePropName="checked"
                  >
                    <Checkbox>Only display my quotes</Checkbox>
                  </Form.Item>
                </Col>

              </Row>
            <Divider/>
          </Space>
        }]}
      />
    </div>
    </Form>
  </>
}

export default QuoteFilterControls;


interface UserSelectorProps extends Omit<UserMultiSelectorProps, "value" | "onChange"> {
  value?:string[]
  onChange?: (users: string[]) => void;
}
export const QuoteSalesSelector = (props: UserSelectorProps) => {

  const configurator = useContext(ConfiguratorContext);

  const userLst = useUsers().data;

  const intl = useIntl();

  const [bmSalespersonLst, bmSalespersonLstAsync] = useAsyncState<User[]>();

  useEffect(() => {
      loadBmSales();
  }, [userLst])

  const user = userLst?.find( u => u.id === configurator.userInfo?.id );
  const loadBmSales = async () => {
    if ( !user?.dealerId ) return;

    bmSalespersonLstAsync.setLoading()

    try {
        const resp = await configurator.api.fetchDealerSales()
        bmSalespersonLstAsync.setDone(resp.data)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load salesperson. " + errorMsg });
      bmSalespersonLstAsync.setFail(e.message);
    }
  }

  const salesLst = user?.dealerId ? bmSalespersonLst : userLst;

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( salesLst?.filter( s => valueSet.has(s.name) ) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={salesLst} 
    onChange={(lst) => props.onChange?.( lst?.map( s => s.name ) ) }
  />
};

