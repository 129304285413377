import axios, { CancelTokenSource } from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {ListTruckRequest} from '../api';
import {
  AXIOS_CANCEL_MSG,
  Page,
} from '../api/models';
import { ConfiguratorContext } from '../context';
import {TruckCalendarDto} from "../pages/production/master_schedule_view";

type MasterScheduleCalendarProps = ListTruckRequest;

export const useMasterScheduleCalendar = (props: MasterScheduleCalendarProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:MasterScheduleCalendarProps  ) : Promise<Page<TruckCalendarDto> | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.listMasterScheduleCalendar(props, cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
    'listMasterScheduleCalendar',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useMasterScheduleCalendar;

