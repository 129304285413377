import axios, {CancelTokenSource} from 'axios';
import { useContext, useRef } from 'react';
import useSWR from 'swr';
import {AXIOS_CANCEL_MSG, Quote,} from '../api/models';
import { ConfiguratorContext } from '../context';

interface QuoteProps {
  quoteId:string | undefined
  revision?: number | undefined
}

export const useQuote = (props: QuoteProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:QuoteProps  ) : Promise<Quote | undefined> => {

    const { quoteId, revision } = props;
    if (!quoteId) return;

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getQuoteByRevision(quoteId, revision, cancelSource?.token);
      cancelTokenSourceRef.current = undefined;
      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  };

  // Use SWR for data fetching
  return useSWR([
        'getQuoteByRevision',
        props
      ],
      ([_k, p]) => fetcher(p),
      {
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval:1
    }
  );
};


export default useQuote;

